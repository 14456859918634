import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {ErrValidateRule} from "../../../_domains/UITypes";
import {LazyLoadEvent} from "primeng/api";
import {DocumentUtilsPrivateService} from "../../doceditor/_services/document-utils-private.service";
import {BehaviorSubject, Observable} from "rxjs";
import {finalize} from "rxjs/operators";
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
  selector: 'dialog-doc-info',
  templateUrl: './dialog-doc-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogDocInfoComponent implements OnInit {
  @Input() idProject = '';
  @Input() idDoc = '';
  @Input() _rowDoc: any;
  _datamode = 0;
  rules: any[];
  selected: ErrValidateRule;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(private dataService: DocumentUtilsPrivateService,
              private messageService: AppMessageService) {
    this.rules = [];
  }

  ngOnInit() {
  }

  private loadData() {
    if (this.idDoc !== null && this.idDoc !== undefined && this.idDoc !== "") {
      this.loadingSubject.next(true);
      this.dataService.checkDoc(this.idDoc)
        .pipe(finalize(() => this.loadingSubject.next(false)))
        .subscribe({
          next: (data) => this.rules = data,
          error: (err) => this.messageService.showMessage(err)
        });
    }

  }

  refresh(iddoc: string, row: any, datamode: number) {
    if (iddoc && iddoc != "") {
      this.idDoc = iddoc;
    }
    this.selected = null;
    this._rowDoc = row;
    this._datamode = 0;
    this.rules = [];
    this.handlePageChange(
      {
        first: 0,
        filters: {}
      });
  }

  handlePageChange(event: LazyLoadEvent) {
    this.loadData();
  }

  handleRowSelect(event) {
    this.selected = event.data;
  }

  outputRowCellValue(data, len) {
    if (data && data.length) {
      return data.length > len ? (`${data.substring(0, len - 1)}...`) : data;
    }
    return data;
  }

  getRowClass(res: string): string {
    return 'rule-grid-' + res.replace('not set', 'not-set');
  }

  outputRowCellValueVal(dataval, len?) {
    let res = "";
    for (const key in dataval) {
      if (res !== "") res += (len === null || isNaN(len) || len < 1) ? "\n" : ", ";
      res += `${key}: ${dataval[key]}`;
    }
    if (len === null || isNaN(len) || len < 1) {
      return res;
    } else {
      return this.outputRowCellValue(res, len);
    }
  }

}
