import {Component, OnInit} from '@angular/core';
import {Pageable} from "../../../_domains/spring/pageable";
import {DataAdminService} from "../_services/data-admin.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {finalize} from "rxjs/operators";
import {AccessUtils, DocRole, UIUtils} from "../../../_utils/ui-utils";
import {SelectItem} from "primeng/api";

@Component({
  selector: 'app-admin-listUserProjects',
  templateUrl: './utl-user-projects.component.html',
  styleUrls: ['./utl-user-projects.component.css']
})
export class UtlUserProjectsComponent implements OnInit {
  private idUser: string;
  private _currentPage: number = 0;
  private _sorting: string = '&sort=hierarchyName,asc';
  private _loading: boolean = false;

  projects: Pageable = new Pageable();

  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService) {
    this.idUser = "";
  }

  ngOnInit(): void {
    this._loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  private loadData(filter: string) {
    if (this.idUser.length > 0) {
      this._loading = true;
      this.dataService.getAccessUserProjects(this.idUser, filter)
        .pipe(finalize(() => {
          this._loading = false;
        }))
        .subscribe(
          data => {
            for (const i in data.content) {
              data.content[i].isChanged = false;
              data.content[i].accessView = (data.content[i].acc & AccessUtils.VIEW) != 0;
              data.content[i].accessCreate = (data.content[i].acc & AccessUtils.CREATE) != 0;
              data.content[i].accessWrite = (data.content[i].acc & AccessUtils.WRITE) != 0;
              data.content[i].accessRemove = (data.content[i].acc & AccessUtils.REMOVE) != 0;
              data.content[i].accessReopen = (data.content[i].acc & AccessUtils.REOPEN) != 0;
              data.content[i].accessSubmit = (data.content[i].acc & AccessUtils.SUBMIT) != 0;
              data.content[i].accessApprove = (data.content[i].acc & AccessUtils.APPROVE) != 0;
              data.content[i].accessDocumentAdmin = (data.content[i].acc & AccessUtils.DOCUMENT_ADMIN) != 0;
              data.content[i].accessProjectAdmin = (data.content[i].acc & AccessUtils.PROJECT_ADMIN) != 0;
              data.content[i].roleUpload = (data.content[i].roles & DocRole.UPLOAD) !== 0;
              data.content[i].roleDownload = (data.content[i].roles & DocRole.DOWNLOAD) !== 0;
              data.content[i].rolePrint = (data.content[i].roles & DocRole.PRINT) !== 0;
              data.content[i].roleBulkUpload = (data.content[i].roles & DocRole.BULK_UPLOAD) !== 0;
              data.content[i].roleBulkReview = (data.content[i].roles & DocRole.BULK_REVIEW) !== 0;
            }
            this.projects = data;
          },
          error => {
            this.messageService.showMessage(error, '[loadHierarchies]');
          }
        );
    }
  }

  handlePageChange(event) {
    let filter: string = '', search: string = UIUtils.getFilterStr(event.filters);
    this._currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    filter = `page=${this._currentPage || '0'}`;
    filter += this._sorting;
    if (search.length > 0) {
      filter += `&search=${search}`;
    }

    this.loadData(filter);
  }

  addProject(iduser: string, project: any) {
    this.idUser = iduser;
    if (project == null) {
      this.refresh(this.idUser);
      return;
    }
    this.dataService.addAccess2Projects(this.idUser, project)
      .subscribe(data => {
          this.messageService.showSuccess("Access rule saved");
          this.refresh(this.idUser);
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  refresh(iduser: string) {
    this.idUser = iduser;

    let filter: string = '';
    this._currentPage = 0;
    filter = `page=${this._currentPage || '0'}`;
    filter += this._sorting;

    this.loadData(filter);
  }
}
