import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppMessageService} from "../../../_services/app-message.service";
import {StatUtilsService} from "../../statistic/_services/stat-utils.service";
import {GoogleChartInterface, GoogleChartType} from "ng2-google-charts";


//<!--          [(visible)]="showDlgCharts"-->

@Component({
  selector: 'app-data-geocharts',
  templateUrl: './geo-charts.component.html'
})
export class GeoChartsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() sColorSurveysNew: string = '#cbcbcb99';
  @Input() sBackgroundSurveysNew: string = '#f5f5f599';
  @Input() sColorSurveysDraft: string = '#ffc28299';
  @Input() sBackgroundSurveysDraft: string = '#fff4e899';
  @Input() sColorSurveysSent: string = '#6ba6e799';
  @Input() sBackgroundSurveysSent: string = '#c3dbf599';
  @Input() sColorFormsNew: string = '#a1a1a199';
  @Input() sBackgroundFormsNew: string = '#dcdcdc99';
  @Input() sColorFormsDraft: string = '#f8ab9b99';
  @Input() sBackgroundFormsDraft: string = '#feeeea99';
  @Input() enableInit: boolean = false;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @ViewChild('mygeochart', {static: false}) mygeochart;


  private resizeObserver: any;
  private _height: number = 400;
  private _width: number = 400;
  // private _googleApiKey='AIzaSyDk_9CmQqLT9mI4hzsevXSj2-gCK7qhhHQ';

  private _geoChart: GoogleChartInterface;
  // private _geoChartSettings: GoogleChartsSettings;


  data0: any;
  charttype: string = "line";
  public ischart: number = 1;
  public isgeo: number = 0;
  header: string = "";
  footer: string = "";

  geoLoaded = false;
  orangeGeo = ['#ffc107', '#fd7e14', '#dc3545'];

  private _idCharts: any;
  private _filter: string = "";
  private _datamode: number = 0;
  chart_options: any;

  constructor(private messageService: AppMessageService,
              private dataService: StatUtilsService) {
    // this._geoChartSettings= {'mapsApiKey': this._googleApiKey};

    this._geoChart = {
      chartType: GoogleChartType.GeoChart,
      //   Markers mode format
      //   The number of columns varies depending on the marker format used, as well as other optional columns.
      //
      //     Marker location [Required]
      //   The first  column is a specific string address (for example, "1600 Pennsylvania Ave").
      //
      // OR
      // The first two columns are numeric, where the first column is the latitude, and the second column is the longitude.
      //
      //   Note: Although we recommend ISO 3166 codes to be used for 'regions' mode, 'markers' mode works best with longer names for regions (like Germany, Panama, etc). This is because geocharts, when in 'markers' mode, uses Google Maps for geocoding the locations (converting a string location to a latitude and longitude). This may result in ambiguous locations not getting geocoded as you might expect; such as 'DE' standing for Germany or Delaware, or 'PA' standing for Panama or Pennsylvania.
      //
      //   Marker color [Number, Optional] The next column is an optional numeric column used to assign a color to this marker, based on the scale specified in the colorAxis.colors property. If this column is not present, all markers will be the same color. If the column is present, null values are not allowed. Values are scaled relative to each other, or absolutely to values specified in the colorAxis.values property.
      //   Marker size [Number, Optional] An optional numeric column used to assign the marker size, relative to the other marker sizes. If this column is not present, the value from the previous column will be used (or default size, if no color column is provided as well). If the column is present, null values are not allowed.
      dataTable: [
        ['Country', 'Count']
      ],
      options: {
         width: 1000,
        // height: 600,
        // 'auto' - Choose based on the format of the DataTable.
        // 'regions' - Color the regions on the geochart.
        // 'markers' - Place markers on the regions.
        // 'text' - Label the regions with text from the DataTable.
        displayMode: 'regions',
        colorAxis: {colors: this.orangeGeo},
        backgroundColor: '#ffffff',
        datalessRegionColor: '#ededed',
        defaultColor: '#6c757d',
      }
    };
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(v => {
        this._height = v.contentRect.height + 38;
        this._width = v.contentRect.width;
        this._geoChart.options.width = this._width;
        this._geoChart.options.height = this._height - 38;
      });
    });
    setTimeout(() => {
      if (document.getElementById('stat-docs-distribution')) {
        this.resizeObserver.observe(document.getElementById('stat-docs-distribution'));
      }
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver && document.getElementById('stat-docs-distribution')) {
      this.resizeObserver.unobserve(document.getElementById('stat-docs-distribution'));
    }
  }

  ngOnInit(): void {
    this.geoLoaded = false;
  }

  clear() {
    this.data0 = undefined;
    this.header = undefined;
    this.footer = undefined;
  }

  refresh(idCharts: any, criteria?: string, datamode?: number) {
    this._idCharts = idCharts;
    this._filter = criteria;
    this._datamode = !datamode ? 0 : datamode;

    this.chart_options = {color: "black"};

    this.getSurveysMonthlyStat();
  }


  private calculateGeoStat(data: any) {
    if (!(data.datasets && data.datasets.length > 0)) {
      console.log("calculateGeoStat no data");
      return;
    }
    this._geoChart.options.colorAxis = {colors: this.orangeGeo};
    this._geoChart.options.backgroundColor = '#ffffff';
    this._geoChart.options.defaultColor = '#6c757d';
    this._geoChart.options.datalessRegionColor = '#ededed';
    if ((data.datasets[0].data.length * 2) == data.labels.length) {
      this._geoChart.options.displayMode = 'markers';
    } else {
      this._geoChart.options.displayMode = 'regions';
      this._geoChart.options.enableRegionInteractivity = true;
    }


    for (let j = 0; j < data.datasets.length; j++) {
      let data11 = data.datasets[j];
      let lables11 = data.labels;
      if (data11.backgroundColor) {
        this._geoChart.options.backgroundColor = data11.backgroundColor;
      }

      if (data11.borderColor) {
        this._geoChart.options.defaultColor = data11.borderColor;
      }


      if (data11.datalessRegionColor) {
        this._geoChart.options.datalessRegionColor = data11.datalessRegionColor;
      }

      if (data11.geoChartColors) {
        this._geoChart.options.colorAxis = {colors: data11.geoChartColors.split(",")};
      }

      if (this._geoChart.options.displayMode === 'markers') { //markers mode
        this._geoChart.dataTable = [['Latitude', 'Longitude', data11.label]];
        for (let i = 0; i < data11.data.length; i++) {
          var latitude: number = +lables11[i * 2];
          var longtitude: number = +lables11[i * 2 + 1];
          this._geoChart.dataTable.push([latitude, longtitude, data11.data[i]]);
        }
      } else {
        //regions mode
        this._geoChart.dataTable = [['Country', data11.label]];
        for (let i = 0; i < data11.data.length; i++) {
          this._geoChart.dataTable.push([lables11[i], data11.data[i]]);
        }

      }
    }
    if (this.mygeochart?.wrapper) {
      this.mygeochart.draw();
    }


  }

  get geoChart(): GoogleChartInterface {
    return this._geoChart;
  }


  private getSurveysMonthlyStat() {
    this.data0 = {};
    this.header = "Please wait, loading data";
    this.charttype = 'line';
    // this._filter ="some filter";


    this.dataService.getListDocChartsStat(this._idCharts.cdoc + '.' + this._idCharts.id, this._filter, this._datamode)
      .subscribe(data => {
          this.geoLoaded = false;
          this.data0 = data;
          this.header = data.header;
          this.ischart = 1;
          this.isgeo = 0;
          if (data?.charttype && data.charttype.toLowerCase().startsWith("geo:")) {
            this.charttype = data.charttype.replace("geo:", "");
            this.ischart = 0;
            this.isgeo = 1;
            this.calculateGeoStat(data);
          }
          this.geoLoaded = true;

        },
        error => {
          this.messageService.showError(error);
        });
  }


}
