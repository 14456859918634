import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-thanks-of-submitted',
  template:
 `<p-panel header="Information" [style]="{'margin-bottom':'50px'}">
      <div style="text-align: center;">
	      <img src="assets/images/success.png" height="230" width="260"/>
        <h1>Thank You!</h1>
        <h3>{{'message.document.submit.received' | translate}}</h3>
      </div>
  </p-panel>`
})
export class ThanksOfSubmittedComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
