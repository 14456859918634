/**
 * Created by skrom on 13.01.17.
 */
import {Injectable} from "@angular/core";

function _window(): any {
    return window;
}

@Injectable()
export class Windowref {
    get nativeWindow(): any {
        return _window();
    }
}
