import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BuilderUtilsService} from "../_services/builder-utils.service";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
  selector: 'project-builder-templateRecord',
  templateUrl: './builder-template-record.component.html',
  providers: [ BuilderUtilsService ]
})
export class BuilderTemplateRecordComponent implements OnInit {
  @Input() idProject = '';
  @Output() templateSaved = new EventEmitter<string>();

  sourceParams: any[];
  targetParams: any[];
  builderForm: UntypedFormGroup;

  private idTemplate: string = "";

  constructor(private dataService: BuilderUtilsService,
              private messageService: AppMessageService,
              private fb: UntypedFormBuilder) {
    this.builderForm = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      descript: new UntypedFormControl(''),
      isShortList: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    this.idTemplate = "";
  }

  refresh(event: any, columns: any[]) {
    this.sourceParams = [];
    this.targetParams = [];
    this.builderForm.reset();
    this.idTemplate = event.templateId;

    const {
      name,
      descript,
      isShortList
    } = this.builderForm.controls;

    name.setValue(event.label || '');
    descript.setValue(event.descript || '');
    isShortList.setValue(event.isShortList || false);

    this.sourceParams = this.prepareSourceParams(columns);
    if (this.idTemplate.length !== 0) {
      this.initTemplateParams(this.idTemplate);
    }
  }

  saveTemplate(value: any) {
    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() +s4() + s4() + s4() + s4() + s4() + s4();
    }

    let params = [];
    this.targetParams.forEach(v => {
      params.push({cdoc: v.cdoc, field: v.field});
      if (v.editType === 'file') {
        params.push({cdoc: v.cdoc, field: v.field + 'Id'});
      }
    });

    value['id_hierarchy'] = this.idProject;
    value['params'] = params;
    value['isShortList'] = value['isShortList'] == true ? 1 : 0;

    this.idTemplate = this.idTemplate.length !== 0 ? this.idTemplate : guid();
    this.dataService.saveBuilderTemplate(this.idTemplate, value)
      .subscribe( data => {
        this.templateSaved.emit(data);
      }, error => {
        this.messageService.showMessage(error);
      });
  }

  private prepareSourceParams(data: any) {
    let ret = [];
    data.filter(v => v.editType !== 'file-meta')
      .forEach(rec => {
        rec["code"] = `${rec.cdoc}_${rec.field}`;
        ret.push({
          code: `${rec.cdoc}_${rec.field}`,
          label: rec.label,
          cdoc: rec.cdoc,
          field: rec.field,
          editType: rec.editType
        });
      });
    return ret;
  }

  private initTemplateParams(id) {
    // get template params
    this.dataService.getBuilderTemplateParams(id)
      .then(data => {
        for (let i in data) {
          const rdata = data[i];
          const code = (rdata.cdoc === 'SYS' ? `${rdata.field}` : `${rdata.cdoc}_${rdata.field}`);

          for (let k in this.sourceParams) {
            const prm = this.sourceParams[k];
            if (prm.code === code) {
              this.targetParams.push(prm);
              this.sourceParamsRemove(k);
              break;
            }
          }
        }
        this.targetParams = [...this.targetParams];
      });
  }

  private sourceParamsRemove(from) {
    this.sourceParams.splice(from, 1);
  }
}
