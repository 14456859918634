import {Injectable} from '@angular/core';
import {Cookie} from "ng2-cookies";

export class LogonUserOrganization {
  id: string; // ID organization
  name: string; // Organization name
  centre: string; // Country
  org_level: number; // Level of organization
}

/** */
export class LogonUser {
  id: string;
  email: string;
  username: number;
  fullname: string;
  typeAdmin: number;

}

export class OAuthToken {
  access_token: string;
  token_type: string;
  refresh_token: string;
  user: LogonUser;
}

@Injectable()
export class OAuthHelper {
  private static TOKEN_NAME = 'OAUTH_TOKEN';

  static get tokenObject(): OAuthToken {
    const token: OAuthToken = <OAuthToken>JSON.parse((localStorage.getItem(OAuthHelper.TOKEN_NAME)));
    return token || new OAuthToken();
  }

  static set tokenObject(token: OAuthToken) {
    if (token == null) {
      localStorage.removeItem(OAuthHelper.TOKEN_NAME);
    } else {
      localStorage.setItem(OAuthHelper.TOKEN_NAME, JSON.stringify(token));
    }
  }

  static get isLogin(): boolean {
    const token: OAuthToken = <OAuthToken>JSON.parse((localStorage.getItem(OAuthHelper.TOKEN_NAME))) || new OAuthToken();
    const val: string = Cookie.get("DCPS-AUTH");
    return token.token_type != null && val.length > 0;
  }
}

