import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AccessUtils} from '../../../_utils/ui-utils';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'project-workflow',
  templateUrl: './project-workflow.component.html',
  styleUrls: ['./project-workflow.component.css']
})
export class ProjectWorkflowComponent {
  @Input() idProject = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  private prevOpenedValue: any;
  private prevClosedValue: any[];

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      type: [2, Validators.required],
      opened: fb.group({
        view: true,
        edit: null,
        reopen: null,
        del: null,
        submit: null,
      }),
      closed: fb.array([])
    }, {validators: [this.fullFormValidator]});
    for (let i = 0; i < 3; i++) {
      const closedItem = fb.group({
        checked: true,
        view: true,
        create: null,
        edit: null,
        reopen: null,
        del: null,
        submit: null,
      });
      closedItem.get('checked').valueChanges.subscribe(v => {
        const {view, create, edit, reopen, del, submit} = closedItem.controls;
        const controls = [view, create, edit, reopen, del, submit];
        if (v) {
          controls.forEach(c => c.enable({emitEvent: false}));
        } else {
          controls.forEach(c => {
            c.disable({emitEvent: false});
            c.setValue(null, {emitEvent: false});
          });
        }
      });
      this.closed.push(closedItem);
    }
    this.prevOpenedValue = this.form.get('opened').getRawValue();
    this.prevClosedValue = this.form.get('closed').getRawValue();
    this.form.get('type').valueChanges.subscribe(v => {
      if (v === 2) {
        // opened project
        this.prevClosedValue = this.form.get('closed').getRawValue();
        this.form.get('closed').reset({}, {emitEvent: false});
        this.form.get('opened').reset(this.prevOpenedValue, {emitEvent: false});
        const controls = Object.values((this.form.get('opened') as FormGroup).controls);
        controls.filter(c => c.dirty).forEach(c => {
          c.setErrors(null);
        });
      } else {
        // closed project
        this.prevOpenedValue = this.form.get('opened').getRawValue();
        this.form.get('opened').reset({}, {emitEvent: false});
        const closed = this.closed;
        closed.reset(this.prevClosedValue, {emitEvent: false});
      }
    });
    this.form.valueChanges.subscribe(v => this.valueChange.emit(this.form.valid ? this.convertOut(v) : null));
  }

  @Input()
  set value(val: any) {
    if (val) {
      this.form.reset(val);
    } else {
      this.form.get('opened').reset(this.prevOpenedValue);
    }
  }

  fullFormValidator(form: FormGroup) {
    const formVal = form.value;
    if (formVal.type === 2) {
      // opened project
      const controls = Object.values((form.get('opened') as FormGroup).controls);
      if (controls.every(c => !c.value)) {
        controls.forEach(c => {
          c.markAsDirty();
          c.setErrors({required: true});
        });
      } else {
        controls.filter(c => c.dirty && c.errors).forEach(c => c.setErrors(null));
      }
    } else {
      // closed project
      const closed = form.controls['closed'] as FormArray;
      if (closed.controls.every(c => !c.value.checked)) {
        closed.controls.forEach(c => {
          c.get('checked').markAsDirty();
          c.get('checked').setErrors({required: true});
        });
      } else {
        closed.controls.filter(c => c.get('checked').dirty && c.get('checked').errors)
          .forEach(c => c.get('checked').setErrors(null));
        closed.controls.filter(c => c.get('checked'))
          .forEach((c: FormGroup) => {
            const levelControls = Object.entries(c.controls).filter(v => v[0] !== 'checked').map(v => v[1]);
            if (levelControls.every(c => !c.value)) {
              levelControls.forEach(c => {
                c.markAsDirty();
                c.setErrors({required: true});
              });
            } else {
              levelControls.filter(c => c.dirty && c.errors).forEach(c => c.setErrors(null));
            }
          });
      }
    }
    return null;
  }

  private convertOut(formVal: any): any {
    const res = {type: formVal.type, data: {}};
    if (formVal.type == 2) {
      res.data = {lvl0Acc: this.getAccBitMask(formVal.opened), lvl1Acc: 0, lvl2Acc: 0};
    } else {
      for (const i in formVal.closed) {
        const row = formVal.closed[i];
        res.data['lvl' + i + 'Acc'] = row.checked ? this.getAccBitMask(row) : 0;
      }
    }
    return res;
  }

  private getAccBitMask(val: any): number {
    return (val.view && AccessUtils.VIEW || 0)
      + (val.create && AccessUtils.CREATE || 0)
      + (val.edit && AccessUtils.WRITE || 0)
      + (val.reopen && AccessUtils.REOPEN || 0)
      + (val.del && AccessUtils.REMOVE || 0)
      + (val.submit && AccessUtils.SUBMIT || 0);
  }

  get closed(): FormArray {
    return this.form.controls['closed'] as FormArray;
  }
}
