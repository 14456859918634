import {ModuleWithProviders, NgModule, Pipe, PipeTransform} from "@angular/core";
import {BuilderUtils} from "../_utils/builder-utils";

@Pipe({
  name: 'trim'
})
export class TrimLengthPipe implements PipeTransform {
  transform(item: string, length: number): string {
    return typeof item === 'string' && item?.length > length ? `${item.substring(0, 40)}...` : item;
  }
}

@Pipe({
  name: 'removeHtml'
})
export class RemoveHtmlPipe implements PipeTransform {
  transform(item: string): string {
    return BuilderUtils.removeHtmlTags(item);
  }
}

@NgModule({
  declarations: [
    TrimLengthPipe,
    RemoveHtmlPipe
  ],
  exports: [
    TrimLengthPipe,
    RemoveHtmlPipe
  ]
})
export class CustomPipesModule {
  static forRoot(): ModuleWithProviders<CustomPipesModule> {
    return {
      ngModule: CustomPipesModule,
      providers: [
        TrimLengthPipe,
        RemoveHtmlPipe
      ]
    };
  }
}
