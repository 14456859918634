<div style="padding: 4px;">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton icon="pi pi-plus" type="button"
              (click)="create()" pTooltip="Add new organization"></button>
      <button pButton type="button" icon="pi pi-pencil"
              [disabled]="!selectedOrg"
              (click)="open(selectedOrg.data)" pTooltip="Edit hte organization"></button>
      <button pButton icon="pi pi-trash" type="button"
              [disabled]="!selectedOrg"
              (click)="delete()" pTooltip="Remove the oragnization"></button>
    </div>
  </p-toolbar>

  <p-treeTable [value]="orgs"
               selectionMode="single" [(selection)]="selectedOrg"
               [loading]="loading$ | async"
               [scrollable]="true" [scrollHeight]="'calc(100vh - 370px)'">
    <ng-template pTemplate="caption">
      The list of organizations
    </ng-template>
    <ng-template pTemplate="colgroup" *ngIf="isMobileResolution == false">
      <colgroup>
        <col>
        <col style="width:10rem;">
        <col style="width:14rem;">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Level</th>
        <th>Centre</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr [ttSelectableRow]="rowNode" (dblclick)="handleDblClickRow($event, rowData);">
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          {{rowData.name}}
        </td>
        <td>{{(rowData.orgLevel === 0 ? 'Level 1' : (rowData.orgLevel === 1 ? 'Level 2' : (rowData.orgLevel === 2 ? 'Level 3' : '?')))}}</td>
        <td>{{rowData.centreName}}</td>
      </tr>
    </ng-template>
  </p-treeTable>

  <p-dialog header="Organization record data" [(visible)]="showDlgOrgRecord"
            [modal]="true" [style]="{ minWidth: '800px' }"
            [maximizable]="false">
    <form [formGroup]="orgForm" (ngSubmit)="save(orgForm.getRawValue())">
      <div class="p-fluid">
        <div class="row form-group">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Name</div>
          <div class="col-8">
              <textarea pInputTextarea formControlName="name" placeholder="Required"
                        rows="4"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="orgForm.get('name')"></app-show-error>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-4">Level</div>
          <div class="col-8">
            <p-dropdown [options]="items" formControlName="orgLevel"></p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="orgForm.get('orgLevel')"></app-show-error>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-4">Parent organization</div>
          <div class="col-8">
            <p-autoComplete [suggestions]="filteredOrgs"
                            (completeMethod)="handleFilterOrgs($event)"
                            field="name"
                            [minLength]="1"
                            formControlName="org" [dropdown]="true"
                            [forceSelection]="true"
                            appendTo="body"></p-autoComplete>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">Country</div>
          <div class="col-8">
            <p-autoComplete [suggestions]="filteredStates"
                            (completeMethod)="handleFilterStates($event)"
                            field="name"
                            [minLength]="1"
                            formControlName="state" [dropdown]="true"
                            appendTo="body"></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row float-right">
        <div class="col-12">
          <button pButton icon="pi pi-save" type="submit" label="Save"
                  [disabled]="saveButtonDisabled"></button>
          <button pButton type="button" icon="pi pi-times"
                  [disabled]="cancelButtonDisabled"
                  (click)="showDlgOrgRecord = false" label="Close"></button>
        </div>
      </div>
    </form>
  </p-dialog>
</div>
