import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/_services/config.service';
import {AppMessageService} from "../../../_services/app-message.service";

@Injectable()
export class ExportUtilsService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) { }

  exportDocument(iddoc: string, prefix?: string) {
    this.http.get(`${this.configService.api.export}/${iddoc}`, {
          responseType: 'blob'
        })
        .subscribe(
          data => {
            FileSaver.saveAs(data, (prefix || 'DOC') + '_' + iddoc + '.zip');
          },
          error => {
            this.messageService.showMessage(error, '[exportDocument]');
          });
  }
}
