import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProxySurveyComponent} from './proxy-survey/proxy.survey.component';
import {RouterModule, Routes} from '@angular/router';
import {ProxyTokenComponent} from './proxy-survey/proxy-token.component';
import {ErrorComponent} from '../core/error/error.component';
import {PanelModule} from 'primeng/panel';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {FileUploadModule} from 'primeng/fileupload';

const routes: Routes = [
  {path: 'survey', component: ProxySurveyComponent},
  {path: 'token', component: ProxyTokenComponent},
  {path: 'error', component: ErrorComponent}
];

@NgModule({
  declarations: [
    ProxySurveyComponent,
    ProxyTokenComponent,
  ],
  exports: [
    ProxySurveyComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PanelModule,
    DataViewModule,
    OverlayPanelModule,
    DialogModule,
    FileUploadModule
  ]
})
export class PublicModule {
}
