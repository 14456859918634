import {Injectable} from '@angular/core';
import {ConfigService} from 'src/app/_services/config.service';
import {HttpClient} from '@angular/common/http';
import {AppMessageService} from "../../../_services/app-message.service";

@Injectable()
export class ViewDictPrivateService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) {
  }

  getStateRec(code: string) {
    return this.http.get<any>(`${this.configService.api.dicts.public.states}/${code}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  // getHydrationFactor(sex: number, age: number) {
  //   return this.http.get<any>(`${this.configService.api.dicts.tbw}/get-hf/${sex}/${age}`)
  //     .toPromise()
  //     .catch(err => {
  //       this.messageService.showMessage(err);
  //     });
  // }

  getDictData(dictName: string, valueField: string, parentDictName: string, parentValueField: string, parentValue: string) {
    return this.http.get<any>(`${this.configService.api.dicts.public.tables}?name=${dictName}&valueField=${valueField}` +
      `&parentDictName=${parentDictName}&parentValueField=${parentValueField}&parentValue=${parentValue}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }
}

@Injectable()
export class ViewDictPublicService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) {
  }

  getStateRec(code: string) {
    return this.http.get<any>(`${this.configService.api.dicts.public.states}/${code}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  // getHydrationFactor(sex: number, age: number) {
  //   return this.http.get<any>(`${this.configService.api.dicts.tbw}/get-hf/${sex}/${age}`)
  //     .toPromise()
  //     .catch(err => {
  //       this.messageService.showMessage(err);
  //     });
  // }

  getDictData(dictName: string, valueField: string, parentDictName: string, parentValueField: string, parentValue: string) {
    return this.http.get<any>(`${this.configService.api.dicts.public.tables}?name=${dictName}&valueField=${valueField}` +
      `&parentDictName=${parentDictName}&parentValueField=${parentValueField}&parentValue=${parentValue}`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }
}
