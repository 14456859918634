import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SelectItem} from 'primeng/api';
import {DocumentUtilsService} from '../_services/document-utils.service';

import {AuthService} from '../../../_services/auth.service';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {ProjectDocumentsComponent} from '../project-documents/project-documents.component';
import {ProjectDocumentParamsComponent} from '../project-document-params/project-document-params.component';
import {ProjectDocumentRulesComponent} from '../project-document-rules/project-document-rules.component';
import {AppMessageService} from '../../../_services/app-message.service';
import {ProjectSummaryComponent} from '../project-summary/project-summary.component';
import {ProjectUsersComponent} from '../project-users/project-users.component';


@Component({
  selector: 'project-start',
  templateUrl: './project-start.component.html'
})
export class ProjectStartComponent implements OnInit {
  @ViewChild('projectDataCmp', {static: false}) projectDataCmp;
  @ViewChild(ProjectSummaryComponent, {static: false}) projectSummaryCmp;
  @ViewChild(ProjectDocumentsComponent, {static: false}) projectTemplatesCmp;
  @ViewChild(ProjectDocumentParamsComponent, {static: false}) projectTemplateParamsCmp;
  @ViewChild(ProjectDocumentRulesComponent, {static: false}) projectTemplateRulesCmp;
  @ViewChild(ProjectUsersComponent, {static: false}) projectUsersCmp;

  index = 0;
  itemsTemplates: SelectItem[] = [];
  itemsReports: SelectItem[] = [];
  itemsUploads: SelectItem[] = [];
  itemsStagings: SelectItem[] = [];
  idTemplate = '';
  idReport = '';
  idUpload = '';
  idStaging = '';
  docCharts = [];
  docChartsBI = [];
  project: any = null;

  private access: any[];
  private _uiSettings: any[];
  private documentAdmin: boolean;
  hasBulkUploadRole: boolean;
  hasBulkReviewRole: boolean;
  hasFileUploadRole!: boolean;
  hasFileViewRole!: boolean;
  hasApproveRole: boolean = false;
  summaryTabHidden = false;
  statisticsTabHidden = false;
  statisticsTabText: string;

  refreshOnLoadTab!: string;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private dataService: DocumentUtilsService,
              private projectService: ProjectUtilsService,
              private messageService: AppMessageService) {
  }


  ngOnInit() {
    this.hasApproveRole = false;
    this.activatedRoute.params.subscribe(params => {
      const idProject = params['id'];
      this.index = 0;
      this.projectService.getAdmHierarchy(idProject)
        .subscribe(data => {
          const summaryTab = data?.tabs?.find(v => v.name === 'summary');
          const statisticsTab = data?.tabs?.find(v => v.name === 'statistics');
          const summaryTabHidden = !(summaryTab?.show == undefined ? true : summaryTab.show);
          const statisticsTabHidden = !(statisticsTab?.show == undefined ? true : statisticsTab.show);
          this.statisticsTabText = data?.tabs?.find(v => v.name === 'summary')?.text;
          this.projectService.getCurrentUserProjectAccess(idProject)
            .subscribe(data1 => {
              this.hasApproveRole = false;
              this.access = data1;
              this.getListReports();
              // set document admin
              if (this.authService.user.typeAdmin == 1) {
                this.documentAdmin = true;
                this.hasApproveRole = true;
              } else if (this.access?.length) {
                this.documentAdmin = this.access.some(v => v.accessDocumentAdmin);
                this.hasBulkUploadRole = this.access.some(v => v.roleBulkUpload);
                this.hasBulkReviewRole = this.access.some(v => v.roleBulkReview);
                this.hasFileUploadRole = this.access.some(v => v.roleFileUpload);
                this.hasFileViewRole = this.access.some(v => v.roleFileView);
                this.hasApproveRole = this.hasApproveRole || this.access.some(v => v.accessApprove);
              }
              if (!this.isProjectAdmin) {
                this.summaryTabHidden = summaryTabHidden;
                this.statisticsTabHidden = statisticsTabHidden;
                if (this.summaryTabHidden) {
                  if (this.hasBulkUploadRole) {
                    this.refreshOnLoadTab = 'upload';
                  } else if (this.hasBulkReviewRole) {
                    this.refreshOnLoadTab = 'staging';
                  } else {
                    this.refreshOnLoadTab = 'data';
                  }
                }
              }
            });
          this.projectService.getCurrentUserProjectUiSettings(idProject)
            .subscribe(data1 => this._uiSettings = data1);
          this.project = data;
        }, error => {
          this.messageService.showMessage(error);
        });
    });
  }

  handleChangeForAdminUsers($event) {
    const offset1 = 0;
    const offset2 = this.itemsReports?.length ? 1 : 0;
    // is survey
    const offset3 = !this.isSurvey ? 1 : 0;
    switch ($event.index) {
      // Project summary
      case 0:
        if ((this.projectSummaryCmp && typeof (this.projectSummaryCmp.refresh) !== 'undefined')) {
          this.projectSummaryCmp.refreshStat();
        }
        break;
      // Project Data
      case 1 + offset1:
        if ((this.projectDataCmp && typeof (this.projectDataCmp.refresh) !== 'undefined')) {
          this.projectDataCmp.refresh(false);
        }
        break;
      // 2 + ... Dashboard

      // Project Documents
      case 3 + offset1 + offset2:
        if (this.projectTemplatesCmp && typeof (this.projectTemplatesCmp.refresh) !== 'undefined') {
          this.projectTemplatesCmp.refresh();
        }
        break;
      // Project users
      case 4 + offset1 + offset2 + offset3:
        if (this.projectUsersCmp && typeof (this.projectUsersCmp.refresh) !== 'undefined') {
          this.projectUsersCmp.refresh();
        }
        break;
    }
  }

  handleChangeForNoAdminUsers($event) {
    const firstIndex = this.summaryTabHidden ? 0 : 1;
    const docAdmin = this.isDocumentAdmin;
    const offset1 = 0;
    const offset2 = this.itemsReports?.length ? 1 : 0;
    const offset4 = -1;
    switch ($event.index) {
      // Project summary
      case 0:
        if ((this.projectSummaryCmp && typeof (this.projectSummaryCmp.refresh) !== 'undefined')) {
          this.projectSummaryCmp.refreshStat();
        }
        break;

      case firstIndex + offset1:
        if (this.projectDataCmp && typeof (this.projectDataCmp.refresh) !== 'undefined') {
          this.projectDataCmp.refresh(false);
        }
        break;

      // ... Dashboard

      // Project templates
      case (docAdmin ? (firstIndex + 4 + offset1 + offset2 + offset4) : -1):
        if (this.projectTemplatesCmp && typeof (this.projectTemplatesCmp.refresh) !== 'undefined') {
          this.projectTemplatesCmp.refresh();
        }
        break;
    }
  }

  onSaveDocuments(event: any) {
    this.projectService.getAdmHierarchy(this.project.id)
      .subscribe(data => {
        this.project = data;
      }, error => {
        this.messageService.showMessage(error);
      });
  }

  private getListTemplates(callback: () => any) {
    this.itemsTemplates = [];
    this.idTemplate = '';
    this.dataService.getListProjectDocs(this.idProject)
      .subscribe(data => {
          for (const i in data) {
            const rdata = data[i];
            if (rdata.mode >= 2) {
              continue;
            }
            this.itemsTemplates.push({value: rdata.cdoc, label: rdata.name});
          }

          if (this.itemsTemplates.length > 0) {
            this.idTemplate = this.itemsTemplates[0].value;
          }
          callback();
        },
        error => {

        });
  }

  private getListReports() {
    this.itemsReports = [];
    this.itemsUploads = [];
    this.itemsStagings = [];
    this.idReport = '';
    this.idUpload = '';
    this.idStaging = '';
    this.docCharts = [];
    this.docChartsBI = [];

    this.hasBulkUploadRole = false;
    this.hasBulkReviewRole = false;
    this.hasFileUploadRole = false;
    this.hasFileViewRole = false;
    const observer = {
      next: (data: any) => {
        for (const i in data) {
          let rdata = data[i];
          if (rdata.docCharts) {
            if (rdata.docCharts.substr(rdata.docCharts.length - 1) == ';') {
              rdata.docCharts = rdata.docCharts.substr(0, rdata.docCharts.length - 1);
            }
            if (rdata.mode === 2) {
              this.docChartsBI = rdata.docCharts.split(';');
            } else {
              this.docCharts = rdata.docCharts.split(';');
            }
          }
          if (rdata.mode === 2) { // report
            for (const j in this.access) {
              if (this.access[j].cdoc === data[i].cdoc) {
                if (this.access[j].accessCreate
                  || this.access[j].accessDocumentAdmin
                  || this.access[j].accessProjectAdmin
                  || this.access[j].accessView
                  || this.access[j].accessWrite
                  || this.access[j].fullAccess) {
                  this.itemsReports.push({value: rdata.cdoc, label: rdata.name});
                }
              }
            }
          } else if (rdata.mode === 3) { // upload
            if (this.authService.user.typeAdmin == 1) {
              this.hasBulkUploadRole = true;
              this.hasBulkReviewRole = true;
            }
            for (const j in this.access) {
              if (this.access[j].cdoc === data[i].cdoc) {
                if (this.access[j].accessCreate
                  || this.access[j].accessDocumentAdmin
                  || this.access[j].accessProjectAdmin
                  || this.access[j].accessView
                  || this.access[j].accessWrite
                  || this.access[j].fullAccess) {
                  this.itemsUploads.push({value: rdata.cdoc, label: rdata.name});
                  this.itemsStagings.push({value: rdata.cdoc, label: rdata.name});
                }
              }
            }
          }

        }
        if (this.itemsReports.length > 0) {
          this.idReport = this.itemsReports[0].value;
        }
        if (this.itemsUploads.length > 0) {
          this.idUpload = this.itemsUploads[0].value;
        }
        if (this.itemsStagings.length > 0) {
          this.idStaging = this.itemsStagings[0].value;
        }
      }
    }
    this.dataService.getListProjectDocs(this.idProject)
      .subscribe(observer);
  }


  get idProject() {
    return this.project && this.project.id ? this.project.id : '';
  }

  get grType() {
    return this.project && this.project.grType ? this.project.grType : 0;
  }

  get isSurvey() {
    return this.project?.grType === 2;
  }

  get isProjectAdmin() {
    if (this.authService.user.typeAdmin === 1) return true;
    if (this.access?.length) {
      return this.access[0].accessProjectAdmin;
    }
    return null;
  }

  get workflow() {
    return this.projectSummaryCmp?.data?.workflow;
  }

  get isDocumentAdmin() {
    return this.documentAdmin;
  }

  get uiSettings(): any[] {
    return this._uiSettings;
  }

  get includeMetadata(): number {
    return this.project?.includeMetadata ? 1 : 0;
  }

  get accessdoc(): any[] {
    return this.access;
  }
}
