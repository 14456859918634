import {Component, Input, OnInit} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {LazyLoadEvent, SelectItem} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UIUtils} from '../../../_utils/ui-utils';
import {DataAdminService} from '../_services/data-admin.service';
import {finalize} from 'rxjs/operators';
import {AppMessageService} from "../../../_services/app-message.service";
import {AuthService} from "../../../_services/auth.service";
import {LogonUser} from "../../../_services/oauth.helper";

@Component({
  selector: 'app-adminlog-public',
  templateUrl: './log-evt-public.component.html'
})
export class LogEvtPublicComponent implements OnInit {
  private _data: Pageable;
  private _loading = false;
  private _currentPage: number = 0;
  private _sorting: string;
  private _iddoc: string = '';
  private _filters: any = {};
  private _items: SelectItem[] = [];

  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService,
              private authService: AuthService) {
    this._data = new Pageable();
  }

  ngOnInit() {
    // Form typeItems
    this._items = [
      { label: 'All', value: '' },
      { label: 'Open', value: '0' },
      { label: 'Save', value: '1' },
      { label: 'Silent save', value: '2' },
      { label: 'Submit force', value: '3' },
      { label: 'Submit', value: '4' }
    ];
  }

  public refreshVars(iddoc: string) {
    this._data = new Pageable();
    this._iddoc = iddoc;
    this.loadData();
  }

  private loadData(filter?: string) {
    const _user: LogonUser = this.authService.user;
    const _typeAdmin: number = _user.typeAdmin;
      //
    if (_typeAdmin === 0) {
      this._loading = false;
      return;
    }
      //
    let _filter: string = filter && filter.length > 0 ? `?${filter}` : '',
        _search: string = UIUtils.getFilterStr(this._filters);
    if (this._iddoc.length > 0) {
        _search += (_search.length > 0 ? ',' : '') + `iddoc:${this._iddoc}`;
    }
    if (_search.length > 0) {
      _filter += (_filter.length > 0 ? '&' : '?') + `search=${_search}`;
    }
      //
    this._loading = true;
    this.dataService.getEvtPublic(_filter)
            .pipe(finalize(() => { this._loading = false; }))
            .subscribe(
                    data => {
                      this._data = data;
                    },
                    error => {
                      this.messageService.showMessage(error, 'Error receiving data');
                    }
            );
  }

  handleRowSelect(event:any) {
  }

  handlePageChange(event: LazyLoadEvent) {
    this._data.content = [];
    this._filters = event.filters;
    this._currentPage = ( event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;

    this.loadData('page=' + this._currentPage + this._sorting);
  }

  get isShowDocInformation(): boolean {
    return this._iddoc.length === 0;
  }

  get tableHeader(): string {
    if (this._iddoc.length > 0) {
      return `Events for the document "${this._iddoc}"`;
    }

    return 'Public events';
  }

  get data(): Pageable {
    return this._data;
  }

  get loading(): boolean {
    return this._loading;
  }

  get items(): SelectItem[] {
    return this._items;
  }
}
