import {Component, OnInit} from '@angular/core';
import {DataAdminService} from "../../_services/data-admin.service";
import {AppMessageService} from "../../../../_services/app-message.service";
import {AuthService} from "../../../../_services/auth.service";
import {Pageable} from "../../../../_domains/spring/pageable";
import {LazyLoadEvent, SelectItem} from "primeng/api";
import {LogonUser} from "../../../../_services/oauth.helper";
import {UIUtils} from "../../../../_utils/ui-utils";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {
  private _data: Pageable;
  private _loading = true;
  private _currentPage: number = 0;
  private _sorting: string;
  private _filters = {};
  private _items: SelectItem[];

  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService,
              private authService: AuthService) {
    this._data = new Pageable();
    this._items = [
      {label: 'All', value: ''},
      {label: 'Add', value: '0'},
      {label: 'Edit', value: '1'},
      {label: 'Upload', value: '2'},
      {label: 'Delete', value: '3'}
    ];
  }

  ngOnInit(): void {
  }

  private loadData(inFilter?: string) {
    const user: LogonUser = this.authService.user;
    const typeAdmin = user.typeAdmin;
    if (typeAdmin === 0) {
      this._loading = false;
      return;
    }
    let filter: string = inFilter?.length ? `?${inFilter}` : '',
      search: string = UIUtils.getFilterStr(this._filters);
    if (search?.length) {
      filter += (filter?.length ? '&' : '?') + `search=${search}`;
    }
    this._loading = true;
    const obserevr = {
      next: (data) => {
        this._data = data;
        this._data?.content
          .forEach(v => v.modeName = v.mode === 0 ? 'Add' : (v.mode === 1 ? 'Edit' : (v.mode === 2 ? 'Upload' : 'Delete')));
      },
      error: (err: any) => this.messageService.showMessage(err, 'Error receiving data')
    }
    this.dataService.getEvtTemplates(filter)
      .pipe(finalize(() => this._loading = false))
      .subscribe(obserevr);
  }

  handlePageChange(event: LazyLoadEvent) {
    this._data.content = [];
    this._filters = event.filters;
    this._currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;

    this.loadData('page=' + this._currentPage + this._sorting);
  }


  get data(): Pageable {
    return this._data;
  }

  get loading(): boolean {
    return this._loading;
  }

  get items(): SelectItem[] {
    return this._items;
  }
}
