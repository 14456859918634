<div style="padding: 4px;">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button *ngIf="uploadButtonVisible"
              pButton type="button" icon="pi pi-upload"
              (click)="beforeUpload()" [pTooltip]="'button.dict.upload' | translate"></button>
      <button *ngIf="downloadButtonVisible"
              pButton type="button" icon="pi pi-download"
              (click)="download()" [pTooltip]="'button.dict.download' | translate"></button>
      <button pButton type="button" icon="pi pi-refresh"
              (click)="refresh()" pTooltip="Refresh"></button>
    </div>
  </p-toolbar>

  <p-table #dt
           [value]="data.content"
           [totalRecords]="data.totalElements"
           [rows]="data.size"
           [lazy]="true"
           (onLazyLoad)="handlePageChange($event)"
           [loading]="loading"
           [paginator]="true"
           paginatorPosition="bottom"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 250px)'"
           sortField="dictionaryId" [sortOrder]="1"
           tableStyleClass="table-singleline">
    <ng-template pTemplate="header">
      <tr>
        <th class="w-7rem">DictionaryID</th>
        <th class="w-7rem">DictionaryGroup</th>
        <th class="w-15rem">VariableName</th>
        <th class="w-7rem">ItemID</th>
        <th class="w-15rem">ItemCode</th>
        <th class="w-15rem">Description</th>
        <th class="w-15rem">ItemGroupVariableName</th>
        <th class="w-7rem">ItemGroupID</th>
        <th class="w-15rem">ItemGroupCode</th>
        <th class="w-15rem">Comment</th>
      </tr>
      <tr>
        <th></th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.variableName"
                 (change)="dt.filter($event.target.value, 'variableName', 'contains')"/>
        </th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.itemCode"
                 (change)="dt.filter($event.target.value, 'itemCode', 'contains')"/>
        </th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.itemGroupVariableName"
                 (change)="dt.filter($event.target.value, 'itemGroupVariableName', 'contains')"/>
        </th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.itemGroupCode"
                 (change)="dt.filter($event.target.value, 'itemGroupCode', 'contains')"/>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td class="w-7rem">{{row.dictionaryId}}</td>
        <td class="w-7rem">{{row.dictionaryGroup}}</td>
        <td class="w-15rem">{{row.variableName}}</td>
        <td class="w-7rem">{{row.itemId}}</td>
        <td class="w-15rem">{{row.itemCode}}</td>
        <td class="w-15rem">{{row.description}}</td>
        <td class="w-15rem">{{row.itemGroupVariableName}}</td>
        <td class="w-7rem">{{row.itemGroupId}}</td>
        <td class="w-15rem">{{row.itemGroupCode}}</td>
        <td class="w-15rem">{{row.comment}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Upload" [(visible)]="uploadShow" [modal]="true" [style]="{width: '600px'}">
  <div>
    <p-fileUpload name="file[]" customUpload="true" (uploadHandler)="fileUploader($event)"></p-fileUpload>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-times" (click)="uploadShow=false" label="Close"></button>
  </ng-template>
</p-dialog>
