<ng-container *ngIf="isProjectAdmin === true">
  <!-- user - admin -->
  <p-tabView [(activeIndex)]="index" (onChange)="handleChangeForAdminUsers($event)">
    <p-tabPanel header="Summary">
      <project-summary [idProject]="idProject" [grType]="grType" [access]="isProjectAdmin"></project-summary>
    </p-tabPanel>

    <p-tabPanel header="Data">
      <project-data #projectDataCmp [idProject]="idProject" [uiSettings]="uiSettings"
                    [docCharts]="docCharts" [includeMetadata]="includeMetadata"></project-data>
    </p-tabPanel>

    <p-tabPanel header="Reports" *ngIf="itemsReports?.length">
      <ng-template pTemplate="content">
        <div style="margin-bottom: 5px;">
          <label>Template&nbsp;</label>
          <p-dropdown [options]="itemsReports"
                      [(ngModel)]="idReport"
                      [ngModelOptions]="{standalone: true}"
                      [disabled]="itemsReports?.length < 2"
                      [style]="{'width':'600px'}"></p-dropdown>
        </div>
        <app-stat-bi [idReport]="idReport" [accessdoc]="accessdoc" [docCharts]="docChartsBI"></app-stat-bi>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel header="Dashboard">
      <ng-template pTemplate="content">
        <app-stat-survey-answers [idProject]="idProject"></app-stat-survey-answers>

      </ng-template>
    </p-tabPanel>

    <p-tabPanel header="Registry">
      <project-documents [idProject]="idProject" [projectAdmin]="isProjectAdmin"
                         [grType]="grType"
                         (onSavedData)="onSaveDocuments($event)"></project-documents>
    </p-tabPanel>

    <p-tabPanel *ngIf="!isSurvey" header="Organizations" [cache]="false">
      <ng-template pTemplate="content">
        <project-organizations [idProject]="idProject"></project-organizations>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel header="Users">
      <ng-template pTemplate="content">
        <project-users [idProject]="idProject" [grType]="grType" [workflow]="workflow"></project-users>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</ng-container>

<!-- user - no admin -->
<ng-container *ngIf="isProjectAdmin === false">
  <p-tabView [(activeIndex)]="index" (onChange)="handleChangeForNoAdminUsers($event)">
    <p-tabPanel header="Summary" *ngIf="!summaryTabHidden">
      <project-summary [idProject]="idProject" [grType]="grType"></project-summary>
    </p-tabPanel>

    <p-tabPanel header="Data">
      <project-data #projectDataCmp [idProject]="idProject" [uiSettings]="uiSettings"
                    [loadOnInit]="refreshOnLoadTab === 'data'"
                    [docCharts]="docCharts" [includeMetadata]="includeMetadata"></project-data>
    </p-tabPanel>

    <p-tabPanel header="Reports" *ngIf="itemsReports?.length">
      <ng-template pTemplate="content">
        <div style="margin-bottom: 5px;">
          <label>Template&nbsp;</label>
          <p-dropdown [options]="itemsReports"
                      [(ngModel)]="idReport"
                      [ngModelOptions]="{standalone: true}"
                      [disabled]="itemsReports?.length < 2"
                      [style]="{'width':'600px'}"></p-dropdown>
        </div>
        <app-stat-bi [idReport]="idReport" [accessdoc]="accessdoc" [docCharts]="docChartsBI"></app-stat-bi>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel header="Dashboard">
      <ng-template pTemplate="content">
        <app-stat-survey-answers [idProject]="idProject"></app-stat-survey-answers>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel *ngIf="isDocumentAdmin" header="Registry">
      <project-documents [idProject]="idProject" [projectAdmin]="isProjectAdmin"
                         [grType]="grType"
                         (onSavedData)="onSaveDocuments($event)"></project-documents>
    </p-tabPanel>
  </p-tabView>
</ng-container>

<!-- Confirmation modal-->
<p-confirmDialog [baseZIndex]="10100"></p-confirmDialog>
