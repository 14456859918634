import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {TemplateUtilsPrivateService} from '../_services/template-utils.service';
import {DocumentEditorComponent} from '../document-editor/document-editor.component';
import {ConfigService} from '../../../_services/config.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {AuthService} from '../../../_services/auth.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-dialog-template-preview',
  templateUrl: './dialog-template-preview.component.html',
  styleUrls: ['../_css/documentEditor.css'],
  providers: [
    TemplateUtilsPrivateService
  ]
})
export class DialogTemplatePreviewComponent implements OnInit {
  @ViewChild(DocumentEditorComponent, {static: false}) editorCmp;

  processing: boolean = false;
  showDialog: boolean = false;
  popupShow: boolean = false;
  popupHeader: string = '';
  popupContent: string = '';
  popupType = 'info';

  private cdoc: string;

  constructor(private utlService: TemplateUtilsPrivateService,
              private configService: ConfigService,
              private messageService: AppMessageService,
              private authService: AuthService,
              private translate: TranslateService,
              private ngZone: NgZone) {
  }

  ngOnInit() {
  }

  onHide() {
    this.editorCmp.deattachCmp();
  }

  async refresh(cdoc: string) {
    this.processing = false;
    this.showDialog = true;
    this.cdoc = cdoc;
    let _self = this;
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
    $.extend($.jdm, {
      // isMobileDevice
      isMobileDevice: function () {
        return _self.configService.deviceType > 0;
      },
      // Save data
      save: function () {
      },
      // Submit data
      submit: function () {
      },
      // Confirm dialog
      confirm: function (title, message, clbYes, clbNo) {
      },
      // info_dialog
      info_dialog: function (title, message, type) {
        _self.ngZone.run(() => {
          switch (type) {
            case 'error':
              _self.messageService.showError(message, title);
              break;
            case 'info':
              _self.messageService.showSuccess(message, title);
              break;
            default:
              _self.messageService.showWarn(message, title);
              break;
          }
        });
      },
      // open-dialog
      open_dialog: function (title, content, type?: string) {
        _self.ngZone.run(() => {
          _self.popupHeader = title;
          _self.popupContent = content;
          _self.popupShow = true;
          _self.popupType = type || 'info';
        });
      },
      // get country record
      getCountryRecord: function (code, clb) {
      },
      showRichTextEditor: function (rowId, value) {
        console.log('showRichTextEditor');
      }
    });

    const callbackGetData = (success, failure): void => {
      setTimeout(function () {
        success({});
      }, 100);
    };
    const tmplVars = await this.utlService.getFormTemplateVars(cdoc);
    tmplVars.rules = [];
    const dctDoc = tmplVars.sprdoc;
    if (dctDoc.style) {
      $('.dialog-wrapper, .dialog-wrapper .p-toolbar').css(dctDoc.style);
    } else {
      $('.dialog-wrapper, .dialog-wrapper .p-toolbar').removeAttr('style');
    }
    this.editorCmp.refreshVars(
      null, // iddoc
      {cdoc: cdoc, docState: 0, editable: false, optsAcc: {}}, // the doc status
      tmplVars, // the template vars
      {
        id: this.authService.user.id || '?',
        email: this.authService.user.email || '?',
        username: this.authService.user.username || '?',
        fullname: this.authService.user.fullname || '?'
      }, // auth user record
      callbackGetData // callback
    );
  }

  print2pdf() {
    this.processing = true;
    const fileName = this.cdoc + '_' + moment().format('YYYYMMDD_HHmmss') + '.pdf';
    const callback = (success: boolean): void => {
      if (!success) {
        this.messageService.showError(this.translate.instant('message.document.print.error'));
      }
      this.processing = false;
    }
    this.editorCmp.print2pdf(fileName, callback);
  }

  get dialogStyle(): any {
    const width = window.innerWidth -
    window.innerWidth <= 1024 ? window.innerWidth - 100 : window.innerWidth * 0.8;
    return {height: `${window.innerHeight - 135}px`, width: `${width}px`}
  }

  get isDisabledBtnPdf(): boolean {
    return this.processing;
  }

}
