import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImportComponent} from './import-excel/import.component';
import {DialogModule} from 'primeng/dialog';
import {FileUploadModule} from 'primeng/fileupload';
import {TableModule} from 'primeng/table';
import {FormsModule} from '@angular/forms';
import {ImportUtilsService} from './_services/import-utils.service';
import {DocumentUtilsService} from '../projects/_services/document-utils.service';
import {ToolbarModule} from 'primeng/toolbar';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';
import {TabViewModule} from 'primeng/tabview';
import {BlockUIModule} from "primeng/blockui";

@NgModule({
  declarations: [
    ImportComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        DialogModule,
        FileUploadModule,
        ToolbarModule,
        TableModule,
        TabViewModule,
        DropdownModule,
        CheckboxModule,
        TooltipModule,
        BlockUIModule
    ],
  exports: [
    ImportComponent
  ],
  providers: [
    ImportUtilsService,
    DocumentUtilsService
  ]
})
export class ImportModule {
}
