import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../_services/config.service';
import {Observable} from 'rxjs';

@Injectable()
export class ImportUtilsService {
  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }

  uploadProjectFile(idHierarchy: string, file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<any>(`${this.configService.api.import}/${idHierarchy}`, formData)
  }

  getListMapFields(code: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.import}/${code}/map`);
  }

  setDocumentCode(code: string, cdoc: string): Observable<any> {
    return this.http.put<any>(`${this.configService.api.import}/${code}/map/${cdoc}`, null);
  }

  getListData(code: string, filter?: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.import}/${code}/data` + (filter || ''));
  }

  process(code: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.configService.api.import}/${code}/load`, data || {});
  }

  delete(code: string): Observable<any> {
    return this.http.delete<any>(`${this.configService.api.import}/${code}`);
  }

  setRowStatus(iddoc: string, status: number): Observable<any> {
    return this.http.put<any>(`${this.configService.api.import}/${iddoc}/set/${status}`, null);
  }

  bulkDataValidation(idHierarchy: string, queuecode: string, datamode:number, ids: string[]): Observable<any> {
    return this.http.post<any>(`${this.configService.api.import}/checkrule/${queuecode}/${idHierarchy}?datamode=${datamode}`, ids);
  }

}
