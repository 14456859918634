<div class="container">
  <div *ngIf="!failed">
    <div class="col-12 pb-3">
      <div class="logo-image logo-app"></div>
    </div>
    <h1 class="text-center">
      <p>IPNA</p>
    </h1>
    <div class="col-12 text-center">
      <h3>
        <p>Integrated Platform for Needs Assessment</p>
      </h3>
    </div>
    <div class="col-12 text-center p-3">
      <button pButton type="button" label="Confirm registration"
              [disabled]="confirmButtonDisabled"
              (click)="confirm()"></button>
    </div>
  </div>
  <div *ngIf="failed" class="row" style="min-height: 200px">
    <div class="col-12 text-center">
      <img src="assets/images/failure.png" height="210" width="210"/>
      <h3>{{ message }}</h3>
    </div>
  </div>
</div>
