import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatCountryVisitsComponent} from './stat-country-visits/stat-country-visits.component';
import {StatSurveyAnswerChartComponent} from './stat-survey-answer-chart/stat-survey-answer-chart.component';
import {StatSurveyAnswersComponent} from './stat-survey-answers/stat-survey-answers.component';
import {StatSurveysCommonComponent} from './stat-surveys-common/stat-surveys-common.component';
import {StatSurveysDashboardComponent} from './stat-surveys-dashboard/stat-surveys-dashboard.component';
import {StatSurveysDataComponent} from './stat-surveys-data/stat-surveys-data.component';
import {StatUtilsService} from './_services/stat-utils.service';
import {RouterModule, Routes} from '@angular/router';
import {StatDailyDistributionComponent} from './stat-daily-distribution/stat-daily-distribution.component';
import {StatDocsDistributionComponent} from './stat-docs-distribution/stat-docs-distribution.component';
import {StatDocsGeoDistributionComponent} from './stat-docs-geodistribution/stat-docs-geo-distribution.component';
import {FormsModule} from "@angular/forms";
import {TableModule} from 'primeng/table';
import {TreeTableModule} from 'primeng/treetable';
import {DataViewModule} from 'primeng/dataview';
import {ChartModule} from 'primeng/chart';
import {ToolbarModule} from 'primeng/toolbar';
import {PanelModule} from 'primeng/panel';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {DoceditorModule} from "../doceditor/doceditor.module";
import {ToggleButtonModule} from "primeng/togglebutton";
import {StatGeoUtilsService} from "./_services/stat-geo-utils.service";
import {MultiSelectModule} from "primeng/multiselect";
import {WordCloudController, WordElement} from 'chartjs-chart-wordcloud';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {DialogModule} from "primeng/dialog";
import {SplitButtonModule} from "primeng/splitbutton";
import {Chart} from 'chart.js';
import {SkeletonModule} from "primeng/skeleton";
import {PickListModule} from "primeng/picklist";
import {RadioButtonModule} from "primeng/radiobutton";

Chart.register(WordCloudController, WordElement);
Chart.register(ChartDataLabels);
const routes: Routes = [
  {path: 'forms', component: StatSurveysDataComponent},
  {path: 'visits', component: StatCountryVisitsComponent},
];

@NgModule({
  declarations: [
    StatCountryVisitsComponent,
    StatSurveyAnswerChartComponent,
    StatSurveyAnswersComponent,
    StatSurveysCommonComponent,
    StatSurveysDashboardComponent,
    StatSurveysDataComponent,
    StatDailyDistributionComponent,
    StatDocsDistributionComponent,
    StatDocsGeoDistributionComponent
  ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        TreeTableModule,
        TableModule,
        DataViewModule,
        ChartModule,
        ToolbarModule,
        PanelModule,
        ButtonModule,
        DropdownModule,
        Ng2GoogleChartsModule,
        DoceditorModule,
        ToggleButtonModule,
        MultiSelectModule,
        DialogModule,
        SplitButtonModule,
        SkeletonModule,
        PickListModule,
        RadioButtonModule
    ],
  exports: [
    StatCountryVisitsComponent,
    StatSurveyAnswerChartComponent,
    StatSurveyAnswersComponent,
    StatSurveysCommonComponent,
    StatSurveysDashboardComponent,
    StatSurveysDataComponent,
    StatDailyDistributionComponent,
    StatDocsDistributionComponent,
    StatDocsGeoDistributionComponent
  ],
  providers: [
    StatUtilsService, StatGeoUtilsService
  ]
})
export class StatisticModule {
}
