import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import { StatUtilsService } from '../_services/stat-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
  selector: 'app-stat-countryVisits',
  template: `
        <p-table [value]="data" [lazy]="true" (onLazyLoad)="loadDataLazy($event)"
                 [loading]="loading"
                 [resizableColumns]="true"
                 [scrollable]="true" [scrollHeight]="'calc(100vh - 220px)'">
          <ng-template pTemplate="caption">
            Country statistic
          </ng-template>
 	        <ng-template pTemplate="colgroup">
		        <colgroup>
                    <col>
                    <col style="width:8rem;">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th>Country</th>
                    <th>Count of visits</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td><span class="flag-icon flag-icon-{{rowData.countryCode.toLowerCase()}} flag-icon-squared"></span>&nbsp;&nbsp;{{rowData.countryName}}</td>
	                <td>{{rowData.cnt}}</td>
                </tr>
            </ng-template>
        </p-table>`
})
export class StatCountryVisitsComponent implements OnInit {
	data: any[] = [];
	totalRecords: number;
	loading: boolean = true;
	private _params: any = {};

  constructor(private messageService: AppMessageService,
              private dataService: StatUtilsService) { }

  ngOnInit() {
      this.data = [];
  }

	loadDataLazy(event: LazyLoadEvent) {
    	this.getUsersStat(this._params.cdoc, this._params.grtype);
	}

	private getUsersStat(cdoc: string, grtype: number) {
      this.loading = true;
      this.dataService.getStatListUsers(cdoc, grtype)
          .subscribe(data => {
                this.loading = false;
                this.data = data;
                this.totalRecords = this.data.length;
              },
              error => {
                this.loading = false;
                this.messageService.showError(error, 'Error receiving data');
                console.log(error);
              });
	}
}
