<p-table #dataTable
         [value]="projects.content"
         dataKey="id"
         [rows]="projects.size"
         [totalRecords]="projects.totalElements"
         [lazy]="true"
         (onLazyLoad)="handlePageChange($event)"
         [loading]="loading"
         editMode="row"
         [paginator]="true"
         paginatorPosition="bottom"
         [(selection)]="selected"
         [scrollable]="true"
         selectionMode="multiple"
         [scrollHeight]="'calc(100vh - 295px)'"
         rowGroupMode="rowspan"
         groupRowsBy="idUser"
         scrollDirection="both"
         sortField="user.username"
         [sortOrder]="1">
  <ng-template pTemplate="caption">
    <div style="text-align: left;">
      <button pButton type="button" icon="pi pi-plus"
              (click)="beforeAdd(UserAddType.NEW)" pTooltip="Add user to the questionnaire"></button>
      <button pButton type="button" icon="pi pi-user-plus"
              (click)="beforeAdd(UserAddType.CLONE)" pTooltip="Add user to the questionnaire as selected user"
              [disabled]="editButtonDisabled"></button>
      <button pButton type="button" icon="pi pi-pencil"
              [disabled]="editButtonDisabled"
              (click)="beforeEditRow()"></button>
      <button pButton type="button" icon="pi pi-trash"
              [disabled]="deleteButtonDisabled"
              (click)="onRowDelete()"></button>
      <button pButton type="button" icon="pi pi-users"
              pTooltip="User Custom variables"
              [disabled]="variablesButtonDisabled"
              (click)="beforeEditVariables()"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="headergrouped">
    <tr>
      <th class="w-2rem"></th>
      <th class="w-2rem"></th>
      <th class="w-15rem"></th>
      <th class="w-15rem"></th>
      <th *ngIf="!isSurvey" class="w-15rem"></th>
      <th class="w-15rem"></th>
      <th class="w-5rem"></th>
      <th *ngIf="docSavedVisible" class="w-5rem"></th>
      <th *ngIf="docSubmittedVisible" class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-7rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-7rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5-5rem"></th>
    </tr>
    <tr>
      <th rowspan="2">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th rowspan="2"></th>
      <th pSortableColumn="user.username" rowspan="2">Login
        <p-sortIcon field="user.username"></p-sortIcon>
        <p-columnFilter field="username"
                        display="menu" [showAddButton]="false" [showOperator]="false"
                        matchMode="contains" class="p-ml-auto">
        </p-columnFilter>
      </th>
      <th rowspan="2">Full Name
        <p-columnFilter field="fullname"
                        display="menu" [showAddButton]="false" [showOperator]="false"
                        matchMode="contains" class="p-ml-auto">
        </p-columnFilter>
      </th>
      <th rowspan="2">e-Mail
        <p-columnFilter field="email"
                        display="menu" [showAddButton]="false" [showOperator]="false"
                        matchMode="contains" class="p-ml-auto">
        </p-columnFilter>
      </th>
      <th *ngIf="!isSurvey" rowspan="2">Organization
        <p-columnFilter field="orgName"
                        display="menu" [showAddButton]="false" [showOperator]="false"
                        matchMode="contains" class="p-ml-auto">
        </p-columnFilter>
      </th>
      <th rowspan="2">Document
        <p-columnFilter field="cdoc" matchMode="in" display="menu"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                        [showApplyButton]="false"
                        class="p-ml-auto">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-multiSelect [ngModel]="value" [options]="cdocsList" placeholder="Any"
                           (onChange)="filter($event.value)">
            </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="docSavedVisible" class="w-5rem" rowspan="2">Saved
        <p-columnFilter field="docSavedCount"
                        [showAddButton]="false" [showOperator]="false"
                        type="numeric" display="menu" class="p-ml-auto">
        </p-columnFilter>
      </th>
      <th *ngIf="docSubmittedVisible" class="w-5rem" rowspan="2">Submitted
        <p-columnFilter field="docSubmittedCount"
                        [showAddButton]="false" [showOperator]="false"
                        type="numeric" display="menu" class="p-ml-auto">
        </p-columnFilter>
      </th>
      <th colspan="2">View</th>
      <th [colSpan]="6">Edit Options</th>
      <th class="w-5rem" rowspan="2">Download</th>
      <th class="w-5rem" rowspan="2">Upload</th>
      <th class="w-5rem" rowspan="2">Print</th>
      <th class="w-5rem" rowspan="2">Document admin</th>
      <th class="w-5rem" rowspan="2">Questionnaire admin</th>
      <th class="w-7rem" rowspan="2"></th>
    </tr>
    <tr>
      <th>&nbsp;</th>
      <th>Level</th>
      <th>Create</th>
      <th>Edit</th>
      <th>Remove</th>
      <th>Reopen</th>
      <th>Submit</th>
      <th>Level</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-rowgroup="rowgroup" let-rowspan="rowspan"
               let-editing="editing">
    <tr [pEditableRow]="row" class="p-row-no-hover">
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
        <p-tableCheckbox [value]="row"></p-tableCheckbox>
      </td>
      <td [pTooltip]="(row.user.accountLocked ? 'Non-Active' : 'Active') + '\n' + (row.user.lastLogon || '')"
          *ngIf="rowgroup" [attr.rowspan]="rowspan">
        <i class="far fa-check-circle" style="color: green!important;" *ngIf="!row.user.accountLocked"></i>
        <i class="far fa-question-circle" style="color: grey!important;" *ngIf="row.user.accountLocked"></i>
      </td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ row.user.username }}</td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ row.user.fullname }}</td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ row.user.email }}</td>
      <td *ngIf="!isSurvey && rowgroup" [attr.rowspan]="rowspan">{{ row.orgName }}</td>
      <td><span [pTooltip]="row.docName">{{ row.cdoc }}</span></td>
      <td *ngIf="docSavedVisible" class="w-5rem">{{ row.docSavedCount }}</td>
      <td *ngIf="docSubmittedVisible" class="w-5rem">{{ row.docSubmittedCount }}</td>

      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessView" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessView == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="w-7rem">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown [options]="levels" [(ngModel)]="editRowData.viewAcc"
                        placeholder="Select level"
                        [style]="{ 'max-width':'6rem', 'width': '6rem' }"
                        [baseZIndex]="20000" appendTo="body"></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ row.viewAcc == 0 ? 'Document' : (row.viewAcc == 2 ? 'Only user' : 'Organization') }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessCreate" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessCreate == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessWrite" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessWrite == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessRemove" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessRemove == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessReopen" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessReopen == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessSubmit" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessSubmit == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown [options]="levels" [(ngModel)]="editRowData.editAcc"
                        placeholder="Select level"
                        [style]="{ 'max-width':'6rem', 'width': '6rem' }"
                        [baseZIndex]="20000" appendTo="body"></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ row.editAcc == 0 ? 'Document' : (row.editAcc == 2 ? 'Only user' : 'Organization') }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.roleDownload" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.roleDownload == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.roleUpload" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.roleUpload == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.rolePrint" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.rolePrint == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="text-center">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-checkbox [(ngModel)]="editRowData.accessDocumentAdmin" binary="true">></p-checkbox>
          </ng-template>
          <ng-template pTemplate="output">
            <input type="checkbox" disabled [checked]="row.accessDocumentAdmin == true"/>
          </ng-template>
        </p-cellEditor>
      </td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="text-center">
        <input type="checkbox" disabled [checked]="row.accessProjectAdmin == true"/>
      </td>
      <td>
        <button *ngIf="!editing" pButton type="button" pInitEditableRow icon="pi pi-pencil"
                (click)="onRowEditInit(row)"></button>
        <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check"
                class="p-button-text p-button-success"
                (click)="onRowEditSave()"></button>
        <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times"
                class="p-button-text p-button-danger"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <div class="text-left" *ngIf="configService.isDesktopDevice">
      {{ 'Total users/records ' + (usersTotal == null ? '' : usersTotal) + ' / ' + (projects.totalElements) }}
    </div>
  </ng-template>
</p-table>

<p-dialog header="Edit user profile" [(visible)]="editUserVisible"
          [closable]="false"
          [modal]="true" [style]="{width: '600px'}">
  <form [formGroup]="form" (submit)="save(form.getRawValue())" novalidate>
    <div class="p-fluid">
      <div class="row form-group">
        <div class="col-4">Login:</div>
        <div class="col-8">
          {{ userData?.username }}
        </div>
      </div>
      <div class="row form-group">

        <div class="col-4">Full Name:</div>
        <div class="col-8">
          {{ userData?.fullname }}
        </div>
      </div>
      <!-- hide for the surveys-->
      <ng-container *ngIf="grType !== 2">
        <div class="row form-group">
          <div class="col-4">Organization</div>
          <div class="col-8">
            <p-autoComplete [suggestions]="filteredOrgs"
                            (completeMethod)="handleFilterOrgs($event)"
                            field="name"
                            [minLength]="1"
                            formControlName="org" [dropdown]="true"
                            [forceSelection]="true"
                            appendTo="body"></p-autoComplete>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="form.get('org')"></app-show-error>
          </div>
        </div>
      </ng-container>
      <div class="row form-group">
        <div class="col-4">All documents:</div>
        <div class="col-8">
          <p-listbox [options]="docsList"
                     formControlName="docs"
                     [metaKeySelection]="false"
                     [checkbox]="true" [multiple]="true"
                     optionLabel="cdoc" optionValue="cdoc"
                     [listStyle]="{'max-height':'80px'}"
                     filterPlaceHolder="asd">
          </p-listbox>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-4">Current document:</div>
        <div class="col-8">
          <p-dropdown [options]="userData.docs"
                      formControlName="currDoc"
                      appendTo="body"></p-dropdown>
        </div>
      </div>

      <ng-container [formGroup]="currDocAccForm">
        <div class="row form-group">
          <div class="col-4">View:</div>
          <div class="col-8">
            <div class="input-group">
              <div class="input-group-prepend pr-2">
                <p-checkbox formControlName="view" binary="true"></p-checkbox>
              </div>
              <div class="form-control-local">
                <p-dropdown [options]="levels"
                            placeholder="Select level"
                            formControlName="viewAcc"
                            appendTo="body"></p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">Edit Options:</div>
          <div class="col-8">
            <div class="row">
              <div class="col-6 form-group">
                <p-checkbox formControlName="create" binary="true" label="Create"></p-checkbox>
              </div>
              <div class="col-6 form-group">
                <p-checkbox formControlName="write" binary="true" label="Edit"></p-checkbox>
              </div>
              <div class="col-6 form-group">
                <p-checkbox formControlName="remove" binary="true" label="Remove"></p-checkbox>
              </div>
              <div class="col-6 form-group">
                <p-checkbox formControlName="reopen" binary="true" label="Reopen"></p-checkbox>
              </div>
              <div class="col-6 form-group">
                <p-checkbox formControlName="submit" binary="true" label="Submit"></p-checkbox>
              </div>
              <div class="col-12">
                <p-dropdown [options]="levels"
                            placeholder="Select level"
                            formControlName="editAcc"
                            appendTo="body"></p-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
            <p-checkbox formControlName="admin" binary="true" label="Document admin"></p-checkbox>
          </div>
          <div class="col-4">
            <p-checkbox formControlName="download" binary="true" label="Download"></p-checkbox>
          </div>
          <div class="col-4">
            <p-checkbox formControlName="print" binary="true" label="Print"></p-checkbox>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">
          </div>
          <div class="col-4">
            <p-checkbox formControlName="upload" binary="true" label="Upload"></p-checkbox>
          </div>
          <ng-container *ngIf="currDocAccForm.get('mode').value !== 3 && currDocAccForm.get('mode').value !== 4">
            <div class="col-4">
              <p-checkbox formControlName="recordInfo" binary="true" label="View record info"></p-checkbox>
            </div>
          </ng-container>
        </div>

      </ng-container>

      <div class="row form-group"
           [ngStyle]="!isSurvey ? {'margin-top': '-2.3rem'} : {'margin-top': '-2.3rem'}">
        <div class="col-4">
          <p-checkbox formControlName="projectAdmin" binary="true" label="Questionnaire admin"></p-checkbox>
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button pButton type="submit" icon="pi pi-save"
                [disabled]="saveButtonDisabled"
                label="Save"></button>
        <button pButton type="button" icon="pi pi-times"
                (click)="editUserVisible=false" [disabled]="closeButtonDisabled"
                label="Close"></button>
      </div>
    </div>
  </form>
</p-dialog>

<project-add-user [idProject]="idProject" [(visible)]="showDlgUsers"
                  (userSelected)="onUserSelect($event)"></project-add-user>

<p-dialog header="User Custom variables" [(visible)]="showDlgUserVariables"
          [modal]="true" [style]="{ minWidth: '800px' }" [maximizable]="false" [baseZIndex]="10000">
  <div class="p-fluid">
    <div class="row form-group">
      <div class="col-4">Login</div>
      <div class="col-8">
        <input pInputText [value]="userData?.username" class="form-control" type="text" disabled/>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-4">Full name</div>
      <div class="col-8">
        <input pInputText [value]="userData?.fullname" class="form-control" type="text" disabled/>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-4">Custom variables</div>
      <div class="col-8">
        <div class="input-group">
          <textarea pInputTextarea [(ngModel)]="userVariables" class="form-control-local" type="text"
                    rows="5"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="row float-right">
    <div class="col-12">
      <button pButton type="button" icon="pi pi-save" label="Save"
              (click)="saveVariables()"
              [disabled]="saveVarsButtonDisabled"></button>
      <button pButton type="button" icon="pi pi-times"
              [disabled]="closeVarsButtonDisabled"
              (click)="showDlgUserVariables = false" label="Close"></button>
    </div>
  </div>
</p-dialog>
