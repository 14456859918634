<div style="padding: 4px;">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button *ngIf="uploadButtonVisible" pButton type="button" icon="pi pi-upload"
              (click)="beforeUpload()" pTooltip="Upload data"></button>
      <button pButton type="button" icon="pi pi-refresh"
              (click)="refresh()" pTooltip="Refresh"></button>
    </div>
  </p-toolbar>

  <p-table #dt
           [value]="data.content"
           [totalRecords]="data.totalElements"
           [rows]="data.size"
           [lazy]="true"
           (onLazyLoad)="handlePageChange($event)"
           [loading]="loading"
           [paginator]="true"
           paginatorPosition="bottom"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 250px)'"
           sortField="code" [sortOrder]="1"
           tableStyleClass="table-singleline">
    <ng-template pTemplate="header">
      <tr>
        <th rowspan="2" class="w-3rem"></th>
        <th pSortableColumn="code" class="w-7rem">ISO3
          <p-sortIcon field="code"></p-sortIcon>
        </th>
        <th class="w-7rem">ISO2</th>
        <th class="w-7rem" pSortableColumn="codeUN">UN code
          <p-sortIcon field="codeUN"></p-sortIcon>
        </th>
        <th class="w-7rem" pSortableColumn="name">Country
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th class="w-15rem">Country name</th>
        <th class="w-7rem" pSortableColumn="capital">Capital
          <p-sortIcon field="capital"></p-sortIcon>
        </th>
        <th class="w-15rem">Map Country</th>
        <th class="w-7rem" pSortableColumn="populationUN">UN Population
          <p-sortIcon field="populationUN"></p-sortIcon>
        </th>
        <th class="w-7rem" pSortableColumn="population">Population
          <p-sortIcon field="population"></p-sortIcon>
        </th>

        <th class="w-7rem" pSortableColumn="incomeGroup">Income Group
          <p-sortIcon field="incomeGroup"></p-sortIcon>
        </th>

      </tr>
      <tr>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.code"
                 (change)="dt.filter($event.target.value, 'code', 'contains')"/>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.name"
                 (change)="dt.filter($event.target.value, 'name', 'contains')"/>
        </th>
        <th></th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.capital"
                 (change)="dt.filter($event.target.value, 'capital', 'contains')"/>
        </th>
        <th></th>
        <th></th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 [ngModel]="tableFilterData.incomeGroup"
                 (change)="dt.filter($event.target.value, 'incomeGroup', 'contains')"/>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <th class="w-3rem"><img [src]="row.flag" height="32"/></th>
        <td>{{row.code}}</td>
        <td class="w-4rem">{{row.iso2}}</td>
        <td class="w-4rem">{{row.codeUN}}</td>
        <td class="w-10rem">{{row.name}}</td>
        <td pTooltip="<td>{{row.fullName}}</td>">{{ outputRowCellValue(row.fullName, 30) }}</td>
        <td class="w-7rem">{{row.capital}}</td>
        <td class="w-10rem">{{row.mapcountry}}</td>
        <td class="w-6rem">{{row.populationUN}}</td>
        <td class="w-6rem">{{row.population}}</td>
        <td class="w-4rem">{{row.incomeGroup}}</td>

      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog header="Upload" [(visible)]="uploadShow" [modal]="true" [style]="{width: '600px'}">
  <div>
    <p-fileUpload name="file[]" customUpload="true" (uploadHandler)="fileUploader($event)"></p-fileUpload>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-times" (click)="uploadShow=false" label="Close"></button>
  </ng-template>
</p-dialog>
