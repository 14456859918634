/** */
export class Organization {
  id: string; // ID organization
  name: string; // Organization name
  orgLevel: number; // Level of organization
}

export class Label {
  id: string;
  idLabel?: string;
  label: string; // tag
  severity: string;
  name: string;
}

export class CustomButton {
  button: string;
  id: string;
  idHierarchy: string;
  lang: string;
  buttonType: string;
  blabel: string; // tag
  icon: string;
  tooltip: string;
  customized: number;
}


export class ErrValidateRule {
  id: string; //ID
  rnForm: number; // #in inport list
  cdoc: string; //cdoc
  field: string; //field name
  expression: string; //expression to check
  errmess: string; //error message
  rn: number; // row number
  tabn: number; // tab number
  znInput: string; //input value
  znchk: string; //calculated value
  res: string; //result of check  /ok/error/fail/other
  type: string; //type of check
  vals: any; //values
}


/** */
export class User {
  id: string;
  username: number;
  fullname: string;
  accountLocked: boolean;
  psw: string;
  email: string;
  phone: string;
  variables: string;
  isAdmin: boolean;
  typeAdmin: number;
  accessTokenValidity: number;
}

/** */
export class Hierarchy {
  id: string;
  name: string;
  description: string;
  organization: Organization;
  grType: number;
  idOrg: string;
  access: number;
  crpCode: string;
  eventNumber: string;
  projectNumber: string;
  template: string;
  hiddenDocStatus: number;
  labels: Label[];
  docsEmailNotify: string[];
  emailMembers: string;
  includeMetadata: number;
  tabs: any[];
  workflow: any;
}

/** */
export class Period {
  private _per_month: number;
  private _per_type: number;
  private _name: string;

  constructor(month: number, type: number, name: string) {
    this._per_month = month;
    this._per_type = type;
    this._name = name;
  }

  get prdMonth(): number {
    return this._per_month;
  }

  set prdMonth(value: number) {
    this._per_month = value;
  }

  get prdType(): number {
    return this._per_type;
  }

  set prdType(value: number) {
    this._per_type = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
}

/** */
export const enum CProcessType {
  Save,
  Check,
  Remove,
  Copy,
  Send,
  SendForce,
  Accept,
  Decline,
  Reset,
  SaveFailed
}

export class RespOnProcessDoc {
  oper: CProcessType;
  status?: number;
  iddoc?: string;
  logs?: [];
}

export const enum CMenuRefreshType {
  FULL_REFRESH,
  STORE_POSITION
}

export class MenuRefreshOpt {
  opt: CMenuRefreshType;

  constructor(_opt: CMenuRefreshType) {
    this.opt = _opt;
  }
}
