<p-dialog header="ORDER LIST" [(visible)]="visible"
          [modal]="true"
          [closeOnEscape]="false"
          [style]="{ width: '800px' }" [baseZIndex]="12000">

  <p-pickList [source]="sourceList" [target]="targetList" sourceHeader="Available" targetHeader="Selected" dragdrop="true"
              [responsive]="true" [sourceStyle]="{'height':'250px'}" [targetStyle]="{'height':'250px'}" filterBy="name"
              [showSourceControls]="false"
              sourceFilterPlaceholder="Search" targetFilterPlaceholder="Search">
    <ng-template let-product pTemplate="item">
      <div class="product-item">
        <div class="product-list-detail">
          <span class="p-mb-2">{{product.name}}</span>
        </div>
      </div>
    </ng-template>
  </p-pickList>
  <div class="row float-right pt-3">
    <div class="col-12">
      <button pButton type="button" icon="pi pi-save"  (click)="save($event)"  label="Save"></button>
      <button pButton type="button" icon="pi pi-times" (click)="close()" label="Close"></button>
    </div>
  </div>
</p-dialog>
