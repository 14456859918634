import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {LogEvtPrivateComponent} from "./log-evt-private.component";

@Component({
  selector: 'app-dialog-adminlog-private',
  templateUrl: './dialog-log-evt-private.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogLogEvtPrivateComponent {
  @ViewChild(LogEvtPrivateComponent, {static: false}) logViewCmp;
  @Input() idDoc: string;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  cmpVisible = false;
  private _visible = false;

  constructor() {
  }

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
    if (this._visible && !this.cmpVisible) {
      this.logViewCmp.refreshVars(this.idDoc);
      this.cmpVisible = true;
    } else if (!this._visible && this.cmpVisible) {
      this.cmpVisible = false;
    }
  }

  hide() {
    this.visibleChange.emit(false);
  }

  get dialogStyle(): any {
    return {height: `${window.innerHeight - 20}px`, width: `${window.innerWidth - 200}px`}
  }
}
