import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './_services/auth.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfigService} from './_services/config.service';
import {LoaderService} from './components/core/loader/loader.service';
import {HTTPStatus} from './_services/oauth.http.interceptor';
import {AppMessageService} from './_services/app-message.service';
import {CustomMenuItem} from './_domains/CustomMenuItem';
import {CMenuRefreshType} from './_domains/UITypes';
import {ClipboardService} from 'ngx-clipboard';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptionMessages: Subscription;
  private subscriptionAuthUserService: Subscription;
  private subscriptionAuthDataService: Subscription;
  isMenuVisible: boolean;
  isMobileResolution = false;
  menuItems: CustomMenuItem[];
  searchField: string = '';
  autoComplSuggestion: any[];

  constructor(private msgService: MessageService,
              private httpStatus: HTTPStatus,
              private _authService: AuthService,
              private _configService: ConfigService,
              private router: Router,
              private messageService: AppMessageService,
              private loaderService: LoaderService,
              private clipboardService: ClipboardService,
              private translate: TranslateService) {
    translate.setDefaultLang('en');
    // Subscribe for loadingService
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      if (status) {
        this.loaderService.show();
      } else {
        this.loaderService.hide();
      }
    });

    // Handle copy response globally https://github.com/maxisam/ngx-clipboard#handle-copy-response-globally
    this.clipboardService.copyResponse$.subscribe(re => {
      if (re.isSuccess) {
        this.messageService.showSuccess(re.successMessage || 'Link is copied to clipboard');
      } else {
        this.messageService.showWarn('Copy to clipboard is failed');
      }
    });

    this.subscriptionAuthDataService = this._authService.getAuthData$.subscribe(async userLoggidIn => {
      if (userLoggidIn) { // Login
        this.isMenuVisible = !(window.innerWidth <= 992) && (this._authService.user?.id != null);
        this.menuItems = await this._authService.setMenuItems();
        // this.locationService.getLocation();
      } else { // Logout
        this.isMenuVisible = false;
        this.menuItems = [];
        if (this.router.url !== '/login' && !this.router.url.startsWith('/sso-login/')) {
          this.router.navigate(['']);
        }
        // this.locationService.getLocation();
      }
    });
    this.subscriptionMessages = this.messageService.notificationMessages
      .subscribe(notification => {
        const rec = notification;
        const severity = rec['severity'];
        if (severity === 'error' || severity === 'warn') {
          rec['life'] = 10000;
        }
        this.msgService.add(rec);
      });
  }

  async ngOnInit() {
    this.isMenuVisible = this._authService.loggedIn() && (this._authService.user?.id != null);
    if (this._configService.isMobileDevice) {
      this.isMobileResolution = true;
      this.isMenuVisible = false;
    }
    this.menuItems = (this._authService.loggedIn()) ? await this._authService.setMenuItems() : [];

    this.subscriptionAuthUserService = this._authService.getUserData$.subscribe(async data => {
      this.menuItems = await this._authService.setMenuItems();
      this.filterProc(this.searchField);
      if (data.opt === CMenuRefreshType.FULL_REFRESH) {
        this.router.navigate(['start']);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionMessages.unsubscribe();
    this.subscriptionAuthUserService.unsubscribe();
    this.subscriptionAuthDataService.unsubscribe();
  }

  get loggedIn(): boolean {
    return this._authService.loggedIn();
  }

  get privateHeaderVisible(): boolean {
    return this._authService.loggedIn() && !this._authService.projectDataView;
  }

  get publicHeaderVisible(): boolean {
    const url = this.router.routerState.snapshot.url;
    return !this._authService.loggedIn();
  }

  get footerVisible(): boolean {
    return (!this._authService.loggedIn()) || (this._authService.loggedIn() && !this._authService?.user?.id);
  }

  toggleMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  setHidden() {
    for (const j in this.menuItems) {
      if ((this.menuItems[j].Childs) && (
        (this.menuItems[j].Label.startsWith('Questionnaires')) ||
        (this.menuItems[j].Label.startsWith('Saved')) ||
        (this.menuItems[j].Label.startsWith('Archive')))) {
        for (const i in this.menuItems[j].Childs) {
          let found = (this.searchField + '' === '');
          if (!found) {
            for (let k in this.autoComplSuggestion) {
              if (this.menuItems[j].Childs[i].RouterArgs === this.autoComplSuggestion[k].val) {
                found = true;
              }
            }
          }
          this.menuItems[j].Childs[i].isHidden = !found;
        }
      }
    }
  }

  filterProc(strIn: string) {
    this.autoComplSuggestion = [];
    for (const j in this.menuItems) {
      if ((this.menuItems[j].Childs) && (
        (this.menuItems[j].Label.startsWith('Questionnaires')) ||
        (this.menuItems[j].Label.startsWith('Saved')) ||
        (this.menuItems[j].Label.startsWith('Archive')))) {
        for (const i in this.menuItems[j].Childs) {
          if (this.menuItems[j].Childs[i].Label.toLowerCase().indexOf(strIn.toLowerCase()) !== -1) {
            this.autoComplSuggestion.push({
              name: this.menuItems[j].Childs[i].Label + ' ' + this.menuItems[j].Childs[i].labels,
              val: this.menuItems[j].Childs[i].RouterArgs,
              type: 'project'
            });
          } else if (this.menuItems[j].Childs[i].labels && this.menuItems[j].Childs[i].labels.toLowerCase().indexOf(strIn.toLowerCase()) !== -1) {
            this.autoComplSuggestion.push({
              name: this.menuItems[j].Childs[i].Label + ' ' + this.menuItems[j].Childs[i].labels,
              val: this.menuItems[j].Childs[i].RouterArgs,
              type: 'tag'
            });
          }
        }
      }
    }
    this.setHidden();
  }

  onSearch(evt: any, type: string) {
    if ((type === 'enter') || (type === 'input2')) {
      this.filterProc(this.searchField);
    }
  }
}
