import {Sort} from "./sort";

export class Pageable {
    private _content: Array<any> = [];
    private _last: boolean;
    private _totalPages: number;
    private _totalElements: number;
    private _sort: Sort;
    private _first: boolean;
    private _numberOfElements: number;
    private _size: number;
    private _number: number;

    constructor()
    constructor(content?: Array<any>, last?: boolean, totalPages?: number, totalElements?: number, sort?: Sort, first?: boolean,
                numberOfElements?: number, size?: number, number?: number) {
        this._content = content || [];
        this._last = last || false;
        this._totalPages = totalPages || 0;
        this._totalElements = totalElements || 0;
        this._sort = sort;
        this._first = first || true;
        this._numberOfElements = numberOfElements || 0;
        this._size = size || 0;
        this._number = number || 0;
    }

    get content(): Array<any> {
        return this._content;
    }

    set content(value: Array<any>) {
        this._content = value;
    }

    get last(): boolean {
        return this._last;
    }

    set last(value: boolean) {
        this._last = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: number) {
        this._totalPages = value;
    }

    get totalElements(): number {
        return this._totalElements;
    }

    set totalElements(value: number) {
        this._totalElements = value;
    }

    get sort(): Sort {
        return this._sort;
    }

    set sort(value: Sort) {
        this._sort = value;
    }

    get first(): boolean {
        return this._first;
    }

    set first(value: boolean) {
        this._first = value;
    }

    get numberOfElements(): number {
        return this._numberOfElements;
    }

    set numberOfElements(value: number) {
        this._numberOfElements = value;
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }

    get number(): number {
        return this._number;
    }

    set number(value: number) {
        this._number = value;
    }
}
