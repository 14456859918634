import {Component, Input, OnInit} from '@angular/core';
import {AppMessageService} from "../../../_services/app-message.service";
import {StatUtilsService} from "../_services/stat-utils.service";
import {DateUtils} from "../../../_utils/date-utils";
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-stat-docs-distribution',
  templateUrl: './stat-docs-distribution.component.html'
})
export class StatDocsDistributionComponent implements OnInit {
  @Input() sColorSurveysNew: string = '#cbcbcb99';
  @Input() sBackgroundSurveysNew: string = '#f5f5f599';
  @Input() sColorSurveysDraft: string = '#ffc28299';
  @Input() sBackgroundSurveysDraft: string = '#fff4e899';
  @Input() sColorSurveysSent: string = '#6ba6e799';
  @Input() sBackgroundSurveysSent: string = '#c3dbf599';
  @Input() sColorFormsNew: string = '#a1a1a199';
  @Input() sBackgroundFormsNew: string = '#dcdcdc99';
  @Input() sColorFormsDraft: string = '#f8ab9b99';
  @Input() sBackgroundFormsDraft: string = '#feeeea99';
  @Input() enableInit: boolean = false;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;

  items: SelectItem[] = [
    {value: 0, label: "Monthly", disabled: false},
    {value: 1, label: "4 weeks until last activity", disabled: false},
    {value: 2, label: "Daily for a month", disabled: false}
  ];
  metric: number = 0;
  data0: any;
  data1: any;

  header: string = "";
  footer: string = "";

  private _idProject: string;
  private _grType: number;
  private _minDate: Date;
  private _maxDate: Date;
  private labels0: string[] = [];
  private _fillableDaily: boolean = false;
  private _fillableMonthly: boolean = true;
  chart_options={plugins: {datalabels:false}};

  constructor(private messageService: AppMessageService,
              private dataService: StatUtilsService) {
  }


  ngOnInit(): void {
    if (this.enableInit) this.refresh(null, null, this.minDate, this.maxDate);
  }

  clear() {
    this.data0 = undefined;
    this.data1 = undefined;
    this.header = undefined;
    this.footer = undefined;
  }

  refresh(idProject?: string, grType?: number, minDate?: Date, maxDate?: Date) {
    this.metric = 0;
    this._idProject = idProject;
    this._grType = grType;
    this._minDate = minDate;
    this._maxDate = maxDate;

    if (this.enableInit == false) {
      const diff = this.differenceInDays(this._maxDate, this._minDate);
      this.metric = (diff < 31) ? 1 : 0;

    }
    this.handleMetricsChanged();
  }

  handleMetricsChanged() {
    if (this.metric == 0) {
      this.getSurveysMonthlyStat();
    } else {
      const _dateEnd = new Date(this._maxDate.getTime());
      let _dateBeg = new Date(this._maxDate.getTime());
      _dateBeg.setDate(_dateBeg.getDate() - 28);

      this.getSurveysDailyStat(_dateBeg, _dateEnd);
    }
  }

  onDataSelect(event) {
    const _label = this.labels0[event.element._index];
    const _parts: string[] = _label.split("-");
    const _dateBeg = new Date(parseInt(_parts[0]), parseInt(_parts[1]) - 1, 1, 0, 0, 0);
    const _dateEnd = new Date(parseInt(_parts[0]), parseInt(_parts[1]), 1, 23, 59, 59);
    _dateEnd.setDate(_dateBeg.getDate() - 1);

    this.metric = 2;
    this.getSurveysDailyStat(_dateBeg, _dateEnd);
  }

  // Monthly statistic
  private getSurveysMonthlyStat() {
    this.labels0 = [];
    this.data0 = {};
    this.header = "Monthly distribution";
    this.footer = "";

    this.dataService.getListSurveysMonthlyStat(this._idProject, this._grType)
      .subscribe(data => {
          this.calculateMonthlyStat(data);
        },
        error => {
          this.messageService.showError(error);
        });
  }


  private calculateMonthlyStat(data: any) {
    let cntCrfs = 0, cntSurveys = 0;
    let surveysNewData: number[] = [];
    let surveysDraftData: number[] = [];
    let surveysSentData: number[] = [];
    let crfDraftData: number[] = [];
    let crfNewData: number[] = [];
    let nday = 0;
    // main cycle
    for (let i in data) {
      const rdata = data[i];
      const rval = `${rdata.periodYear}-${("" + rdata.periodMonth).padStart(2, '0')}`;
      let fnd = false;
      for (let k in this.labels0) {
        const rdata1 = this.labels0[k];
        if (rdata1 === rval) {
          fnd = true;
          break;
        }
      }
      if (fnd === false) {
        this.labels0.push(rval);

        surveysNewData[nday] = 0;
        surveysDraftData[nday] = 0;
        surveysSentData[nday] = 0;
        crfDraftData[nday] = 0;
        crfNewData[nday++] = 0;
      }
    }
    for (let i in data) {
      const rdata = data[i], rval = `${rdata.periodYear}-${("" + rdata.periodMonth).padStart(2, '0')}`,
        ind = this.labels0.indexOf(rval), grType = parseInt(rdata.grtype, 10);
      if (ind !== -1) {
        switch (grType) {
          case 1: // Form
          {
            if (rdata.state == 1) { // Draft
              crfDraftData[ind] += rdata.cnt;
            } else {
              crfNewData[ind] += rdata.cnt;
            }
            cntCrfs += rdata.cnt;
          }
            break;
          case 2: // Survey
          {
            if (rdata.state === 0) { // New
              surveysNewData[ind] += rdata.cnt;
            } else if (rdata.state === 1) { // Draft
              surveysDraftData[ind] += rdata.cnt;
            } else { // Submitted
              surveysSentData[ind] += rdata.cnt;
            }
            cntSurveys += rdata.cnt;
          }
            break;
        }
      }
    }
    if (this._grType == 1) { // Form
      this.data0 = {
        labels: this.labels0,
        datasets: [
          {
            label: 'Forms processed',
            data: crfDraftData,
            borderColor: this.sColorFormsDraft,
            backgroundColor: this.sBackgroundFormsDraft,
            fill: this._fillableMonthly
          },
          {
            label: 'Forms opened',
            data: crfNewData,
            borderColor: this.sColorFormsNew,
            backgroundColor: this.sBackgroundFormsNew,
            fill: this._fillableMonthly
          }]
      };
    } else if (this._grType == 2) { // Survey
      this.data0 = {
        datasets: [
          {
            label: 'Submitted',
            data: surveysSentData,
            borderColor: this.sColorSurveysSent,
            backgroundColor: this.sBackgroundSurveysSent,
            fill: this._fillableMonthly
          },
          {
            label: 'Saved',
            data: surveysDraftData,
            borderColor: this.sColorSurveysDraft,
            backgroundColor: this.sBackgroundSurveysDraft,
            fill: this._fillableMonthly
          },
          {
            label: 'Opened',
            data: surveysNewData,
            borderColor: this.sColorSurveysNew,
            backgroundColor: this.sBackgroundSurveysNew,
            fill: this._fillableMonthly
          }
        ],
        labels: this.labels0
      };
    } else { // All data
      this.data0 = {
        labels: this.labels0,
        datasets: [
          {
            label: 'Submitted',
            data: surveysSentData,
            borderColor: this.sColorSurveysSent,
            backgroundColor: this.sBackgroundSurveysSent,
            fill: this._fillableMonthly
          },
          {
            label: 'Saved',
            data: surveysDraftData,
            borderColor: this.sColorSurveysDraft,
            backgroundColor: this.sBackgroundSurveysDraft,
            fill: this._fillableMonthly
          },
          {
            label: 'Opened',
            data: surveysNewData,
            borderColor: this.sColorSurveysNew,
            backgroundColor: this.sBackgroundSurveysNew,
            fill: this._fillableMonthly
          },
          {
            label: 'Forms processed',
            data: crfDraftData,
            borderColor: this.sColorFormsDraft,
            backgroundColor: this.sBackgroundFormsDraft,
            fill: this._fillableMonthly
          },
          {
            label: 'Forms opened',
            data: crfNewData,
            borderColor: this.sColorFormsNew,
            backgroundColor: this.sBackgroundFormsNew,
            fill: this._fillableMonthly
          }
        ]
      };
    }
    if (this._grType == 1) {
      this.footer = `Total forms: ${cntCrfs}`;
    } else if (this._grType == 2) {
      this.footer = `Total surveys: ${cntSurveys}`;
    } else {
      this.footer = `Total surveys: ${cntSurveys}, Total forms: ${cntCrfs}`;
    }
  }

  // Daily Statistic
  private getSurveysDailyStat(_dateBeg, _dateEnd) {
    this.data1 = {};

    let diff = Math.abs(_dateEnd.getTime() - _dateBeg.getTime());
    diff = Math.ceil(diff / (1000 * 3600 * 24));

    if (diff === 28) {
      const txt = DateUtils.formatDate(_dateEnd, "YYYY-MM-DD");
      this.header = `Daily distribution for 4 weeks until ${txt}`;
    } else { // default
      this.header = `Daily distribution between ${DateUtils.formatDate(_dateBeg, "YYYY-MM-DD")} and ${DateUtils.formatDate(_dateEnd, "YYYY-MM-DD")}`;
    }
    this.footer = "";

    this.dataService.getListSurveysDailyStat(_dateBeg, _dateEnd, this._idProject, this._grType)
      .subscribe(data => {
          this.calculateDailyStat(data, _dateBeg, _dateEnd);
        },
        error => {
          this.messageService.showError(error);
        });
  }

  private calculateDailyStat(data: any, dbeg: Date, dend: Date) {
    let cntSurveys = 0, cntCrfs = 0;
    let days: string[] = [];
    let surveysNewData: number[] = [];
    let surveysDraftData: number[] = [];
    let surveysSentData: number[] = [];
    let crfDraftData: number[] = [];
    let crfNewData: number[] = [];
    let nday = 0;
    // main cycle
    for (let dt = dbeg; dt <= dend; dt.setDate(dt.getDate() + 1)) {
      days.push(DateUtils.formatDate(dt, 'YYYY-MM-DD'));
      surveysNewData[nday] = 0;
      surveysDraftData[nday] = 0;
      surveysSentData[nday] = 0;
      crfDraftData[nday] = 0;
      crfNewData[nday] = 0;

      nday++;
    }

    for (let i in data) {
      const rec = data[i], ind = days.indexOf('' + rec.operDate), grType: number = parseInt(rec.grtype, 10);
      if (ind !== -1) {
        switch (grType) {
          case 1: // Form
          {
            if (rec.state == 1) { // Draft
              crfDraftData[ind] += rec.cnt;
            } else {
              crfNewData[ind] += rec.cnt;
            }
            cntCrfs += rec.cnt;
          }
            break;
          case 2: // Survey
          {
            if (rec.state == 0) { // New
              surveysNewData[ind] += rec.cnt;
            } else if (rec.state == 1) { // Draft
              surveysDraftData[ind] += rec.cnt;
            } else { // Submitted
              surveysSentData[ind] += rec.cnt;
            }
            cntSurveys += rec.cnt;
          }
            break;
        }
      }
    }

    if (this._grType == 1) { // Form
      this.data1 = {
        labels: days,

        datasets: [{
          label: 'Forms opened',
          data: crfNewData,
          borderColor: this.sColorFormsNew,
          fill: this._fillableDaily
        },
          {
            label: 'Forms processed',
            data: crfDraftData,
            borderColor: this.sColorFormsDraft,
            fill: this._fillableDaily
          }]
      };
    } else if (this._grType == 2) { // Survey
      this.data1 = {
        labels: days,
        datasets: [
          {
            label: 'Surveys opened',
            data: surveysNewData,
            borderColor: this.sColorSurveysNew,
            fill: this._fillableDaily
          },
          {
            label: 'Surveys draft',
            data: surveysDraftData,
            borderColor: this.sColorSurveysDraft,
            fill: this._fillableDaily
          },
          {
            label: 'Surveys submitted',
            data: surveysSentData,
            borderColor: this.sColorSurveysSent,
            fill: this._fillableDaily
          }]
      };
    } else { // All data
      this.data1 = {
        labels: days,
        datasets: [
          {
            label: 'Surveys opened',
            data: surveysNewData,
            borderColor: this.sColorSurveysNew,
            fill: this._fillableDaily
          },
          {
            label: 'Surveys draft',
            data: surveysDraftData,
            borderColor: this.sColorSurveysDraft,
            fill: this._fillableDaily
          },
          {
            label: 'Surveys submitted',
            data: surveysSentData,
            borderColor: this.sColorSurveysSent,
            fill: this._fillableDaily
          },
          {
            label: 'Forms opened',
            data: crfNewData,
            borderColor: this.sColorFormsNew,
            fill: this._fillableDaily
          },
          {
            label: 'Forms processed',
            data: crfDraftData,
            borderColor: this.sColorFormsDraft,
            fill: this._fillableDaily
          }]
      };
    }
    if (this._grType == 1) {
      this.footer = `Total forms: ${cntCrfs}`;
    } else if (this._grType == 2) {
      this.footer = `Total surveys: ${cntSurveys}`;
    } else {
      this.footer = `Total surveys: ${cntSurveys}, Total forms: ${cntCrfs}`;
    }
  }

  private differenceInDays(d2: Date, d1: Date) {
    const diff = Math.abs(d2.getTime() - d1.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
  }
}
