import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConfirmationService, SelectItem, TreeNode} from 'primeng/api';
import {AuthService} from '../../../_services/auth.service';
import {ConfigService} from '../../../_services/config.service';
import {Organization} from '../../../_domains/UITypes';
import {AppMessageService} from '../../../_services/app-message.service';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'project-organizations',
  templateUrl: './project-orgs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectOrgsComponent implements OnInit {
  _idProject = '';
  orgForm: UntypedFormGroup;
  orgs: TreeNode[];
  selectedOrg: TreeNode;
  filteredOrgs: Array<any>;
  filteredStates: Array<any>;
  items: SelectItem[] = [
    {label: 'Level 1', value: '0'},
    {label: 'Level 2', value: '1'},
    {label: 'Level 3', value: '2'}];
  showDlgOrgRecord = false;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  constructor(private dataService: ProjectUtilsService,
              private authService: AuthService,
              private configService: ConfigService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private fb: UntypedFormBuilder,
              private translate: TranslateService,
              private cd: ChangeDetectorRef) {
    this.orgForm = fb.group({
      id: null,
      name: [null, [Validators.required, Validators.maxLength(1024), Validators.pattern('^[^\\s]+(\\s.*)?$')]],
      org: null,
      state: {},
      orgLevel: null
    });
  }

  ngOnInit(): void {
  }

  @Input()
  set idProject(val: string) {
    const prev = this._idProject;
    this._idProject = val;
    if (!val) {
      this.orgs = null;
    } else if (prev !== val) {
      this.reloadData();
    }
  }

  get isMobileResolution() {
    return this.configService.isMobileDevice;
  }

  fillOrgs(list: any[], idPar?: string): any[] {
    const ret = [];
    const arr = list.filter(v => (!idPar && !v.idPar) || (idPar && v.idPar === idPar));
    for (const row of arr) {
      const children = (row.cntChilds !== 0) ? row.children = this.fillOrgs(list, row.id) : null;
      ret.push({
        data: row,
        children: children,
        leaf: row.cntChilds === 0,
        expanded: row.cntChilds !== 0
      });
    }
    return ret;
  }

  loadOrgNodes() {
    this.loadingSubject.next(true)
    this.dataService.getOrganizations(this._idProject)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe({
          next: (data) => this.orgs = this.fillOrgs(data),
          error: (error) => this.messageService.showMessage(error, '[loadOrgs]')
        }
      );
  }

  private findOrgById(id: string, orgs?: TreeNode[]): TreeNode {
    const list: TreeNode[] = (orgs || this.orgs);
    const org: TreeNode = list.find(item => item != null && item.data.id === id);
    if (!org) {
      for (const item of list) {
        if (!item?.children) {
          continue;
        }
        const ret: TreeNode = this.findOrgById(id, item.children);
        if (ret != null) {
          return ret;
        }
      }
    } else {
      return org;
    }
  }

  handleFilterOrgs(event: any) {
    const query = event.query;
    this.dataService.getOrganizations(this._idProject, query)
      .subscribe(
        data => {
          this.filteredOrgs = data;
          this.cd.detectChanges();
        }
      );
  }

  handleFilterStates(event: any) {
    const query = event.query;
    this.dataService.getStates(query)
      .subscribe(
        data => {
          this.filteredStates = data;
          this.cd.detectChanges();
        }
      );
  }

  handleDblClickRow(event: any, row: any) {
    event.preventDefault();
    this.open(row);
  }

  create() {
    this.orgForm.reset();
    if (this.selectedOrg) {
      const autocompleteOrg = new Organization();
      autocompleteOrg.id = this.selectedOrg.data.id;
      autocompleteOrg.name = this.selectedOrg.data.name;
      this.orgForm.get('org').setValue(autocompleteOrg);
      this.orgForm.get('org').enable();
    }
    this.showDlgOrgRecord = true;
  }

  open(row: any) {
    if (!row) {
      return;
    }
    const orgNode: TreeNode = this.findOrgById(row.idPar);
    this.orgForm.reset();
    const {
      id, name, org, state, orgLevel
    } = this.orgForm.controls;
    id.setValue(row.id);
    name.setValue(row.name);
    org.setValue((orgNode && orgNode.data) ? orgNode.data : null);
    state.setValue({code: row.centre, name: row.centreName});
    orgLevel.setValue(('' + row.orgLevel));
    org.disable();
    this.showDlgOrgRecord = true;
  }

  private reloadData() {
    this.orgForm.reset();
    this.selectedOrg = null;
    this.loadOrgNodes();
  }

  save(value: any) {
    this.loadingSubject.next(true)
    const currOrg: any = {};
    currOrg.id = value.id;
    currOrg.name = value.name;
    currOrg.idPar = value.org?.id;
    currOrg.centre = value.state?.code;
    currOrg.orgLevel = value.orgLevel;
    this.dataService.saveOrganization(this._idProject, currOrg)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        data => {
          this.reloadData();
          this.messageService.showSuccess(this.translate.instant('message.org.save.success'));
          this.showDlgOrgRecord = false;
        },
        error => {
          this.messageService.showMessage(error, '[save]');
        }
      );
  }

  delete() {
    if (!this.selectedOrg) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('message.org.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => this.deleteOrg()
    });
  }

  private deleteOrg() {
    this.loadingSubject.next(true)
    this.dataService.delAdmOrganization(this._idProject, this.selectedOrg.data.id)
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        _ => {
          this.reloadData();
          this.messageService.showSuccess(this.translate.instant('message.org.delete.success'));
        },
        error => this.messageService.showMessage(error, '[delete]')
      );
  }

  get saveButtonDisabled(): boolean {
    return this.loadingSubject.value || !this.orgForm.valid;
  }

  get cancelButtonDisabled(): boolean {
    return this.loadingSubject.value;
  }

}
