import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Pageable} from "../../../_domains/spring/pageable";
import {ConfigService} from '../../../_services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }

  getUsers(obj: any): Observable<Pageable> {
    return this.http.post<Pageable>(`${this.configService.api.recordOwners}/list?page=${obj.page}`, obj.data);
  }

  addUsers(data: any): Observable<void> {
    return this.http.put<void>(`${this.configService.api.recordOwners}`, data);
  }

  deleteUsers(data: any): Observable<void> {
    return this.http.delete<void>(`${this.configService.api.recordOwners}`, {body: data});
  }
}
