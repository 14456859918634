import {Injectable} from '@angular/core';
import {ConfigService} from '../../../_services/config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pageable} from '../../../_domains/spring/pageable';

@Injectable()
export class DataAdminService {
  constructor(private configService: ConfigService,
              private http: HttpClient) {
  }

  getEvtConnects(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.log.connects + (filter || ''));
  }

  getEvtPublic(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.log.public + (filter || ''));
  }

  getEvtPrivate(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.log.private + (filter || ''));
  }

  getEvtTemplates(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.log.templates + (filter || ''));
  }

  getAdmListUsers(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.account.users + (filter || ''));
  }

  createUser(data: any): Observable<any> {
    return this.http.put<any>(this.configService.api.account.users, data);
  }

  saveUser(data: any): Observable<any> {
    return this.http.post<any>(this.configService.api.account.users, data);
  }

  deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${this.configService.api.account.users}/${id}`);
  }

  getUserVariables(idProject: string, idUser): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.oper}/${idProject}/users/${idUser}/variables`);
  }

  saveUserVariables(idProject: string, idUser, data: string): Observable<void> {
    return this.http.post<void>(`${this.configService.api.projects.oper}/${idProject}/users/${idUser}/variables`, data);
  }

  getProjectUsers(id: string, filter: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.oper}/${id}/users/unique?` + (filter || ''));
  }

  getProjectOneUserAcc(idProject: string, idUser: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.configService.api.projects.oper}/${idProject}/users/${idUser}`);
  }

  getProjectUsersAcc(id: string, filter: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.oper}/${id}/users/page?` + (filter || ''));
  }

  getAccessUserProjects(id: string, filter: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.users}/${id}/projects/page?` + (filter || ''));
  }

  addAccess2Projects(id: string, acc: any): Observable<any> {
    return this.http.put<any>(`${this.configService.api.projects.users}/${id}/projects/add`, acc);
  }

  updUserAccess2Project(idProject: string, idUser: string, acc: any): Observable<any> {
    return this.http.post<any>(`${this.configService.api.projects.oper}/${idProject}/users/${idUser}`, acc);
  }

  updUserAccess2ProjectDocument(idProject: string, cdoc: string, idUser: string, id: string, acc: any): Observable<void> {
    return this.http.post<void>(`${this.configService.api.projects.oper}/${idProject}/documents/${cdoc}/users/${idUser}/acc/${id}`, acc);
  }

  deleteAccessUserProjects(idUser: string, idProject: string): Observable<any> {
    return this.http.delete<any>(`${this.configService.api.projects.users}/${idUser}/projects/${idProject}`);
  }

  getListDictDocs(filter?: string): Observable<any> {
    return this.http.get(this.configService.api.dicts.docs + (filter || ''));
  }

  getUser(id: string): Observable<any> {
    return this.http.get(`${this.configService.api.account.users}/${id}`)
  }

  getAdmListHierarchies(filter?: string): Observable<any> {
    return this.http.get(this.configService.api.projects.oper + `?${filter || ''}`);
  }

  getAdmDicts(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.dicts.tables + `?${filter || ''}`);
  }

  uploadDicts(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.configService.api.dicts.tables}/upload`, formData);
  }

  downloadDicts(): Observable<void> {
    return this.http.get<void>(`${this.configService.api.dicts.tables}/download`);
  }

  uploadDictCountries(formData: FormData): Observable<any> {
    return this.http.post<any>(`${this.configService.api.dicts.countries}/upload`, formData);
  }

  getDictCountries(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.dicts.countries + `?${filter || ''}`);
  }

  getDictLabels(filter?: string): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.dicts.tags + `?${filter || ''}`);
  }


  createLabel(data: any): Observable<any> {
    return this.http.put<any>(this.configService.api.dicts.tags, data);
  }

  saveLabel(data: any): Observable<any> {
    return this.http.post<any>(this.configService.api.dicts.tags, data);
  }


  deleteLabel(tag: string): Observable<any> {
    return this.http.delete<any>(`${this.configService.api.dicts.tags}/${tag}`);
  }
}
