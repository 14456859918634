<p-table #dt
         [value]="data.content"
         [totalRecords]="data.totalElements"
         [rows]="data.size"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
         selectionMode="single" (onRowSelect)="handleRowSelect($event)"
         [paginator]="true" paginatorPosition="bottom"
         [scrollable]="true" [scrollHeight]="'calc(100vh - 240px)'"
         sortField="dateRec" [sortOrder]="0">
  <ng-template pTemplate="caption">
    {{tableHeader}}
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="w-5rem" pSortableColumn="iduser" *ngIf="isShowUserInformation">ID User
        <p-sortIcon field="iduser"></p-sortIcon>
      </th>
      <th class="w-4rem" pSortableColumn="username" *ngIf="isShowUserInformation">Login
        <p-sortIcon field="username"></p-sortIcon>
      </th>
      <th class="w-2rem" pSortableColumn="ipaddress">IP Address
        <p-sortIcon field="ipaddress"></p-sortIcon>
      </th>
      <th class="w-2-5rem" pSortableColumn="countryCode">Country Code
        <p-sortIcon field="countryCode"></p-sortIcon>
      </th>
      <th class="w-2-5rem" pSortableColumn="countryName">Country Name
        <p-sortIcon field="countryName"></p-sortIcon>
      </th>
      <th class="w-2-5rem" pSortableColumn="cityName">City Name
        <p-sortIcon field="cityName"></p-sortIcon>
      </th>
      <th class="w-3rem" pSortableColumn="dateRec">Date
        <p-sortIcon field="dateRec"></p-sortIcon>
      </th>
      <th class="w-6rem" pSortableColumn="deviceDetails">Device detail
        <p-sortIcon field="deviceDetails"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th *ngIf="isShowUserInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'iduser', 'eq')"/>
      </th>
      <th *ngIf="isShowUserInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'username', 'eq')"/>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'ipaddress', 'eq')"/>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'countryCode', 'eq')"/>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'countryName', 'contains')"/>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'cityName', 'contains')"/>
      </th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-i="rowIndex">
    <tr [pSelectableRow]="row">
      <td class="w-5rem" *ngIf="isShowUserInformation">{{row.iduser}}</td>
      <td class="w-4rem" *ngIf="isShowUserInformation">{{row.username}}</td>
      <td class="w-2rem">{{row.ipaddress}}</td>
      <td class="w-2-5rem">{{row.countryCode}}</td>
      <td class="w-2-5rem">{{row.countryName}}</td>
      <td class="w-2-5rem">{{row.cityName}}</td>
      <td class="w-3rem">{{row.dateRec}}</td>
      <td class="w-6rem">{{row.deviceDetails}}</td>
    </tr>
  </ng-template>
</p-table>
