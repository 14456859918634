import {ChangeDetectionStrategy, Component, computed, effect, input, OnInit, signal} from '@angular/core';
import {StatUtilsService} from '../_services/stat-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";
import {HttpErrorResponse} from "@angular/common/http";
import {of, Subject} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";

@Component({
  selector: 'app-stat-survey-answer-chart',
  templateUrl: './stat-survey-answer-chart.component.html',
  styleUrl: './stat-survey-answer-chart.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatSurveyAnswerChartComponent implements OnInit {
  data = signal<any>({});
  readonly BG_COLORS_NAMED = [
    {color: '#8cd17d', value: ['yes', 'likely']},
    {color: '#ff9d9a', value: ['no', 'veryunlikely']},
    {color: '#f1ce63', value: ['dontknow', 'neither']},
    {color: '#ffbe7d', value: ['unlikely']},
    {color: '#a0cbe8', value: ['verylikely']},
    {color: '#d3d3d3', value: ['__null_value']}
  ];
  readonly BG_COLORS = [
    '#ff9d9a', '#f1ce63', '#ffbe7d', '#8cd17d',
    '#a0cbe8', '#4e79a7', '#59a14f', '#b6992d',
    '#86bcb6', '#e15759', '#d37295'
  ];
  param = input.required<any>();
  agg = signal<string>('sum');
  chartOptionsTmpl = {
    responsive: true,
    indexAxis: 'y',
    datasets: {
      pie: {
        radius: '70%'
      }
    },
    plugins: {
      datalabels: {
        display: 'auto',
        anchor: 'end',
        align: 'end',
        textAlign: 'center',
        font: {
          family: '"Open Sans SemiBold", sans-serif',
          size: '10pt',
          weight: 'bold'
        },
        formatter: function (value, context) {
          return value === null ? '' : (value + '\n' + context.chart.data.labels[context.dataIndex]);
        }
      },
      legend: {
        display: true,
        position: 'right',
        font: {
          size: '9pt'
        },
        beforeInit: function (chart, options) {
          chart.legend.afterFit = function () {
            this.height = this.height + 350;
          }
        }
      }
    }
  };
  chartType = computed(() =>
    this.param().dataType === 'NUMBER' || this.param().dataType === 'INTEGER'
      ? 'number'
      : (this.param().editType.startsWith('checkgroup')
      || this.param().editType.startsWith('combobox')
      || this.param().editType.startsWith('select')
      || this.param().inputList.split(';').length > 6
        ? 'bar'
        : 'pie'));
  chartOptions = computed(() =>
    ({
      ...this.chartOptionsTmpl,
      plugins: {
        ...this.chartOptionsTmpl.plugins,
        datalabels: {
          ...this.chartOptionsTmpl.plugins.datalabels,
          formatter: this.chartType() !== 'bar' ? this.chartOptionsTmpl.plugins.datalabels.formatter : null
        },
        legend: {...this.chartOptionsTmpl.plugins.legend, display: this.chartType() !== 'bar'}
      }
    })
  );
  private filterSubject: Subject<any>;
  private firstLoad = true;

  constructor(private dataService: StatUtilsService,
              private messageService: AppMessageService) {
    this.initObservables();
    effect(() => this.loadData(this.param().cdoc, this.param().field));
    effect(() => {
      if (this.agg() && !this.firstLoad) {
        this.loadData(this.param().cdoc, this.param().field, this.agg());
      } else {
        this.firstLoad = false;
      }
    });
  }

  ngOnInit() {
  }

  private handleError(err: HttpErrorResponse) {
    this.messageService.showMessage(err, '[ngOnInit]')
    return of('ERROR');
  }

  private initObservables() {
    this.filterSubject = new Subject();
    this.filterSubject.asObservable()
      .pipe(
        // tap(() => this.loading = true),
        switchMap(v => this.dataService.getStatGrpDocuments(v.cdoc, v.field, v.aggMethod).pipe(catchError((err) => this.handleError(err))))
      ).subscribe({
        next: (data) => {
          if (typeof data !== 'string') {
            this.parseResponse(data);
          }
          // this.loading = false;
        },
        error: (error) => {
          this.messageService.showMessage(error, '[ngOnInit]')
          // this.loading = false;
        }
      }
    );
  }

  loadData(cdoc: string, field: string, aggMethod?: string) {
    this.filterSubject.next({cdoc, field, aggMethod});
  }

  private parseResponse(resp: any) {
    if (this.chartType() === 'number') {
      this.data.set({value: resp?.length ? resp[0]?.count : 0});
    } else {
      let labels = [];
      let values = [];
      // Form _labels
      let items = this.param().inputList.split(';');
      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        if (item.indexOf(':') == -1) {
          labels[i] = /*_item; */String.fromCharCode(i + 65);
          values[i] = item;
        } else {
          let _vals = item.split(':');
          labels[i] = _vals[1];
          values[i] = _vals[0];
        }
      }
      if (items.length) {
        labels[items.length] = 'N/A';
        values[items.length] = '__NULL_VALUE';//resp.find(v => v.value === '__NULL_VALUE')?.count;
      }
      // Form _data
      let _data = [];
      for (let i = 0; i < items.length; i++) {
        _data[i] = 0;
      }
      // Process resp
      for (let i in resp) {
        let r = resp[i];
        for (let k = 0; k < values.length; k++) {
          if (values[k] === r.value) {
            if (this.chartType() === 'pie' && r.count === 0) {
              _data[k] = null;
            } else {
              _data[k] = r.count;
            }
            break;
          }
        }
      }
      let bgColors = this.BG_COLORS;
      values.forEach((v, i) => {
        const bg = this.BG_COLORS_NAMED.find(c => c.value.indexOf(v.toLowerCase()) >= 0);
        if (bg) {
          bgColors[i] = bg.color;
        }
      });
      this.data.set({
        labels: labels,
        datasets: [
          {
            label: '',
            backgroundColor: bgColors,
            borderWidth: 1,
            data: _data
          }
        ]
      });
    }
  }
}
