<p-table [value]="projects.content"
         dataKey="id"
         [rows]="projects.size"
         [totalRecords]="projects.totalElements"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
         [paginator]="true" paginatorPosition="bottom"
         [resizableColumns]="true" [scrollable]="true"
         scrollHeight="340px"
         [style]="{ height: '390px' }"
         rowGroupMode="rowspan"
         groupRowsBy="project.name"
         sortField="project.name"
         [sortOrder]="1">
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col>
      <col style="width: 5rem;">
      <col style="width: 7rem;">
      <col style="width: 5rem;">
      <col style="width: 5rem;">
      <col style="width: 5rem;">
      <col style="width: 5rem;">
      <col style="width: 5rem;">
      <col style="width: 5rem;">
      <col style="width: 7rem;">
      <col style="width: 5rem;">
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <th class="w-5rem"></th>
      <col style="width: 8rem;">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="project.name" rowspan="2">Project Name
        <p-sortIcon field="project.name"></p-sortIcon>
      </th>
      <th rowspan="2">Organization</th>
      <th rowspan="2">Document</th>
      <th rowspan="2">Saved</th>
      <th colspan="2">View</th>
      <th colspan="7">Edit Options</th>
      <th class="w-5rem" rowspan="2">Download</th>
      <th class="w-5rem" rowspan="2">Upload</th>
      <th class="w-5rem" rowspan="2">Print</th>
      <th class="w-5rem" rowspan="2">Bulk upload</th>
      <th class="w-5rem" rowspan="2">Bulk review</th>
      <th rowspan="2">Document admin</th>
      <th rowspan="2">Questionnaire admin</th>
    </tr>
    <tr>
      <th>&nbsp;</th>
      <th>Level</th>
      <th>Create</th>
      <th>Edit</th>
      <th>Remove</th>
      <th>Reopen</th>
      <th>Submit</th>
      <th>Approve</th>
      <th>Level</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-rowgroup="rowgroup" let-rowspan="rowspan">
    <tr>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ row.project.name }}</td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan"><span [pTooltip]="row.orgName">{{ row.orgName }}</span></td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan"><span [pTooltip]="row.docName">{{ row.cdoc }}</span></td>
      <td *ngIf="rowgroup" [attr.rowspan]="rowspan">{{ row.docSavedCount }}</td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessView == true"/>
      </td>
      <td>
        {{ row.viewAcc == 0 ? 'Document' : (row.viewAcc == 2 ? 'Only user' : 'Organization') }}
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessCreate == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessWrite == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessRemove == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessReopen == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessSubmit == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessApprove == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessReopen == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.roleDownload == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.roleUpload == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.rolePrint == true"/>
      </td>
      <td>
        <input *ngIf="row.docMode === 3" type="checkbox" disabled [checked]="row.roleBulkUpload == true"/>
      </td>
      <td>
        <input *ngIf="row.docMode === 3" type="checkbox" disabled [checked]="row.roleBulkReview == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessDocumentAdmin == true"/>
      </td>
      <td>
        <input type="checkbox" disabled [checked]="row.accessProjectAdmin == true"/>
      </td>
    </tr>
  </ng-template>
</p-table>
