import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pageable} from "../../../_domains/spring/pageable";
import {of, Subject} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {AppMessageService} from "../../../_services/app-message.service";
import {catchError, finalize, switchMap, tap} from "rxjs/operators";
import {DataService} from "./data.service";
import {ConfirmationService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {User} from "../../../_domains/UITypes";

@Component({
  selector: 'project-document-users',
  templateUrl: './project-document-users.component.html',
  styleUrls: ['./project-document-users.component.css']
})
export class ProjectDocumentUsersComponent implements OnInit {
  @Input() idProject: string;
  @Input() idDocs: string[];
  @Input() projectAdmin: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  data: Pageable = new Pageable();
  loading: boolean;
  private filterSubject: Subject<any>;
  firstLoad = true;
  first: number;
  selected: any[];
  cmpVisible = false;
  private _visible = false;
  showDlgUsers = false;

  constructor(private messageService: AppMessageService,
              private dataService: DataService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService) {
    this.initObservables();
  }

  ngOnInit(): void {
  }

  @Input()
  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
    if (this._visible && !this.cmpVisible) {
      this.first = 0;
      this.selected = [];
      this.data = undefined;
      this.filterSubject.next({data: this.idDocs, page: 0});
      this.cmpVisible = true;
    } else if (!this._visible && this.cmpVisible) {
      this.cmpVisible = false;
    }
  }

  hide() {
    this.visibleChange.emit(false);
  }

  private handleError(err: HttpErrorResponse) {
    this.messageService.showMessage(err);
    return of('ERROR');
  }

  private initObservables() {
    this.filterSubject = new Subject();
    this.filterSubject.asObservable()
      .pipe(
        tap(_ => this.loading = true),
        switchMap(v => this.dataService.getUsers(v).pipe(catchError((err) => this.handleError(err))))
      ).subscribe((data) => {
        if (typeof data !== 'string') {
          data.content = data.content.reduce((a, b) => {
            const el = a.find((v: any) => v.idUser === b.idUser);
            if (el) {
              el.cnt++;
              el.idDocs.push(b.idDoc);
            } else {
              a.push({idUser: b.idUser, userName: b.userName, fullName: b.fullName, cnt: 1, idDocs: [b.idDoc]});
            }
            return a;
          }, []);
          this.data = data;
        }
        this.loading = false;
      },
      (err) => {
        this.messageService.showMessage(err);
        this.loading = false;
      }
    );
  }

  loadData(event) {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    this.first = event.first || 0;
    const page = this.first / (event.rows || 1);
    this.filterSubject.next({data: this.idDocs, page: page});
  }

  add() {
    this.showDlgUsers = true;
  }

  beforeDelete() {
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.record-owner.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => this.delete()
    });
  }

  delete() {
    if (!this.selected?.length) {
      return;
    }
    this.loading = true;
    const data = [];
    this.selected.forEach(v => this.idDocs.forEach(a => data.push({idUser: v.idUser, idDoc: a})));
    this.dataService.deleteUsers(data)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        _ => this.hide(),
        (err) => this.messageService.showMessage(err)
      );
  }

  addUsers(user: User) {
    const idUser = user?.id;
    if (!idUser) {
      return;
    }
    this.showDlgUsers = false;
    this.loading = true;
    this.dataService.addUsers(this.idDocs.map(v => ({idUser: idUser, idDoc: v})))
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        _ => this.filterSubject.next({data: this.idDocs, page: 0}),
        (err) => this.messageService.showMessage(err)
      );
  }

  save() {
    if (!this.data?.content?.length) {
      return;
    }
    this.loading = true;
    const data = [];
    this.data.content.forEach(v => this.idDocs.forEach(a => data.push({idUser: v.idUser, idDoc: a})));
    this.dataService.addUsers(data)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        _ => this.hide(),
        (err) => this.messageService.showMessage(err)
      );
  }

  get addButtonDisabled(): boolean {
    return this.loading;
  }

  get deleteButtonDisabled(): boolean {
    return this.loading || !this.selected?.length;
  }

  get saveButtonDisabled(): boolean {
    return this.loading || !this.data?.content?.length;
  }

  get closeButtonDisabled(): boolean {
    return this.loading;
  }
}
