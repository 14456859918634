import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DocumentUtilsPublicService} from "../../doceditor/_services/document-utils-public.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {TokensStoreService} from "../../../_services/tokens-store.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-proxy-token',
  template: `<div style="width: 500px; height: 200px; position: relative; left: 50%; top: 100px; transform: translate(-50%, -50%);">
                <div style="float: left;" *ngIf="processing"><i class="fas fa-spinner" style="font-size: 30px; color: cornflowerblue;"></i>&nbsp;</div>
                <div style="float: left; text-align: center;"><span class="status-process">{{statusMessage}}</span></div>
            </div>`,
  styles: [
    ` .status-process {
        color: #22314A;
        font-weight: bold;
        font-size: 1.1rem;
      }`
  ]
})
export class ProxyTokenComponent implements OnInit {
  statusMessage = 'Checking query parameters...';
  processing: boolean = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: AppMessageService,
              private tokenService: TokensStoreService,
              private docService: DocumentUtilsPublicService) { }

  ngOnInit(): void {
    const _tokenId: string = this.route.snapshot.queryParamMap.get('token_id') || '*';

    if (_tokenId === "*" || _tokenId.length === 0) {
      this.statusMessage = "Invalid tokenId";
      return;
    }

    const _key: string = this.tokenService.getClientId(_tokenId)
    this.statusMessage = 'Please wait...Checking token...';
    if (_key === '') {
      this._createClientIdAndCheck(_tokenId);
    } else {
      this._checkClientId(_tokenId, _key);
    }
  }

  private _createClientIdAndCheck(tokenId: string) {
    const clientId: string = this.tokenService.generateClient();
    this.docService.checkClientToken(tokenId, clientId)
      .pipe(finalize(() => { this.processing = false; }))
      .subscribe(
        data => {
          const res = data;
          if (res.status !== 1) {
            const v: string = "Sorry, your link has expired or already been used. To protect your data, please come back to your survey and copy the link again.";
            this.router.navigate(['/public/error'], { queryParams: { v:  v }, skipLocationChange: true });
          } else {
            let _queryParams = { };
            _queryParams["id"] = res.message;
            this.statusMessage = 'Redirect to editing...';
            // save to local DB
            this.tokenService.save(tokenId, clientId);
            // route to edit mode
            this.router.navigate(['/public/report/view'], { queryParams : _queryParams, skipLocationChange: true });
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  private _checkClientId(tokenId: string, clientId: string) {
    this.docService.checkClientToken(tokenId, clientId)
      .pipe(finalize(() => { this.processing = false; }))
      .subscribe(
        data => {
          const res = data;
          if (res.status !== 1) {
            this.statusMessage = "Sorry, your link has expired or already been used. To protect your data, please come back to your survey and copy the link again.";
            this.tokenService.del(tokenId);
          } else {
            let _queryParams = { };
            _queryParams["id"] = res.message;
            this.statusMessage = 'Redirect to editing...';
            // route to edit mode
            this.router.navigate(['/public/report/view'], { queryParams : _queryParams, skipLocationChange: true });
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }
}
