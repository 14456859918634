import {RouterModule, Routes} from '@angular/router';
import {ProjectRecordComponent} from './project-record/project-record.component';
import {ProjectButtonsComponent} from './project-buttons/project-buttons.component';
import {ProjectWorkflowComponent} from './project-workflow/project-workflow.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentModule} from 'ngx-moment';
import {ProjectStartComponent} from './project-start/project-start.component';
import {AddProjectComponent} from './add-project/add-project.component';
import {ImportModule} from '../import/import.module';
import {ProjectUtilsService} from './_services/project-utils.service';
import {StatisticModule} from '../statistic/statistic.module';
import {BuilderListTemplatesComponent} from './builder-list-templates/builder-list-templates.component';
import {ProjectDocumentsComponent} from './project-documents/project-documents.component';
import {ProjectDocumentRecordComponent} from './project-document-record/project-document-record.component';
import {ProjectDocumentRulesComponent} from './project-document-rules/project-document-rules.component';
import {ProjectDocumentParamsComponent} from './project-document-params/project-document-params.component';
import {ProjectDocumentOrderComponent} from './project-document-order/project-document-order.component';
import {ProjectDocumentChartsComponent} from './project-document-charts/project-document-charts.component';
import {TemplateUtilsPrivateService} from '../doceditor/_services/template-utils.service';
import {ProjectSummaryComponent} from './project-summary/project-summary.component';
import {DoceditorModule} from '../doceditor/doceditor.module';
import {ProjectFilesComponent} from './project-files/project-files.component';
import {ProjectFilesTileComponent} from './project-files-tile/project-files-tile.component';
import {ProjectUsersComponent} from './project-users/project-users.component';
import {AddProjectUserComponent} from './add-project-user/add-project-user.component';
import {ClipboardModule} from 'ngx-clipboard';
import {BuilderTemplateRecordComponent} from './builder-template-record/builder-template-record.component';
import {
  DataColumnClassPipe,
  OutputErrorName,
  OutputErrorNameWoUser,
  QueryDataComponent
} from './query-data/query-data.component';
import {QueryTabDataComponent} from './query-tab-data/query-tab-data.component';
import {AdminModule} from '../admin/admin.module';
import {LayoutFooterModule} from '../layout-footer/layout-footer.module';
import {ProjectDataComponent} from './project-data/project-data.component';
import {TableModule} from 'primeng/table';
import {SplitButtonModule} from 'primeng/splitbutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TreeTableModule} from 'primeng/treetable';
import {ButtonModule} from 'primeng/button';
import {ToolbarModule} from 'primeng/toolbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {InputMaskModule} from 'primeng/inputmask';
import {PanelModule} from 'primeng/panel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextModule} from 'primeng/inputtext';
import {ConfirmationService} from 'primeng/api';
import {MessageModule} from 'primeng/message';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TabViewModule} from 'primeng/tabview';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {PickListModule} from 'primeng/picklist';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {BlockUIModule} from 'primeng/blockui';
import {ShowErrorModule} from '../show-error/show-error.module';
import {MultiSelectModule} from 'primeng/multiselect';
import {BadgeModule} from 'primeng/badge';
import {TagModule} from 'primeng/tag';
import {ProjectDocumentUsersComponent} from './project-document-users/project-document-users.component';
import {ListboxModule} from 'primeng/listbox';
import {EmailTmplModule} from '../email-tmpl/email-tmpl.module';
import {CustomPipesModule} from '../../_services/CustomPipesModule';
import {ProjectOrgsComponent} from './project-orgs/project-orgs.component';
import {DialogDocInfoComponent} from "./dialog-doc-info/dialog-doc-info.component";
import {DialogDatastageWorkflowComponent} from "./dialog-datastage-workflow/dialog-datastage-workflow.component";
import {DialogDownloadOptionsComponent} from "./dialog-download-options/dialog-download-options.component";
import {GeoChartsComponent} from "./geo-charts/geo-charts.component";
import {DataChartsComponent} from "./data-charts/data-charts.component";
import {Ng2GoogleChartsModule} from "ng2-google-charts";
import {ChartModule} from "primeng/chart";
import {ToggleButtonModule} from "primeng/togglebutton";
import {InputNumberModule} from "primeng/inputnumber";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {CustomButtonPipe} from "../../pipes/custom-button.pipe";
import {ColorPickerModule} from "primeng/colorpicker";
import {ProjectBiComponent} from "./project-bi/project-bi.component";

const routes: Routes = [
  {path: 'view/:id', component: ProjectStartComponent},
  {path: 'add', component: AddProjectComponent},
  {path: ':id/data', component: ProjectDataComponent}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    ImportModule,
    DoceditorModule,
    ButtonModule,
    TableModule,
    CalendarModule,
    ConfirmDialogModule,
    MomentModule, InputMaskModule, CalendarModule, MessageModule, CheckboxModule, RadioButtonModule, PanelModule,
    DropdownModule, ToolbarModule, DialogModule, BreadcrumbModule, SplitButtonModule, TabViewModule,
    TooltipModule, AutoCompleteModule, InputTextareaModule, InputTextModule, TreeTableModule, PickListModule,
    FileUploadModule, ScrollPanelModule, BlockUIModule, AdminModule, LayoutFooterModule, ShowErrorModule,
    MultiSelectModule, BadgeModule, ListboxModule, EmailTmplModule, TagModule, CustomPipesModule, StatisticModule, Ng2GoogleChartsModule, ChartModule, ToggleButtonModule, InputNumberModule, OverlayPanelModule, ColorPickerModule
  ],
  declarations: [
    AddProjectComponent,
    ProjectWorkflowComponent,
    ProjectRecordComponent,
    ProjectButtonsComponent,
    ProjectStartComponent,
    BuilderListTemplatesComponent,
    BuilderTemplateRecordComponent,
    ProjectDocumentsComponent,
    ProjectDocumentRecordComponent,
    ProjectDocumentRulesComponent,
    ProjectDocumentChartsComponent,
    ProjectDocumentParamsComponent,
    ProjectDocumentOrderComponent,
    ProjectSummaryComponent,
    ProjectFilesComponent,
    ProjectFilesTileComponent,
    ProjectUsersComponent,
    AddProjectUserComponent,
    BuilderTemplateRecordComponent,
    QueryDataComponent,
    QueryTabDataComponent,
    ProjectDataComponent,
    DataColumnClassPipe,
    OutputErrorName,
    OutputErrorNameWoUser,
    ProjectDocumentUsersComponent,
    ProjectOrgsComponent,
    ProjectBiComponent,
    DialogDocInfoComponent,
    DialogDatastageWorkflowComponent,
    DialogDownloadOptionsComponent,
    GeoChartsComponent,
    DataChartsComponent,
    CustomButtonPipe
  ],
  exports: [
    ProjectFilesTileComponent,
    DialogDocInfoComponent,
    DialogDatastageWorkflowComponent,
    DialogDownloadOptionsComponent,
    DataChartsComponent,
    GeoChartsComponent
  ],
  providers: [
    ProjectUtilsService,
    TemplateUtilsPrivateService,
    ConfirmationService
  ]
})
export class ProjectModule {
}
