<div>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="pi pi-pencil"
              [disabled]="selected == null" (click)="openDlgLabelRecord(false)" pTooltip="Edit the label"></button>
      <button pButton type="button" icon="pi pi-trash"
              [disabled]="selected == null || selected.customized!==1" (click)="delete()"
              pTooltip="Remove the custom label"></button>

    </div>
  </p-toolbar>

  <p-table #dtb
           [value]="buttons"
           [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
           (onRowSelect)="handleRowSelect($event)" selectionMode="single" [selection]="selected"
           [paginator]="false"
           [resizableColumns]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 290px)'"
           sortField="button" [sortOrder]="1">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col/>
        <col/>
        <col/>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="button">Button path
          <p-sortIcon field="button"></p-sortIcon>
        </th>
        <th>Preview</th>

        <th pSortableColumn="icon">Icon
          <p-sortIcon field="icon"></p-sortIcon>
        </th>
        <th pSortableColumn="blabel">Label
          <p-sortIcon field="blabel"></p-sortIcon>
        </th>
        <th pSortableColumn="tooltip">Tool tip
          <p-sortIcon field="tooltip"></p-sortIcon>
        </th>
        <!--        <th pSortableColumn="lang">Language-->
        <!--          <p-sortIcon field="lang"></p-sortIcon>-->
        <!--        </th>-->
      </tr>
      <tr>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dtb.filter($event.target.value, 'button', 'contains')"/>
        </th>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dtb.filter($event.target.value, 'icon', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dtb.filter($event.target.value, 'blabel', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dtb.filter($event.target.value, 'tooltip', 'contains')"/>
        </th>
<!--        <th>-->
<!--          <input pInputText type="text" [style.width]="'99%'"-->
<!--                 (change)="dtb.filter($event.target.value, 'lang', 'contains')"/>-->
<!--        </th>-->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row" (dblclick)="handleDblRowClick($event, row)">

        <td [class]="rowStyleClass(row)">
          <i [pTooltip]="row.buttonType + (row?.customized>0?' customized':' default')">{{row.button}}</i>
        </td>
        <td>
          <button *ngIf="row?.buttonType==='button' ||row?.buttonType===undefined " pButton type="button"
                  icon="{{row.icon}}"
                  pTooltip="{{row.tooltip}}" label="{{row.blabel}}"
                  (click)="emptyCommand(row.button)"

          ></button>
          <p-splitButton *ngIf="row?.buttonType!==undefined && row?.buttonType==='split_button'" appendTo="body"
                         icon="{{row.icon}}"
                         [model]="itemsSplitButton(row.button)" iconPos="left"
                         (click)="emptyCommand(row.button)"
                         pTooltip="{{row.tooltip}}" label="{{row.blabel}}"></p-splitButton>

        </td>
        <td [class]="rowStyleClass(row)">{{row.icon}}</td>
        <td [class]="rowStyleClass(row)">{{row.blabel}}</td>
        <td [class]="rowStyleClass(row)">{{row.tooltip}}</td>
        <!--        <td>{{row.lang}}</td>-->

        <!--        <td>-->
        <!--          <i pTooltip="customized" *ngIf="row.customized >0 " class="pi pi-plus"-->
        <!--             style="color:darkblue" aria-hidden="true"></i>-->
        <!--          <i pTooltip="system default" *ngIf="row.customized ==0" aria-hidden="true">no</i>-->
        <!--        </td>-->


      </tr>
    </ng-template>
  </p-table>

  <p-dialog header="Label details" [(visible)]="showDlgLabelRecord" [closable]="false"
            [modal]="true" [style]="{ minWidth: '800px' }" [maximizable]="false" [baseZIndex]="10000">
    <form [formGroup]="ButtonsEditForm" (ngSubmit)="save(ButtonsEditForm.value)">
      <div class="p-fluid">
        <div class="row form-group">
          <div class="col-4">Label</div>
          <div class="col-8">
            <input pInputText formControlName="button" class="form-control" type="text"/>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">Label</div>
          <div class="col-8">
            <input pInputText formControlName="blabel" class="form-control" type="text"/>
          </div>
        </div>


        <div class="row form-group">
          <div class="col-4">
            Icon name
            <i class="pi pi-question-circle info"
            ></i>
            <p-overlayPanel #opi [showCloseIcon]="false" [style]="{width: '450px'}">
              <ng-template pTemplate>
                <pre>
put there icon name like pi pi-question-circle <b></b>
or like
<b>fa fa-chart-area</b>
or like
<b>fas fa-share-square</b>
                  </pre>
              </ng-template>
            </p-overlayPanel>
          </div>
          <div class="col-8">
            <input pInputText formControlName="icon" class="form-control" type="text" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="ButtonsEditForm.get('icon')"></app-show-error>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-4">Tool tip</div>
          <div class="col-8">
            <input pInputText formControlName="tooltip" class="form-control" type="text"/>
          </div>
        </div>


      </div>

      <div class="row float-right">
        <div class="col-12">
          <button pButton type="submit" icon="pi pi-save" label="Save"
                  [disabled]="saveButtonDisabled"></button>
          <button pButton type="button" icon="pi pi-times"
                  [disabled]="cancelButtonDisabled"
                  (click)="showDlgLabelRecord = false" label="Close"></button>
        </div>
      </div>
    </form>
  </p-dialog>

</div>
