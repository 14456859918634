import {Component, Input, OnInit} from '@angular/core';
import {StatUtilsService} from '../_services/stat-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
  selector: 'app-stat-surveysCommon',
  template: `
    <p-panel [header]="header" [toggleable]="toggleable">
      <p-chart type="pie" [data]="dataSurveys"></p-chart>
      <ng-template pTemplate="footer">
        {{footer}}
      </ng-template>
    </p-panel>`
})
export class StatSurveysCommonComponent implements OnInit {
  @Input() toggleable: boolean;
  @Input() sColorSurveysNew = '';
  @Input() sColorSurveysDraft = '';
  @Input() sColorSurveysSent = '';
  @Input() sColorFormsDraft = '';
  @Input() sHoverColorSurveysNew = '';
  @Input() sHoverColorSurveysDraft = '';
  @Input() sHoverColorSurveysSent = '';
  @Input() sHoverColorFormsDraft = '';
  @Input() enableInit: boolean;
  dataSurveys: any;
  cntSurveys = 0;
  cntCrfs = 0;

  private grType = 0;
  private _docName = '';

  constructor(private messageService: AppMessageService,
              private dataService: StatUtilsService) {
  }

  ngOnInit() {
    this.toggleable = this.toggleable || false;
    if (this.enableInit) this.getSurveysStat(null, null);
  }

  clear() {
    this.dataSurveys = undefined;
    this.grType = undefined;
  }

  refresh(idProject?: string, grType?: number) {
    this.grType = grType;
    this.getSurveysStat(idProject, grType);
  }

  get header(): string {
    if (this._docName.length > 0) {
      return `Total summary "${this._docName}"`;
    }
    return 'Total summary';
  }

  get footer(): string {
    if (this.grType == 1) {
      return `Total forms: ${this.cntCrfs}`;
    } else if (this.grType == 2) {
      return `Total surveys: ${this.cntSurveys}`;
    }
    return `Total surveys: ${this.cntSurveys}, Total forms: ${this.cntCrfs}`;
  }

  private getSurveysStat(cdoc: string, grtype: number) {
    this.dataService.getStatListSurveys(cdoc, grtype,"CountryISO3Code,MissionCity,InstitutionName,id")
      .subscribe(data => {
          this.calculateSurveysStat(data);
        },
        error => {
          this.messageService.showError(error, 'Error receiving data');
          console.log(error);
        });
  }

  private calculateSurveysStat(data: any) {
    let cntSurveys_0 = 0, cntSurveys_1 = 0, cntSurveys_2 = 0;
    let cntCrfs_1 = 0, cntCrfs_2 = 0;
    for (let i in data) {
      let rec = data[i];
      if (rec.grtype === 2) { // Survey
        if (rec.state == 2) { // Submited
          cntSurveys_2 += rec.cnt;
        } else if (rec.state == 1) { // Draft
          cntSurveys_1 += rec.cnt;
        } else { // Opened
          cntSurveys_0 += rec.cnt;
        }
      } else if (rec.grtype == 1) { // Form
        if (rec.state > 1) { // Submitted
          cntCrfs_2 += rec.cnt;
        } else { // Draft + Opened
          cntCrfs_1 += rec.cnt;
        }
      }
    }
    this.cntSurveys = cntSurveys_0 + cntSurveys_1 + cntSurveys_2;
    this.cntCrfs = cntCrfs_1 + cntCrfs_2;

    if (this.grType == 1) {
      this.dataSurveys = {
        labels: ['Forms'],
        datasets: [
          {
            data: [cntCrfs_1],
            backgroundColor: [this.sColorFormsDraft],
            hoverBackgroundColor: [this.sHoverColorFormsDraft]
          }]
      };
    } else if (this.grType == 2) {
      this.dataSurveys = {
        labels: ['Submitted', 'Saved', 'Opened'],
        datasets: [
          {
            data: [cntSurveys_2, cntSurveys_1, cntSurveys_0, cntCrfs_1],
            backgroundColor: [this.sColorSurveysSent, this.sColorSurveysDraft, this.sColorSurveysNew],
            hoverBackgroundColor: [this.sHoverColorSurveysSent, this.sHoverColorSurveysDraft, this.sHoverColorSurveysNew]
          }]
      };
    } else {
      this.dataSurveys = {
        labels: ['Surveys submitted', 'Surveys draft', 'Surveys opened', 'Forms'],
        datasets: [
          {
            data: [cntSurveys_2, cntSurveys_1, cntSurveys_0, cntCrfs_1],
            backgroundColor: [this.sColorSurveysSent, this.sColorSurveysDraft, this.sColorSurveysNew, this.sColorFormsDraft],
            hoverBackgroundColor: [this.sHoverColorSurveysSent, this.sHoverColorSurveysDraft, this.sHoverColorSurveysNew, this.sHoverColorFormsDraft]
          }]
      };
    }
  }
}
