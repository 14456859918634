import {Injectable} from '@angular/core';
import {ConfigService} from 'src/app/_services/config.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class RegistrationService {
  constructor(private configService: ConfigService,
              private http: HttpClient) {
  }

  userRegistration(cdoc: string): Observable<any> {
    return this.http.post(`${this.configService.api.account.registration}`, cdoc);
  }
}
