<div id="datatab-desc-text" class="row">
  <div class="col-12" *ngIf="dataMode!==4  && dataMode!==3 && descriptiveText">
    <div [innerHTML]="descriptiveText"></div>
  </div>
</div>
<p-toolbar *ngIf="showMode === 0"> <!-- Data mode -->
  <ng-container>
    <div class="p-toolbar-group-left">
      <p-button *ngIf="isShowBtnDocCreate() && docs.length === 1 " (click)="docCreate()"
                [icon]="uiButtons | customButton : 'icon' : '/data/add' :'pi pi-plus'"
                [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/add' :'Create new document'"
                [label]="uiButtons | customButton : 'label' : '/data/add' : ''"
      ></p-button>
      <p-splitButton *ngIf="isShowBtnDocCreate() && docs.length > 1" [model]="docs" iconPos="left"
                     [icon]="uiButtons | customButton : 'icon' : '/data/add' : 'pi pi-plus'"
                     [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/add' :  'Create new document'"
                     [label]="uiButtons | customButton : 'label' : '/data/add' : ''"
      ></p-splitButton>
      <button pButton type="button"
              *ngIf="showBtnDocOpen" [disabled]="isDisabledBtnDocOpen()" (click)="docOpen()"
              [icon]="uiButtons | customButton : 'icon' : '/data/open' : 'pi pi-pencil'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/open' : 'Open the document data'"
              [label]="uiButtons | customButton : 'label' : '/data/open' : ''"
      ></button>
      <button pButton type="button"
              *ngIf="showBtnDocInfo"
              [disabled]="isDisabledBtnInfo()" (click)="docInfo(dataMode,null)"
              [icon]="uiButtons | customButton : 'icon' : '/data/info' : 'pi pi-info'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/info' : 'Info'"
              [label]="uiButtons | customButton : 'label' : '/data/info' : ''"
      ></button>
      <button *ngIf="hasApproveRole && (dataMode === 3)" pButton type="button"
              [disabled]="isDisabledBtnRejectApprove" (click)="datastageWorkflow(dataMode,null)"
              [icon]="uiButtons | customButton : 'icon' : '/data/approve' : 'pi pi-check-square'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/approve' : 'Approve/reject'"
              [label]="uiButtons | customButton : 'label' : '/data/approve' : ''"
      ></button>
      <button pButton type="button"
              *ngIf="isShowBtnDocDuplicate() && dataMode!==3 && dataMode!==4" [disabled]="isDisabledBtnDocDuplicate()"
              (click)="docDuplicate()"
              [icon]="uiButtons | customButton : 'icon' : '/data/duplicate' : 'pi pi-copy'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/duplicate' : 'Duplicate the record(s)'"
              [label]="uiButtons | customButton : 'label' : '/data/duplicate' : ''"
      ></button>
      <button pButton type="button"
              *ngIf="isShowBtnDocRemove()" [disabled]="isDisabledBtnDocRemove()" (click)="docDelete()"
              [icon]="uiButtons | customButton : 'icon' : '/data/remove' : 'pi pi-trash'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/remove' : 'Remove the record(s)'"
              [label]="uiButtons | customButton : 'label' : '/data/remove' : ''"
      ></button>
      <button pButton type="button"
              *ngIf="isShowBtnDocSend() && dataMode!==4  && dataMode!==3" [disabled]="isDisabledBtnDocSend()"
              (click)="docSend()"
              [icon]="uiButtons | customButton : 'icon' : '/data/send' : 'fas fa-share-square'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/send' : 'Submit the document'"
              [label]="uiButtons | customButton : 'label' : '/data/send' : ''"
      ></button>
      <p-splitButton *ngIf="isShowBtnChangeOwner"
                     [disabled]="isDisabledBtnChangeOwner"
                     [model]="itemsAdmin" iconPos="left"
                     (onDropdownClick)="itemsAdminOptionClick()"
                     [icon]="uiButtons | customButton : 'icon' : '/data/admin' : 'pi pi-users'"
                     [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/admin' : 'Admin Options'"
                     [label]="uiButtons | customButton : 'label' : '/data/admin' : ''"
      ></p-splitButton>

      <p-splitButton *ngIf="isShowBtnDownloadUpload"
                     [disabled]="isDisabledBtnDownloadUpload"
                     [model]="itemsExcel" iconPos="left"
                     (onDropdownClick)="itemsExcelDropdownClick()"
                     [icon]="uiButtons | customButton : 'icon' : '/data/download' : 'pi pi-file-excel'"
                     [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/download' : 'Download/Upload the document data to/from Excel file'"
                     [label]="uiButtons | customButton : 'label' : '/data/download' : ''"
      ></p-splitButton>

      <p-splitButton *ngIf="hasApproveRole &&  (dataMode === 4) "
                     [disabled]="isDisabledBtnRejectApprove"
                     [model]="itemsRejectAprove" iconPos="left"
                     (onDropdownClick)="itemsRejectAproveClick()"
                     [icon]="uiButtons | customButton : 'icon' : '/data/detApprove' : 'pi pi-check-square'"
                     [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/detApprove' : 'Approve/Reject set status to data'"
                     [label]="uiButtons | customButton : 'label' : '/data/detApprove' : ''"
      ></p-splitButton>

      <!-- <p-splitButton *ngIf="dataMode!==3 && dataMode!==4" [disabled]="isDisabledBtnLogs" [hidden]="typeAdmin == 0"
                     [model]="itemsLogs" iconPos="left"
                     [icon]="uiButtons | customButton : 'icon' : '/data/log' : 'far fa-list-alt'"
                     [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/log' : 'View activity logs'"
                     [label]="uiButtons | customButton : 'label' : '/data/log' : ''"
      ></p-splitButton> -->
      <button pButton type="button" (click)="copyLink()"
              *ngIf="showBtnCopyLink" [disabled]="isDisabledBtnCopyLink()"
              [icon]="uiButtons | customButton : 'icon' : '/data/clone' : 'pi pi-clone'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/clone' : 'Copy public link'"
              [label]="uiButtons | customButton : 'label' : '/data/clone' : ''"
      ></button>
      <button pButton type="button"
              [disabled]="isDisabledBtnReset"
              (click)="resetFilter()"
              [icon]="uiButtons | customButton : 'icon' : '/data/refresh' : 'pi pi-undo'"
              [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/refresh' : 'Refresh data'"
              [label]="uiButtons | customButton : 'label' : '/data/refresh' : ''"
      ></button>

      <p-splitButton *ngIf="configService.isDesktopDevice && dataMode!==3 && !isDisabledBtnCharts"
                     [disabled]="isDisabledBtnCharts"
                     [model]="itemsCharts" iconPos="left"
                     [icon]="uiButtons | customButton : 'icon' : '/data/chart' : 'fa fa-line-chart'"
                     [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/chart' : 'View charts'"
                     [label]="uiButtons | customButton : 'label' : '/data/charth' : ''"
      ></p-splitButton>

    </div>
  </ng-container>

  <div class="p-toolbar-group-right" [class.d-none]="configService.isMobileDevice">
    <project-builder-templates [idProject]="idProject" [parent]="this"
                               [uiButtons]="uiButtons"
                               [defaultTemplate]="defaultTemplate"
                               (templateChanged)="onTemplateChanged($event)"
                               (templateOpers)="onTemplateOper($event)"></project-builder-templates>
  </div>
</p-toolbar>

<div *ngIf="showMode === 1" style="padding-top: 2px;"> <!-- Template mode-->
  <project-builder-templateRecord [idProject]="idProject"
                                  (templateSaved)="onSavedTemplate($event)"></project-builder-templateRecord>
</div>

<div style="padding-top: 2px;" *ngIf="showMode === 0"> <!-- Data mode-->
  <!--    ProjectBuilderData-->
  <p-table #projectBuilderData
           [value]="projectData.content"
           [totalRecords]="projectData.totalElements"
           [rows]="pageSize"
           [rowsPerPageOptions]="configService.isDesktopDevice ? [20,50,100] : null"
           [lazy]="true" (onLazyLoad)="onProjectDataLazyLoad($event)" [loading]="loading"
           selectionMode="multiple"
           [(selection)]="dataSelectedRows"
           [paginator]="true"
           paginatorPosition="bottom"
           [scrollable]="true" [scrollHeight]="scrollHeight"
           scrollDirection="both"
           sortField="createDate" [sortOrder]="0">
    <ng-template pTemplate="headergrouped">
      <tr>
        <th class="selection" rowspan="3">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>

        <ng-container *ngFor="let column of visibleColumns"> <!-- For .. -->
          <!-- Fields without sort -->
          <ng-container *ngIf="column.sortField == null">
            <th
              class="header-field datatype-{{column.dataType.toLowerCase()}} header-field-{{column.name.toLowerCase()}}"
              [class.required]="column.required">
              {{column.header | removeHtml | trim:40}}
            </th>
          </ng-container>
          <!-- Fields with sort -->
          <ng-container *ngIf="column.sortField != null">
            <th pSortableColumn="{{column.sortField || column.name}}"
                class="header-field {{getAsteriskClass(column)}} datatype-{{column.dataType.toLowerCase()}} header-field-{{column.name.toLowerCase()}}"
            >
              {{column.header | removeHtml | trim:40}}
              <p-sortIcon field="{{column.sortField}}" *ngIf="column.sortField"></p-sortIcon>
              <ng-container *ngIf="(column.filterable || false) === true">
                <p-columnFilter *ngIf="column.name === 'STATUS'" [field]="column.name" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                [showApplyButton]="false"
                                class="p-ml-auto">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="statusList" placeholder="Any"
                                   (onChange)="filter($event.value)">
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <ng-container *ngIf="column.name !== 'STATUS' && (column.filterable || false) === true">
                  <ng-container [ngSwitch]="column.dataType">
                    <p-columnFilter *ngSwitchCase="'INTEGER'"
                                    type="numeric" [field]="column.name"
                                    [showAddButton]="false" [showOperator]="false"
                                    display="menu" class="p-ml-auto">
                      <ng-template pTemplate="header">
                      </ng-template>
                    </p-columnFilter>

                    <p-columnFilter *ngSwitchCase="'NUMBER'"
                                    type="numeric" [field]="column.name" display="menu"
                                    [showAddButton]="false" [showOperator]="false" [maxFractionDigits]="2"
                                    class="p-ml-auto">
                    </p-columnFilter>

                    <p-columnFilter *ngSwitchCase="'DATE'"
                                    type="date" [field]="column.name" display="menu"
                                    [showAddButton]="false" [showOperator]="false"
                                    class="p-ml-auto">
                    </p-columnFilter>

                    <p-columnFilter *ngSwitchDefault [field]="column.name"
                                    display="menu" [showAddButton]="false" [showOperator]="false"
                                    matchMode="contains"
                                    class="p-ml-auto">
                    </p-columnFilter>
                  </ng-container>
                </ng-container>
              </ng-container>
            </th>
          </ng-container>
          <th *ngIf="column.name === 'STATUS' && dataMode===3" class="header-field datatype-table header-field-cdoc ">
            Log File
          </th>

        </ng-container>

      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr (dblclick)="onDblRowClick($event, row)" [class.p-highlight]="isSelected(row.ID)">
        <td class="selection">
          <p-tableCheckbox [value]="row"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let column of visibleColumns">

          <td *ngIf="column.name === 'STATUS'"
              [ngClass]="row.STATE | dataColumnClass"
              class="data-field datatype-{{column.dataType.toLowerCase()}} field-{{column.name.toLowerCase()}}"
              pTooltip="{{includeMetadata? (row | outputErrorName): (row | OutputErrorNameWoUser)}}">
            <div class="row align-items-center">
              <div [ngSwitch]="errorStatus(row)"
                   class="col-auto pr-0">
                <i class="fa fa-question w-1-5rem" style="color: orange!important;" *ngSwitchCase="3"
                   (click)="docInfo(dataMode,row)"></i>
                <i class="fas fa-exclamation-triangle w-1-5rem" style="color: red!important;" *ngSwitchCase="2"
                   (click)="docInfo(dataMode,row)"></i>
                <i class="fa fa-check w-1-5rem" style="color: green!important;" *ngSwitchCase="1"
                   (click)="docInfo(dataMode,row)"></i>
                <i class="fa fa-question w-1-5rem" style="color: grey!important;" *ngSwitchDefault
                   (click)="docInfo(dataMode,row)"></i>
              </div>
              <div class="col pl-0" [innerHTML]="row[column.field]"></div>
              <div *ngIf="!row.VIEWED" class="col-auto pl-0">
                <i class="pi pi-circle-on float-right status-new"></i>
              </div>
            </div>
          </td>
          <td *ngIf="column.name === 'STATUS' && dataMode===3 ">
            {{row['AttachmentFileDataUploadStat'] | trim:40}}
            <span class="pr-2">
              <i
                *ngIf="( (row['AttachmentFileDataUploadLog'] !== null ) && (row['AttachmentFileDataUploadLog']+'' !== 'undefined' )&& (row['AttachmentFileDataUploadLog']+'' !== '' ))"
                class="far fa-file-alt w-1-5rem" style="color: brown!important;"
                (click)="downloadLog(row['AttachmentFileDataUploadLog']+'')"></i>
            </span>
          </td>

          <td *ngIf="column.name != 'STATUS'"
              class="data-field datatype-{{column.dataType.toLowerCase()}} field-{{column.name.toLowerCase()}}"
              [pTooltip]="column.dataType === 'TABLE' || column.dataType === 'SUBFORM'? '' : row[column.field]">
            <a *ngIf="column.editType === 'file'" href="javascript:void(0)"
               (click)="downloadFileData(row.ID, row[column.field + 'Id'])">
              {{row[column.field] | trim:40}}
            </a>
            <a *ngIf="column.dataType === 'TABLE' && row[column.field]" href="javascript:void(0)"
               (click)="viewTableData(row.ID, row.CDOC, column)">View Data</a>
            <ng-container *ngIf="column.dataType === 'SUBFORM' && row[column.field]">
              <a *ngFor="let s of row[column.field].split(';')" href="javascript:void(0)"
                 class="pr-2"
                 (click)="openReport(s.split(':')[1])">{{s.split(':')[0]}}</a>
            </ng-container>
            <span *ngIf="column.editType !== 'file' && column.dataType !== 'TABLE' && column.dataType !== 'SUBFORM'">
              {{row[column.field] | trim:40}}
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="paginatorleft">
      <div class="text-left" *ngIf="configService.isDesktopDevice">
        {{dataSelectedRows?.length ? ('Selected ' + dataSelectedRows.length + ' of ' + projectData.totalElements) : ('Total records ' + projectData.totalElements)}}
      </div>
    </ng-template>
  </p-table>
  <!-- ProjectBuilderData >> -->
</div>


<!-- Import project's data -->
<app-import-data [idProject]="idProject" (onProcess)="onImportProc($event)"></app-import-data>
<!-- DocEditor -->
<app-dialog-doceditor (onProcess)="onProcessDoc($event)"
                      [(visible)]="docEditorVisible"
                      [idDoc]="idDoc"
                      [cdoc]="createCdoc"
                      [dataMode]="dataMode">
</app-dialog-doceditor>
<!-- View table data -->
<app-view-tabdata [idProject]="idProject"></app-view-tabdata>
<!--View  public events-->
<app-dialog-adminlog-public [idDoc]="idDoc" [(visible)]="publicLogsVisible"></app-dialog-adminlog-public>
<!--View private events-->
<app-dialog-adminlog-private [idDoc]="idDoc" [(visible)]="privateLogsVisible"></app-dialog-adminlog-private>

<project-add-user [idProject]="idProject" [(visible)]="showDlgUsers"
                  source="owner-change"
                  (userSelected)="docChangeOwner($event)"></project-add-user>

<p-dialog header="Record Information" [(visible)]="showDlgInfo"
          [modal]="true" [style]="{ width: '1100px', minWidth: '1100px' }"
          [maximizable]="false">
  <div *ngIf="dataSelectedRows?.length === 1 && dataSelectedRows[0].ID === idDoc">
    Document code:&nbsp;<b>{{getRowAttr('CDOC')}}</b> / ID:&nbsp;<b>{{getRowAttr('ID')}}</b>
    <br/>Created Date:&nbsp;<b>{{getRowAttr('D_CREATED')}}</b> / Created
    by:&nbsp;<b>{{getRowAttr('CREATE_USER_NAME')}}</b>
    <br/>Updated date:&nbsp;<b>{{getRowAttr('D_UPDATED')}}</b> / Updated
    by:&nbsp;<b>{{getRowAttr('UPDATE_USER_NAME')}}</b>
    <br/>Submitted date:&nbsp;<b>{{getRowAttr('D_SUBMITTED')}}</b>
    <br/>Metadata:&nbsp;<b>{{getRowAttr('metadata')}}</b>
    <br/>Status:&nbsp;<b>{{getRowAttr('STATUS')}}</b>
    <br/>Conditions:&nbsp;<span [ngSwitch]="errorStatus(dataSelectedRows[0])" class="pr-2">
              <i class="fas fa-exclamation-triangle" style="color: red!important;" *ngSwitchCase="2">Errors</i>
              <i class="far fa-check-circle" style="color: green!important;" *ngSwitchCase="1">No error</i>
              <i class="far fa-question-circle" style="color: grey!important;" *ngSwitchDefault>New</i>
            </span>
  </div>
  <dialog-doc-info [idDoc]="idDoc" [idProject]="idProject"></dialog-doc-info>
</p-dialog>

<p-dialog [(visible)]="showDlgCharts"
          [modal]="true" [style]="{ width: '1100px', minWidth: '1100px' }"
          [maximizable]="false"
          [header]="dlgChartHeader"
          showHeader="true"
          (onHide)="onHideCharts()">
  <app-data-charts></app-data-charts>
</p-dialog>

<p-dialog [(visible)]="showDlgGeoCharts"
          [modal]="true" [style]="{ width: '1300px', minWidth: '1200px',  height: '850px', minHeight: '850px' }"
          [maximizable]="false"
          [header]="dlgChartHeader"
          showHeader="true"
          (onHide)="onHideGeoCharts()">
  <app-data-geocharts></app-data-geocharts>
</p-dialog>

<p-dialog header="Data Upload workflow" [(visible)]="showDlgWorkflow"
          [modal]="true" [style]="{ width: '700px', minWidth: '700px' }"
          [maximizable]="false">
  <dialog-docstage-workflow [projectAdmin]="projectAdmin" [idDoc]="idDoc" [idProject]="idProject"
                            (onSave)="docstageWorkflowSuccess($event)"
                            (onClose)="docstageWorkflowSuccessClose()"></dialog-docstage-workflow>
</p-dialog>

<project-document-users [(visible)]="recOwnersVisible" [idProject]="idProject" [idDocs]="selectedIdDocs"
                        [projectAdmin]="projectAdmin">
</project-document-users>

<p-dialog header="Download options" [(visible)]="showDlgDownloadOptions"
          [modal]="true" [style]="{ width: '700px', minWidth: '700px' }"
          [maximizable]="false">
  <dialog-download-options [options]="9"
                           (onSave)="downloadOptionsSuccess($event)"
                           (onClose)="downloadOptionsSuccessClose()"></dialog-download-options>
</p-dialog>
