<p-table #dt
         [value]="data.content"
         [totalRecords]="data.totalElements"
         [rows]="data.size"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
         selectionMode="single" (onRowSelect)="handleRowSelect($event)"
         [paginator]="true" paginatorPosition="bottom"
         [resizableColumns]="true"
         [scrollable]="true" [scrollHeight]="'calc(100vh - 320px)'"
         sortField="dateRec" [sortOrder]="0">
  <ng-template pTemplate="caption">
    {{tableHeader}}
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col style="width:12rem;" *ngIf="isShowDocInformation">
      <col style="width:8rem;" *ngIf="isShowDocInformation">
      <col style="width:8rem;">
      <col style="width:10rem;" *ngIf="isShowUserInformation">
      <col style="width:10rem;" *ngIf="isShowUserInformation">
      <col style="width:8rem;">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="iddoc" *ngIf="isShowDocInformation">ID doc<p-sortIcon field="iddoc"></p-sortIcon></th>
      <th pSortableColumn="cdoc" *ngIf="isShowDocInformation">Document code<p-sortIcon field="cdoc"></p-sortIcon></th>
      <th pSortableColumn="mode">Mode<p-sortIcon field="mode"></p-sortIcon></th>
      <th pSortableColumn="iduser" *ngIf="isShowUserInformation">ID user<p-sortIcon field="iduser"></p-sortIcon></th>
      <th pSortableColumn="username" *ngIf="isShowUserInformation">User name<p-sortIcon field="username"></p-sortIcon></th>
      <th pSortableColumn="dateRec">Date<p-sortIcon field="dateRec"></p-sortIcon></th>
    </tr>
    <tr>
      <th *ngIf="isShowDocInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'iddoc', 'eq')" />
      </th>
      <th *ngIf="isShowDocInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'cdoc', 'eq')" />
      </th>
      <th>
        <p-dropdown appendTo="body" [options]="items" [style]="{ 'min-width':'120px'  }"
                    (onChange)="dt.filter($event.value, 'mode', 'eq')"></p-dropdown>
      </th>
      <th *ngIf="isShowUserInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'iduser', 'eq')" />
      </th>
      <th *ngIf="isShowUserInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'username', 'contains')" />
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-i="rowIndex">
    <tr [pSelectableRow]="row">
      <td *ngIf="isShowDocInformation">{{row.iddoc}}</td>
      <td *ngIf="isShowDocInformation">{{row.cdoc}}</td>
      <td>{{row.mode == 0?
          'Open':
          (row.mode == 1 ?
              'Save' :
              (row.mode == 2 ?
                  'Silent save' :
                  (row.mode == 3 ?
                      'Submit force' :
                      (row.mode == 4 ?
                          'Submit' :
                          (row.mode == 5 ?
                              'Remove' :
                              (row.mode == 6 ?
                                  'Accept' : (row.mode == 7 ? 'Decline' : '?'))))))) }}</td>
      <td  *ngIf="isShowUserInformation">{{row.iduser}}</td>
      <td  *ngIf="isShowUserInformation">{{row.username}}</td>
      <td>{{row.dateRec}}</td>
    </tr>
  </ng-template>
</p-table>
