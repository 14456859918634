import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Pageable} from "../../../_domains/spring/pageable";
import {AppMessageService} from "../../../_services/app-message.service";
import {finalize} from "rxjs/operators";
import {UIUtils} from "../../../_utils/ui-utils";
import {DataAdminService} from "../_services/data-admin.service";
import {Organization} from "../../../_domains/UITypes";
import {FilterMetadata} from 'primeng/api';
import {Table} from 'primeng/table';

@Component({
  selector: 'app-admin-listProjects',
  templateUrl: './utl-list-projects.component.html',
  styleUrls: ['./utl-list-projects.component.css']
})
export class UtlListProjectsComponent implements OnInit, AfterViewInit {
  @Input() organization: Organization;
  selected: any = null;
  projects1: Pageable = new Pageable();

  private _currentPage: number = 0;
  private _sorting: string = '&sort=name,asc';
  private _loading: boolean = false;
  @ViewChild('dataTable', {static: false})
  private dataTable: Table;

  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._loading = false;
  }

  get loading(): boolean {
    return this._loading;
  }

  private loadData(filter: string) {
    if (this.organization != null) {
      this._loading = true;
      this.dataService.getAdmListHierarchies(filter)
        .pipe(finalize(() => {
          this._loading = false;
        }))
        .subscribe(
          data => {
            this.projects1 = data;
          },
          error => {
            this.messageService.showMessage(error, '[loadHierarchies]');
          }
        );
    }
  }

  private buildFilter(npage: number, sort: string, filters: string): string {
    let filter: string = '', search: string = filters;
    this._currentPage = npage;
    this._sorting = sort;
    if (this.organization) {
      let _search = "";
      if (this.organization.orgLevel == 0) {
        _search = "lvlAccess0<>0";
      } else if (this.organization.orgLevel == 1) {
        _search = "lvlAccess1<>0";
      } else if (this.organization.orgLevel == 2) {
        _search = "lvlAccess2<>0";
      }
      search = search.length != 0 ? `${search},${_search}` : `${_search}`;
    }
    filter = `page=${this._currentPage || '0'}`;
    filter += this._sorting;
    if (search.length > 0) {
      filter += `&search=${search}`;
    }
    return filter;
  }

  handlePageChange(event) {
    this.loadData(
      this.buildFilter(
        (event.rows == 0) ? 0 : Math.floor(event.first / event.rows),
        event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`,
        UIUtils.getFilterStr(event.filters)
      ));
  }

  handleRowSelect(event) {
    this.selected = event.data;
  }

  refresh() {
    this.loadData(this.buildFilter(0, this._sorting, ""));
  }
  filterValue(columnName: string): string {
    return this.dataTable?.filters[columnName] ? (<FilterMetadata>this.dataTable?.filters[columnName]).value : '';
  }
}
