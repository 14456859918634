<div>
  <form [formGroup]="userForm">
    <div  class="p-fluid">
      <div class="row form-group">
        <div class="col-5">Uploaded</div>
        <div class="col-7">
          <textarea [readOnly]="true" formControlName="userUploaded" class="form-control" type="text" id="userUploaded" cols="30" rows="2"></textarea>
        </div>
      </div>

      <div class="row form-group" >
        <div class="col-2">Under review</div>
        <div class="col-3" [hidden]="!cancelUnderReviewHidden">
          <button pButton type="submit" icon="pi pi-save" label="Sign" (click)="save('under_review')" [disabled]="UnderReviewButtonDisabled"></button>
        </div>
        <div class="col-3" [hidden]="cancelUnderReviewHidden">
          <p-button type="submit" icon="pi pi-times" label="Unsign"  (click)="save('uploaded')" [disabled]="UploadedButtonDisabled"></p-button>
        </div>
        <div class="col-7">
          <textarea [readOnly]="true" [hidden]="underReviewHidden" formControlName="userUnderReview" class="form-control" type="text" id="userUnderReview" cols="30" rows="2"></textarea>
        </div>
      </div>

      <div class="row form-group" [hidden]="approveHidden">
        <div class="col-2">Approved</div>
        <div class="col-3" [hidden]="!cancelApprove">
          <button pButton type="submit" icon="pi pi-save" label="Sign" (click)="save('accept')" [disabled]="ApprovedButtonDisabled"></button>
        </div>
        <div class="col-3" [hidden]="cancelApprove">
          <p-button type="submit" icon="pi pi-times" label="Unsign"  (click)="save('under_review')" ></p-button>
        </div>
        <div class="col-7">
          <textarea [readOnly]="true" [hidden]="approveHidden" formControlName="userApproved" class="form-control" type="text" id="userApproved" cols="30" rows="2"></textarea>
        </div>
      </div>

      <div class="row form-group" [hidden]="rejectHidden">
        <div class="col-2">Rejected</div>
        <div class="col-3" [hidden]="!cancelReject">
          <button pButton type="submit" icon="pi pi-save" label="Sign" (click)="save('decline')" [disabled]="RejectedButtonDisabled"></button>
        </div>
        <div class="col-3" [hidden]="cancelReject">
          <p-button type="submit" icon="pi pi-times" label="Unsign"  (click)="save('under_review')" ></p-button>
        </div>
        <div class="col-7">
          <textarea [readOnly]="true" [hidden]="rejectHidden" formControlName="userRejected" class="form-control" type="text" id="userRejected" cols="30" rows="2"></textarea>
        </div>
      </div>
    </div>


  </form>

</div>
