import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';

@Component({
  selector: 'app-show-error',
  template: `
    <ul *ngIf="shouldShowErrors()" style="margin: 0 !important; list-style-type: none;">
      <li *ngFor="let error of listOfErrors()">
        <div class="form-input-error">
          {{error}}
        </div>
      </li>
    </ul>
  `

})
export class ShowErrorComponent implements OnInit {
  private static readonly errorMessages = {
    required: () => 'Field is required',
    minlength: (params) => 'Min length is ' + params.requiredLength,
    maxlength: (params) => 'Max length is ' + params.requiredLength,
    min: (params) => 'Min value is ' + params.min,
    max: (params) => 'Max value is ' + params.max,
    pattern: () => 'Field has invalid format',
    invalidOrg: () => 'You have to select an organization',
    invalidSeverity: () => 'You have to set severity one of (info,success,warning,danger) or set color: , background-color: ',
    invalidIcon: () => 'You have to set icon like this: "pi pi-pencil" or "fa[or fas or far or fab] fa-chart-area"  on lower case'
  };
  @Input()
  private control: AbstractControlDirective | AbstractControl;

  constructor() {
  }

  ngOnInit() {
  }

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    return ShowErrorComponent.errorMessages[type](params);
  }
}
