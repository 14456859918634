import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'customButton'
})
export class CustomButtonPipe implements PipeTransform {

  transform(uiButton: any[], ...args: string[]): string {
    const type = args[0] === 'label' ? 'blabel' : (args[0] === 'icon' ? 'icon' : 'tooltip');
    return uiButton?.hasOwnProperty(args[1]) ? uiButton[args[1]][type] : args[2];
  }

}
