<form [formGroup]="hierarchyForm" (ngSubmit)="saveProject(hierarchyForm.getRawValue())">
  <div class="p-fluid">
    <p-tabView [(activeIndex)]="tabIndex">
      <p-tabPanel header="Basic">
        <div [class.project-tab-content]="!isNew">
          <div class="row form-group">
            <div class="col-4"><span class="field-required">*&nbsp;</span>Name</div>
            <div class="col-8">
              <textarea pInputTextarea class="form-control" formControlName="name" rows="2"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0">
              <app-show-error [control]="hierarchyForm.get('name')"></app-show-error>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-4">Description</div>
            <div class="col-8">
              <textarea pInputTextarea class="form-control" formControlName="description" rows="2"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12 p-0">
              <app-show-error [control]="hierarchyForm.get('description')"></app-show-error>
            </div>
          </div>

          <div class="row form-group">
            <label class="col-4 col-form-label" for="access">Status</label>
            <div class="col-8">
              <p-dropdown [options]="projectList" formControlName="access" id="access"
                          appendTo="body" [overlayOptions]="{baseZIndex: 9999}"></p-dropdown>
            </div>
          </div>

          <div class="row form-group" *ngIf="!isNew">
            <div class="col-4">Code</div>
            <div class="col-8">
              <div class="input-group">
                <input pInputText class="form-control" [readonly]="true" formControlName="id" type="text"/>
                <div class="input-group-append">
                  <button pButton type="button" class="p-button-sm" icon="pi pi-clone"
                          ngxClipboard [cbContent]="hierarchyForm.get('id').value"
                          cbSuccessMsg="Questionnaire Code is copied to the clipboard"></button>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-group" *ngIf="!isNew">
            <div class="col-4">Default Column filter</div>
            <div class="col-8">
              <p-dropdown [options]="templatesList"
                          placeholder="Select a column filter"
                          [showClear]="true"
                          formControlName="template"></p-dropdown>
            </div>
          </div>

          <div class="row form-group" *ngIf="!isNew">
            <div class="col-4">Tags</div>
            <div class="col-8">
              <p-multiSelect [options]="labelsList"
                             [maxSelectedLabels]="1"
                             display="chip"
                             formControlName="labels"
                             appendTo="body" [overlayOptions]="{baseZIndex: 10100}"></p-multiSelect>
            </div>
          </div>

          <div class="row form-group mb-0" *ngIf="!isNew">
            <div class="col-4"></div>
            <div class="col-8">
              <div class="input-group">
                <p-checkbox class="form-control pl-0 border-0" name="emailMembersChecked"
                            formControlName="includeMetadata"
                            label="Include metadata fields into Data view"
                            binary="true"></p-checkbox>
              </div>
            </div>
          </div>

        </div>
      </p-tabPanel>

      <p-tabPanel header="Advanced">
        <div [class.project-tab-content]="!isNew">
          <table class="table" formGroupName="tabs">
            <thead>
            <tr>
              <th scope="col" class="w-3rem">Show</th>
              <th scope="col" class="w-6rem">Tab name</th>
              <th scope="col">Descriptive text</th>
            </tr>
            </thead>
            <tbody>
            <tr formGroupName="summary">
              <td class="align-middle">
                <p-checkbox class="form-control pl-0 border-0"
                            formControlName="show"
                            binary="true"></p-checkbox>
              </td>
              <td class="align-middle">Summary</td>
              <td><textarea pInputTextarea class="form-control" formControlName="text" rows="4"></textarea></td>
            </tr>
            <tr formGroupName="data">
              <td class="align-middle">
                <p-checkbox class="form-control pl-0 border-0"
                            formControlName="show"
                            binary="true"></p-checkbox>
              </td>
              <td class="align-middle">Data</td>
              <td><textarea pInputTextarea class="form-control" formControlName="text" rows="4"></textarea></td>
            </tr>
            </tbody>
          </table>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Security">
        <div [class.project-tab-content]="!isNew">
          <project-workflow [idProject]="hierarchyForm.get('id').value"
                            [(value)]="workflow"></project-workflow>
        </div>
      </p-tabPanel>
    </p-tabView>

  </div>

  <div class="row">
    <div class="col-8">
      <span *ngIf="sFirstActivity != ''"><i>First activity:&nbsp;{{ sFirstActivity }}</i></span> &nbsp;
      <span *ngIf="sLastActivity != ''"><i>Last activity:&nbsp;{{ sLastActivity }}</i></span>
    </div>
    <div class="col-4 text-right">
      <button pButton type="submit" icon="pi pi-save"
              [disabled]="saveButtonDisabled" label="Save"></button>
      <button pButton type="button" icon="pi pi-times"
              [disabled]="cancelButtonDisabled"
              (click)="close()" label="Close"></button>
    </div>
  </div>
</form>
