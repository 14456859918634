<div class="row">
  <!-- Questionnaire summary -->
  <div class="col-7">
    <span style="font-weight: bold; font-size: 1.2rem;">{{ data.name }}</span>
    <br/><span><i>{{ data.description }}</i></span>
    <br/>
    <ng-container *ngFor="let col of data.labels">
      <p-tag styleClass="mr-2 project-label"
             *ngIf="(col.severity.indexOf(':') === -1) && (col.label.indexOf('DOC:') === -1) && (col.label.indexOf('BTN') === -1)"
             severity="{{col.severity}}"
             icon="pi {{col.severity==='danger'?'pi-times':col.severity==='success'?'pi-check':col.severity==='warning'?'pi-exclamation-triangle':'pi-info-circle'}}"
             [rounded]="true" value="{{col.label}}"></p-tag>
      <p-tag styleClass="mr-2 project-label"
             *ngIf="(col.severity.indexOf(':') !== -1) && (col.label.indexOf('DOC:') === -1) && (col.label.indexOf('BTN:') === -1) "
             [style]="getclassOfLabel(col.severity)"
             [icon]="getIconOfLabel(col.severity)"
             [rounded]="true" value="{{col.label}}"></p-tag>
    </ng-container>
  </div>
  <div class="col-5 text-right">

    <button pButton type="button" icon="pi pi-pencil"
            (click)="edit()" *ngIf="access"
            pTooltip="Edit the questionnaire" tooltipPosition="left"></button>
    <button pButton type="button" icon="pi pi-palette"
            (click)="buttonsEdit()" *ngIf="access"
            pTooltip="Customize the buttons" tooltipPosition="left"></button>
    <button pButton type="button" icon="pi pi-trash"
            (click)="remove()" *ngIf="access && typeAdmin == 1"
            pTooltip="Remove the questionnaire" tooltipPosition="left"></button>

  </div>
  <div class="col-12" *ngIf="descriptiveText">
    <div [innerHTML]="descriptiveText"></div>
  </div>

  <div class="col-12">
    <app-stat-dashboardForms [grType]="grType" [enableInit]="false"></app-stat-dashboardForms>
  </div>
</div>

<p-dialog header="Questionnaire details" [(visible)]="showProjectProp"
          [modal]="true" [style]="{ width: '700px', minWidth: '700px' }"
          [maximizable]="false" [baseZIndex]="9000">
  <project-record (onSavedData)="onSaveProperty($event)" (onClose)="showProjectProp = false"></project-record>
</p-dialog>
<p-dialog header="Customize questionnaire buttons" [(visible)]="showProjectButtons"
          [modal]="true" [style]="{ width: '1000px', minWidth: '1000px' }"
          [closable]="true"
          [maximizable]="false" [baseZIndex]="9000" [closeOnEscape]="false">
  <project-buttons (onClose)="showProjectButtons = false"></project-buttons>
</p-dialog>
