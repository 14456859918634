import {Component, EventEmitter, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {DocumentUtilsPublicService} from '../_services/document-utils-public.service';
import {ViewDictPublicService} from '../_services/view-dict.service';
import {TemplateUtilsPublicService} from '../_services/template-utils.service';
import {UploaderComponent} from '../../uploader/uploader.component';
import {DocumentEditorComponent} from '../document-editor/document-editor.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigService} from '../../../_services/config.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {SurveysStoreService} from '../../../_services/surveys-store.service';
import {ConfirmationService} from 'primeng/api';
import {ClipboardService} from 'ngx-clipboard';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {UIUtils} from '../../../_utils/ui-utils';
import {Dialog} from 'primeng/dialog';
import {LoaderService} from '../../core/loader/loader.service';
import * as moment from 'moment';
import {OrderListComponent} from '../orderlist/orderlist.component';
import {DynamicDirective} from '../../../_directives/dynamic.directive';
import {showDynamicComponent} from '../_services/document-utils';
import {firstValueFrom} from 'rxjs';

declare var $: any;
declare var pz: any;

@Component({
  selector: 'public-document-editor',
  templateUrl: './public-report-editor.component.html',
  styleUrls: ['../_css/documentEditor.css'],
  providers: [
    DocumentUtilsPublicService, ViewDictPublicService, TemplateUtilsPublicService
  ]
})
export class PublicReportEditorComponent implements OnInit {
  @Input() dictFilter: string;
  @ViewChild(UploaderComponent, {static: false}) uploaderCmp;
  @ViewChild(OrderListComponent, {static: false}) orderListCmp;
  @ViewChild(DocumentEditorComponent, {static: false}) editorCmp;
  @ViewChild('rowEditDialog') rowEditDialogCmp: Dialog;
  @ViewChild(DynamicDirective, {static: true}) private dynamicTemplate!: DynamicDirective;

  popupShow: boolean = false;
  popupHeader: string = '';
  popupContent: string = '';
  popupType = 'info';

  private processing: boolean = false;
  private idpar: string;
  private cdoc: string;
  private editable: boolean = false;
  private operatorId: string = '';
  private contactId: string = '';
  private countryCode: string = '';
  private countryName: string = '';
  private fileFieldName!: string;
  private fileRowNumber!: number;
  rowEditDialogVisible: boolean;
  dialogHelpVisible: boolean;
  private queryParams!: any;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private docService: DocumentUtilsPublicService,
              private utlService: TemplateUtilsPublicService,
              private dictService: ViewDictPublicService,
              private configService: ConfigService,
              private messageService: AppMessageService,
              private surveysService: SurveysStoreService,
              private confirmationService: ConfirmationService,
              private clipboardService: ClipboardService,
              private ngZone: NgZone,
              private translate: TranslateService,
              private loader: LoaderService) {
    this.dictFilter = '';
  }

  ngOnInit() {
    const _self = this;
    $.extend($.jdm, {
      //
      isMobileDevice: function () {
        return _self.configService.deviceType > 0;
      },
      //
      save: function () {
        _self.ngZone.run(() => {
          _self.saveWithoutValidating();
        });
      },
      //
      saveWithoutValidating: function () {
        _self.ngZone.run(() => {
          _self.saveWithoutValidating();
        });
      },
      //
      saveWitValidating: function () {
        _self.ngZone.run(() => {
          _self.save(true);
        });
      },
      //
      submit: function () {
        _self.ngZone.run(() => {
          _self.send();
        });
      },
      // confirm
      confirm: function (title, message, clbYes, clbNo) {
        let clbAccept = typeof (clbYes) !== 'undefined' ? clbYes : null;
        let clbReject = typeof (clbNo) !== 'undefined' ? clbNo : null;
        _self.ngZone.run(() => {
          _self.confirmationService.confirm({
            message: message,
            header: title,
            icon: 'fa fa-question-circle',
            accept: () => {
              clbAccept();
            },
            reject: () => {
              clbReject();
            }
          });
        });
      },
      // open dialog
      open_dialog: function (title, content, type?: string) {
        _self.ngZone.run(() => {
          _self.popupHeader = title;
          _self.popupContent = content;
          _self.popupShow = true;
          _self.popupType = type || 'info';
        });
      },
      // info dialog
      info_dialog: function (title, message, type) {
        _self.ngZone.run(() => {
          switch (type) {
            case 'error':
              _self.messageService.showError(message, title);
              break;
            case 'info':
              _self.messageService.showSuccess(message, title);
              break;
            default:
              _self.messageService.showWarn(message, title);
              break;
          }
        });
      },
      // get country record
      getCountryRecord: function (code, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getStateRec(code).then(data => {
            if (clb) clb(data);
          });
        });
      },
      // getTBWHydrationFactor
      // getTBWHydrationFactor: function (sex, age, clb) {
      //   _self.ngZone.run(() => {
      //     _self.dictService.getHydrationFactor(sex, age).then(data => {
      //       if (clb) clb(data);
      //     });
      //   });
      // },
      // show upload form
      showUploadForm: function (fieldName: string, rowNumber: number) {
        _self.ngZone.run(() => _self.showUploadForm(fieldName, rowNumber));
      },
      // show Ordered list picker
      showOrderListForm: function (rowId) {
        _self.ngZone.run(() => {
          _self.showOrderListForm(rowId);
        });
      },
      // remove item from order list
      removeItemOrderListForm: function (rowId, val2del) {
        _self.ngZone.run(() => {
          _self.removeItemOrderListForm(rowId, val2del);
        });
      },      // upload file data
      uploadFileData: function (fieldName: string, rowNumber: number, fileData: any) {
        _self.ngZone.run(() => _self.uploadFileData(fieldName, rowNumber, fileData));
      },
      // clear file data
      clearFileData: function (fieldName: string, rowNumber: number) {
        _self.ngZone.run(() => _self.clearFileData(fieldName, rowNumber));
      },
      // download file data
      downloadFileData: function (id) {
        _self.ngZone.run(() => _self.downloadFileData(id));
      },
      // get dictionary data
      getDictData: function (dictName, valueField, parentDictName, parentValueField, parentValue, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getDictData(dictName, valueField, parentDictName, parentValueField, parentValue).then(data => {
            if (clb) clb(data);
          });
        });
      },
      showRowEditDialog: function (clbShow, clbHide) {
        _self.ngZone.run(() => {
          _self.showRowEditDialog(clbShow, clbHide);
        });
      },
      showRichTextEditor: function (rowId, value) {
        _self.ngZone.run(() => showDynamicComponent(_self, {rowId, value}));
      }
    });

    let _iddoc: string = this.activatedRoute.snapshot.queryParamMap.get('id') || '?';
    if (_iddoc != '?') {
      this.operatorId = this.activatedRoute.snapshot.queryParamMap.get('operatorId') || '';
      this.contactId = this.activatedRoute.snapshot.queryParamMap.get('contactId') || '';
      this.countryCode = this.activatedRoute.snapshot.queryParamMap.get('countryCode') || '';
      const cdoc = this.activatedRoute.snapshot.queryParamMap.get('cdoc') || '';
      this.idpar = _iddoc;
      const queryParam = this.activatedRoute.snapshot.queryParams;
      this.queryParams = {...queryParam};
      delete this.queryParams.cdoc;
      const params = [];
      if (queryParam) {
        for (const k in queryParam) {
          if (k === cdoc) {
            continue;
          }
          params.push({name: k, value: queryParam[k]});
        }
      }
      this._initParams(_iddoc, cdoc, params);
    }
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
  }

  private async _initParams(iddoc: string, cdoc, params: any) {
    const _docStatus = await this.docService.getDocumentStatus(iddoc, cdoc);
    if (_docStatus.errorCode) { // error
      this.messageService.showWarn(_docStatus.message);
      return;
    } else { // no errors
      this.cdoc = _docStatus.cdoc;
      this.editable = _docStatus.editable;
      // Retrieving additional form record, parameters, rules, groups
      const _tmplVars = await this.utlService.getFormTemplateVars(this.cdoc);
      // checking country code
      if (this.countryCode != '') {
        const _countryRec = await this.dictService.getStateRec(this.countryCode);
        this.countryName = _countryRec.name;
      } else {
        this.countryName = '';
      }
      if (this.editable) {
        const _self = this;
        let callbackGetData = (success, failure): void => {
          _self.ngZone.run(() => {
            _self.callbackGetRptData(this.idpar, cdoc, params, success, failure)
          });
        }
        const dctDoc = _tmplVars.sprdoc;
        if (dctDoc.access === 0 || dctDoc.project.access === 0) {
          this.messageService.showWarn(this.translate.instant('message.document.closed'))
        }
        if (dctDoc.style) {
          $('#doc-container, #doc-container .p-toolbar').css(dctDoc.style);
        } else {
          $('.dialog-wrapper, .dialog-wrapper .p-toolbar').removeAttr('style');
        }
        this.editorCmp.refreshVars(
          this.idpar,
          _docStatus,
          _tmplVars,
          {
            id: null,
            email: null,
            username: null,
            fullname: null
          },
          callbackGetData
        );
      } else {
        this.editorCmp.setTemplate(
          `<div style='margin-bottom:50px;'>
          <div style='text-align: center;'>
              <img src='assets/images/failure.png' height='210' width='210' />
              <h3>${this.translate.instant('message.document.submit.notAllowedToView')}</h3>
          </div>
        </div>`);
      }
    }
  }

  async save(output: boolean) {
    this.processing = true;
    //
    this.editorCmp.commit();
    this.editorCmp.reset();
    //
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      this.processing = false;
      return;
    }
    this.docService.save(this.idpar, this.editorCmp.dynamicVars(), this.editorCmp.data(), (this.editorCmp.check()).errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            if (output) this.messageService.showSuccess(this.translate.instant('message.document.saved'));
            (pz.$inst).jqDm('wizardHighlight', []);
          } else if (res.status === 0) {
            if (output) {
              this.messageService.showSuccess(this.translate.instant('message.document.savedWarn', {message: res.message}));
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
            }
          } else if (res.status < 0) {
            if (output) this.messageService.showWarn(res.message);
          }
          if (res.status === 1 || res.status === 0) {
            if (this.fileFieldName) {
              this.uploaderCmp.finish(true);
              this.editorCmp.setCellValue(this.fileFieldName + 'Parts', this.fileRowNumber, '');
              this.editorCmp.setCellValue(this.fileFieldName + 'UploadId', this.fileRowNumber, '');
              this.fileFieldName = null;
              this.fileRowNumber = 0;
            }
          } else {
            if (this.fileFieldName) {
              this.uploaderCmp.finish(false);
              this.fileFieldName = null;
              this.fileRowNumber = 0;
            }
          }
        },
        error => {
          this.messageService.showMessage(error);
          if (this.fileFieldName) {
            this.uploaderCmp.finish(false);
            this.fileFieldName = null;
            this.fileRowNumber = 0;
          }
        })
  }

  async saveWithoutValidating() {
    //
    this.editorCmp.commit();
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      return;
    }
    try {
      await this.docService.saveWithoutValidate(this.idpar, this.editorCmp.dynamicVars(), this.editorCmp.data()).toPromise();
    } catch (error) {
      this.messageService.showMessage(error);
    }
  }

  async checkNeedCreateBeforeSave() {
    if (this.idpar === '-1') {
      try {
        this.idpar = await firstValueFrom(this.docService.createEmptyReport(this.cdoc));
        this.surveysService.save(this.cdoc, this.queryParams, this.idpar);
        return true;
      } catch (e) {
        this.messageService.showMessage(e);
        return false;
      }
    }
    return true;
  }

  check() {
    this.processing = true;
    this.editorCmp.reset();
    this.docService.check(this.idpar, this.cdoc, this.editorCmp.data(), (this.editorCmp.check()).errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.validation.success'));
            (pz.$inst).jqDm('wizardHighlight', []);
          } else if (res.status === 0) {
            this.messageService.showWarn(res.message);
            this.editorCmp.parseLogs(res.logs);
            (pz.$inst).jqDm('wizardHighlight', this.editorCmp.errorsSteps);
          } else if (res.status < 0) {
            this.messageService.showWarn(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  async send() {
    this.processing = true;
    //
    this.editorCmp.commit();
    this.editorCmp.reset();
    //
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      return;
    }
    this.docService.submit(this.idpar, this.editorCmp.dynamicVars(), this.editorCmp.data(), (this.editorCmp.check()).errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          let res = data;
          if (res.status === 1) {
            // Show success message
            this.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
            // Get Document status
            this.getDocumentStatus();
            // Parse logs
            this.editorCmp.parseLogs(res.logs);
            // go to submitted route
            this.router.navigate(['/public/report/submitted']/*, { skipLocationChange: true }*/);
            // delete from local db
            this.surveysService.del(this.idpar);
          } else if (res.status === 0) {
            // Show warning message
            this.messageService.showSuccess(this.translate.instant('message.document.submit.successWarn', {message: res.message}));
            // Parse logs
            this.editorCmp.parseLogs(res.logs);
            this.sendForce();
          } else {
            this.messageService.showWarn(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  copyLink() {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.copyLink'),
      header: this.translate.instant('message.header.warn'),
      icon: 'fas fa-exclamation',
      accept: () => _self.getDocumentToken()
    });
  }

  private async getDocumentToken() {
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      return;
    }
    this.docService.getToken(this.idpar)
      .subscribe(data => {
          this.clipboardService.copy(UIUtils.getPublicDocLink(data.message));
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  private sendForce() {
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.submit.warnConfirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.processing = true;
        this.docService.submitForce(this.idpar)
          .pipe(finalize(() => {
            this.processing = false;
          }))
          .subscribe(
            data => {
              const res = data;
              if (res.status === 1) {
                this.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
                this.getDocumentStatus();
                this.router.navigate(['/public/report/submitted']/*, { skipLocationChange: true }*/);
                this.surveysService.del(this.idpar);
              } else {
                this.messageService.showWarn(res.message);
              }
            },
            error => {
              this.messageService.showMessage(error);
            });
      },
      reject: () => {
      }
    });
  }

  private callbackGetRptData(iddoc: string, cdoc: string, params: any, success: (resp) => any, failure: (error) => any) {
    const data = {};
    if (cdoc) {
      data['cdoc'] = cdoc;
      data['params'] = params;
    }
    this.docService.getJSONData(iddoc, data)
      .subscribe(
        resp => {
          success(resp);
        },
        error => {
          this.messageService.showMessage(error);
          if (typeof failure === 'function') {
            failure(error);
          }
        });
  }

  get isDisabledBtnDocSave(): boolean {
    return this.editable === false || this.processing;
  }

  get isDisabledBtnDocCheck(): boolean {
    return this.editable === false || this.processing;
  }

  get isDisabledBtnDocSend(): boolean {
    return this.editable === false || this.processing;
  }

  get isDisabledBtnCopyLink(): boolean {
    return this.processing;
  }

  get isDisabledBtnPdf(): boolean {
    return this.processing;
  }

  get isDisabledBtnHelp(): boolean {
    return this.processing;
  }

  private async getDocumentStatus() {
    this.docService.getDocumentStatus(this.idpar)
      .then(resp => {
        this.editable = resp.editable;
        this.editorCmp.setStatus(resp);
      });
  }

  async showUploadForm(fieldName: string, rowNumber: number) {
    const check = await this.checkNeedCreateBeforeSave();
    if (!check) {
      return;
    }
    this.fileFieldName = fieldName;
    this.fileRowNumber = rowNumber;
    this.uploaderCmp.show()
  }

  showOrderListForm(rowId: string) {
    this.orderListCmp.show(rowId)
  }

  removeItemOrderListForm(rowId: string, val2del: string) {
    let cellValue = pz.$inst[0].p.data[0][rowId];
    cellValue = cellValue.replace(new RegExp(`^${val2del}($|;)`), '');
    cellValue = cellValue.replace(new RegExp(`(;${val2del})($|;)`), '$2');
    pz.$inst.jqDm('setCellValue', 0, rowId, cellValue);
  }

  uploadFileData(fieldName: string, rowNumber: number, fileData: any) {
    this.fileFieldName = fieldName;
    this.fileRowNumber = rowNumber;
    this.uploaderCmp.show();
    this.uploaderCmp.fileUploader(fileData);
  }

  clearFileData(fieldName: string, rowNumber: number) {
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.file.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.clearFileDataInt(fieldName, rowNumber)
      }
    });
  }

  private clearFileDataInt(fieldName: string, rowNumber: number) {
    this.editorCmp.clearFileDataInt(fieldName, rowNumber);
  }

  downloadFileData(id: string) {
    this.docService.getFileLink(this.idpar, id)
      .subscribe({
        next: (data) => window.open(data, '_blank'),
        error: (error) => this.messageService.showMessage(error, this.translate.instant('message.document.file.download.error'))
      });
  }

  fileUploadSuccess(data: any) {
    this.editorCmp.fileUploadSuccess(this.fileFieldName, this.fileRowNumber, data);
    this.save(true);
  }

  fileUploadFailed(event: any) {
    this.clearFileDataInt(this.fileFieldName, this.fileRowNumber);
  }

  orderListSuccess(ret: any) {
    this.editorCmp.orderListSuccess(ret.rowId, ret.data);
  }

  onclose() {
    this.orderListCmp.close();
  }

  showRowEditDialog(clbShow, clbHide) {
    this.rowEditDialogVisible = true;
    if (clbShow && this.rowEditDialogCmp) {
      this.rowEditDialogCmp.onShow = new EventEmitter<void>();
      this.rowEditDialogCmp.onShow.subscribe(_ => clbShow());
    }
    if (clbHide && this.rowEditDialogCmp) {
      this.rowEditDialogCmp.onHide = new EventEmitter<void>();
      this.rowEditDialogCmp.onHide.subscribe(_ => clbHide());
    }
  }

  print2pdf() {
    this.processing = true;
    this.loader.show();
    const fileName = this.cdoc + '_' + moment().format('YYYYMMDD_HHmmss') + '.pdf';
    (pz.$inst).jqDm('export2pdf', fileName, (success) => {
      if (!success) {
        this.messageService.showError(this.translate.instant('message.document.print.error'));
      }
      this.processing = false;
      this.loader.hide();
    });
  }

  get iddoc(): string {
    return this.idpar;
  }

  get cDoc(): string {
    return this.cdoc;
  }
}
