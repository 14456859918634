import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-error',
  template:
    `<div [style]="{ 'margin-bottom':'50px', 'padding-top': '20px' }">
      <div style="text-align: center;">
          <img src="assets/images/failure.png" height="210" width="210"/>
          <h3>{{errorMessage}}</h3>
      </div>
  </div>`
})
export class ErrorComponent implements OnInit {
  errorMessage: string = "";

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.errorMessage = params['v'];
    });
  }
}
