import { Component, OnInit } from '@angular/core';
import {Pageable} from "../../../_domains/spring/pageable";
import {ProjectUtilsService} from "../_services/project-utils.service";
import {finalize} from "rxjs/operators";
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
  selector: 'project-document-rules',
  templateUrl: './project-document-rules.component.html'
})
export class ProjectDocumentRulesComponent implements OnInit {
  selected: any = null;
  showDialog: boolean = false;

  private _reports: Pageable = new Pageable();
  private cdoc = '';
  private currentPage = 0;
  private _loading: boolean;
  private sorting = '';

  constructor(private messageService: AppMessageService,
              private dataService: ProjectUtilsService) {
  }

  ngOnInit() {
    this._loading = false;
  }

  refresh(v: string) {
    this.showDialog = true;
    this.cdoc = v;
    this.loadData();
  }

  get reports(): Pageable {
    return this._reports;
  }

  get loading(): boolean {
    return this._loading;
  }

  get header(): string {
    return `"${this.cdoc || '?'}": Form rules`;
  }

  get dialogStyle(): any {
    return { width : `${window.innerWidth - 100}px` }
  }

  handlePageChange(event) {
    let filter = '';
    this.currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    filter = `?page=${this.currentPage || '0'}`;
    filter += (this.sorting || '') !== '' ? this.sorting : '';

    this.loadData(filter);
  }

  private loadData(filter?: string) {
    if (this.cdoc.length > 0) {
      this._loading = true;
      this.dataService.getListDictDocRules(this.cdoc, filter)
        .pipe(finalize(() => {
          this._loading = false;
        }))
        .subscribe(
          data => {
            this._reports = data;
          },
          error => {
            this.messageService.showMessage(error);
          }
        );
    }
  }
}
