import {Injectable} from '@angular/core';
import {ConfigService} from 'src/app/_services/config.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class StatGeoUtilsService {
  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }


  getListSurveysGeoStat(code: string, isDraft: number): Observable<any> {
    let _filter: string = this._getFIlterStr(code, isDraft);
    return this.http.get<any>(`${this.configService.api.chartstat.surveys}/geo?${_filter}`);
  }


  private _getFIlterStr(code, grtype): string {
    let _filter: string = '';
    if (code != null) if (code.length > 0) _filter += `code=${code}`;
    if (grtype != null) _filter += (_filter.length > 0 ? `&` : '') + `grtype=${grtype}`;

    return _filter;
  }
}
