<p-dialog header="{{header}}" [(visible)]="showDialog"
          [modal]="true" [style]="dialogStyle" [minY]="70"
          [maximizable]="true" [baseZIndex]="8000">
  <p-table [value]="reports.content"
           [totalRecords]="reports.totalElements"
           [rows]="reports.size"
           [loading]="loading"
           [lazy]="true"
           (onLazyLoad)="handlePageChange($event)"
           selectionMode="single"
           dataKey="norder"
           [selection]="selected"
           [paginator]="true"
           paginatorPosition="bottom"
           [resizableColumns]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 420px)'">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col style="width:1rem;">
        <col style="width:6rem;">
        <col style="width:13rem;">
        <col style="width:6rem;">
        <col style="width:4rem;">
        <col style="width:4rem;">
        <col style="width:13rem;">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>#</th>
        <th>ID</th>
        <th>Name</th>
        <th>Short Name</th>
        <th>Type</th>
        <th>Chart for Questionnaire</th>
        <th>Details</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row">
        <td>{{row.norder}}</td>
        <td>{{row.id}}</td>
        <td pTooltip="{{row.name}}">{{ outputRowCellValue(row.name, 60) }}</td>
        <td>{{row.shortName}}</td>
        <td>{{row.type}}</td>
        <td>{{row.chartForProject?1:0}}</td>
        <td pTooltip="{{row.details}}">{{ row.details }}</td>

      </tr>
    </ng-template>
  </p-table>
</p-dialog>
