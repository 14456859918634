import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UploaderComponent} from './uploader.component';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogModule} from 'primeng/dialog';


@NgModule({
  declarations: [UploaderComponent],
  exports: [UploaderComponent],
  imports: [
    CommonModule,
    DialogModule,
    FileUploadModule
  ]
})
export class UploaderModule {
}
