<p-dialog header="{{header}}" [(visible)]="showDialog"
          [modal]="true" [style]="dialogStyle" [minY]="70"
          [maximizable]="true" [baseZIndex]="8000">
  <p-table [value]="reports.content"
           [totalRecords]="reports.totalElements"
           [rows]="reports.size"
           [loading]="loading"
           [lazy]="true"
           (onLazyLoad)="handlePageChange($event)"
           selectionMode="single"
           [selection]="selected"
           [paginator]="true"
           paginatorPosition="bottom"
           [resizableColumns]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 420px)'"
           sortField="norder" [sortOrder]="1">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col style="width: 4rem;">
        <col style="width: 12rem;">
        <col style="min-width: 10rem;">
        <col style="width: 8rem;">
        <col style="width: 6rem;">
        <col style="width: 6rem;">
        <col style="width: 6rem;">
        <col style="width: 6rem;">
        <col style="width: 5rem;">
        <col style="width: 5rem;">
        <col style="width: 5rem;">
        <col style="width: 5rem;">
        <col style="width: 5rem;">
        <col style="width: 12rem;">
        <col style="width: 5rem;">
        <ng-container *ngIf="mode==2">
          <col style="width: 12rem;">
          <col style="width: 5rem;">
        </ng-container>
        <col style="width: 5rem;">
        <col style="width: 10rem;">

      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="norder">#
          <p-sortIcon field="norder"></p-sortIcon>
        </th>
        <th pSortableColumn="field">Field
          <p-sortIcon field="field"></p-sortIcon>
        </th>
        <th pSortableColumn="label">Label
          <p-sortIcon field="label"></p-sortIcon>
        </th>
        <th pSortableColumn="hint">Hint
          <p-sortIcon field="hint"></p-sortIcon>
        </th>
        <th pSortableColumn="metadataField">Metadata Field
          <p-sortIcon field="metadataField"></p-sortIcon>
        </th>
        <th pSortableColumn="tabNum">Table#
          <p-sortIcon field="tabNum"></p-sortIcon>
        </th>
        <th pSortableColumn="dynTabForm">Dynamic table edit form
          <p-sortIcon field="dynTabForm"></p-sortIcon>
        </th>
        <th pSortableColumn="dataType">Data type
          <p-sortIcon field="dataType"></p-sortIcon>
        </th>
        <th pSortableColumn="required">Required
          <p-sortIcon field="required"></p-sortIcon>
        </th>
        <th pSortableColumn="editable">Editable
          <p-sortIcon field="editable"></p-sortIcon>
        </th>
        <th pSortableColumn="visible">Visible
          <p-sortIcon field="visible"></p-sortIcon>
        </th>
        <th pSortableColumn="lvl">Level
          <p-sortIcon field="lvl"></p-sortIcon>
        </th>
        <th pSortableColumn="grpType">Group#
          <p-sortIcon field="grpType"></p-sortIcon>
        </th>
        <th pSortableColumn="htmlInputType">Edit type
          <p-sortIcon field="htmlInputType"></p-sortIcon>
        </th>
        <th pSortableColumn="linePlace">Line Place
          <p-sortIcon field="linePlace"></p-sortIcon>
        </th>
        <ng-container *ngIf="mode==2">
          <th pSortableColumn="rptExpression">Report field expression
            <p-sortIcon field="rptExpression"></p-sortIcon>
          </th>
          <th pSortableColumn="rptAgregate">Report agregate function
            <p-sortIcon field="rptAgregate"></p-sortIcon>
          </th>
        </ng-container>
        <th pSortableColumn="uniqKey">Unique key
          <p-sortIcon field="uniqKey"></p-sortIcon>
        </th>
        <th pSortableColumn="initVal">Initial value
          <p-sortIcon field="initVal"></p-sortIcon>
        </th>


      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row">
        <td>{{row.norder}}</td>
        <td>{{row.field}}</td>
        <td pTooltip="{{row.label}}">{{ outputRowCellValue(row.label, 60) }}</td>
        <td pTooltip="<td>{{row.hint}}</td>">{{ outputRowCellValue(row.hint, 40) }}</td>
        <td>{{row.metadataField}}</td>
        <td>{{row.tabNum}}</td>
        <td>{{row.dynTabForm}}</td>
        <td>{{ outputRowDataType(row) }}</td>
        <td><input type="checkbox" checked="{{row.required === 1 ? 'checked' : '' }}" disabled/></td>
        <td><input type="checkbox" checked="{{row.editable === 1 ? 'checked' : '' }}" disabled/></td>
        <td><input type="checkbox" checked="{{row.visible === 1 ? 'checked' : '' }}" disabled/></td>
        <td>{{row.lvl}}</td>
        <td>{{row.grpType}}</td>
        <td pTooltip="{{row.htmlList}}">{{row.editType}}</td>
        <td>{{row.linePlace}}</td>
        <ng-container *ngIf="mode==2">
          <td>{{row.rptExpression}}</td>
          <td>{{row.rptAgregate}}</td>
        </ng-container>
        <td><input type="checkbox" checked="{{row.uniqKey === 1 ? 'checked' : '' }}" disabled/></td>
        <td>{{row.initVal}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
