import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppMessageService} from "../../../_services/app-message.service";
import {StatUtilsService} from "../../statistic/_services/stat-utils.service";


//<!--          [(visible)]="showDlgCharts"-->

@Component({
  selector: 'app-data-charts',
  templateUrl: './data-charts.component.html'
})
export class DataChartsComponent implements OnInit, OnDestroy, AfterViewInit {
  // @Input() sColorSurveysNew: string = '#cbcbcb99';
  // @Input() sBackgroundSurveysNew: string = '#f5f5f599';
  // @Input() sColorSurveysDraft: string = '#ffc28299';
  // @Input() sBackgroundSurveysDraft: string = '#fff4e899';
  // @Input() sColorSurveysSent: string = '#6ba6e799';
  // @Input() sBackgroundSurveysSent: string = '#c3dbf599';
  // @Input() sColorFormsNew: string = '#a1a1a199';
  // @Input() sBackgroundFormsNew: string = '#dcdcdc99';
  // @Input() sColorFormsDraft: string = '#f8ab9b99';
  // @Input() sBackgroundFormsDraft: string = '#feeeea99';
  @Input() enableInit: boolean = false;
  // @Input() minDate: Date = null;
  // @Input() maxDate: Date = null;
  @ViewChild('mychart', {static: false}) mychart;


  private resizeObserver: any;
  private _height: number = 400;
  private _width: number = 400;


  data0: any;
  charttype: string = "line";
  public ischart: number = 1;
  public isgeo: number = 0;
  header: string = "";
  footer: string = "";

  geoLoaded = false;

  private _idCharts: any;
  private _filter: string = "";
  private _datamode: number = 0;
  chart_options: any;

  constructor(private messageService: AppMessageService,
              private dataService: StatUtilsService) {
  }

  ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(v => {
        this._height = v.contentRect.height + 38;
        this._width = v.contentRect.width;
        if (this.mychart?.wrapper) {
          this.mychart.draw();
        }
      });
    });
    setTimeout(() => {
      if (document.getElementById('stat-docs-distribution')) {
        this.resizeObserver.observe(document.getElementById('stat-docs-distribution'));
      }
    }, 100);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver && document.getElementById('stat-docs-distribution')) {
      this.resizeObserver.unobserve(document.getElementById('stat-docs-distribution'));
    }
  }

  ngOnInit(): void {
    this.geoLoaded = false;
  }

  clear() {
    this.data0 = undefined;
    this.header = undefined;
    this.footer = undefined;
  }

  refresh(idCharts: any, criteria?: string, datamode?: number) {
    this._idCharts = idCharts;
    this._filter = criteria;
    this._datamode = !datamode ? 0 : datamode;
    this.chart_options = {color: "black"};
    this.getSurveysMonthlyStat();
  }


  private getSurveysMonthlyStat() {
    this.data0 = {};
    this.header = "Please wait, loading data";
    this.charttype = 'line';
    // this._filter ="some filter";

    this.dataService.getListDocChartsStat(this._idCharts.cdoc + '.' + this._idCharts.id, this._filter, this._datamode)
      .subscribe(data => {
          this.geoLoaded = false;
          this.data0 = data;
          this.header = data.header;
          this.ischart = 1;
          this.isgeo = 0;
          let plug_labels: any;


          plug_labels = data.datalabels_plug;
          if (data?.charttype && (data.charttype.toLowerCase().startsWith("chart:wordcloud"))) {
            plug_labels = false;
          }
          let l_scales: any = {};
          if (data?.font_labels && Object.keys(data.font_labels).length) {
            l_scales[data?.indexAxis === 'y' ? 'y' : 'x'] = {
              ticks: {
                font: data.font_labels
              }
            };
          }
          if (data?.font_values && Object.keys(data?.font_values).length) {
            l_scales[data?.indexAxis === 'y' ? 'x' : 'y'] = {
              ticks: {
                font: data.font_values
              }
            };
          }
          if (plug_labels && (data?.datasets.length === 1) && (data.datasets[0].data.length > 1)) {
            const l_max = Math.max(...data.datasets[0].data);
            if (l_max > 0) {

              if (data?.font_values && Object.keys(data?.font_values).length) {
                l_scales[data?.indexAxis === 'y' ? 'x' : 'y'] = {
                  suggestedMax: l_max * 1.2,
                  ticks: {
                    font: data.font_values
                  }
                };
              } else {
                l_scales[data?.indexAxis === 'y' ? 'x' : 'y'] = {suggestedMax: l_max * 1.2};
              }
            }
          }

          if (plug_labels && Object.keys(plug_labels).length > 0) {
            if ((plug_labels?.font_weight && plug_labels.font_weight != "undefined") || (plug_labels?.font_size && plug_labels.font_size != "undefined")) {
              plug_labels['font'] = {};
              if (plug_labels?.font_weight) {
                plug_labels.font['weight'] = plug_labels?.font_weight;
              }
              if (plug_labels?.font_size) {
                plug_labels.font['size'] = plug_labels?.font_size;
              }
              if (plug_labels?.font_family) {
                plug_labels.font['family'] = plug_labels?.font_family;
              }
            }
          } else {
            plug_labels = false;
          }
          // font_labels
          //   :
          // {size: "20px", weight: "bold", family: "Josefin Sans"}
          // family
          //   :
          //   "Josefin Sans"
          // size
          //   :
          //   "20px"
          // weight
          //   :
          //   "bold"
          if (data?.charttype && data.charttype.toLowerCase().startsWith("chart:")) {
            this.charttype = data.charttype.replace("chart:", "");
            let legend: any = {};
            if (data?.font_legend && Object.keys(data?.font_legend).length) {
              legend['labels'] = {font: data?.font_legend};
            }
            this.chart_options = {
              indexAxis: (data?.indexAxis && (data.indexAxis)) ? data.indexAxis : 'x',
              plugins: {
                legend: legend,

                datalabels: plug_labels
              },
              color: data?.fontColor?.length ? data.fontColor : "black",
              scales: l_scales
            };


            this.ischart = 1;
            this.isgeo = 0;

            this.mychart.reinit();
          } else {
            this.charttype = 'line';
            this.ischart = 1;
            this.isgeo = 0;
            this.mychart.reinit();
          }
          this.geoLoaded = true;

        }
        ,
        error => {
          this.messageService.showError(error);
        }
      )
    ;
  }


}
