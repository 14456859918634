<div class="row">
  <div class="col-12 col-lg-8 col-xl-6 header">
    {{ param().label }}
  </div>
</div>
<div class="row">
  <div class="col-12 col-lg-8 col-xl-6 border-bottom pb-2 mb-3">
    <div *ngIf="chartType() === 'number'; else chart" class="text-center">
      <div class="number">{{ data().value }}</div>
      <p-radioButton value="sum" [(ngModel)]="agg"
                         label="sum" labelStyleClass="pr-2"></p-radioButton>
      <p-radioButton value="avg" [(ngModel)]="agg"
                         label="avg"></p-radioButton>
    </div>

    <ng-template #chart>
      <p-chart [type]="chartType()" [data]="data()" [options]="chartOptions()" height="20vw"></p-chart>
    </ng-template>
  </div>
</div>

