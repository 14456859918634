import {Component, OnInit, ViewChild} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {LazyLoadEvent} from 'primeng/api';
import {UIUtils} from '../../../_utils/ui-utils';
import {finalize} from 'rxjs/operators';
import {DataAdminService} from '../_services/data-admin.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {Table} from 'primeng/table';
import {AuthService} from '../../../_services/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-dicts',
  templateUrl: './dicts.component.html',
  styleUrls: ['./dicts.component.css']
})
export class DictsComponent implements OnInit {
  data: Pageable;
  loading = false;
  private currentPage: number = 0;
  private sorting: string;
  private tableFilter: any;
  tableFilterData: any = {};
  uploadShow: boolean;
  @ViewChild('dt', {static: false})
  private dataTable: Table;


  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService,
              private translate: TranslateService,
              private authService: AuthService) {
    this.data = new Pageable();
  }

  ngOnInit(): void {
  }

  private loadData(filter?: string) {
    filter = filter || '';

    if (this.tableFilter) {
      let search = UIUtils.getFilterStr(this.tableFilter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    this.loading = true;
    this.dataService.getAdmDicts(filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.data = data,
        error: (error) => this.messageService.showMessage(error, 'Error receiving data')
      });
  }

  handlePageChange(event: LazyLoadEvent) {
    this.data.content = [];
    this.tableFilter = event.filters;
    this.currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('&page=' + this.currentPage + this.sorting);
  }

  refresh() {
    this.tableFilter = null;
    this.currentPage = 0;
    this.tableFilterData = {variableName: '', itemCode: '', itemGroupVariableName: '', itemGroupCode: ''};
    this.dataTable.reset();
  }

  beforeUpload() {
    this.uploadShow = true;
  }

  fileUploader(event) {
    let fileList: FileList = event.files;
    if (fileList.length === 1) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file);
      this.dataService.uploadDicts(formData)
        .subscribe(
          data => {
            this.uploadShow = false;
            if (data.status !== 1) {
              this.messageService.showError(data.message);
            }
          },
          error => {
            this.messageService.showMessage(error);
          });
    }
  }

  download() {
    this.loading = true;
    this.dataService.downloadDicts()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: () => this.messageService.showInfo(this.translate.instant('message.request.inProgress')),
        error: (error) => this.messageService.showMessage(error, 'Error receiving data')
      });
  }

  get uploadButtonVisible(): boolean {
    return this.authService?.user?.typeAdmin > 0;
  }

  get downloadButtonVisible(): boolean {
    return this.authService?.user?.typeAdmin > 0;
  }
}
