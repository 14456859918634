import {Injectable} from '@angular/core';
import {globalConfig} from '../_utils/globals';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private isMobileResolution = false;

  constructor() {
    if (window.innerWidth <= 992) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  get config(): any {
    return globalConfig;
  }

  get api(): any {
    return globalConfig.api;
  }

  get isMobileDevice(): boolean {
    return this.isMobileResolution === true;
  }

  get isDesktopDevice(): boolean {
    return this.isMobileResolution === false;
  }

  get deviceType(): number {
    return (this.isMobileResolution === true) ? 2 : 0;
  }

  get touchable(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

}
