<div class="row">
  <div class="col-12">
    <label>Mission Code &nbsp;</label>
    <p-dropdown [options]="missions"
                [(ngModel)]="mission"
                [disabled]="missionDisabled() || loading()"
                optionLabel="value"
                placeholder="Please, select a Mission Code"
                [style]="{width: '15rem'}"></p-dropdown>
  </div>

  <div class="col-12">
    <p-pickList #pickList
                [source]="allInputs"
                [target]="inputs()"
                filterBy="label"
                sourceHeader="Available"
                targetHeader="Selected"
                [dragdrop]="true"
                [responsive]="true"
                [sourceStyle]="{ height: '10rem' }"
                [targetStyle]="{ height: '10rem' }"
                [disabled]="inputsLoading()"
                breakpoint="1400px">
      <ng-template let-item pTemplate="item">
          <span>
            {{ item?.label }}
          </span>
      </ng-template>
    </p-pickList>

    <ng-container *ngFor="let prm of inputs()">
      <div class="col-12">
        <app-stat-survey-answer-chart [param]="prm"></app-stat-survey-answer-chart>
      </div>
    </ng-container>
  </div>
</div>
