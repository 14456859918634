import {ChangeDetectionStrategy, Component, effect, inject, input, OnInit, signal, ViewChild} from '@angular/core';
import {StatUtilsService} from '../_services/stat-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";
import {finalize} from "rxjs/operators";
import {PickList} from "primeng/picklist";

declare var $: any;
declare var makeHTML2PDF: any;

@Component({
  selector: 'app-stat-survey-answers',
  templateUrl: './stat-survey-answers.component.html',
  styleUrls: ['./stat-survey-answers.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatSurveyAnswersComponent implements OnInit {
  idProject = input.required<string>();
  private statService = inject(StatUtilsService);
  private messageService = inject(AppMessageService);
  allInputs: any[] = [];
  inputs = signal<any[]>([]);
  private _width: number = 0;
  showPreview: boolean = false;
  missions: any[];
  mission = signal<any>(null);
  missionDisabled = signal<boolean>(true);
  loading = signal<boolean>(false);
  inputsLoading = signal<boolean>(false);
  @ViewChild('pickList') pickList: PickList;

  constructor() {
    effect((onCleanup) => {
      if (this.idProject()?.length) {
        this.init();
      }

      onCleanup(() => {
        this.missions = null;
        this.mission.set(null);
        this.missionDisabled.set(true);
        this.loading.set(false);
        this.pickList.resetFilter();
      });
    }, {allowSignalWrites: true});
    effect((onCleanup) => {
      this.getListParams(this.mission()?.cdoc);
    }, {allowSignalWrites: true});
  }

  ngOnInit() {
  }

  private init() {
    this.loading.set(true);
    this.statService.getUniqueValues(this.idProject(), 'MissionCode')
      .pipe(finalize(() => this.loading.set(false)))
      .subscribe({
        next: (data) => {
          this.missions = data;
          if (data.length === 1) {
            this.mission.set(data[0]);
          } else {
            this.missionDisabled.set(false);
          }
        },
        error: (err) => this.messageService.showError(err)
      });
    // this._cdoc = cdoc;
    // this.getListParams(this._cdoc);
  }

  export() {
    // this.statService.exportSurveyStat(this._cdoc);
  }

  preview() {
    this.showPreview = true;
    let cont = $('#SurveyStatistic .ui-panel-content');
    this._width = cont.width();
    cont.width(810);
  }

  pdf() {
    // let dt = new Date();
    // let fileName: string = 'stat_' + this._cdoc + '_' +
    //   StringUtils.pad('' + dt.getFullYear(), 4) +
    //   StringUtils.pad('' + (dt.getMonth() + 1), 2) +
    //   StringUtils.pad('' + dt.getDate(), 2) + '_' +
    //   StringUtils.pad('' + dt.getHours(), 2) +
    //   StringUtils.pad('' + dt.getMinutes(), 2) +
    //   StringUtils.pad('' + dt.getSeconds(), 2) + '.pdf';
    // let cont = $('#SurveyStatistic .ui-panel-content');
    // let self = this;
    // makeHTML2PDF(cont[0], fileName, function () {
    //   self.back();
    // });
  }

  back() {
    let cont = $('#SurveyStatistic .ui-panel-content');
    cont.width(this._width);
    this.showPreview = false;
  }

  private getListParams(cdoc: string) {
    this.inputsLoading.set(true);
    this.inputs.set([]);
    this.statService.getListParams(cdoc)
      .pipe(finalize(() => this.inputsLoading.set(false)))
      .subscribe({
        next: (data) => {
          this.parseResponse(data);
        },
        error: (error) => this.messageService.showMessage(error, '[ngOnInit]')
      });
  }

  private parseResponse(data: any[]) {
    this.allInputs = [];
    let pos: number = 0;
    let isGrid = false;
    let gridCnt = 0;
    for (let i in data) {
      const rdata = data[i], editType = rdata.editType, dataType = rdata.dataType;
      if (rdata.editType === 'grid' && rdata.htmlList) {
        const grid = rdata.htmlList.split(':');
        if (grid?.length > 1) {
          gridCnt = grid[0].split(';').length * grid.length;
          continue;
        }
      }
      isGrid = gridCnt > 0;
      if (isGrid) {
        gridCnt--;
      }
      if (rdata.tabNum === 0 && !rdata.htmlList?.startsWith('DICT:')
        && (editType.startsWith('radiogroup') || editType.startsWith("checkgroup")
          || editType.startsWith("select") || editType.startsWith("combobox"))
        || (!isGrid && (dataType === 'NUMBER' || dataType === 'INTEGER'))) {
        const listAnswers = rdata.htmlList;
        const items = listAnswers?.split(';');
        if (items?.length < 15) {
          pos++;
          const input = {
            cdoc: rdata.cdoc,
            pos: pos,
            field: rdata.field,
            label: rdata.label,
            inputList: rdata.htmlList,
            editType: editType,
            dataType: dataType
          };
          input['items'] = this.getItems(input);
          this.allInputs.push(input);
        }
      }
    }
  }

  getItems(prm: any): any {
    const listAnswers = prm.inputList;
    let _labels = [];
    // Form _labels
    let _items = listAnswers.split(';');
    for (let i = 0; i < _items.length; i++) {
      let _item = _items[i];
      if (_item.indexOf(':') == -1) {
        _labels.push({value: _item, label: _item});
      } else {
        let _vals = _item.split(':');
        _labels.push({value: _vals[0], label: _vals[1]});
      }
    }
    return _labels;
  }
}
