<form [formGroup]="userForm" (ngSubmit)="save(userForm.value)">
  <div class="p-fluid">
    <div class="row form-group">
      <div class="form-label-header col-8">
        <span><b>Include in the header</b></span> &nbsp;
      </div>
    </div>
    <div class="row form-group">
      <div class="col-8">
        <p-checkbox class="pl-0 border-0" name="headerVarName"
                    formControlName="headerVarName"
                    label="Variable name"
                    binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-8">
        <p-checkbox class="pl-0 border-0" name="headerDescription"
                    formControlName="headerDescription"
                    label="Variable description"
                    binary="true"></p-checkbox>
      </div>
    </div>

    <div class="row form-group">
      <div class="form-label-header col-8">
        <span><b>Download options</b></span> &nbsp;
      </div>
    </div>

    <div class="row form-group">
      <div class="col-12">
        <div class="form-check">
          <p-radioButton label="Download raw data including codes" inputId="downloadDescription0"
                         name="downloadDescription" [value]="0" formControlName="downloadDescription"></p-radioButton>
        </div>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-12">
        <div class="form-check">
          <p-radioButton label="Download descriptive values instead of codes" class="pl-0 border-0"
                         inputId="downloadDescription1" name="downloadDescription" [value]="1"
                         formControlName="downloadDescription"></p-radioButton>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-8">
        <div class="input-group">
          <p-checkbox class="form-control pl-0 border-0" name="fullDownload"
                      formControlName="fullDownload"
                      label="Full data download (Ignore column filter)"
                      binary="true"></p-checkbox>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-2"></div>
    <div class="col-6 text-right">
      <button pButton type="submit" icon="pi pi-save"
              label="Download"></button>
      <button pButton type="button" icon="pi pi-times"
              (click)="close()" label="Cancel"></button>
    </div>
  </div>

</form>
