<div class="layout-topbar">
  <div [class.container]="!ccanUser">
    <div class="row mr-0 vw-100" [class.no-gutters]="!ccanUser">
      <div class="pl-1">
        <div class="site-name">
          <a routerLink="">
            <img ngSrc="/assets/images/logo-r.png" height="50" width="190" alt="City Cancer Challenge">
          </a>
        </div><!-- /site-name -->
      </div>
      <div class="topbar-right" [class.topbar-right-mobile]="configService.isMobileDevice">
        <ul>
          <li *ngIf="configService.isDesktopDevice">
            <div class="app-messages">
              <app-list-messages (onDelete)="onDeleteMessage($event)"></app-list-messages>
            </div>
          </li>
          <li>
            <div class="app-context">
              <div class="app-userinfo">
                <span title="My account" class="app-user">
                  <span class="app-user">
                    <p-splitButton #userBtn [model]="authItems"
                                   [label]="configService.isDesktopDevice ? authService?.user?.fullname : ''"
                                   [icon]="configService.isMobileDevice ? 'pi pi-user' : ''"
                                   [styleClass]="'app-user ' + (configService.isMobileDevice ? 'p-button-lg' : '')"
                                   (onClick)="showUserMenu($event)"></p-splitButton>
                  </span>
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div><!-- /topbar-right -->
      <div class="col-1 ml-auto align-self-center">
        <!--   bars-->
        <a class="float-right pr-2 d-block d-md-none" href="#" (click)="onMenuButtonClick($event)">
          <i class="fa fa-bars fa-2x"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation modal-->
<p-confirmDialog [baseZIndex]="11000"></p-confirmDialog>
<p-confirmDialog [baseZIndex]="11000" key="uncloseable" [closable]="false"></p-confirmDialog>
