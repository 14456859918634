import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersComponent} from './users/users.component';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StickyPanelDirective} from '../../_directives/sticky-panel.directive';
import {OnlyNumberDirective} from '../../_directives/only-number.directive';
import {LogConnectsComponent} from './events/log-connects.component';
import {LogEvtPublicComponent} from './events/log-evt-public.component';
import {LogEvtPrivateComponent} from './events/log-evt-private.component';
import {DataAdminService} from './_services/data-admin.service';
import {UtlListProjectsComponent} from './utl-list-projects/utl-list-projects.component';
import {UtlUserProjectsComponent} from './utl-user-projects/utl-user-projects.component';
import {DialogLogEvtPublicComponent} from './events/dialog-log-evt-public.component';
import {DialogLogEvtPrivateComponent} from './events/dialog-log-evt-private.component';
import {ClipboardModule} from 'ngx-clipboard';
import {DictsComponent} from './dicts/dicts.component';
import {TableModule} from 'primeng/table';
import {SplitButtonModule} from 'primeng/splitbutton';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {TreeTableModule} from 'primeng/treetable';
import {ButtonModule} from 'primeng/button';
import {ToolbarModule} from 'primeng/toolbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {InputMaskModule} from 'primeng/inputmask';
import {PanelModule} from 'primeng/panel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {TooltipModule} from 'primeng/tooltip';
import {PasswordModule} from 'primeng/password';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextModule} from 'primeng/inputtext';
import {ConfirmationService} from 'primeng/api';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ShowErrorModule} from '../show-error/show-error.module';
import {TemplatesComponent} from './events/templates/templates.component';
import {DictCountriesComponent} from "./countries/dict-countries.component";
import {LabelsComponent} from "./labels/labels.component";
import {TagModule} from 'primeng/tag';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
  {path: 'users', component: UsersComponent},
  {path: 'users/evt/connects/:id', component: LogConnectsComponent},
  {path: 'users/evt/private/:iduser', component: LogEvtPrivateComponent},
  {path: 'log/evt/connects', component: LogConnectsComponent},
  {path: 'log/evt/private', component: LogEvtPrivateComponent},
  {path: 'log/evt/public', component: LogEvtPublicComponent},
  {path: 'docs/evt/private/:iddoc', component: LogEvtPrivateComponent},
  {path: 'docs/evt/public/:iddoc', component: LogEvtPublicComponent},
  {path: 'dicts', component: DictsComponent},
  {path: 'countries', component: DictCountriesComponent},
  {path: 'tags', component: LabelsComponent},
  {path: 'log/evt/templates', component: TemplatesComponent},
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        SplitButtonModule, ButtonModule, TreeTableModule, TableModule, AutoCompleteModule, ToolbarModule,
        RadioButtonModule, CheckboxModule, DropdownModule, CalendarModule, InputMaskModule,
        PanelModule, ConfirmDialogModule, DialogModule,
        ProgressBarModule, TooltipModule, PasswordModule, InputTextModule, ClipboardModule, FileUploadModule,
        InputTextareaModule, ShowErrorModule, TagModule, OverlayPanelModule, TranslateModule
    ],
  declarations: [
    UsersComponent,
    OnlyNumberDirective,
    StickyPanelDirective,
    LogConnectsComponent,
    LogEvtPublicComponent,
    LogEvtPrivateComponent,
    UtlListProjectsComponent,
    UtlUserProjectsComponent,
    DialogLogEvtPublicComponent,
    DialogLogEvtPrivateComponent,
    DictsComponent,
    DictCountriesComponent, LabelsComponent,
    TemplatesComponent
  ],
  exports: [
    DialogLogEvtPublicComponent,
    DialogLogEvtPrivateComponent
  ],
  providers: [
    DataAdminService,
    ConfirmationService
  ]
})
export class AdminModule {
}
