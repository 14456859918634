import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailTmplComponent } from './email-tmpl.component';
import {ReactiveFormsModule} from "@angular/forms";
import {ShowErrorModule} from "../show-error/show-error.module";
import {FileUploadModule} from "primeng/fileupload";
import {MultiSelectModule} from "primeng/multiselect";
import {DropdownModule} from "primeng/dropdown";



@NgModule({
  declarations: [
    EmailTmplComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ShowErrorModule,
        FileUploadModule,
        MultiSelectModule,
        DropdownModule
    ],
  exports:[
    EmailTmplComponent
  ]
})
export class EmailTmplModule { }
