<p-dialog header="{{header}}" [(visible)]="showDialog"
          [modal]="true" [style]="dialogStyle" [minY]="70"
          [maximizable]="true" [baseZIndex]="8000">
  <p-table [value]="reports.content"
           [totalRecords]="reports.totalElements"
           [rows]="reports.size"
           [loading]="loading"
           [lazy]="true"
           (onLazyLoad)="handlePageChange($event)"
           selectionMode="single"
           [selection]="selected"
           [paginator]="true"
           paginatorPosition="bottom"
           [resizableColumns]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 420px)'">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col style="width:1rem;">
        <col style="width:4rem;">
        <col style="width:1rem;">
        <col style="width:13rem;">
        <col style="width:6rem;">
        <col style="width:1.4rem;">
        <col style="width:1.4rem;">
        <col style="width:1.4rem;">
        <col style="width:1.4rem;">
        <col style="width:1.4rem;">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>#</th>
        <th pSortableColumn="c_doc_rowc">Field<p-sortIcon field="c_doc_rowc"></p-sortIcon></th>
        <th pSortableColumn="sign">Sign<p-sortIcon field="sign"></p-sortIcon></th>
        <th pSortableColumn="expression">Expression<p-sortIcon field="expression"></p-sortIcon></th>
        <th pSortableColumn="description">Description<p-sortIcon field="description"></p-sortIcon></th>
        <th>Filling</th>
        <th>Checking</th>
        <th>For import</th>
        <th>For API</th>
        <th>Check before save</th>
        <th>Backend only</th>
        <th>Blocking save</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row">
        <td>{{row.n_order}}</td>
        <td>{{row.c_doc_rowc}}</td>
        <td>{{row.sign}}</td>
        <td>{{row.expression}}</td>
        <td>{{row.description}}</td>
        <td><input type="checkbox" checked="{{row.fillonly ? 'checked' : '' }}" disabled /></td>
        <td><input type="checkbox" checked="{{row.checkonly ? 'checked' : '' }}" disabled /></td>
        <td><input type="checkbox" checked="{{row.isForImport ? 'checked' : '' }}" disabled /></td>
        <td><input type="checkbox" checked="{{row.isForApi ? 'checked' : '' }}" disabled /></td>
        <td><input type="checkbox" checked="{{row.isBeforeSave ? 'checked' : '' }}" disabled /></td>
        <td><input type="checkbox" checked="{{row.isBackendOnly ? 'checked' : '' }}" disabled /></td>
        <td><input type="checkbox" checked="{{row.isBlockSave ? 'checked' : '' }}" disabled /></td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
