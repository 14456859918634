<p-table #dt
         [value]="rules"
         [lazy]="true"
         (onLazyLoad)="handlePageChange($event)"
         [loading]="loading$ | async"
         (onRowSelect)="handleRowSelect($event)"
         selectionMode="single"
         [selection]="selected"
         [resizableColumns]="true" [scrollable]="true"
         [scrollHeight]="'500px'">

  <ng-template pTemplate="header">
    <tr>
      <th class="w-8rem">Field</th>
      <th class="w-8rem">Type or rule</th>
      <th class="w-8rem">Input value</th>
      <th class="w-8rem">Caclulated value</th>
      <th class="w-2rem">Result</th>
      <th class="w-10rem">Expression</th>
      <th class="w-10rem">Values</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-i="rowIndex">
    <tr [pSelectableRow]="row" [class]="getRowClass(row.res)">
      <td>{{row.field}}</td>
      <td>{{row.type}}</td>
      <td pTooltip="{{row.znInput}}">{{ outputRowCellValue(row.znInput, 30) }}</td>
      <td pTooltip="{{row.znchk}}">{{ outputRowCellValue(row.znchk, 30) }}</td>
      <td>{{row.res}}</td>
      <td pTooltip="{{row.expression}}">{{ outputRowCellValue(row.expression, 50) }}</td>
      <td pTooltip="{{ outputRowCellValueVal(row.vals) }}">{{ outputRowCellValueVal(row.vals, 50) }}</td>
    </tr>
  </ng-template>
</p-table>
