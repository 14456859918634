<div class="p-badge	p-position">
  <i class="pi pi-bell" [style]="{ 'font-size': '1.75em', 'cursor': 'pointer', 'color': 'white' }"></i>
  <span *ngIf="cntMessages!= 0"
        [class]="badgeClass" [ngStyle]="{ 'background-color': backgroundColor }"
        [ngClass]="{'p-widget p-widget-content p-corner-all p-badge-content':true,'fixed': true, 'dot': dot }"
        (click)="showList()" pTooltip="{{ messagesTitle }}">
            {{ countMessages }}
      </span>
</div>

<p-sidebar [(visible)]="showMessages" position="right" appendTo="body"
           [style]="{ width:'36em', 'overflow-y': 'scroll' }" showCloseIcon="true"
           #sidebar>
  <div class="row">
    <div class="col-12" style="text-align: right;">
      <a (click)="clearMessages()" class="link-command">Clear all</a>
    </div>
    <div class="col-12 group-item" *ngFor="let rec of messages">
      <div class="row">
        <div class="col-11 message-title">
          <ng-container [ngSwitch]="rec.state">
            <i class="fas fa-exclamation-triangle" style="color: yellow!important;" *ngSwitchCase="3"></i>
            <i class="fas fa-exclamation-circle" style="color: #f69a92;!important;" *ngSwitchCase="4"></i>
            <i class="far fa-check-circle" style="color: green!important;" *ngSwitchCase="2"></i>
            <i class="far fa-question-circle" style="color: grey!important;" *ngSwitchDefault></i>
          </ng-container>
          {{ rec.title }}
        </div>
        <div class="col-1"><i class="fas fa-trash-alt" style="cursor: pointer;" (click)="delete(rec.code)"></i></div>
      </div>
      <div class="row">
        <div class="col-12 message-msg">
          {{ rec.message }}
          <span *ngIf="rec.executionTime != 0" class="execTime">
            &nbsp;Execution time:&nbsp;{{ outputExecTimeInMins(rec.executionTime) }}
            min {{ outputExecTimeInSecs(rec.executionTime) }}&nbsp;sec.</span>
          <br/>
          <span *ngIf="rec.idDownload && rec.fileName">
            &nbsp;<a (click)="download(rec.idDownload)" class="link-download-file">Download file "{{rec.fileName}}"</a>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 message-oper-date" *ngIf="rec.dateRec">
          {{ rec.dateRec }}
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
