import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/_services/config.service';
import {AppMessageService} from '../../../_services/app-message.service';

@Injectable()
export class TemplateUtilsPrivateService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) { }

  getFormTemplateVars(cdoc: string) {
    return this.http.get<any>(`${this.configService.api.dicts.docutl}/${cdoc}/tmpl-vars`)
      .toPromise()
      .catch(err => { this.messageService.showMessage(err); });
  }

  getListProjectDocs(id: string) {
    return this.http.get<any>(`${this.configService.api.projects.others}/${id}/docs`)
      .toPromise()
      .catch(err => { this.messageService.showMessage(err); });
  }

  getListProjectAppendixDocs(id: string) {
    return this.http.get<any>(`${this.configService.api.dicts.docs}/childs/project/${id}`)
      .toPromise()
      .catch(err => { this.messageService.showMessage(err); });
  }

  getListAppendixDocs(cdoc: string) {
    return this.http.get<any>(`${this.configService.api.dicts.docs}/${cdoc}/childs`);
  }
}

@Injectable()
export class TemplateUtilsPublicService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) {
  }

  getFormTemplateVars(cdoc: string) {
    return this.http.get<any>(`${this.configService.api.dicts.pubDocs}/${cdoc}/tmpl-vars`)
      .toPromise()
      .catch(err => { this.messageService.showMessage(err); });
  }
}
