import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthMessagesService {
  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }

  getCount(state?: number): Observable<any> {
    return this.http.get<any>(`${this.configService.api.authuser.messages}/count?state=${state || ''}`);
  }

  getList(state?: number): Observable<any> {
    return this.http.get<any>(`${this.configService.api.authuser.messages}/list?state=${state || ''}`);
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.configService.api.authuser.messages}/${id}`);
  }

  deleteAll(): Observable<any> {
    return this.http.post<any>(`${this.configService.api.authuser.messages}/clear-all`, null);
  }
}
