<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-12 pt-1">
      <div class="form-check form-check-inline">
        <p-radioButton inputId="projectType2" [value]="2"
                       formControlName="type"></p-radioButton>
        <label class="form-check-label" for="projectType2">Open Questionnaires</label>
      </div>
      <div class="form-check form-check-inline">
        <p-radioButton inputId="projectType1" [value]="1"
                       formControlName="type"></p-radioButton>
        <label class="form-check-label" for="projectType1">Closed Questionnaires</label>
      </div>

      <ng-container *ngIf="form.get('type').value == 2">
        <div class="row form-group pl-5" formGroupName="opened">
          <div class="col-12 pt-1">
            <p-checkbox [binary]="true" label="View" formControlName="view"></p-checkbox>
          </div>
          <div class="col-12 pt-1">
            <p-checkbox [binary]="true" label="Edit" formControlName="edit"></p-checkbox>
          </div>
          <div class="col-12 pt-1">
            <p-checkbox [binary]="true" label="Reopen" formControlName="reopen"></p-checkbox>
          </div>
          <div class="col-12 pt-1">
            <p-checkbox [binary]="true" label="Delete" formControlName="del"></p-checkbox>
          </div>
          <div class="col-12 pt-1">
            <p-checkbox [binary]="true" label="Submit" formControlName="submit"></p-checkbox>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="form.get('type').value == 1">
        <div class="row form-group pl-5" formArrayName="closed">
          <ng-container *ngFor="let level of closed.controls ; index as ind"
                        [formGroupName]="ind">
            <div class="col-4 pt-1">
              <p-checkbox [binary]="true" label="Level {{ind + 1}}" formControlName="checked"
                          labelStyleClass="font-weight-bold"></p-checkbox>
              <div class="row form-group">
                <div class="col-12 pt-2">
                  <p-checkbox [binary]="true" label="View" formControlName="view"></p-checkbox>
                </div>
                <div class="col-12 pt-1">
                  <p-checkbox [binary]="true" label="Create" formControlName="create"></p-checkbox>
                </div>
                <div class="col-12 pt-1">
                  <p-checkbox [binary]="true" label="Edit" formControlName="edit"></p-checkbox>
                </div>
                <div class="col-12 pt-1">
                  <p-checkbox [binary]="true" label="Reopen" formControlName="reopen"></p-checkbox>
                </div>
                <div class="col-12 pt-1">
                  <p-checkbox [binary]="true" label="Delete" formControlName="del"></p-checkbox>
                </div>
                <div class="col-12 pt-1">
                  <p-checkbox [binary]="true" label="Submit" formControlName="submit"></p-checkbox>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

    </div>
  </div>
</form>
