<div class="fullcontent d-flex align-items-center">
  <div class="text-center">
    <h1>IPNA</h1>
    <h2>Integrated Platform for Needs Assessment</h2>

    <h5 class="d-none d-md-block"><p>One of the foundational pieces of
      <a href="https://citycancerchallenge.org" class="ccan-link" target="_blank">City Cancer Challenge’s (C/Can)</a>
      city engagement process is a needs
      assessment
      that provides in-depth information on the quality and capacity of cancer care services in a city. The Integrated
      Platform for Needs Assessment is the online tool to collect this information.</p></h5>
  </div>
  <div class="footer-logo text-center fixed-bottom">
    <img ngSrc="/assets/images/logo-r.png" height="80" width="305" alt="City Cancer Challenge">
  </div>
</div>
