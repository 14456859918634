<p-table [value]="reports.content"
         [totalRecords]="reports.totalElements" [rows]="reports.size"
         [loading]="loading"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)"
         selectionMode="single" [(selection)]="selectedReport"
         [paginator]="true" paginatorPosition="bottom"
         [scrollable]="true" [scrollHeight]="'calc(100vh - 295px)'"
         scrollDirection="both"
         sortField="cdoc" [sortOrder]="1">
  <ng-template pTemplate="caption">
    <div>
      <button *ngIf="docAddButtonVisible" pButton type="button" icon="pi pi-plus"
              [disabled]="!isSetIdProject" (click)="docCreate()" pTooltip="Add new template"></button>
      <button *ngIf="docEditButtonVisible" pButton type="button" icon="pi pi-pencil"
              [disabled]="docEditButtonDisabled" (click)="docEdit()"
              pTooltip="Edit the template"></button>
      <button *ngIf="docDeleteButtonVisible" pButton type="button" icon="pi pi-trash"
              [disabled]="docDeleteButtonDisabled" (click)="docDelete()"
              pTooltip="Remove the template"></button>
      <button pButton type="button" icon="pi pi-clone"
              [disabled]="copyLinkButtonDisabled" (click)="copyToClipbord()"
              [pTooltip]="genLinkLabel"></button>
      <!-- <i *ngIf="projectAdmin" class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i> -->

      <p-splitButton icon="fa fa-download"
                     [disabled]="itemsExportButtonDisabled"
                     [model]="itemsExport" iconPos="left" pTooltip="Download templates"
                     (onClick)="download('XLSX')">


      </p-splitButton>
      <p-splitButton icon="fa fa-upload"
                     [disabled]="itemsImportButtonDisabled"
                     [model]="itemsImport" iconPos="left" pTooltip="Upload templates"
                     (onClick)="this.upload('XLSX')"></p-splitButton>

      <!-- <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i> -->

      <button pButton type="button" icon="pi pi-file-o"
              [disabled]="docPreviewButtonDisabled" (click)="callDocBuilder()"
              pTooltip="Preview the template"></button>


      <!-- <p-splitButton icon="pi pi-cog"
                     [disabled]="itemsOptsButtonDisabled"
                     [model]="itemsOpts" iconPos="left" pTooltip="Options"
                     (onClick)="optsParameters()"
      ></p-splitButton> -->
    </div>
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col style="width:2rem;">
      <col style="width:12rem;">
      <col>
      <col style="width:8rem;">
      <col style="width:8rem;">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="headergrouped">
    <tr>
      <th>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="cdoc">Code
        <p-sortIcon field="cdoc"></p-sortIcon>
      </th>
      <th pSortableColumn="name">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="mode">Mode
        <p-sortIcon field="mode"></p-sortIcon>
      </th>
      <th>Enabled</th>
      <th>Main</th>
      <th pSortableColumn="createDate">Created on
        <p-sortIcon field="createDate"></p-sortIcon>
      </th>
      <th>Created by</th>
      <th pSortableColumn="operDate">Updated on
        <p-sortIcon field="operDate"></p-sortIcon>
      </th>
      <th>Updated by</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-i="rowIndex">
    <tr (dblclick)="rowDoubleClick(row)">
      <td class="selection">
        <p-tableCheckbox [value]="row"></p-tableCheckbox>
      </td>
      <td>{{row.cdoc}}</td>
      <td>{{row.name}}</td>
      <td>{{row.mode == 0 ? "form" : (row.mode == 1 ? "wizard" : (row.mode == 2 ? "report" : "data upload"))}}</td>
      <td>
        <input type="checkbox" checked="{{row.access == 1 ? 'checked' : ''}}" disabled/>
      </td>
      <td>
        <input type="checkbox" checked="{{row.mainDoc == 1 ? 'checked' : ''}}" disabled/>
      </td>
      <td>{{row.createDate}}</td>
      <td>{{row.createUserName}}</td>
      <td>{{row.operDate}}</td>
      <td>{{row.operUserName}}</td>
    </tr>
  </ng-template>
</p-table>

<!-- Upload template -->
<p-dialog header="Upload" [(visible)]="uploadShow" [modal]="true" [style]="{width: '600px'}">
  <div>
    <p-fileUpload name="file[]" customUpload="true" (uploadHandler)="fileUploader($event)"></p-fileUpload>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-times" (click)="uploadShow=false" label="Close"></button>
  </ng-template>
</p-dialog>

<!-- Document property -->
<p-dialog header="{{dialogDocTitle}}" [(visible)]="showDocProp"
          [modal]="true" [style]="{ width: '600px', minWidth: '600px' }"
          [maximizable]="false" [baseZIndex]="9000">
  <project-document-record (onSavedData)="onSaveProperty($event)"
                           (onClose)="showDocProp = false"></project-document-record>
</p-dialog>

<!-- Document property -->
<p-dialog [header]="genLinkLabel" [(visible)]="genLinkVisible"
          [modal]="true" [style]="{ width: '600px', minWidth: '600px' }"
          [maximizable]="false" [baseZIndex]="9000">
  <div class="p-fluid">
    <div *ngIf="grType === 2; else formLink" class="border p-3">
      {{genLink}}
    </div>

    <ng-template #formLink>
      <div class="row form-group">
        <div class="col-12">
          <p-radioButton [value]="0" [(ngModel)]="genLinkType" (onClick)="genLinkTypeChanged()"
                         label="For users with 'Document' rights"></p-radioButton>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-12">
          <p-radioButton [value]="2" [(ngModel)]="genLinkType" (onClick)="genLinkTypeChanged()"
                         label="For users with 'User only' rights"></p-radioButton>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-12">
          <p-radioButton [value]="1" [(ngModel)]="genLinkType" (onClick)="genLinkTypeChanged()"
                         label="For users with 'Organization' rights"></p-radioButton>
        </div>
      </div>
      <ng-container *ngIf="genLinkType === 1 || genLinkType === 2">
        <div class="row form-group">
          <div class="col-3">Organization</div>
          <div class="col-9">
            <p-dropdown [options]="orgsList"
                        [(ngModel)]="genLinkIdOrg"
                        (onChange)="genLinkTypeChanged()"
                        [showClear]="genLinkType === 2"
                        placeholder="Please, select an organization"
                        optionValue="id"
                        optionLabel="name"
                        appendTo="body"></p-dropdown>
          </div>
        </div>
      </ng-container>

      <div class="border p-3">
        {{genLink}}
      </div>
    </ng-template>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-clone"
            (click)="copyGeneratedLink()" [disabled]="copyGeneratedLinkButtonDisabled"
            label="Copy"></button>
    <button pButton type="button" icon="pi pi-times"
            (click)="genLinkVisible = false"
            label="Close"></button>
  </ng-template>
</p-dialog>

<!-- Form preview -->
<app-dialog-template-preview></app-dialog-template-preview>

<!-- Form parameters -->
<project-document-params></project-document-params>

<!-- Form rules -->
<project-document-rules></project-document-rules>

<!-- Form rules -->
<project-document-charts></project-document-charts>
