<!-- Modal "UPLOAD EXTERNAL EXCEL FILE -->
<p-dialog header="Upload" [(visible)]="uploadShow" [modal]="true" [style]="{width: '600px'}"
          [closable]="false">
  <div [class.d-none]="uploading">
    <p-fileUpload name="file[]" customUpload="true"
                  (uploadHandler)="fileUploader($event)"></p-fileUpload>
  </div>
  <div *ngIf="uploading" class="text-center d-block">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    <div style="font-size: 1.1rem">Uploading...</div>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="fa a-close" (click)="uploadShow=false" label="Close"
            [disabled]="closeButtonDisabled"></button>
  </ng-template>
</p-dialog>

<!-- Modal "MAPPING FIELDS for LOADING DATA" -->
<p-dialog header="Mapping fields" [(visible)]="mapShow" [modal]="true"
          [style]="dialogStyle" [minY]="70">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="fa fa-check"
              (click)="importRun()" label="Commit"
              [disabled]="importRunButtonDisabled"></button>
      <button pButton type="button" icon="fas fa-times"
              (click)="deleteImport()" label="Close"
              [disabled]="deleteImportButtonDisabled"></button>

      <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i>

      <p-checkbox [(ngModel)]="removeAllData" [binary]="true" label="Remove all prior data"></p-checkbox>
      &nbsp;&nbsp;<p-checkbox [(ngModel)]="fullLog" [binary]="true" label="Detailed log"></p-checkbox>
      <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i>

      <p-dropdown id="formDropdown" *ngIf="!formDropdownDisabled"
                  [options]="cdocs"
                  [(ngModel)]="documentCode" [style]="{ 'min-width': '600px', 'max-width': '600px', 'width': '600px' }"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="formDropdownDisabled"
                  (onChange)="handleDocumentCodeChanged(documentCode)"></p-dropdown>
    </div>
  </p-toolbar>

  <p-tabView [(activeIndex)]="indexTb" (onChange)="onChangeImportTabs($event)">
    <p-tabPanel header="Fields">
      <!-- Map fields-->
      <p-table [value]="fields" [scrollable]="true" scrollHeight="490px" editMode="row" dataKey="fieldSrc">
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col>
            <col>
            <col style="width: 9rem;">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th>Your variable</th>
            <th>Application variable</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="rowData">
            <td>
              {{rowData.fieldSrc}}
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown [options]="targetFields(rowData.tabNum)"
                              [(ngModel)]="rowData.fieldTrg" [style]="{'width':'100%'}"></p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{rowData.fieldTrg}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td style="text-align:center">
              <button *ngIf="!editing && rowData.type === 2" pButton type="button" pInitEditableRow icon="pi pi-pencil"
                      (click)="onRowEditInit(rowData)"></button>
              <button *ngIf="editing" pButton type="button" pSaveEditableRow icon="pi pi-check"
                      style="margin-right: .5em"
                      (click)="onRowEditSave(rowData, ri)"></button>
              <button *ngIf="editing" pButton type="button" pCancelEditableRow icon="pi pi-times"
                      (click)="onRowEditCancel(rowData, ri)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>

    <p-tabPanel header="Data">
      <p-toolbar>
        <div class="p-toolbar-group-left">
          <button pButton type="button" [disabled]="selected == null || (selected && selected.status == 1)"
                  icon="pi pi-check" label="New row" (click)="setRowStatus(1)"></button>
          <button pButton type="button" [disabled]="selected == null || (selected && selected.status == 0)"
                  icon="pi pi-times" label="Skip row" (click)="setRowStatus(0)"></button>
        </div>
      </p-toolbar>
      <!-- Data -->
      <p-table [value]="dictData.content"
               [totalRecords]="dictData.totalElements"
               [rows]="dictData.size"
               [lazy]="true"
               (onLazyLoad)="handlePageDictChange($event)"
               [loading]="loading"
               selectionMode="single" [selection]="selected" (onRowSelect)="handleRowSelect($event)"
               [paginator]="true" paginatorPosition="bottom"
               [resizableColumns]="true"
               [scrollable]="true" scrollHeight="375px">
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col style="width:8rem;">
            <ng-container *ngFor="let col of dataFields">
              <col *ngIf="col.cdoc != '*'" [ngStyle]="formatStyleCol(col)">
            </ng-container>
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="status">Status
              <p-sortIcon field="status"></p-sortIcon>
            </th>
            <th>#</th>
            <ng-container *ngFor="let col of dataFields">
              <th *ngIf="col.cdoc != '*'">{{ col.fieldSrc }}</th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex">
          <tr [pSelectableRow]="row">
            <td [ngClass]="getStatusNgClass(row)">{{ row.status == 1 ? 'New' : 'Skip' }}</td>
            <td>{{ row.rn  }}</td>

            <ng-container *ngFor="let col of dataFields">
              <td *ngIf="col.cdoc != '*'" style="white-space: nowrap; width: 250px; max-width: 250px;"
                  pTooltip="{{ col.tabNum === 0 ? getRowValue(row.vals || {}, col.fieldSrc) : ''}}">
                <a *ngIf="col.tabNum !== 0" href="javascript:void(0)"
                   (click)="viewTableData(row, col.tabNum)">View Data</a>
                <span *ngIf="col.tabNum === 0">
                  {{getRowValue(row.vals || {}, col.fieldSrc)}}
                  </span>
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</p-dialog>
<!-- /Modal -->

<p-dialog [header]="tabHeader" [(visible)]="tabDialogVisible" [modal]="true"
          [style]="{ 'width': '80%', 'minWidth': '400px' }">
  <p-table [value]="tabData"
           [totalRecords]="tabData?.length" [rows]="10"
           [paginator]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'"
           paginatorPosition="bottom">
    <ng-template pTemplate="header">
      <tr>
        <ng-container *ngFor="let col of currTabFields">
          <th *ngIf="col.cdoc != '*'">{{ col.fieldSrc }}</th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <ng-container *ngFor="let col of currTabFields">
          <td *ngIf="col.cdoc !== '*'">
            {{ getRowValue(row || {}, col.fieldSrc) }}
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
