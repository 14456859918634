<p-table #dataTable
         [value]="projects1.content"
         (onRowSelect)="handleRowSelect($event)" selectionMode="single" [selection]="selected"
         [rows]="projects1.size" [totalRecords]="projects1.totalElements"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
         [paginator]="true" paginatorPosition="bottom"
         [resizableColumns]="true" [scrollable]="true" [scrollHeight]="'400px'"
         sortField="name" [sortOrder]="1" [style]="{height: '560px'}">
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col style="width: 2.5rem;">
      <col style="width:20rem;">
      <col>
      <col style="width:8rem;">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th rowspan="2"></th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               [value]="filterValue('name')"
               (change)="dataTable.filter($event.target.value, 'name',  'contains')"/>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               [value]="filterValue('description')"
               (change)="dataTable.filter($event.target.value, 'description',  'contains')"/>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               [value]="filterValue('orgName')"
               (change)="dataTable.filter($event.target.value, 'orgName',  'contains')"/>
      </th>
    </tr>
    <tr>
      <th pSortableColumn="name">Name
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="description">Description
        <p-sortIcon field="description"></p-sortIcon>
      </th>
      <th pSortableColumn="rootsName">Organization
        <p-sortIcon field="rootsNode"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-rowIndex let-i="rowIndex">
    <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex">
      <td [pTooltip]="(row.access !== 1 ? 'Non-Active' : 'Active') + '\n' + (row.operDate || '')"
          [tooltipZIndex]="1000001">
        <i class="far fa-check-circle" style="color: green!important;" *ngIf="row.access === 1"></i>
        <i class="far fa-question-circle" style="color: grey!important;" *ngIf="row.access !== 1"></i>
      </td>
      <td>{{row.name}}</td>
      <td>{{row.description}}</td>
      <td>{{row.organization.name}}</td>
    </tr>
  </ng-template>
</p-table>
<!--row.access == 1-->
