import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {StatSurveysCommonComponent} from '../../statistic/stat-surveys-common/stat-surveys-common.component';
import {StatSurveysDashboardComponent} from '../../statistic/stat-surveys-dashboard/stat-surveys-dashboard.component';
import {ProjectRecordComponent} from '../project-record/project-record.component';
import {CMenuRefreshType, Hierarchy, Label, MenuRefreshOpt} from '../../../_domains/UITypes';
import {ConfirmationService} from 'primeng/api';
import {AppMessageService} from '../../../_services/app-message.service';
import {AuthService} from '../../../_services/auth.service';
import {StatDocsDistributionComponent} from '../../statistic/stat-docs-distribution/stat-docs-distribution.component';
import {TranslateService} from '@ngx-translate/core';
import {ProjectButtonsComponent} from '../project-buttons/project-buttons.component';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'project-summary',
  templateUrl: './project-summary.component.html'
})
export class ProjectSummaryComponent implements OnInit, OnDestroy {
  private _idProject!: string;
  @Input() grType = 0;
  @Input() access: boolean = false;

  @ViewChild(StatDocsDistributionComponent, {static: false}) cmpStatDistribution;

  @ViewChild(StatSurveysCommonComponent, {static: false}) cmpStatFormsCommon;
  @ViewChild(StatSurveysDashboardComponent, {static: false}) cmpStatDashboard;
  @ViewChild(ProjectRecordComponent, {static: false}) cmpProjectRecord;
  @ViewChild(ProjectButtonsComponent, {static: false}) cmpProjectButtons;

  data: Hierarchy = new Hierarchy();
  showProjectProp = false;
  showProjectButtons = false;
  sLastActivity = '';
  sFirstActivity = '';
  descriptiveText!: string;

  constructor(private projectService: ProjectUtilsService,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private messageService: AppMessageService,
              private translate: TranslateService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  @Input() set idProject(id: string) {
    if (this._idProject !== id && id) {
      this._idProject = id;
      this.clear();
      this.refresh();
    }
  }

  clear() {
    this.data = new Hierarchy();
    this.descriptiveText = '';

    if (this.cmpStatDistribution && typeof (this.cmpStatDistribution) !== 'undefined') {
      this.cmpStatDistribution.clear();
    }
    if (this.cmpStatDashboard && typeof (this.cmpStatDashboard) !== 'undefined') {
      this.cmpStatDashboard.clear();
    }
    if (this.cmpProjectRecord && typeof (this.cmpProjectRecord) !== 'undefined') {
      this.cmpProjectRecord.clear()
    }
    // if (this.cmpProjectButtons && typeof (this.cmpProjectButtons) !== 'undefined') {
    //   this.cmpProjectButtons.clear()
    // }
    if (this.cmpStatFormsCommon && typeof (this.cmpStatFormsCommon) !== 'undefined') {
      this.cmpStatFormsCommon.clear()
    }
  }

  get typeAdmin() {
    return this.authService.user.typeAdmin;
  }

  edit() {
    this.cmpProjectRecord.reload();
    this.showProjectProp = true;
  }

  buttonsEdit() {
    this.cmpProjectButtons.reload();
    this.showProjectButtons = true;
  }

  sanityObj(str) {
    return str.replace(new RegExp('\'|\}|\{"|', 'g'), '').trim();
  }

  getclassOfLabel(s: string): any {
    let res = {};
    let obs = s.split(',');
    obs.forEach(element => {
        const vals = element.split(':');
        if (vals.length === 2) {
          let key = this.sanityObj(vals[0]),
            val = this.sanityObj(vals[1]);
          if ((key !== '') && (key !== 'icon') && (val !== ''))
            res[key] = val;
        }
      }
    )
    return res;
  }

  getIconOfLabel(s: string): string {
    let res = '';
    let obs = s.split(',');
    obs.forEach(element => {
        const vals = element.split(':');
        if (vals.length === 2) {
          let key = this.sanityObj(vals[0]),
            val = this.sanityObj(vals[1]);
          if ((key === 'icon') && (val !== ''))
            res = val;
        }
      }
    )
    return res;
  }


  remove() {
    let _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.project.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        _self.projectService.delAdmHierarchy(this.data)
          .subscribe(data => {
            _self.authService.updateStatus(new MenuRefreshOpt(CMenuRefreshType.FULL_REFRESH));
          }, error => {
            _self.messageService.showMessage(error);
          });
      }
    });
  }

  onSaveProperty(event: any) {
    this.data = event.data;
    this.showProjectProp = false;
    this.refresh();
  }

  private static sortLabels(items: Label[]): Label[] {
    items = items.sort((n1, n2) => {
      if (((n1.label.startsWith('DOC:') && n2.label.startsWith('DOC:')) || (!n1.label.startsWith('DOC:') && !n2.label.startsWith('DOC:'))) && n1.label > n2.label) {
        return 1;
      } else if (((n1.label.startsWith('DOC:') && n2.label.startsWith('DOC:')) || (!n1.label.startsWith('DOC:') && !n2.label.startsWith('DOC:'))) && n1.label < n2.label) {
        return -1;
      } else if (!n1.label.startsWith('DOC:') && n2.label.startsWith('DOC:')) {
        return -1;
      } else if (n1.label.startsWith('DOC:') && !n2.label.startsWith('DOC:')) {
        return 1;
      }
      return 0;
    });
    return items;
  }

  private refresh() {
    forkJoin([
      this.projectService.getAdmHierarchy(this._idProject),
      this.projectService.getAdmWorkflow(this._idProject)
    ]).subscribe((allData: any[]) => {
      this.data = allData[0];
      this.data.workflow = allData[1];
      this.descriptiveText = this.data?.tabs?.find(v => v.name === 'summary')?.text;
      if (this.data.labels && this.data.labels.length > 1) {
        this.data.labels = ProjectSummaryComponent.sortLabels(this.data.labels);
      }
      this.grType = this.data.grType;
      //
      this.projectService.getAdmHierarchySummary(this._idProject)
        .subscribe(resp => {
          let dateFirstActivity: Date = null, dateLastActivity: Date = null;
          if (resp.maxUpdatedDate) {
            const dt = resp.maxUpdatedDate;
            const parts0 = dt.split(' '), parts = parts0[0].split('-');
            dateLastActivity = new Date(parts[0], parts[1] - 1, parts[2], 23, 59, 0, 0);

            this.sLastActivity = parts0[0];
          } else {
            dateFirstActivity = new Date();
            dateLastActivity = new Date();
          }
          if (resp.minUpdatedDate) {
            const dt = resp.minUpdatedDate;
            const parts0 = dt.split(' '), parts = parts0[0].split('-');
            dateFirstActivity = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);

            this.sFirstActivity = parts0[0];
          }

          if (this.cmpStatDistribution && typeof (this.cmpStatDistribution) !== 'undefined') {
            this.cmpStatDistribution.refresh(this._idProject, this.grType, dateFirstActivity, dateLastActivity);
          }

          if (this.cmpStatDashboard && typeof (this.cmpStatDashboard) !== 'undefined') {
            this.cmpStatDashboard.refresh(this._idProject, this.grType);
          }
        });
      //
      if (this.cmpProjectRecord && typeof (this.cmpProjectRecord) !== 'undefined') {
        this.cmpProjectRecord.refresh(this.data);
      }
      if (this.cmpProjectButtons && typeof (this.cmpProjectButtons) !== 'undefined') {
        this.cmpProjectButtons.refresh(this.data);
      }
      if (this.cmpStatFormsCommon && typeof (this.cmpStatFormsCommon) !== 'undefined') {
        this.cmpStatFormsCommon.refresh(this._idProject, this.grType);
      }
    });
  }

  refreshStat() {
    if (this.cmpStatDashboard && typeof (this.cmpStatDashboard) !== 'undefined') {
      this.cmpStatDashboard.refresh(this._idProject, this.grType);
    }
  }

  get idProject(): string {
    return this._idProject;
  }
}
