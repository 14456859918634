import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

declare var pz: any;

@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.css']
})
export class OrderListComponent implements OnInit {
  @Input()
  private src: string;
  @Input()
  private iddoc: string;
  @Input()
  private cdoc: string;
  @Output() onClose = new EventEmitter<void>();
  @Output() onSave = new EventEmitter<any>();
  returnList: string = "";

  visible: boolean;


  private rowId: string;
  sourceList: any[];
  targetList: any[];


  constructor() {
    this.returnList = "";
  }


  close() {
    if (this.visible) {
      this.visible = false;
      this.onClose.emit();
    }
  }

  save(evt: any) {
    if (this.visible) {
      this.visible = false;
      this.returnList = "";
      for (let i = 0; i < this.targetList.length; i++) {
        if (this.returnList !== "") this.returnList += ";";
        this.returnList += this.targetList[i].value;
      }
      this.onSave.emit({rowId: this.rowId, data: this.targetList});
    }
  }

  ngOnInit(): void {
  }

  show(rowId: string) {
    const opt = pz.$inst[0].p.colModel.find(v => v.index === rowId).editoptions.value;
    const val = pz.$inst[0].p.data[0][rowId];

    this.returnList = val;
    this.targetList = [];
    this.sourceList = [];
    let _items = opt.split(';');
    let _vals0 = val.split(';');

    for (let i = 0; i < _items.length; i++) {
      let _item = _items[i], _name = _items[i], _value = _items[i];
      if (_item.indexOf(':') == -1) {
        this.sourceList.push({value: _item, name: _item});
      } else {
        let _vals = _item.split(':');
        _value = _vals[0];
        _name = _vals[1];
      }
      let found = false;
      for (let j = 0; j < _vals0.length; j++) {
        if (_vals0[j] == _value) {
          found = true;
          this.targetList.push({value: _value, name: _name});
          break;
        }
      }
      if (!found) {
        this.sourceList.push({value: _value, name: _name});
      }

    }


    this.rowId = rowId;

    this.visible = true;
  }


}
