<ul [ngClass]="className">
  <li *ngFor="let menu of items">
    <a *ngIf="!menu.BadgeValue && !menu.isHidden"
       (click)="onMenuClick(menu)"
       [ngClass]="itemCssClass(menu)">
      <i class="fa fa-fw layout-menuitem-icon" [ngClass]="menu.Icon"></i>
      {{menu.Label}}
      <i class="fa right layout-menuitem-icon" *ngIf="menu.Childs != null"
         [ngClass]="{'fa-angle-down': !menu.IsChildVisible,'fa-angle-up': menu.IsChildVisible }"></i>
    </a>
    <a *ngIf="menu.BadgeValue && !menu.isHidden"
       (click)="onMenuClick(menu)"
       [ngClass]="itemCssClass(menu)"
       pBadge [value]="menu.BadgeValue"
       class="menu-badge">
      <i class="fa fa-fw layout-menuitem-icon" [ngClass]="menu.Icon"></i>
      {{menu.Label}}
      <i class="fa right layout-menuitem-icon" *ngIf="menu.Childs != null"
         [ngClass]="{'fa-angle-down': !menu.IsChildVisible,'fa-angle-up': menu.IsChildVisible }"></i>
    </a>
    <app-privateMenu *ngIf="menu.Childs != null && menu.IsChildVisible && !menu.isHidden"
                     (closeClicked)="onCloseClicked()"
                     [items]="menu.Childs" className="layout-appmenu-sub"
                     itemClassName="appmenu-box-sub"></app-privateMenu>
  </li>
</ul>
