import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../../_services/auth.service';
import {ConfigService} from '../../../_services/config.service';
import {MenuItem} from "primeng/api";
import {MessageListComponent} from "../message-list/message-list.component";
import {SplitButton} from 'primeng/splitbutton';
import {EnvService} from "../../../_services/env.service";

@Component({
  selector: 'app-privateHeader',
  templateUrl: './layout-private-header.component.html',
  styleUrls: ['./layout-private-header.component.css']
})
export class LayoutPrivateHeaderComponent implements OnInit {
  @Output() toggleMenubar: EventEmitter<any> = new EventEmitter();
  @ViewChild(MessageListComponent, {static: false}) messageLstCmp;

  authItems: MenuItem[] = [
    {label: 'Profile', url: `${this.envService.idpEntityId}/account`, target: '_blank'},
    {label: 'Sign out', command: () => this.logout()}
  ];
  @ViewChild('userBtn', {static: false}) userBtn: SplitButton;

  constructor(private _authService: AuthService,
              private _configService: ConfigService,
              private envService: EnvService) {
  }

  ngOnInit() {
  }

  onMenuButtonClick(event: Event) {
    event.preventDefault();
    this.toggleMenu();
  }

  logout() {
    this._authService.logout();
    setTimeout(() => window.location.href = `${this.envService.idpEntityId}/protocol/openid-connect/logout?client_id=${encodeURIComponent(this.envService.spEntityId)}`, 10);
  }

  toggleMenu() {
    this.toggleMenubar.emit();
  }

  onDeleteMessage(evt) {
  }

  get authService(): AuthService {
    return this._authService;
  }

  get configService(): ConfigService {
    return this._configService;
  }

  showUserMenu(event: MouseEvent) {
    setTimeout(() => this.userBtn.onDropdownButtonClick(event));
  }

  get ccanUser(): boolean {
    return this._authService.loggedIn() && this._authService.user?.id != null;
  }
}
