<p-dialog header="Text editor" [(visible)]="visible"
          [modal]="true"
          [closeOnEscape]="false"
          [style]="{ width: '850px' }">
  <p-editor [(ngModel)]="textValue"
            [style]="{'height':'320px'}">
    <ng-template pTemplate="header">
      <span class="ql-formats" pTooltip="Font size">
        <select class="ql-size">
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
      </span>
      <span class="ql-formats" pTooltip="Font">
        <select class="ql-font">
          <option selected></option>
          <option value="serif"></option>
          <option value="monospace"></option>
        </select>
      </span>
      <span class="ql-formats">
        <button class="ql-bold" pTooltip="Bold"></button>
        <button class="ql-italic" pTooltip="Italic"></button>
        <button class="ql-underline" pTooltip="Underline"></button>
        <button class="ql-strike" pTooltip="Strikethrough"></button>
      </span>
      <span class="ql-formats" pTooltip="Text color">
        <select class="ql-color">
          <option selected></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>
      <span class="ql-formats" pTooltip="Background color">
        <select class="ql-background">
          <option value="#000000"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option selected></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>

      <span class="ql-formats">
        <button class="ql-indent" value="-1" pTooltip="Indent -1"></button>
        <button class="ql-indent" value="+1" pTooltip="Indent +1"></button>
      </span>

      <span class="ql-formats" pTooltip="Alignment">
        <select class="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="justify"></option>
          <option value="right"></option>
        </select>
      </span>

      <span class="ql-formats">
        <button class="ql-list" value="ordered" pTooltip="Ordered list"></button>
        <button class="ql-list" value="bullet" pTooltip="Bulleted list"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-script" value="sub" pTooltip="Subscript"></button>
        <button class="ql-script" value="super" pTooltip="Superscript"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-code-block" pTooltip="Code block"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-link" pTooltip="Link"></button>
        <button class="ql-image" pTooltip="Image"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-clean" pTooltip="Clear all formatting"></button>
      </span>

    </ng-template>
  </p-editor>
  <ng-template pTemplate="footer">
    <button pButton type="button" icon="pi pi-save" (click)="save()" label="Save"></button>
    <button pButton type="button" icon="pi pi-times" (click)="close()" label="Close"></button>
  </ng-template>
</p-dialog>
