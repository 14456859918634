import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProjectUtilsService} from '../_services/project-utils.service';

@Component({
  selector: 'app-project-data',
  templateUrl: './project-data.component.html',
  styleUrls: ['./project-data.component.css']
})
export class ProjectDataComponent implements OnInit {
  private _idProject = '';
  private _uiSettings: any[];

  constructor(private activatedRoute: ActivatedRoute,
              private projectService: ProjectUtilsService) {
    this._idProject = this.activatedRoute.snapshot.paramMap.get('id');
    this.projectService.getCurrentUserProjectUiSettings(this._idProject)
      .subscribe(data => this._uiSettings = data);
  }

  ngOnInit(): void {
  }

  get idProject(): string {
    return this._idProject;
  }

  get uiSettings(): any[] {
    return this._uiSettings;
  }
}
