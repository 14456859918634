import {Component, Input} from '@angular/core';

declare var pz: any;
declare var $: any;

@Component({
  selector: 'app-richtext',
  templateUrl: './richtext.component.html',
  styleUrls: ['./richtext.component.css']
})
export class RichtextComponent {
  private _rowId: string;
  textValue: string;
  visible = true;
  readonly MAX_LENGTH = 32767;

  constructor() {
  }

  @Input()
  set rowId(rowId: string) {
    this._rowId = rowId;
    this.textValue = pz.$inst[0].p.data[0][rowId];
  }

  save() {
    if (this.textValue?.length > this.MAX_LENGTH) {
      $.jdm.info_dialog('Error', 'Value must be less than ' + this.MAX_LENGTH + ' characters', 'warn');
    } else {
      pz.$inst.jqDm("setCellValue", 0, this._rowId, this.textValue);
      this.visible = false;
    }
  }

  close() {
    this.visible = false;
  }
}
