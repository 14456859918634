import {Component, OnInit, ViewChild} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../_services/auth.service';
import {Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MenuItem, SelectItem} from 'primeng/api';
import {User} from '../../../_domains/UITypes';
import {UIUtils} from '../../../_utils/ui-utils';
import {DataAdminService} from '../_services/data-admin.service';
import {finalize, skip} from 'rxjs/operators';
import {AppMessageService} from '../../../_services/app-message.service';
import {UtlListProjectsComponent} from '../utl-list-projects/utl-list-projects.component';
import {UtlUserProjectsComponent} from '../utl-user-projects/utl-user-projects.component';
import {TranslateService} from '@ngx-translate/core';
import {ConfigService} from "../../../_services/config.service";

@Component({
  selector: 'app-admin-users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  @ViewChild(UtlListProjectsComponent, {static: false}) listAddProjectsCmp;
  @ViewChild(UtlUserProjectsComponent, {static: false}) listUserProjectsCmp;

  readonly userForm: UntypedFormGroup;
  users: Pageable;
  loading: boolean = false;
  currentUser: User = new User();
  private currentPage: number = 0;
  private sorting: string;
  private tableFilter: any;

  selected: User;
  items: SelectItem[] = [];
  types: SelectItem[] = [];

  showDlgAddProjects: boolean = false;
  showDlgListProjects: boolean = false;
  showDlgUserRecord: boolean = false;
  showDlgLogs: boolean;
  private viewLogsMenu: MenuItem[];
  showDlgLogsType: number;

  constructor(private dataService: DataAdminService,
              private authService: AuthService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              public configService: ConfigService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private translate: TranslateService) {
    this.users = new Pageable();
    this.userForm = fb.group({
      id: {value: '', disabled: true},
      username: [null, [Validators.required, Validators.maxLength(96)]],
      fullname: [null, [Validators.required, Validators.maxLength(1024), Validators.pattern('^[^\\s]+(\\s.*)?$')]],
      psw: '',
      email: [null, [Validators.required, Validators.maxLength(256)]],
      phone: [null, Validators.maxLength(256)],
      variables: '',
      isAdmin: [0],
      enabled: [null],
      accessTokenValidity: ''
    });
    this.userForm.get('isAdmin').valueChanges
      .pipe(skip(1))
      .subscribe(val => {
          if (val === 4) {
            this.userForm.get('psw').setValidators(this.currentUser?.typeAdmin === 4 ?
              [Validators.minLength(3)] : [Validators.required, Validators.minLength(3)]);
          } else {
            this.userForm.get('psw').setValidators(null);
          }
          this.userForm.get('psw').setValue(null);
          this.userForm.get('accessTokenValidity').setValidators(val === 4 ?
            [Validators.required, Validators.min(1), Validators.max(9999), Validators.pattern('^\\d+$')] : null);
          this.userForm.get('accessTokenValidity').setValue(7);
        }
      );
    this.viewLogsMenu = [
      {
        label: 'Logons', command: () => {
          this.listLogConnects(this.selected);
        }
      },
      {
        label: 'Actions', command: () => {
          this.listLogPrivate(this.selected);
        }
      }
    ];
  }

  ngOnInit() {
    this.loading = true;
    this.types.push({label: 'All', value: ''});
    this.types.push({label: 'None', value: '0'});
    this.types.push({label: 'System administrator', value: '1'});
    this.types.push({label: 'API User', value: '4'});
  }

  private loadData(filter?: string) {
    if (this.tableFilter) {
      let search = UIUtils.getFilterStr(this.tableFilter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    this.loading = true;
    this.dataService.getAdmListUsers(filter)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        data => this.users = data,
        error => this.messageService.showMessage(error, 'Error receiving data')
      );
  }

  listLogConnects(row: any) {
    this.showDlgLogsType = 1;
    this.showDlgLogs = true;
  }

  listLogPrivate(row: any) {
    this.showDlgLogsType = 2;
    this.showDlgLogs = true;
  }

  openDlgUserRecord(isCreate: boolean) {
    if (isCreate) {
      this.currentUser = new User();
      this.userForm.reset();
      this.userForm.get('isAdmin').setValue(0);
      this.userForm.get('enabled').setValue(true);
    } else {
      this.userForm.reset();
      this.userForm.get('id').setValue(this.currentUser.id);
      this.userForm.get('username').setValue(this.currentUser.username);
      this.userForm.get('fullname').setValue(this.currentUser.fullname);
      this.userForm.get('enabled').setValue(this.currentUser.accountLocked == false);
      this.userForm.get('email').setValue(this.currentUser.email);
      this.userForm.get('phone').setValue(this.currentUser.phone);
      this.userForm.get('variables').setValue(this.currentUser.variables);
      this.userForm.get('isAdmin').setValue(this.currentUser.typeAdmin);
      this.userForm.get('accessTokenValidity').setValue(this.currentUser.accessTokenValidity);
    }
    this.showDlgUserRecord = true;
  }

  openDlgListProjects() {
    this.showDlgAddProjects = false;
    this.showDlgListProjects = true;
    this.listUserProjectsCmp.refresh(this.currentUser.id);
  }

  handlePageChange(event: LazyLoadEvent) {
    this.users.content = [];
    this.tableFilter = event.filters;
    this.currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('?page=' + this.currentPage + this.sorting);
  }

  handleRowSelect(event) {
    this.selected = event.data;
    this.currentUser = {...this.selected};
  }

  handleDblRowClick(event, row) {
    this.openDlgUserRecord(false);
  }

  private reloadData() {
    this.currentPage = 0;
    this.loadData('?page=' + this.currentPage + (this.sorting || ''));
    this.selected = null;
    this.currentUser = new User();
    this.userForm.reset();
  }

  save(value: any) {
    if (!this.currentUser) return;
    this.loading = true;
    this.currentUser.username = value.username;
    this.currentUser.fullname = value.fullname;
    this.currentUser.typeAdmin = value.isAdmin;
    this.currentUser.email = value.email;
    this.currentUser.phone = value.phone;
    this.currentUser.variables = value.variables;
    this.currentUser.accountLocked = value.enabled == false;
    if (value.isAdmin === 4) {
      this.currentUser.psw = value.psw;
      this.currentUser.accessTokenValidity = value.accessTokenValidity;
    }
    (this.currentUser.id == null ? this.dataService.createUser(this.currentUser) : this.dataService.saveUser(this.currentUser))
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => {
          this.showDlgUserRecord = false;
          this.messageService.showSuccess(this.currentUser.id == null ?
            this.translate.instant('message.user.add.success') : this.translate.instant('message.user.save.success'));
          if (this.currentUser.variables && this.currentUser.variables !== data.variables
            && this.currentUser.variables.length !== data.variables?.length) {
            this.messageService.showInfo(this.translate.instant('message.user.variables.check'));
          }
          this.reloadData();
        },
        error: (error) => this.messageService.showMessage(error, 'Error')
      });
  }

  delete() {
    if (this.currentUser == null || this.currentUser.id == null) return;
    this.loading = true;
    this.confirmationService.confirm({
      message: this.translate.instant('message.user.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.dataService.deleteUser(this.currentUser.id)
          .pipe(finalize(() => this.loading = false))
          .subscribe(
            data => {
              this.reloadData();
              this.messageService.showSuccess(this.translate.instant('message.user.delete.success'));
            },
            error => {
              this.messageService.showMessage(error, this.translate.instant('message.header.error'));
            }
          );
      }
    });
  }

  get saveButtonDisabled(): boolean {
    return this.loading || !this.userForm.valid;
  }

  get cancelButtonDisabled(): boolean {
    return this.loading;
  }

  get menu(): MenuItem[] {
    return this.viewLogsMenu;
  }

  get userIdVisible(): boolean {
    return this.authService.user.typeAdmin === 1 && this.currentUser?.id?.length > 0;
  }
}
