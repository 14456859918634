import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentEditorComponent} from './document-editor/document-editor.component';
import {ThanksOfSubmittedComponent} from './thanks-of-submitted/thanks-of-submitted.component';
import {PublicReportEditorComponent} from './public-report-editor/public-report-editor.component';
import {DialogReportEditorComponent} from './dialog-report-editor/dialog-report-editor.component';
import {DocumentUtilsPublicService} from './_services/document-utils-public.service';
import {DocumentUtilsPrivateService} from './_services/document-utils-private.service';
import {DialogTemplatePreviewComponent} from './dialog-template-preview/dialog-template-preview.component';
import {UploaderModule} from '../uploader/uploader.module';
import {ClipboardModule} from "ngx-clipboard";
import {TranslateModule} from '@ngx-translate/core';
import {PageReportEditorComponent} from './page-report-editor/page-report-editor.component';
import {ViewDictPrivateService} from './_services/view-dict.service';
import {TableModule} from 'primeng/table';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ButtonModule} from 'primeng/button';
import {ToolbarModule} from 'primeng/toolbar';
import {PanelModule} from 'primeng/panel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {ProgressBarModule} from 'primeng/progressbar';
import {TooltipModule} from 'primeng/tooltip';
import {FileUploadModule} from 'primeng/fileupload';
import {ConfirmationService} from 'primeng/api';
import {DialogReportAsFormComponent} from "../projects/dialog-report-as-form/dialog-report-as-form.component";
import {OrderListModule} from "./orderlist/orderlist.module";
import {DynamicDirective} from "../../_directives/dynamic.directive";
import {RichtextModule} from "./richtext/richtext.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextModule} from "primeng/inputtext";
import {RadioButtonModule} from "primeng/radiobutton";


@NgModule({
  declarations: [
    DocumentEditorComponent,
    DialogReportEditorComponent,
    PageReportEditorComponent,
    PublicReportEditorComponent,
    ThanksOfSubmittedComponent,
    PublicReportEditorComponent,
    PageReportEditorComponent,
    DialogTemplatePreviewComponent,
    DialogReportAsFormComponent,
    DynamicDirective
  ],
  exports: [
    DialogReportEditorComponent,
    PageReportEditorComponent,
    PublicReportEditorComponent,
    ThanksOfSubmittedComponent,
    DialogTemplatePreviewComponent,
    DialogReportAsFormComponent
  ],
    imports: [
        CommonModule,
        DialogModule, ConfirmDialogModule, ToolbarModule, PanelModule, ButtonModule, TableModule, SplitButtonModule, ProgressBarModule, TooltipModule,
        FileUploadModule, UploaderModule, ClipboardModule, TranslateModule, OrderListModule, RichtextModule, ReactiveFormsModule, CheckboxModule, InputTextModule, RadioButtonModule
    ],
  providers: [
    DocumentUtilsPublicService,
    DocumentUtilsPrivateService,
    ConfirmationService,
    ViewDictPrivateService
  ]
})
export class DoceditorModule {
}
