import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {Windowref} from './_services/windowref';
import {MessageService} from 'primeng/api';
import {HTTPStatus, OAuthHttpInterceptor} from './_services/oauth.http.interceptor';
import {AuthGuard} from './_services/auth.guard';
import {StartComponent} from './components/start/start.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {RolesDirective} from './_directives/roles.directive';
import {AppRoutingModule} from './app-routing.module';
import {ImportModule} from './components/import/import.module';
import {StatisticModule} from './components/statistic/statistic.module';
import {DoceditorModule} from './components/doceditor/doceditor.module';
import {PublicModule} from './components/public/public.module';
import {LayoutPublicHeaderComponent} from './components/layout-public/header/layout-public-header.component';
import {LayoutPrivateMenuComponent} from './components/layout-private/menu/layout-private-menu.component';
import {LayoutPrivateHeaderComponent} from './components/layout-private/header/layout-private-header.component';
import {RegistrationModule} from './components/registration/registration.module';
import {ProjectModule} from './components/projects/projects.module';
import {HomeComponent} from './components/home/home.component';
import {SsoLoginComponent} from './components/registration/sso-login/sso-login.component';
import {ClipboardModule} from 'ngx-clipboard';
import {MessageListComponent} from './components/layout-private/message-list/message-list.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {LayoutFooterModule} from './components/layout-footer/layout-footer.module';
import {EnvServiceProvider} from './_services/env.service.provider';
import {ListboxModule} from 'primeng/listbox';
import {ToastModule} from 'primeng/toast';
import {DialogModule} from 'primeng/dialog';
import {LoaderModule} from './components/core/loader/loader.module';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {SidebarModule} from 'primeng/sidebar';
import {TooltipModule} from 'primeng/tooltip';
import {BadgeModule} from 'primeng/badge';
import {PrivacyNoticeComponent} from './components/privacy-notice/privacy-notice.component';
import {FormsModule} from '@angular/forms';
import {CustomPipesModule} from "./_services/CustomPipesModule";
import {GoogleChartsSettings} from "ng2-google-charts";
import {EnvService} from "./_services/env.service";
import {NgOptimizedImage} from "@angular/common";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function MyGoogleChartsSettingsFactory(env: EnvService) {
  const MyGoogleChartsSettings: GoogleChartsSettings = {
    mapsApiKey: env.googleMapsApiKey,
    googleChartsVersion: '51',
    language: 'en',
    safeMode: false
  };
  return MyGoogleChartsSettings;
}

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    RolesDirective,
    LayoutPublicHeaderComponent,
    LayoutPrivateMenuComponent,
    LayoutPrivateHeaderComponent,
    LayoutPrivateMenuComponent,
    MessageListComponent,
    HomeComponent,
    SsoLoginComponent,
    PrivacyNoticeComponent
  ],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CustomPipesModule.forRoot(),
    ClipboardModule,
    RegistrationModule,
    ImportModule,
    StatisticModule,
    DoceditorModule,
    PublicModule,
    NgIdleKeepaliveModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ProjectModule,
    ListboxModule,
    LayoutFooterModule,
    ToastModule,
    DialogModule,
    LoaderModule,
    SplitButtonModule,
    ConfirmDialogModule,
    SidebarModule,
    TooltipModule,
    BadgeModule,
    FormsModule,
    NgOptimizedImage
  ],
  providers: [
    AuthGuard,
    MessageService,
    Windowref,
    HTTPStatus,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OAuthHttpInterceptor,
      multi: true
    },
    EnvServiceProvider,
    {
      provide: 'googleChartsSettings',
      useFactory: MyGoogleChartsSettingsFactory,
      deps: [EnvService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
