<div class="dialog-wrapper" [ngClass]="getContentClass()">
  <p-toolbar [ngClass]="getToolbarClass()">
    <ng-container *ngIf="configService.isDesktopDevice">
      <div class="p-toolbar-group-left">
        <button pButton type="button" icon="pi pi-save" (click)="save(true)"
                [disabled]="isDisabledBtnDocSave()" *ngIf="!previewShow"
                pTooltip="Save the document data"></button>
        <button pButton type="button" icon="pi pi-check" (click)="check()"
                [disabled]="isDisabledBtnDocCheck()" *ngIf="!isHiddenBtnDocCheck()"
                pTooltip="Check the document data"></button>
        <button pButton type="button" icon="pi pi-trash" (click)="delete()"
                [disabled]="isDisabledBtnDocRemove()" *ngIf="!previewShow"
                pTooltip="Remove the document"></button>
        <button pButton type="button" icon="fas fa-share-square" *ngIf="false"
                [disabled]="isDisabledBtnDocSend()" (click)="send()"
                pTooltip="Submit the document"></button>
        <button pButton type="button" icon="fa fa-lock-open"
                [disabled]="isDisabledBtnDocReset()" (click)="reset()" *ngIf="!previewShow"
                pTooltip="Reopen the submitted document"></button>

        <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle" *ngIf="!previewShow"></i>

        <button pButton type="button" icon="pi pi-clone" (click)="copyLink()"
                *ngIf="isShowBtnCopyLink()" [disabled]="isDisabledBtnCopyLink()"
                pTooltip="Copy public link"></button>
        <button *ngIf="btnPdfVisible" pButton type="button" icon="pi pi-file-pdf" (click)="print2pdf()"
                [disabled]="isDisabledBtnPdf"
                pTooltip="Generate PDF file"></button>
      </div>
    </ng-container>

    <ng-container *ngIf="!configService.isDesktopDevice">
      <div class="p-toolbar-group-left">
        <button pButton type="button" icon="fa fa-floppy-o" label="Save"
                (click)="save(true)" [disabled]="isDisabledBtnDocSave()"></button>
        <button pButton type="button" icon="fa fa-check" label="Check"
                (click)="check()" [disabled]="isDisabledBtnDocCheck()"></button>

        <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i>

        <p-splitButton label="Actions" icon="fa fa-cog" [model]="items" styleiconPos="left"></p-splitButton>
      </div>
    </ng-container>
  </p-toolbar>

  <!-- Sidebar Holder -->
  <nav class="dialog-sidebar" *ngIf="listDctDocs.length > 1">
    <ul class="list-unstyled components">
      <li *ngFor="let r of listDctDocs" [ngClass]="getMenuClass(r)">
        <a (click)="clickActiveMenu(r)" [innerHTML]="getMenuTitle(r)"></a>
      </li>
    </ul>
  </nav>

  <!-- Page Content Holder [style.height.px]="contentHeight" -->
  <div class="dialog-content">
    <document-editor></document-editor>
  </div>
</div>

<!-- Popup for field definition  -->
<p-dialog header="{{popupHeader}}" [(visible)]="popupShow" [modal]="true"
          [style]="popupType === 'info' ? { 'max-width':'400px', 'width':'400px', 'max-height':'250px'} : { 'max-width':'800px', 'width':'800px'}">
  <div [innerHTML]="popupContent"></div>
</p-dialog>
<!-- -->

<app-uploader src="document-editor" [iddoc]="iddoc"
              (onSuccess)="fileUploadSuccess($event)"
              (onFailed)="fileUploadFailed($event)"></app-uploader>

<app-orderlist src="document-editor" [iddoc]="iddoc" [cdoc]="cDoc"
               (onSave)="orderListSuccess($event)"
               (onClose)="onclose()"></app-orderlist>

<p-confirmDialog #cd header="Confirmation" icon="fa fa-question-circle">
</p-confirmDialog>

<ng-template appDynamic></ng-template>
