import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutFooterComponent} from './layout-footer.component';
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    LayoutFooterComponent
  ],
  exports: [
    LayoutFooterComponent
  ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class LayoutFooterModule {
}
