import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {User} from '../../../_domains/UITypes';
import {LazyLoadEvent} from 'primeng/api';
import {DataAdminService} from '../../admin/_services/data-admin.service';
import {AuthService} from '../../../_services/auth.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {UIUtils} from '../../../_utils/ui-utils';
import {finalize} from 'rxjs/operators';
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'project-add-user',
  templateUrl: './add-project-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddProjectUserComponent implements OnInit {
  @Input() idProject = '';
  @Input() source;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() userSelected = new EventEmitter<User>();
  users: Pageable;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();
  private _visible = false;
  cmpVisible = false;
  private _currentPage: number = 0;
  private _sorting: string;
  private _tableFilter: any;
  selected: User;

  constructor(private dataService: DataAdminService,
              private authService: AuthService,
              private messageService: AppMessageService) {
    this.users = new Pageable();
  }

  ngOnInit() {
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    if (this._visible && !this.cmpVisible) {
      this.selected = undefined;
      this.cmpVisible = true;
      this.refresh();
    } else if (!this._visible && this.cmpVisible) {
      this.cmpVisible = false;
    }
  }


  private loadData(filter?: string) {
    if (!this.idProject || !this.cmpVisible) {
      return;
    }
    // filter = '?idProject=' + this.idProject + (filter || '');
    filter = filter || '?';
    let search = null;
    if (this.source === 'record-users' || this.source === 'owner-change') {
      search = '&search=viewAccLevel:2';
    }
    if (this._tableFilter && Object.keys(this._tableFilter).length) {
      search = (search ? search + ',' : '&search=') + UIUtils.getFilterStr(this._tableFilter);
    }
    if (search?.length) {
      filter += search;
    }
    this.loadingSubject.next(true);
    const observable = this.source === 'record-users' || this.source === 'owner-change'
      ? this.dataService.getProjectUsers(this.idProject, filter)
      : this.dataService.getAdmListUsers(filter);
    observable.pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(
        data => {
          this.users = data;
          this.users.content.map(item => item.organization = item.organization || {});
        },
        error => {
          this.messageService.showMessage(error, 'Error receiving data');
        }
      );
  }

  private refresh() {
    this.handlePageChange(
      {
        first: 0,
        rows: 20,
        sortField: 'username',
        sortOrder: 1,
        filters: {}
      });
  }

  handlePageChange(event: LazyLoadEvent) {
    this.selected = undefined;
    this.users.content = [];
    this._tableFilter = event.filters;
    this._currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('?page=' + this._currentPage + this._sorting);
  }

  hide() {
    this.visibleChange.emit(false);
  }

  rowSelect() {
    if (!this.selected) {
      return;
    }
    this.visibleChange.emit(false);
    this.userSelected.emit(this.selected);
  }

  get selectButtonDisabled(): boolean {
    return this.loadingSubject.value || !this.selected
  }
}
