<form [formGroup]="builderForm">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="submit" icon="pi pi-save"
              [disabled]="!builderForm.valid"
              (click)="saveTemplate(builderForm.value)"></button>
    </div>
  </p-toolbar>

  <div class="col-12" style="margin: 10px 0;">
    <div class="row form-group">
      <div class="col-2">
        Name&nbsp;<span class="field-required">*</span>:
      </div>
      <div class="col-10">
        <input pInputText type="text" formControlName="name" placeholder="Required" class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-show-error [control]="builderForm.get('name')"></app-show-error>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-2">
        Description&nbsp;:
      </div>
      <div class="col-10">
        <input pInputText type="text" formControlName="descript" class="form-control"/>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-5">&nbsp;</div>
      <div class="col-7">
        <p-checkbox formControlName="isShortList" label="Use as short list?" binary="true"></p-checkbox>
      </div>
    </div>
  </div>

  <p-pickList [source]="sourceParams" [target]="targetParams"
              sourceHeader="Available" targetHeader="Selected"
              filterBy="code"
              dragdrop="true" sourceFilterPlaceholder="Search by field" targetFilterPlaceholder="Search by field"
              [sourceStyle]="{'height':'400px'}" [targetStyle]="{'height':'400px'}">
    <ng-template let-row pTemplate="item">
      <div>
        <div style="float:left; display:inline-block; width: 250px; overflow: hidden;"><b>{{row.code}}</b></div>
        <div style="float:left; display:inline-block; padding-left:3px; overflow: hidden;">{{row.label}}</div>
      </div>
    </ng-template>
  </p-pickList>
</form>
