import {Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {CProcessType, RespOnProcessDoc} from '../../../_domains/UITypes';
import {UploaderComponent} from "../../uploader/uploader.component";
import {DocumentUtilsPrivateService} from "../../doceditor/_services/document-utils-private.service";
import {TemplateUtilsPrivateService} from "../../doceditor/_services/template-utils.service";
import {ViewDictPrivateService} from "../../doceditor/_services/view-dict.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {ExportUtilsService} from "../../doceditor/_services/export-utils.service";
import {ConfirmationService, MenuItem} from "primeng/api";
import {AuthService} from "../../../_services/auth.service";
import {ConfigService} from "../../../_services/config.service";
import {ClipboardService} from "ngx-clipboard";
import {TranslateService} from "@ngx-translate/core";
import {finalize} from "rxjs/operators";
import {DocumentEditorComponent} from "../../doceditor/document-editor/document-editor.component";
import {Dialog} from 'primeng/dialog';
import * as moment from 'moment';

declare var $: any;
declare var pz: any;

@Component({
  selector: 'app-dialog-report-as-form',
  templateUrl: './dialog-report-as-form.component.html',
  styleUrls: ['../../doceditor/_css/documentEditor.css'],
  providers: [
    DocumentUtilsPrivateService,
    ViewDictPrivateService,
    TemplateUtilsPrivateService,
    ExportUtilsService]
})
export class DialogReportAsFormComponent implements OnInit {
  @Output() onProcess = new EventEmitter<RespOnProcessDoc>();
  @Input() classToolbar: string = '';
  @Input() classContent: string = '';
  @ViewChild('rowBiEditDialog') rowBiEditDialogCmp: Dialog;

  displayDialog: boolean = false;
  previewShow: boolean = false;
  popupShow: boolean = false;
  popupHeader: string = '';
  popupContent: string = '';
  listDctDocs: any[] = [];
  listSubdocs: any[] = [];
  activeMenuId: string;
  processing: boolean = false;

  private idpar: string;
  private cdoc: string = '';
  private columns = [];
  private groupByLst: string[] = []
  private row: any[] = [];
  private access: any = null;
  private editable: boolean = false;
  private fileRowId;
  private docState: number = 0;
  private grType: number = 0;
  rowBiEditDialogVisible: boolean;

  @ViewChild(UploaderComponent, {static: false}) uploaderCmp;
  @ViewChild(DocumentEditorComponent, {static: false}) editorCmp;

  constructor(private docService: DocumentUtilsPrivateService,
              private utlService: TemplateUtilsPrivateService,
              private dictService: ViewDictPrivateService,
              private messageService: AppMessageService,
              private exportService: ExportUtilsService,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private _configService: ConfigService,
              private clipboardService: ClipboardService,
              private ngZone: NgZone,
              private translate: TranslateService) {
  }

  ngOnInit() {
  }

  onHide() {
    let _self = this;
    if (this.editable) {
      const _isCommitted = this.editorCmp.isCommitted();
      if (_isCommitted == false && this.access.accessWrite) {
        _self.confirmationService.confirm({
          message: this.translate.instant('message.document.saveProgress'),
          header: this.translate.instant('message.header.confirmation'),
          icon: 'fa fa-question-circle',
          accept: () => {
            // _self.save(false);
            _self.editorCmp.deattachCmp();
          },
          reject: () => {
            _self.editorCmp.deattachCmp();
          }
        });
      } else { // (_isCommitted == false && this.access.accessWrite)
        _self.editorCmp.deattachCmp();
      }
    } else { // (this.editable)
      _self.editorCmp.deattachCmp();
    }
  }

  refreshVars(cdoc: string, columns: any, groupByLst: any, row: any) {
    let _self = this;

    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
    $.extend($.jdm, {
      //
      isMobileDevice: function () {
        return _self.configService.deviceType > 0;
      },
      //
      save: function () {
      },
      //
      saveWitValidating: function () {
        _self.ngZone.run(() => {
          // _self.save(true);
          console.log("DialogReportAsFormComponent save command");
        });
      },
      // submit data
      submit: function () {
        _self.ngZone.run(() => {
          // _self.send();
          console.log("DialogReportAsFormComponent send command");
        });
      },
      // confirm dialog
      confirm: function (title, message, clbYes, clbNo) {
        let clbAccept = typeof (clbYes) !== 'undefined' ? clbYes : null;
        let clbReject = typeof (clbNo) !== 'undefined' ? clbNo : null;
        _self.ngZone.run(() => {
          _self.confirmationService.confirm({
            message: message,
            header: title,
            icon: 'fa fa-question-circle',
            accept: () => {
              clbAccept();
            },
            reject: () => {
              clbReject();
            }
          });
        });
      },
      // info-dialog
      info_dialog: function (title, message, type) {
        _self.ngZone.run(() => {
          switch (type) {
            case 'error':
              _self.messageService.showError(message, title);
              break;
            case 'info':
              _self.messageService.showSuccess(message, title);
              break;
            default:
              _self.messageService.showWarn(message, title);
              break;
          }
        });
      },
      // open-dialog
      open_dialog: function (title, content) {
        _self.ngZone.run(() => {
          _self.popupHeader = title;
          _self.popupContent = content;
          _self.popupShow = true;
        });
      },
      // get country record
      getCountryRecord: function (code, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getStateRec(code).then(data => {
            if (clb) clb(data);
          });
        });
      },
      // TBW: getHydration factors
      // getTBWHydrationFactor: function (sex, age, clb) {
      //   _self.ngZone.run(() => {
      //     _self.dictService.getHydrationFactor(sex, age).then(data => {
      //       if (clb) clb(data);
      //     });
      //   });
      // },
      // show upload file. Where?
      showUploadForm: function (rowId) {
        _self.ngZone.run(() => {
          // _self.showUploadForm(rowId);
          console.log("DialogReportAsFormComponent upload command", rowId);
        });
      },
      // show order list
      showOrderListForm: function (rowId, opt, val) {
        _self.ngZone.run(() => {
          // _self.showUploadForm(rowId);
          console.log("DialogReportAsFormComponent showOrderListForm command", rowId);
        });
      },
      // remove item from order list
      removeItemOrderListForm: function (rowId, val2del) {
        _self.ngZone.run(() => {
          // _self.showUploadForm(rowId);
          console.log("DialogReportAsFormComponent removeItemOrderListForm command", rowId);
        });
      },
      // upload file data
      uploadFileData: function (rowId, fileData) {
        _self.ngZone.run(() => {
          console.log("DialogReportAsFormComponent upload file data command", rowId);
        });
      },
      // clear files data
      clearFileData: function (rowId) {
        _self.ngZone.run(() => {
          // _self.clearFileData(rowId);
          console.log("DialogReportAsFormComponent clear command", rowId);
        });
      },
      // download files data
      downloadFileData: function (id) {
        _self.ngZone.run(() => {
          // _self.downloadFileData(id);
          console.log("DialogReportAsFormComponent downloadFileData command", id);
        });
      },
      // get dictionary data
      getDictData: function (dictName, valueField, parentDictName, parentValueField, parentValue, clb) {
        _self.ngZone.run(() => {
          _self.dictService.getDictData(dictName, valueField, parentDictName, parentValueField, parentValue).then(data => {
            if (clb) clb(data);
          });
        });
      },
      showrowBiEditDialog: function (clbShow, clbHide) {
        _self.ngZone.run(() => {
          _self.showrowBiEditDialog(clbShow, clbHide);
        });
      }
    });

    this._initParams(cdoc, columns, groupByLst, row);
    window['editorComponentRef'] = {component: _self, zone: _self.ngZone};
    this.displayDialog = true;
  }

  private async _initParams(cdoc: string, columns: any, groupByLst: any, row: any) {
    this.listDctDocs = [];
    this.listSubdocs = [];
    this.idpar = cdoc;
    // this.idpar = '1';
    this.cdoc = cdoc;
    this.columns = columns;
    this.groupByLst = groupByLst;
    this.row = row;
    this.editable = true;
    this.access = null;
    this.docState = 0;
    this.grType = 0;
    let opt_asc = {
      accessApprove: false, accessCreate: false, accessDocumentAdmin: false, accessProjectAdmin: false,
      accessRemove: false, accessReopen: false, accessSubmit: false, accessView: true, accessWrite: false,
      cdoc: cdoc, fullAccess: false, lvlEdit: 0
    };

    const _docStatus = {cdoc: this.cdoc, docState: 0, editable: false, optsAcc: opt_asc, errorCode: 0, message: "ok"}; // the doc status
    this.activeMenuId = _docStatus.cdoc;
    const _tmplVars = await this.utlService.getFormTemplateVars(this.cdoc);
    for (let i in columns) {
      if (!columns[i].visible) {
        for (let ii in _tmplVars.params) {
          if (_tmplVars.params[ii].field == columns[i].field) {
            _tmplVars.params[ii].visible = 0;
          }
        }
      }
    }
    this.listDctDocs = [];
    this.listSubdocs = [{iddoc: this.idpar, cdoc: this.cdoc}];

    let _self = this;
    const callbackGetData = (success, failure): void => {
      _self.ngZone.run(() => {
        _self.callbackGetRptData(row, columns, groupByLst, success, failure)
      });
    }
    this.editorCmp.refreshVars(
      this.idpar, // iddoc
      _docStatus, // the document status
      _tmplVars, // template vars
      {
        id: this.authService.user.id || '?',
        email: this.authService.user.email || '?',
        username: this.authService.user.username || '?',
        fullname: this.authService.user.fullname || '?'
      }, // auth record
      callbackGetData // callback
    );
  }


  check() {
    this.processing = true;
    //
    this.editorCmp.reset();
    //
    this.docService.check(this.iddoc, this.idpar, this.cdoc, this.editorCmp.data(), (this.editorCmp.check()).errors)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        data => {
          const res = data;
          if (res.status === 1) {
            this.messageService.showSuccess(this.translate.instant('message.document.validation.success'));
            (pz.$inst).jqDm("wizardHighlight", []);
            this.onProcess.emit({oper: CProcessType.Check, status: res.status});
          } else if (res.status === 0) {
            this.messageService.showWarn(res.message);
            this.onProcess.emit({oper: CProcessType.Check, status: res.status, logs: res.logs});
            if (res.logs) {
              this.editorCmp.parseLogs(res.logs);
              (pz.$inst).jqDm("wizardHighlight", this.editorCmp.errorsSteps);
            }
          } else {
            this.messageService.showError(res.message);
          }
        },
        error => {
          this.messageService.showMessage(error, '[Check]');
        });
  }


  print2pdf() {
    this.processing = true;
    const fileName = this.cdoc + '_' + moment().format('YYYYMMDD_HHmmss') + '.pdf';
    const callback = (success: boolean): void => {
      if (!success) {
        this.messageService.showError(this.translate.instant('message.document.print.error'));
      }
      this.processing = false;
    }
    this.editorCmp.print2pdf(fileName, callback);
  }


  private callbackGetRptData(row: any, columns: any, groupByLst: any, success: (resp) => any, failure: (error) => any) {
    let clbErr = (error): void => {
      this.messageService.showMessage(error, '[callbackGetRptData]');
      if (typeof failure === 'function') {
        failure(error);
      }
    };
    let fields: any[] = [];
    Object.entries(row).forEach(function (value) {
      if ((value[1] !== null) && (value[0] !== "BiDataServiceId")) {
        fields.push({name: value[0], value: String(value[1])});
      }

    });
    success({main: fields, tabdata: {}});
  }


  isDisabledBtnDocCheck(): boolean {
    return false;
  }

  isHiddenBtnDocCheck(): boolean {
    return false;
  }


  isDisabledBtnDocExport(): boolean {
    return false;
  }


  get configService(): ConfigService {
    return this._configService;
  }

  get items(): MenuItem[] {
    return [
      {
        label: 'Check', icon: 'fa fa-check', disabled: this.isDisabledBtnDocCheck(), command: () => {
          this.check();
        }
      },
      {separator: true},
      {
        label: 'Refresh', icon: 'fa fa-refresh', command: () => {/* this.reload()*/
        }
      },
    ]
  }


  get dialogStyle(): any {
    return {height: `${window.innerHeight - 135}px`, width: `${window.innerWidth - 450}px`}
  }

  get contentStyle() {
    return {'height': `${window.innerHeight - 290}px`};
  }

  getToolbarClass(): string {
    return this.classToolbar.length > 0 ? this.classToolbar : '';
  }

  getContentClass(): string {
    return this.classContent.length > 0 ? this.classContent : '';
  }

  showUploadForm(rowId: string) {
    this.fileRowId = rowId;
    this.uploaderCmp.show();
  }

  uploadFileData(rowId: string, fileData) {
    this.fileRowId = rowId;
    this.uploaderCmp.show();
    this.uploaderCmp.fileUploader(fileData);
  }

  get iddoc(): string {
    return this.idpar;
  }

  showrowBiEditDialog(clbShow, clbHide) {
    this.rowBiEditDialogVisible = true;
    if (clbShow && this.rowBiEditDialogCmp) {
      this.rowBiEditDialogCmp.onShow = new EventEmitter<void>();
      this.rowBiEditDialogCmp.onShow.subscribe(_ => clbShow());
    }
    if (clbHide && this.rowBiEditDialogCmp) {
      this.rowBiEditDialogCmp.onHide = new EventEmitter<void>();
      this.rowBiEditDialogCmp.onHide.subscribe(_ => clbHide());
    }
  }

  get btnPdfVisible(): boolean {
    return this.access?.rolePrint || this.access?.fullAccess;
  }

  get isDisabledBtnPdf(): boolean {
    return this.processing;
  }

}
