import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BuilderUtilsService} from '../_services/builder-utils.service';
import {AuthService} from "../../../_services/auth.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {CustomButtonPipe} from "../../../pipes/custom-button.pipe";

@Component({
  selector: 'project-builder-templates',
  templateUrl: './builder-list-templates.component.html',
  providers: [
    BuilderUtilsService,
    CustomButtonPipe
  ]
})
export class BuilderListTemplatesComponent {
  @Input() idProject = '';
  @Input() parent: any;
  @Input() defaultTemplate: string;
  @Output() templateChanged = new EventEmitter<string>();
  @Output() templateOpers = new EventEmitter<any>();
  private _uiButtons: any[];

  itemsTemplates: any[] = [];
  template = '';
  menuItems: any[];

  constructor(private authService: AuthService,
              private dataService: BuilderUtilsService,
              private messageService: AppMessageService,
              private customButtonPipe: CustomButtonPipe) {
    this.menuItems = [
      {label: 'New Column filter', icon: 'pi pi-plus', command: () => this.addTemplate()},
      {label: 'Edit', icon: 'pi pi-pencil', command: () => this.editTemplate()},
      {label: 'Delete', icon: 'pi pi-times', command: () => this.deleteTemplate()}
    ];
  }

  @Input()
  set uiButtons(val: any[]) {
    this._uiButtons = val;
    let key = '/data/template/add';
    if (val?.hasOwnProperty(key)) {
      this.menuItems[0].label = val[key]?.blabel || 'New Column filter';
      this.menuItems[0].icon = val[key]?.icon || 'pi pi-plus';
    }
    key = '/data/template/edit';
    if (val?.hasOwnProperty(key)) {
      this.menuItems[1].label = val[key]?.blabel || 'Edit';
      this.menuItems[1].icon = val[key]?.icon || 'pi pi-pencil';
    }
    key = '/data/template/delete';
    if (val?.hasOwnProperty(key)) {
      this.menuItems[2].label = val[key]?.blabel || 'Delete';
      this.menuItems[2].icon = val[key]?.icon || 'pi pi-times';
    }
  }

  refresh() {
    this.loadTemplates(true);
  }

  menuShow() {
    this.menuItems[1].disabled = !this.template;
    this.menuItems[2].disabled = !this.template;
  }

  addTemplate() {
    this.templateOpers.emit({
      templateId: "",
      label: "",
      descript: "",
      isShortList: false
    });
  }

  editTemplate() {
    if (!this.template) {
      return;
    }
    const rdata = this.getTemplateRec();
    this.templateOpers.emit({
      templateId: this.template,
      label: rdata.label,
      descript: rdata.descript,
      isShortList: rdata.isShortList
    });
  }

  deleteTemplate() {
    if (!this.template) {
      return;
    }
    this.dataService.deleteBuilderTemplate(this.template)
      .subscribe(data => {
        this.loadTemplates(true);
      }, error => {
        this.messageService.showMessage(error);
      });
  }

  handleTemplatesChanged() {
    this.templateChanged.emit(this.template);
  }

  get typeAdmin(): number {
    return this.authService.user.typeAdmin;
  }

  private loadTemplates(reset?: boolean) {
    if (reset) {
      this.template = '';
    }
    // reset itemsTemplates
    this.itemsTemplates = [];
    // push first item
    this.itemsTemplates.push({value: '', label: 'Full list'});
    this.dataService.getBuilderTemplates(this.idProject)
      .subscribe(data => {
          if (data && data.length) {
            for (let i in data) {
              const rdata = data[i];
              this.itemsTemplates.push({
                value: rdata.id,
                label: rdata.name,
                descript: rdata.descript,
                isShortList: rdata.isShortList == 1
              });

              if (rdata.id === this.defaultTemplate) {
                this.template = rdata.id;
              } else if (rdata.isShortList === 1 && reset) {
                this.template = rdata.id;
              }
            }
          }
          this.templateChanged.emit(this.template);
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  private getTemplateRec() {
    const templateId = this.template;
    const ret = (this.itemsTemplates.find(rdata => rdata.value == templateId)) || {};

    return ret;
  }

  get uiButtons(): any[] {
    return this._uiButtons;
  }
}
