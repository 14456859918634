<div>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="pi pi-plus"
              (click)="openDlgUserRecord(true)" pTooltip="Add new user"></button>
      <button pButton type="button" icon="pi pi-pencil"
              [disabled]="selected == null" (click)="openDlgUserRecord(false)" pTooltip="Edit the user"></button>
      <button pButton type="button" icon="pi pi-trash"
              [disabled]="selected == null" (click)="delete()" pTooltip="Remove the user"></button>

      <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i>

      <p-splitButton icon="far fa-list-alt"
                     [disabled]="selected == null" [model]="menu"
                     iconPos="left" pTooltip="View logs"></p-splitButton>
    </div>
  </p-toolbar>

  <p-table #dt
           [value]="users.content"
           [totalRecords]="users.totalElements" [rows]="users.size"
           [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
           (onRowSelect)="handleRowSelect($event)" selectionMode="single" [(selection)]="selected"
           [paginator]="true" paginatorPosition="bottom"
           [resizableColumns]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 250px)'"
           sortField="username" [sortOrder]="1">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col style="width: 2.5rem;">
        <col/>
        <col/>
        <col/>
        <col/>
        <col/>
        <col/>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th pSortableColumn="username">Login
          <p-sortIcon field="username"></p-sortIcon>
        </th>
        <th pSortableColumn="fullname">Full Name
          <p-sortIcon field="fullname"></p-sortIcon>
        </th>
        <th pSortableColumn="email">e-Mail
          <p-sortIcon field="email"></p-sortIcon>
        </th>
        <th>Admin</th>
        <th pSortableColumn="lastLogon">Last Login
          <p-sortIcon field="lastLogon"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th></th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'username', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'fullname', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'email', 'contains')"/>
        </th>
        <th>
          <p-dropdown appendTo="body" [options]="types" [style]="{ 'max-width':'99%', 'min-width': '99%' }"
                      (onChange)="dt.filter($event.value, 'typeAdmin', 'eq')"></p-dropdown>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row" (dblclick)="handleDblRowClick($event, row)"
          [class.table-row-gray]="row.accountLocked">
        <td [pTooltip]="(row.accountLocked ? 'Non-Active' : 'Active') + '\n' + (row.lastLogon || '')">
          <i class="far fa-check-circle" style="color: green!important;" *ngIf="!row.accountLocked"></i>
          <i class="far fa-question-circle" *ngIf="row.accountLocked"></i>
        </td>
        <td>{{ row.username }}</td>
        <td>{{ row.fullname }}</td>
        <td>{{ row.email }}</td>
        <td>{{ row.typeAdmin == 1 ? 'System administrator' : (row.typeAdmin === 4 ? 'API User' : 'None') }}</td>
        <td>{{ row.lastLogon }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <div class="text-left" *ngIf="configService.isDesktopDevice">
        {{ 'Total records ' + users.totalElements }}
      </div>
    </ng-template>
  </p-table>

  <p-dialog header="User Profile" [(visible)]="showDlgUserRecord"
            [modal]="true" [style]="{ minWidth: '800px' }" [maximizable]="false" [baseZIndex]="10000">
    <form [formGroup]="userForm" (ngSubmit)="save(userForm.value)">
      <div class="p-fluid">
        <div class="row form-group">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Login</div>
          <div class="col-8">
            <input pInputText formControlName="username" class="form-control" type="text" placeholder="Required"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="userForm.get('username')"></app-show-error>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Full name</div>
          <div class="col-8">
            <input pInputText formControlName="fullname" class="form-control" type="text" placeholder="Required"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="userForm.get('fullname')"></app-show-error>
          </div>
        </div>
        <div class="row form-group" *ngIf="userForm.get('isAdmin').value === 4">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Password</div>
          <div class="col-8">
            <p-password formControlName="psw" inputStyleClass="form-control"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="userForm.get('psw')"></app-show-error>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">Phone</div>
          <div class="col-8">
            <input pInputText formControlName="phone" type="text"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="userForm.get('phone')"></app-show-error>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Email</div>
          <div class="col-8">
            <input pInputText formControlName="email" class="form-control" type="text"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="userForm.get('email')"></app-show-error>
          </div>
        </div>
        <div class="row form-group" *ngIf="userIdVisible">
          <div class="col-4">UserID</div>
          <div class="col-8">
            <div class="input-group">
              <input pInputText formControlName="id" class="form-control-local" type="text"/>
              <button pButton type="button" icon="pi pi-clone" style="font-size: 0.8em"
                      ngxClipboard [cbContent]="userForm.get('id').value" cbSuccessMsg="UserID is copied to clipboard"
              ></button>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-4">Custom variables</div>
          <div class="col-8">
            <div class="input-group">
              <textarea pInputTextarea formControlName="variables" class="form-control-local" type="text"
                        placeholder='e.g. a="1";b="Value for B"'></textarea>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <div class="form-check">
                <p-checkbox inputId="enabled" name="access" formControlName="enabled" binary="true"></p-checkbox>
                <label class="form-check-label" for="enabled">Enabled</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <p-radioButton inputId="isAdmin0" name="isAdmin" [value]="0" formControlName="isAdmin"></p-radioButton>
              <label class="form-check-label" for="isAdmin0">None</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <p-radioButton inputId="isAdmin1" name="isAdmin" [value]="1" formControlName="isAdmin"></p-radioButton>
              <label class="form-check-label" for="isAdmin1">System Administrator</label>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-12">
            <div class="form-check">
              <p-radioButton inputId="isAdmin4" name="isAdmin" [value]="4" formControlName="isAdmin"></p-radioButton>
              <label class="form-check-label" for="isAdmin4">API User</label>
            </div>
          </div>
        </div>
        <div class="row form-group" *ngIf="userForm.get('isAdmin').value === 4">
          <div class="col-4"><span class="field-required">*&nbsp;</span>API token lifetime (in days)</div>
          <div class="col-8">
            <input pInputText formControlName="accessTokenValidity" class="form-control"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="userForm.get('accessTokenValidity')"></app-show-error>
          </div>
        </div>
        <div class="row" *ngIf="userForm.get('id').value">
          <div class="col-12">
            <button type="button" class="btn btn-link" (click)="openDlgListProjects()">Assigned questionnaires</button>
          </div>
        </div>
      </div>

      <div class="row float-right">
        <div class="col-12">
          <button pButton type="submit" icon="pi pi-save" label="Save"
                  [disabled]="saveButtonDisabled"></button>
          <button pButton type="button" icon="pi pi-times"
                  [disabled]="cancelButtonDisabled"
                  (click)="showDlgUserRecord = false" label="Close"></button>
        </div>
      </div>
    </form>
  </p-dialog>

  <p-dialog
    [header]="'User access (' + (currentUser?.fullname) + ')'"
    [(visible)]="showDlgListProjects"
    [modal]="true" [style]="{width: '1100px', minWidth: '1000px'}"
    [maximizable]="false">
    <app-admin-listUserProjects></app-admin-listUserProjects>
    <ng-template pTemplate="footer">
      <div>
        <button pButton type="button" icon="pi pi-times"
                (click)="showDlgListProjects=false"
                label="Close"></button>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog header="View Logs" [(visible)]="showDlgLogs"
            [modal]="true" [style]="{width: '1100px', minWidth: '1100px'}"
            [maximizable]="true" [resizable]="true" [baseZIndex]="10000">
    <div [ngSwitch]="showDlgLogsType">
      <ng-template [ngSwitchCase]="1">
        <app-adminlog-connects [idUser]="this.selected && this.selected.id"></app-adminlog-connects>
      </ng-template>
      <ng-template [ngSwitchCase]="2">
        <app-adminlog-private [idUser]="this.selected && this.selected.id"></app-adminlog-private>
      </ng-template>
    </div>
  </p-dialog>

  <!--Confirm dialog-->
  <p-confirmDialog></p-confirmDialog>
</div>
