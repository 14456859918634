import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SurveysStoreService {
  constructor() {
  }

  getIds(): string {
    let _list: any[] = this.getData();
    let _ids = '';
    for (let i in _list) {
      _ids = (_ids.length > 0 ? (_ids + ';') : '') + _list[i].id;
    }
    return _ids;
  }

  checkList(list: any[], dblist: any[]): any[] {
    let _surveys: any[] = this.getData();
    for (let i1 in list) {
      let rec1 = list[i1];
      let iddoc = '';
      for (let i2 in _surveys) {
        let r = _surveys[i2];
        if (r.cdoc == rec1.cdoc) {
          iddoc = r.id;
          break;
        }
      }

      if (iddoc.length > 0) {
        for (let i2 in dblist) {
          let rec2 = dblist[i2];
          if (rec1.cdoc === rec2.cdoc && rec2.id === iddoc) {
            rec1.id = rec2.id;
            rec1.isCompleted = rec2.state > 1;
            list[i1] = rec1;
            break;
          }
        }
      }
    }
    return list;
  }

  private objectsEqual(a: any, b: any): boolean {
    const k1 = Object.keys(a);
    const k2 = Object.keys(b);
    return k1.length === k2.length && k1.every(key => b.hasOwnProperty(key) && b[key] === a[key])
  }

  getIddoc(cdoc: string, params: any): string {
    const surveys: any = this.getData();
    const survey = surveys.find(v => v.cdoc === cdoc && this.objectsEqual(v.params || {}, params || {}));
    return survey?.id || '';
  }

  save(cdoc: string, queryParams: any, id: string) {
    let _surveys: any[] = this.getData();
    let _fnd: boolean = false;
    const params = {...(queryParams || {})};
    delete params.id;
    for (let i in _surveys) {
      let r = _surveys[i];
      if (r.cdoc === cdoc && this.objectsEqual(r.params || {}, params)) {
        _fnd = true;
        _surveys[i].id = id;
        break;
      }
    }
    if (!_fnd) {
      _surveys.push({cdoc: cdoc, id: id, params});
    }
    this.setData(_surveys);
  }

  del(id: string) {
    let _surveys: any = this.getData();
    for (let i in _surveys) {
      let r = _surveys[i];
      if (r.id === id) {
        _surveys.splice(i, 1);
        break;
      }
    }
    this.setData(_surveys);
  }

  private getData(): any[] {
    let _val: string = localStorage.getItem('anonimous.user.data.surveys');
    return _val && _val.length > 0 ? JSON.parse(_val) : [];
  }

  private setData(surveys: any) {
    localStorage.setItem('anonimous.user.data.surveys', JSON.stringify(surveys));
  }
}
