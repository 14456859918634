<p-toast [baseZIndex]="9999" styleClass="global-message">
  <ng-template let-message pTemplate="message">
    <div class="p-toast-message-text ">
      <div class="p-toast-summary" [innerHtml]="message.summary"></div>
      <div class="p-toast-detail" [innerHtml]="message.detail"></div>
    </div>
  </ng-template>
</p-toast>

<app-loader></app-loader>

<div class="layout-wrapper">
  <app-privateHeader (toggleMenubar)="toggleMenu()" *ngIf="privateHeaderVisible"></app-privateHeader>
  <app-publicHeader *ngIf="publicHeaderVisible"></app-publicHeader>
  <div class="row m-0">
    <ng-container *ngIf="isMenuVisible && privateHeaderVisible">

      <div class="layout-menubar" #menubar>
          <span>
      <div class="input-group">
        <div class="input-group-prepend">
          <i class="pi pi-filter input-group-text"></i>
        </div>
        <input type="text" pInputText class="form-control"
               placeholder="Search" [(ngModel)]="searchField" (keydown.enter)="onSearch($event,'enter')"
               (input)="onSearch($event,'input2')">
      </div>
    </span>

        <app-privateMenu (closeClicked)="isMenuVisible=$event"
                         [items]="menuItems" className="layout-appmenu" itemClassName="appmenu-box"></app-privateMenu>
      </div>
    </ng-container>
    <div
      [class]="'col-12 layout-content ' + (loggedIn ? 'login' : 'nologin') + (isMenuVisible ? '' : ' hidden-menu-content')"
      [class.no-menu-no-header]="loggedIn && !privateHeaderVisible">
      <div
        [ngClass]="{ 'hidden-menu-content' : !isMenuVisible, 'login': loggedIn, 'nologin': !loggedIn}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
