<div id="doc-container">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="pi pi-save" (click)="save(true)"
              [disabled]="isDisabledBtnDocSave"
              pTooltip="Save the document data"></button>
      <button pButton type="button" icon="pi pi-check" (click)="check()"
              [disabled]="isDisabledBtnDocCheck"
              pTooltip="Check the document data"></button>


      <button pButton type="button" icon="fas fa-share-square"
              [disabled]="isDisabledBtnDocSend"
              (click)="send()" pTooltip="Submit the document data"></button>


      <button pButton type="button" icon="pi pi-clone" (click)="copyLink()"
              [disabled]="isDisabledBtnCopyLink"
              pTooltip="Copy survey link"></button>
      <button pButton type="button" icon="pi pi-file-pdf" (click)="print2pdf()"
              [disabled]="isDisabledBtnPdf"
              pTooltip="Generate PDF file"></button>
    </div>
  </p-toolbar>

  <div style="padding: 2px;">
    <document-editor></document-editor>
  </div>

  <!-- Popup for field definition  -->
  <p-dialog header="{{popupHeader}}" [(visible)]="popupShow" [modal]="true"
            [style]="popupType === 'info' ? { 'max-width':'400px', 'width':'400px', 'max-height':'250px'} : { 'max-width':'800px', 'width':'800px'}">
    <div [innerHTML]="popupContent"></div>
  </p-dialog>
  <!-- -->

</div>

<app-uploader src="public-document-editor" [iddoc]="iddoc"
              (onSuccess)="fileUploadSuccess($event)"
              (onFailed)="fileUploadFailed($event)"></app-uploader>

<app-orderlist src="document-editor" [iddoc]="iddoc" [cdoc]="cDoc"
               (onSave)="orderListSuccess($event)"
               (onClose)="onclose()"></app-orderlist>

<p-dialog #rowEditDialog id="dynTabRowEditWrapper" [maximizable]="true"
          header="Details" [(visible)]="rowEditDialogVisible" [modal]="true"
          styleClass="dm-document-row-edit-dialog">
  <div id="dynTabRowEdit" class="col-12"></div>
</p-dialog>

<ng-template appDynamic></ng-template>
