import {Component, OnInit} from '@angular/core';
import {DocumentUtilsPublicService} from '../../doceditor/_services/document-utils-public.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SurveysStoreService} from "../../../_services/surveys-store.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-proxy-survey',
  template: `
    <div
      style="width: 500px; height: 300px; position: relative; left: 50%; top: 200px; transform: translate(-50%, -50%);">
      <div style="float: left;" *ngIf="processing"><i class="fas fa-spinner"
                                                      style="font-size: 30px; color: cornflowerblue;"></i>&nbsp;
      </div>
      <div style="float: left;"><span class="status-process">{{statusMessage}}</span></div>
    </div>`,
  styles: [
    ` .status-process {
      color: #22314A;
      font-weight: bold;
      font-size: 1.1rem;
    }`
  ]
})
export class ProxySurveyComponent implements OnInit {
  statusMessage = 'Checking query parameters...';
  processing: boolean = true;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: AppMessageService,
              private surveyPubService: SurveysStoreService,
              private docService: DocumentUtilsPublicService) {
  }

  ngOnInit() {
    const _cdoc: string = this.route.snapshot.queryParamMap.get('cdoc') || 'UTZ10101';

    if (_cdoc.length == 0) {
      this.statusMessage = '\'cdoc\' is not defined';
      return;
    }

    let _operatorId: string = this.route.snapshot.queryParamMap.get('OperatorID') || '?';
    let _contactId: string = this.route.snapshot.queryParamMap.get('ContactID') || '?';
    let _countryCode: string = this.route.snapshot.queryParamMap.get('CountryISO3Code') || '?';
    let _queryParams = {};
    if (_cdoc === 'UTZ10001') {
      _queryParams = {
        operatorId: (_operatorId !== '?') ? _operatorId : '',
        countryCode: (_countryCode !== '?') ? _countryCode : ''
      };
    } else if (_cdoc === 'UTZ10101') {
      _queryParams = {
        operatorId: (_operatorId !== '?') ? _operatorId : ''
      };
    } else if (_cdoc === 'UTZ10201') {
      _queryParams = {
        operatorId: (_operatorId !== '?') ? _operatorId : '',
        contactId: (_contactId !== '?') ? _contactId : ''
      };
    } else {
      this.route.queryParams
        .subscribe(params => {
          for (const k in params) {
            if (k != "cdoc") {
              _queryParams[k] = params[k];
            }
          }
        });
    }

    const _key: string = this.surveyPubService.getIddoc(_cdoc, _queryParams);
    if (_key === '') {
      this._createReport(_cdoc, _queryParams);
    } else {
      this._openReport(_key, _cdoc, _queryParams);
    }
  }

  private _createReport(_cdoc: string, _queryParams: any) {
    this.statusMessage = 'Creating of survey...';
    _queryParams.id = '-1';
    _queryParams.cdoc = _cdoc;
    this.docService.createCheck(_cdoc)
      .pipe(finalize(() => this.processing = false))
      .subscribe(
        data => {
          const res = data;
          if (res.status !== 1) {
            const v = res.message;
            this.router.navigate(['/public/error'], {queryParams: {v: v}, skipLocationChange: true});
          } else {
            _queryParams["id"] = res.message;
            this.statusMessage = 'redirect to editing...';
            // route to edit mode
            this.router.navigate(['/public/report/view'], {queryParams: _queryParams, skipLocationChange: true});
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  private _openReport(_iddoc: string, _cdoc: string, _queryParams: any) {
    this.statusMessage = 'Checking survey...';
    this.docService.isExistReport(_iddoc)
      .pipe(finalize(() => {
        this.processing = false;
      }))
      .subscribe(
        chk => {
          if (chk === 1) {
            _queryParams.id = _iddoc;
            this.statusMessage = 'Opening of survey...';
            this.router.navigate(['/public/report/view'], {queryParams: _queryParams, skipLocationChange: true});
          } else {
            this.surveyPubService.del(_cdoc);
            this._createReport(_cdoc, _queryParams);
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }
}
