<label class="control-label" for="idTemplate">Columns:</label>&nbsp;
<p-dropdown id="idTemplate"
            [options]="itemsTemplates"
            [(ngModel)]="template"
            [ngModelOptions]="{ standalone: true }"
            (onChange)="handleTemplatesChanged()"
            [style]="{'width':'200px'}" [disabled]="!itemsTemplates || itemsTemplates.length <= 1">
  <ng-template let-rec pTemplate="item">
    <div style="position: relative;">
      <i class="pi pi-check" style="float:left; margin-top:2px;" *ngIf="rec.isShortList == '1'"></i>
      <div style="float:left; margin-left:2px;">{{rec.label}}</div>
    </div>
  </ng-template>
</p-dropdown>

<p-splitButton *ngIf="typeAdmin > 0"
               (onDropdownClick)="menuShow()"
               [model]="menuItems" iconPos="left"
               [icon]="uiButtons | customButton : 'icon' : '/data/template/menu' : 'pi pi-bars'"
               [label]="uiButtons | customButton : 'label' : '/data/template/menu' : ''"
               [pTooltip]="uiButtons | customButton : 'tooltip' : '/data/template/menu' : 'Template menu'"></p-splitButton>
