import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {Pageable} from '../../../_domains/spring/pageable';
import {finalize} from 'rxjs/operators';
import {ImportUtilsService} from '../_services/import-utils.service';
import {DocumentUtilsService} from '../../projects/_services/document-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";
import {forkJoin} from "rxjs";
import {AuthService} from "../../../_services/auth.service";

@Component({
  selector: 'app-import-data',
  templateUrl: './import.component.html',
  styles: [`
    .import-row-new {
      color: #295d51;
      font-weight: bold;
      text-transform: uppercase;
    }

    .import-row-skip {
      color: #7f999e;
      font-weight: bold;
      text-transform: uppercase;
    } `],
  providers: [DocumentUtilsService]
})
export class ImportComponent implements OnInit {
  @Input() idProject: string = '';
  @Output() onProcess = new EventEmitter<boolean>();

  private _importCode: string = '';
  private _importCdocs: string[] = [];
  private _sortingDict = '';

  indexTb: number = 0;
  loading: boolean;
  dictData: Pageable = new Pageable();
  uploadShow: boolean = false;
  mapShow: boolean = false;
  removeAllData: boolean = false;
  fullLog: boolean = false;
  selected: any = null;
  fields = [];
  private _dataFields = [];
  private _tabFields = [];
  documentCode: string = "";
  cdocs: SelectItem[] = [];
  importFields: any[] = [];
  private _tabHeader: string;
  tabDialogVisible: boolean;
  private _tabData: any[];
  private _currTabFields: any[];
  private _uploading: boolean;

  constructor(private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private dataService: ImportUtilsService,
              private docUtilsService: DocumentUtilsService,
              private authService: AuthService) {
  }

  ngOnInit() {
  }

  onChangeImportTabs($event) {
    if ($event.index === 1) {
      // Getting the file parsed data
      this.loadDictData(0);
    }
  }

  open(idProject: string) {
    this.idProject = idProject;
    this._uploading = false;
    this.uploadShow = true;
  }

  fileUploader(event) {
    this.loading = true;
    this._uploading = true;
    let fileList: FileList = event.files;
    if (fileList.length >= 0) {
      let file: File = fileList[0];
      this.dataService.uploadProjectFile(this.idProject, file)
        .subscribe(
          imp => {
            const rec = imp.rec;
            this.documentCode = null;
            // set id import task
            this._importCode = rec.code;
            this._importCdocs = imp.docsInRec;
            //
            this.indexTb = 0;
            this.dictData.content = [];

            this.cdocs = [];
            forkJoin([
              this.dataService.getListMapFields(this._importCode),
              this.docUtilsService.getCurrentUserProjectAccess(this.idProject),
              this.docUtilsService.getListProjectDocs(this.idProject)
            ])
              .pipe(finalize(() => {
                this.loading = false;
                this._uploading = false;
                // hide upload dialog
                this.uploadShow = false;
              }))
              .subscribe((res: any) => {
                // getListMapFields
                let data = res[0];
                this.fields = data;
                this._dataFields = data.filter(v => (v.tabNum || 0) === 0);
                this._tabFields = data.filter(v => (v.tabNum || 0) > 0);
                if (this._tabFields.length) {
                  const tabSet = new Set(this._tabFields.map(v => v.tabNum));
                  for (let item of tabSet) {
                    this._dataFields.push({
                      fieldSrc: 'Table' + item,
                      tabNum: item
                    });
                  }
                }

                // project documents access
                const access = res[1];

                // getListProjectDocs
                data = res[2];

                for (let i in data) {
                  if (data[i].mode >= 2 ||
                    (this.typeAdmin === 0 && !access.some(v => v.cdoc === data[i].cdoc && (v.accessCreate || v.accessDocumentAdmin || v.accessProjectAdmin)))) {
                    continue;
                  }
                  const cdoc = data[i].cdoc, nm = data[i].name;
                  this.cdocs.push({
                    value: cdoc,
                    label: `${cdoc}. ${nm.length > 70 ? nm.substring(0, 70) + '...' : nm}`
                  });
                }
                if (this._importCode !== '*' && this.cdocs.some(v => v.value === this._importCode)) {
                  this.documentCode = this._importCode;
                }
                this.mapShow = true;
              });
          },
          error => {
            this.loading = false;
            this._uploading = false;
            this.messageService.showMessage(error);
          });
    }
  }

  handleRowSelect(event) {
    this.selected = event.data;
  }

  handleDocumentCodeChanged(val) {
    this.loading = true;
    this.dataService.setDocumentCode(this._importCode, val)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
          if (data.status === 1) {
            // Getting the list of map fields
            this.dataService.getListMapFields(this._importCode)
              .subscribe(data => {
                this.fields = data;
                this._dataFields = data.filter(v => (v.tabNum || 0) === 0);
                this._tabFields = data.filter(v => (v.tabNum || 0) > 0);
                if (this._tabFields.length) {
                  const tabSet = new Set(this._tabFields.map(v => v.tabNum));
                  for (let item of tabSet) {
                    this._dataFields.push({
                      fieldSrc: 'Table' + item,
                      tabNum: item
                    });
                  }
                }
              });
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
  }

  handleImportDocChanged(val) {
    this.importFields = [];
    this.docUtilsService.getListDocParams(val)
      .subscribe(data => {
        for (let i in data) {
          const rdata = data[i];
          if (rdata.editable) {
            this.importFields.push({value: rdata.field, label: rdata.field, tabNum: rdata.tabNum});
          }
        }
      });
  }

  handlePageDictChange(event: any) {
    this.dictData.content = [];
    let npage = event.rows == 0 ? 0 : Math.floor(event.first / event.rows);
    this._sortingDict = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadDictData(npage);
  }

  onRowEditInit(row: any) {
    this.fields[row.fieldsSrc] = {...row};
    this.importFields = [];
    this.importFields.push({value: '', label: '-'});

    this.docUtilsService.getListDocParams(this.documentCode)
      .subscribe(data => {
        for (let i in data) {
          const rdata = data[i];
          if (["subtitle", "group", "grid", "output", "panel"].indexOf(rdata.editType) == -1) {
            this.importFields.push({value: rdata.field, label: rdata.field, tabNum: rdata.tabNum});
          }
        }
      });
  }

  onRowEditSave(row: any, index: number) {
    if (row.fieldTrg == '') {
      this.fields[index]['cdoc'] = "*";
    } else {
      this.fields[index]['fieldTrg'] = row.fieldTrg;
    }
  }

  onRowEditCancel(row: any, index: number) {
    delete this.fields[row.fieldSrc];
  }

  getRowValue(data: any, nm: string) {
    for (let i in data) {
      const rdata = data[i];
      if (rdata.fieldSrc == nm) {
        return `${rdata.val}`;
      }
    }
    return '-';
  }

  formatStyleCol(col: any) {
    let _w = col.width || '15rem';
    return {'width': _w};
  }

  getStatusNgClass(row: any) {
    return row.status === 1 ? 'import-row-new' : 'import-row-skip';
  }

  setRowStatus(status: number) {
    this.dataService.setRowStatus(this.selected.iddoc, status)
      .subscribe(ret => {
        this.loadDictData(0);
      })
  }

  importRun() {
    if (!this._importCode) {
      return;
    }
    this.loading = true;
    // init var map
    let _map = [];
    this.fields.forEach(fld => {
      _map.push({idmap: fld.idmap, cdoc: fld.cdoc, field: fld.fieldTrg});
    });
    // processImportData
    this.dataService.process(this._importCode, {
      cdoc: (this._importCdocs && this._importCdocs.length > 0 && this._importCdocs[0] !== "") ? this._importCdocs : [this.documentCode],
      clearData: this.removeAllData,
      fullLog: this.fullLog,
      fields: _map
    })
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        data => {
          this.mapShow = false;
          this.onProcess.emit(true);
        },
        error => this.messageService.showMessage(error));
  }

  deleteImport() {
    this.loading = true;
    this.dataService.delete(this._importCode)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        this.mapShow = false;
        this.onProcess.emit(false);
      });
  }

  get dialogStyle(): any {
    return {
      height: `${window.innerHeight - 100}px`,
      minHeight: `${window.innerHeight - 100}px`,
      width: `${window.innerWidth - 300}px`
    }
  }

  private loadDictData(page: number) {
    this.selected = null;
    this.dictData.content = [];

    if (this._importCode != '') {
      let _filter: string = '?page=' + page + '&sort=rn,asc';

      this.loading = true;
      this.dataService.getListData(this._importCode, _filter)
        .pipe(finalize(() => this.loading = false))
        .subscribe(
          data => this.dictData = data,
          error => this.messageService.showMessage(error)
        );
    }
  }

  viewTableData(row: any, tabNum: number) {
    this._tabHeader = 'Table ' + tabNum;
    this._currTabFields = this.tabFields.filter(v => v.tabNum === tabNum);
    const data: any[] = row?.vals?.filter(v => v.tabn === tabNum);
    const rns = Array.from(new Set(data.map(v => v.rn))).sort();
    this._tabData = [];
    rns.forEach(r => this._tabData.push(data.filter(v => v.rn === r)));
    this.tabDialogVisible = true;
  }

  get closeButtonDisabled(): boolean {
    return this.loading;
  }

  get importRunButtonDisabled(): boolean {
    return this.loading || (!this.documentCode && !(this._importCdocs && this._importCdocs.length > 0 && this._importCdocs[0] !== '*'));
  }

  get deleteImportButtonDisabled(): boolean {
    return this.loading;
  }

  get formDropdownDisabled(): boolean {
    return (this.cdocs && this.cdocs.length <= 1) || (this._importCdocs && this._importCdocs.length > 0 && this._importCdocs[0] !== '*');
  }


  targetFields(tabNum: number): SelectItem[] {
    return this.importFields.filter(v => v.tabNum === tabNum);
  }

  get typeAdmin(): number {
    return this.authService.user.typeAdmin;
  }

  get dataFields(): any[] {
    return this._dataFields;
  }

  get tabFields(): any[] {
    return this._tabFields;
  }

  get tabHeader(): string {
    return this._tabHeader;
  }

  get tabData(): any[] {
    return this._tabData;
  }

  get currTabFields(): any[] {
    return this._currTabFields;
  }

  get uploading(): boolean {
    return this._uploading;
  }
}
