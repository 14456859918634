<div class="p-2">
  <div class="text-center">
    <h1>Welcome to</h1>
    <h1>IPNA</h1>
    <h2>Integrated Platform for Needs Assessment</h2>

    <h5><p>One of the foundational pieces of
      <a href="https://citycancerchallenge.org" class="ccan-link-no-color" target="_blank">City Cancer Challenge’s (C/Can)</a> city engagement process is a needs
      assessment  that provides in-depth information on the quality and capacity of cancer care services in a city. The Integrated
      Platform for Needs Assessment is the online tool to collect this information.</p></h5>
  </div>
</div>
