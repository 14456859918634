<p-table #dt [value]="data.content"
         [totalRecords]="data.totalElements"
         [rows]="data.size"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
         [paginator]="true" paginatorPosition="bottom"
         [scrollable]="true" [scrollHeight]="'calc(100vh - 240px)'"
         sortField="operDate" [sortOrder]="-1">
  <ng-template pTemplate="caption">
    Document events
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col style="width:5rem;">
      <col style="width:10rem;">
      <col style="width:5rem;">
      <col style="width:10rem;">
      <col style="width:10rem;">
      <col style="width:8rem;">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="flex: 0 0 4rem" pSortableColumn="cdoc">Document code
        <p-sortIcon field="cdoc"></p-sortIcon>
      </th>
      <th>Document name</th>
      <th pSortableColumn="mode">Mode
        <p-sortIcon field="mode"></p-sortIcon>
      </th>
      <th>Questionnaire name</th>
      <th pSortableColumn="userEmail">User name
        <p-sortIcon field="userEmail"></p-sortIcon>
      </th>
      <th pSortableColumn="operDate">Date
        <p-sortIcon field="operDate"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th style="flex: 0 0 4rem">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'cdoc', 'eq')"/>
      </th>
      <th></th>
      <th>
        <p-dropdown appendTo="body" [options]="items" [style]="{ 'min-width':'110px'  }"
                    (onChange)="dt.filter($event.value, 'mode', 'eq')"></p-dropdown>
      </th>
      <th></th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'userEmail', 'eq')"/>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      <td style="flex: 0 0 4rem">{{row.cdoc}}</td>
      <td>{{row.docName}}</td>
      <td>{{row.modeName}}</td>
      <td>{{row.projectName}}</td>
      <td>{{row.userEmail}}</td>
      <td>{{row.operDate}}</td>
    </tr>
  </ng-template>
</p-table>
