<p-dialog [(visible)]="cmpVisible" header="The list of users"
          [modal]="true" [closable]="true" (onHide)="hide()"
          [style]="{ width: '800px', minWidth: '800px' }">
  <p-table [value]="data?.content"
           [totalRecords]="data?.totalElements"
           [rows]="data?.size"
           [first]="first"
           [loading]="loading"
           [lazy]="true"
           (onLazyLoad)="loadData($event)"
           selectionMode="multiple"
           [(selection)]="selected"
           [paginator]="true"
           paginatorPosition="bottom"
           dataKey="idUser"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 420px)'">
    <ng-template pTemplate="caption">
      <button pButton type="button" icon="pi pi-plus"
              (click)="add()" pTooltip="Add user"
              [disabled]="addButtonDisabled"></button>
      <button pButton type="button" icon="pi pi-trash"
              (click)="beforeDelete()" pTooltip="Delete item"
              [disabled]="deleteButtonDisabled"></button>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th class="selection">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>Login</th>
        <th>Full Name</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td class="selection">
          <p-tableCheckbox [value]="row"></p-tableCheckbox>
        </td>
        <td>
          <div class="row">
            <div class="col">
              {{row.userName}}
            </div>
            <div *ngIf="row.cnt !== 1" class="col-auto pl-0">
              <p-badge [value]="row.cnt"></p-badge>
            </div>
          </div>
        </td>
        <td>{{row.fullName}}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <button pButton type="button" [disabled]="saveButtonDisabled" icon="pi pi-save" (click)="save()"
            label="Save"></button>
    <button pButton type="button" [disabled]="closeButtonDisabled" icon="pi pi-times" (click)="hide()"
            label="Close"></button>
  </ng-template>
</p-dialog>

<project-add-user [idProject]="idProject" [(visible)]="showDlgUsers"
                  source="record-users"
                  (userSelected)="addUsers($event)"></project-add-user>
