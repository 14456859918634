import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RichtextComponent} from './richtext.component';
import {EditorModule} from "primeng/editor";
import {FormsModule} from "@angular/forms";
import {DialogModule} from "primeng/dialog";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";

@NgModule({
  declarations: [
    RichtextComponent
  ],
    imports: [
        CommonModule,
        EditorModule,
        FormsModule,
        DialogModule,
        ButtonModule,
        TooltipModule
    ]
})
export class RichtextModule {
}
