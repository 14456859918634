import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../_services/config.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }

  projectBeforeUpload(idProject: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.configService.api.fs}/projects/${idProject}/files`, data);
  }

  projectUpload(idProject: string, id: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.configService.api.fs}/projects/${idProject}/files/${id}`, data);
  }

  projectCompleteUpload(idProject: string, id: string, data: string[]): Observable<any> {
    return this.http.post<any>(`${this.configService.api.fs}/projects/${idProject}/files/${id}`, data);
  }

  projectFileLink(idProject: string, id: string): Observable<string> {
    return this.http.get(`${this.configService.api.fs}/projects/${idProject}/files/${id}/link`, {responseType: 'text'});
  }

  docBeforeUpload(iddoc: string): Observable<string> {
    return this.http.post(`${this.configService.api.fs}/public/docs/${iddoc}/files/before-upload`, null, {responseType: 'text'});
  }


  docUpload(iddoc: string, id: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.configService.api.fs}/public/docs/${iddoc}/files/${id}`,
      data,
      {observe: 'events', reportProgress: true} as any);
  }

  docFileLink(iddoc: string, id: string): Observable<string> {
    return this.http.get(`${this.configService.api.fs}/public/docs/${iddoc}/files/${id}/link`, {responseType: 'text'});
  }

  publicDocBeforeUpload(idDoc: string, idFile: string, meta: { name: string, type: string }): Observable<string> {
    return this.http.post(`${this.configService.api.fs}/public/docs/${idDoc}/files/${idFile}/before-upload`, meta, {responseType: 'text'});
  }

  publicDocUpload(idDoc: string, idFile: string,
                  data: {
                    uploadId: string,
                    data: string,
                    chunkNumber: number
                  }): Observable<any> {
    return this.http.put<any>(`${this.configService.api.fs}/public/docs/${idDoc}/files/${idFile}/part`,
      data, {observe: 'events', reportProgress: true} as any);
  }

  stageBeforeUpload(iddoc: string): Observable<string> {
    return this.http.post(`${this.configService.api.import}/stage/${iddoc}/files/before-upload`, null, {responseType: 'text'});
  }

  stageUpload(iddoc: string, id: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.configService.api.import}/stage/${iddoc}/files/${id}`,
      data,
      {observe: 'events', reportProgress: true} as any);
  }


}
