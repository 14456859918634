import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CMenuRefreshType, Hierarchy, MenuRefreshOpt} from '../../../_domains/UITypes';
import {AuthService} from 'src/app/_services/auth.service';
import {SelectItem} from 'primeng/api';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {AccessUtils} from "../../../_utils/ui-utils";

@Component({
  selector: 'project-record',
  templateUrl: './project-record.component.html',
  styleUrls: ['./project-record.component.css']
})
export class ProjectRecordComponent implements OnInit {
  @Output() onSavedData = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<void>();

  hierarchyForm: FormGroup;
  data: Hierarchy = null;
  templatesList: SelectItem[];
  readonly statusList: SelectItem[];
  readonly projectList: SelectItem[];

  labelsList: SelectItem[];
  labelsDict: any[];
  private labelsListVals: string[];
  private loading: boolean;
  sLastActivity = '';
  sFirstActivity = '';
  private readonly initFormState: any;
  tabIndex = 0;
  workflows: any;

  constructor(private dataService: ProjectUtilsService,
              private messageService: AppMessageService,
              private authService: AuthService,
              private fb: FormBuilder,
              private translate: TranslateService,
              private projectService: ProjectUtilsService) {
    this.statusList = [
      {value: 1, label: 'Opened'},
      {value: 2, label: 'Saved'},
      {value: 4, label: 'Submitted'}
    ];
    this.projectList = [
      {value: 0, label: 'Archive'},
      {value: 1, label: 'Active'},
      {value: 2, label: 'Saved'}
    ];

    this.data = new Hierarchy();
    this.hierarchyForm = this.fb.group({
      id: null,
      name: [null, [Validators.required, Validators.maxLength(512), Validators.pattern('^[^\\s]+(\\s.*)?$')]],
      description: [null, Validators.maxLength(1024)],
      access: 1,
      crpCode: null,
      eventNumber: null,
      projectNumber: null,
      grType: null,
      template: null,
      hiddenDocStatus: null,
      labels: [],
      includeMetadata: 1,
      tabs: this.fb.group({
        summary: this.fb.group({
          show: new FormControl(true),
          text: [null, Validators.maxLength(2014)]
        }),
        data: this.fb.group({
          show: {value: true, disabled: true},
          text: [null, Validators.maxLength(2014)]
        })
      }),
      workflow: [null, Validators.required]
    });

    this.initFormState = this.hierarchyForm.getRawValue();
  }

  ngOnInit() {
    this.getLabels();
  }

  clear() {
    this.data = new Hierarchy();
  }

  refresh(data): void {
    this.data = data;
    this.getTemplates();
    this.getLabels();
    this.open();
    this.getActivities();
  }

  reload() {
    this.open();
  }

  private open() {
    this.tabIndex = 0;
    this.hierarchyForm.reset(this.initFormState);
    const summaryTab = this.data?.tabs?.find(v => v.name === 'summary');
    const dataTab = this.data?.tabs?.find(v => v.name === 'data');
    this.workflows = this.convertWorkFlow2Form(this.data.grType, this.data?.workflow);
    this.hierarchyForm.patchValue({
      id: this.data.id,
      name: this.data.name,
      access: this.data.access,
      description: this.data.description,
      crpCode: this.data.crpCode,
      eventNumber: this.data.eventNumber,
      projectNumber: this.data.projectNumber,
      grType: this.data.grType,
      template: this.data.template,
      hiddenDocStatus: [1, 2, 4].filter(v => (this.data.hiddenDocStatus & v) === v),
      labels: this.labelsListVals,
      includeMetadata: !!this.data.includeMetadata,
      tabs: {
        summary: {
          show: summaryTab?.show == undefined ? true : summaryTab.show,
          text: summaryTab?.text
        },
        data: {
          show: dataTab?.show == undefined ? true : dataTab.show,
          text: dataTab?.text
        }
      },
      workflows: this.workflows
    });
  }

  private getTemplates() {
    this.projectService.getBuilderTemplates(this.data.id)
      .subscribe(data => this.templatesList = data.map(v => ({value: v.id, label: v.name})));
  }

  getActivities() {
    this.projectService.getAdmHierarchySummary(this.data.id)
      .subscribe(resp => {
        if (resp.maxUpdatedDate) {
          const dt = resp.maxUpdatedDate;
          const parts0 = dt.split(' '), parts = parts0[0].split('-');
          this.sLastActivity = parts0[0];
        }
        if (resp.minUpdatedDate) {
          const dt = resp.minUpdatedDate;
          const parts0 = dt.split(' '), parts = parts0[0].split('-');

          this.sFirstActivity = parts0[0];
        }
      });
  }

  private getLabels() {
    this.projectService.getDictLabels()
      .subscribe(data => {
        this.labelsList = [];
        this.labelsListVals = [];
        this.labelsDict = data.content;
        data.content.forEach(edict => {
          if (this.data !== null && this.data !== undefined && this.data.labels !== null && this.data.labels !== undefined) {
            this.data.labels.forEach(element => {
              if (element.label.indexOf('DOC:') == -1) {
                if (edict.id === element.idLabel) {
                  this.labelsListVals.push(edict.id);
                }
              }
            });
          }
          this.labelsList.push({
            label: edict.label, value: edict.id, styleClass: 'tag-warning', title: edict.label,
            disabled: edict.label.indexOf('DOC:') !== -1
          });
        });
      });

  }

  saveProject(value: any) {
    if (!value) {
      return;
    }
    this.loading = true;
    this.dataService.saveAdmHierarchy(
      {
        ...value,
        hiddenDocStatus: (value.hiddenDocStatus || []).reduce((a, b) => a + b, null),
        workflows: value.workflows
      })
      .pipe(finalize(() => this.loading = false))
      .subscribe({
          next: (data) => {
            this.onSavedData.emit({action: 'save', data: data});
            this.data = data;
            this.authService.updateStatus(new MenuRefreshOpt(CMenuRefreshType.STORE_POSITION));
            this.messageService.showSuccess(this.translate.instant('message.project.save.success'));
          },
          error: (error) => this.messageService.showMessage(error)
        }
      );
  }

  close() {
    this.onClose.emit();
  }

  private convertWorkFlow2Form(grType: number, workflow: any): any {
    if (workflow) {
      const type = grType;
      let formVal: any = {type: type};
      if (type === 2) {
        // opened project
        formVal = {
          ...formVal,
          opened: {
            view: (workflow.lvl0Acc & AccessUtils.VIEW) !== 0,
            edit: (workflow.lvl0Acc & AccessUtils.WRITE) !== 0,
            reopen: (workflow.lvl0Acc & AccessUtils.REOPEN) !== 0,
            del: (workflow.lvl0Acc & AccessUtils.REMOVE) !== 0,
            submit: (workflow.lvl0Acc & AccessUtils.SUBMIT) !== 0,
          }
        };
      } else {
        // closed project
        const closed = [{}, {}, {}];
        for (let i = 0; i < 3; i++) {
          const acc = workflow['lvl' + i + 'Acc'];
          closed[i] = {
            checked: acc > 0,
            view: (acc & AccessUtils.VIEW) !== 0,
            create: (acc & AccessUtils.CREATE) !== 0,
            edit: (acc & AccessUtils.WRITE) !== 0,
            reopen: (acc & AccessUtils.REOPEN) !== 0,
            del: (acc & AccessUtils.REMOVE) !== 0,
            submit: (acc & AccessUtils.SUBMIT) !== 0,
          };
        }
        formVal = {...formVal, closed: closed};
      }
      return formVal;
    } else {
      return null;
    }
  }

  get workflow() {
    return this.workflows;
  }

  set workflow(val: any) {
    this.hierarchyForm.get('grType').setValue(val?.type);
    this.hierarchyForm.get('workflow').setValue(val?.data);
  }

  get saveButtonDisabled(): boolean {
    return this.loading || !this.hierarchyForm.valid;
  }

  get cancelButtonDisabled(): boolean {
    return this.loading;
  }

  get isNew(): boolean {
    return !this.hierarchyForm.get('id').value;
  }
}
