import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from "../../../_services/config.service";
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-publicHeader',
  templateUrl: './layout-public-header.component.html',
  styleUrls: ['./layout-public-header.component.css']
})
export class LayoutPublicHeaderComponent implements OnInit, OnDestroy {
  routeActive: string = "";
  subscriptionRoute: Subscription;
  isPublic: boolean;

  constructor(private _configService: ConfigService,
              private router: Router) {
  }

  ngOnInit() {
    let _self = this;
    this.subscriptionRoute = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(
      (v: NavigationEnd) => {
        _self.routeActive = v.urlAfterRedirects;
        this.isPublic = v.url.startsWith("/public");
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionRoute.unsubscribe();
  }

  login() {
    this.router.navigate(['/login']);
  }

  get configService(): ConfigService {
    return this._configService;
  }
}
