<p-dialog header="Report preview" [(visible)]="displayDialog"
          [modal]="true" [style]="dialogStyle" [minY]="70" (onHide)="onHide()"
          [maximizable]="true" [baseZIndex]="8000">
  <p-toolbar [ngClass]="getToolbarClass()">
    <ng-container *ngIf="configService.isDesktopDevice">
      <div class="p-toolbar-group-left">
        <button pButton type="button" icon="pi pi-check" (click)="check()"
                [disabled]="isDisabledBtnDocCheck()" *ngIf="!isHiddenBtnDocCheck()"
                pTooltip="Check the document data"></button>
        <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle" *ngIf="!previewShow"></i>
        <button *ngIf="btnPdfVisible" pButton type="button" icon="pi pi-file-pdf" (click)="print2pdf()"
                [disabled]="isDisabledBtnPdf"
                pTooltip="Generate PDF file"></button>
      </div>
    </ng-container>

    <ng-container *ngIf="!configService.isDesktopDevice">
      <div class="p-toolbar-group-left">
        <button pButton type="button" icon="fa fa-check" label="Check"
                (click)="check()" [disabled]="isDisabledBtnDocCheck()"></button>

        <i class="p-toolbar-separator pi pi-bars" style="vertical-align: middle"></i>

        <p-splitButton label="Actions" icon="fa fa-cog" [model]="items" style iconPos="left"></p-splitButton>
      </div>
    </ng-container>
  </p-toolbar>

  <div class="dialog-wrapper" [ngClass]="getContentClass()">
    <!-- Page Content Holder [style.height.px]="contentHeight" -->
    <div class="dialog-content" [ngStyle]="contentStyle">
      <document-editor></document-editor>
    </div>
  </div>
</p-dialog>

<!-- Popup for field definition  -->
<p-dialog header="{{popupHeader}}" [(visible)]="popupShow" [modal]="true"
          [style]="{ 'max-width': '400px', 'width': '400px', 'max-height': '250px' }"
          [baseZIndex]="12000">
  <div [innerHTML]="popupContent"></div>
</p-dialog>
<!-- -->


<p-dialog #rowBiEditDialog id="dynTabRowEditWrapper" [maximizable]="true"
          header="Details" [(visible)]="rowBiEditDialogVisible" [modal]="true"
          styleClass="dm-document-row-edit-dialog"
          [baseZIndex]="12000">
  <div id="dynTabRowEdit" class="col-12"></div>
</p-dialog>
