import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../_services/config.service';
import {Observable} from 'rxjs';

@Injectable()
export class DocumentUtilsService {
  constructor(private http: HttpClient,
              private configService: ConfigService) { }
  getListProjectDocs(idProject: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.others}/${idProject}/docs`);
  }

  getCurrentUserProjectAccess(idProject: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.configService.api.authuser.projects}/${idProject}/acc`);
  }

  getListDocParams(cdoc: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.dicts.docutl}/${cdoc}/params`);
  }

  getDictDocData(cdoc: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.dicts.docs}/${cdoc}`)
  }
}
