import {SelectItem} from 'primeng/api';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DateUtils} from './date-utils';

export class UIUtils {
  public static CONNECT_REGISTRY = 'user::connect__info';

  static setUserConnectInfo(data: UserConnectInfo) {
    LocalStorageUtils.removeItem(UIUtils.CONNECT_REGISTRY);
    LocalStorageUtils.setItem(UIUtils.CONNECT_REGISTRY, JSON.stringify(data));
  }

  static getUserConnectInfo(): UserConnectInfo {
    let _val: string = LocalStorageUtils.getItem(UIUtils.CONNECT_REGISTRY);
    let _ret: UserConnectInfo;
    if (_val && _val.length > 0) {
      _ret = <UserConnectInfo>JSON.parse(_val);
      if (_ret) {
        if (_ret.ip_address && _ret.ip_address.length === 0) _ret.countryName = '?';
        if (_ret.countryCode && _ret.countryCode.length === 0) _ret.countryCode = '?';
        if (_ret.countryName && _ret.countryName.length === 0) _ret.countryName = '?';
      } else {
        _ret = new UserConnectInfo('?', '?', '?', '');
      }
    } else {
      _ret = new UserConnectInfo('?', '?', '?', '');
    }
    return _ret;
  }

  static getFilterStr(filters: any) {
    /*
        EQ(":"), NE("<>"), GT(">"), LT("<"), GTE(">:"), LTE("<:"), EMPTY("!:"), CONTAINS("~:"), STARTS("^:"), ENDS("$:");
     */
    let ret: string = '';

    for (let nm in filters) {
      const value = filters[nm].value;
      const matchMode = filters[nm].matchMode;
      let oper: string = '?';

      if (matchMode === 'eq' || matchMode === 'equals') {
        oper = ':';
      } else if (matchMode === 'ne') {
        oper = '<>';
      } else if (matchMode === 'gt') {
        oper = '>';
      } else if (matchMode === 'ge') {
        oper = '>:';
      } else if (matchMode === 'lt') {
        oper = '<';
      } else if (matchMode === 'le') {
        oper = '<:';
      } else if (matchMode === 'empty') {
        oper = '!:';
      } else if (matchMode === 'contains') {
        oper = '~:';
      } else if (matchMode === 'startsWith') {
        oper = '^:';
      } else if (matchMode === 'endsWith') {
        oper = '$:';
      } else if (matchMode === 'in') {
        oper = '#:';
      }
      if (oper !== '?')
        ret += (ret.length > 0 ? ',' : '') + `${nm}${oper}${matchMode === 'in' && value ? value.join(';') : value}`;
    }
    return this.URLEencode(ret);
  }

  static getFilterStrNew(filters: any) {
    /*
        EQ(":"), NE("<>"), GT(">"), LT("<"), GTE(">:"), LTE("<:"),
        EMPTY("!:"), CONTAINS("~:"), NOT_CONTAINS("*:"), STARTS("^:"), ENDS("$:"), IN("#:");
     */
    let ret: string = '';

    for (let nm in filters) {
      let value = filters[nm][0].value;
      if (value === null || value === undefined) {
        continue;
      }
      const matchMode = filters[nm][0].matchMode;
      let oper: string = '?';

      if (matchMode === 'eq' || matchMode === 'equals') {
        oper = ':';
      } else if (matchMode === 'ne' || matchMode === 'notEquals') {
        oper = '<>';
      } else if (matchMode === 'gt') {
        oper = '>';
      } else if (matchMode === 'gte') {
        oper = '>:';
      } else if (matchMode === 'lt') {
        oper = '<';
      } else if (matchMode === 'lte') {
        oper = '<:';
      } else if (matchMode === 'empty') {
        oper = '!:';
      } else if (matchMode === 'contains') {
        oper = '~:';
      } else if (matchMode === 'notContains') {
        oper = '*:';
      } else if (matchMode === 'startsWith') {
        oper = '^:';
      } else if (matchMode === 'endsWith') {
        oper = '$:';
      } else if (matchMode === 'in') {
        oper = '#:';
      } else if (matchMode === 'dateIs') {
        oper = ':';
        value = DateUtils.formatDate(value);
      } else if (matchMode === 'dateIsNot') {
        oper = '<>';
        value = DateUtils.formatDate(value);
      } else if (matchMode === 'dateBefore') {
        oper = '<';
        value = DateUtils.formatDate(value);
      } else if (matchMode === 'dateAfter') {
        oper = '>';
        value = DateUtils.formatDate(value);
      }
      if (oper !== '?')
        ret += (ret.length > 0 ? ',' : '') + `${nm}${oper}${matchMode === 'in' && value ? value.join(';') : value}`;
    }
    return this.URLEencode(ret);
  }

  static URLEencode(str): string {
    str = (str + '').toString();
    // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
    // HTML behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
    return encodeURIComponent(str)
      ;
  }

  static sortSelectItems(items: SelectItem[], field?: string): SelectItem[] {
    if (typeof (field) === 'undefined') field = 'label';
    // Sort array
    items = items.sort((n1, n2) => {
      if (n1[field] > n2[field]) {
        return 1;
      }
      if (n1[field] < n2[field]) {
        return -1;
      }

      return 0;
    });
    return items;
  }

  static getPublicFormLink(cdoc: string) {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/public/survey?cdoc=${cdoc}`;
  }

  static getPublicDocLink(tokenId: string) {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/public/token?token_id=${tokenId}`;
  }

  static getPublicRegistrationLink(cdoc: string, type: number, idOrg?: string) {
    const typeName = type === 0 ? 'doc' : (type === 1 ? 'org' : '');
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/registration/${cdoc}${typeName ? ('?type=' + typeName) : ''}${type === 1 ? ('&id=' + idOrg) : (type === 2 && idOrg ? ('?id=' + idOrg) : '')}`;
  }
}

export class UserConnectInfo {
  ip_address: string;
  countryCode: string;
  countryName: string;
  city?: string;

  constructor(ip: string, countryCode: string, countryNm: string, city: string) {
    this.ip_address = ip;
    this.countryCode = countryCode;
    this.countryName = countryNm;
    this.city = city;
  }
}

/**
 * Session storage service
 * Provides methods to get, set, remove, clear session storage items.
 */
export class SessionStorageUtils {
  /**
   * set session storage item
   * @param key
   * @param value
   */
  static setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * get session storage item
   * @param key
   */
  static getItem(key: string): any {
    var value = sessionStorage.getItem(key);
    return JSON.parse(value);
  }

  /**
   * remove session storage item
   * @param key
   */
  static removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  /**
   * remove all session storage items
   */
  static clear() {
    sessionStorage.clear();
  }
}

/**
 * Session storage service
 * Provides methods to get, set, remove, clear local storage items.
 */
export class LocalStorageUtils {
  /**
   * set session storage item
   * @param key
   * @param value
   */
  static setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * get session storage item
   * @param key
   */
  static getItem(key: string): any {
    var value = localStorage.getItem(key);
    return JSON.parse(value);
  }

  /**
   * remove session storage item
   * @param key
   */
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * remove all session storage items
   */
  static clear() {
    localStorage.clear();
  }
}

export enum AccessUtils {
  VIEW = 1, // Access for viewing documents
  CREATE = 2, // Access for creating documents
  WRITE = 4, // Access for writing documents
  REOPEN = 8, // Access for reopening documents
  REMOVE = 16, // Access for removing documents
  SUBMIT = 32, // Access for submitting documents
  APPROVE = 64, // Access for approving documents
  DOCUMENT_ADMIN = 128, // Document Administrator
  PROJECT_ADMIN = 1024 // Project Administrator
}

export enum DocRole {
  DOWNLOAD = 1,
  UPLOAD = 2,
  BULK_UPLOAD = 4,
  BULK_REVIEW = 8,
  FILE_UPLOAD = 16,
  FILE_VIEW = 32,
  PRINT = 64,
  RECORD_INFO = 128
}

export enum DocStage {
  DATAUPLOAD = 9,
  UNDERREVIEW = 8,
  APPROVED = 6,
  REJECTED = 7
}


/**
 * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
 * @param control AbstractControl
 * @returns AbstractControl
 */
export function cloneAbstractControl<T extends AbstractControl>(control: T, reset?: boolean): T {
  let newControl: T;
  if (control instanceof UntypedFormGroup) {
    const formGroup = new UntypedFormGroup({}, control.validator, control.asyncValidator);
    const controls = control.controls;
    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    })
    newControl = formGroup as any;
  } else if (control instanceof UntypedFormArray) {
    const formArray = new UntypedFormArray([], control.validator, control.asyncValidator);
    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)))
    newControl = formArray as any;
  } else if (control instanceof UntypedFormControl) {
    newControl = new UntypedFormControl(control.value, control.validator, control.asyncValidator) as any;
  } else {
    throw new Error('Error: unexpected control value');
  }
  if (control.disabled) newControl.disable({emitEvent: false});
  if (reset) {
    newControl.reset();
  }
  return newControl;
}
