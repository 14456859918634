import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../../../_services/config.service';
import {AppMessageService} from "../../../_services/app-message.service";
import {Pageable} from "../../../_domains/spring/pageable";
import {Table} from "primeng/table";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../_services/auth.service";
import {DocumentUtilsPrivateService} from "../../doceditor/_services/document-utils-private.service";
import {TranslateService} from "@ngx-translate/core";
import {BuilderUtils} from "../../../_utils/builder-utils";
import {UIUtils} from "../../../_utils/ui-utils";
import {FilterMetadata, MenuItem, SelectItem} from "primeng/api";
import {DialogReportAsFormComponent} from "../dialog-report-as-form/dialog-report-as-form.component";
import {RespOnProcessDoc} from "../../../_domains/UITypes";
import {BiDataUtilsService} from "../_services/bi-data-utils.service";
import {DataChartsComponent} from "../data-charts/data-charts.component";
import {GeoChartsComponent} from "../geo-charts/geo-charts.component";
import {DialogDownloadOptionsComponent} from "../dialog-download-options/dialog-download-options.component";

@Component({
  selector: 'app-stat-bi',
  templateUrl: './project-bi.component.html',
  styleUrls: ['./project-bi.component.css'],
  providers: [
    BiDataUtilsService
  ]
})
export class ProjectBiComponent implements OnInit, OnDestroy {
  @ViewChild(Table, {static: false}) builderData: Table;
  @ViewChild(DialogReportAsFormComponent, {static: false}) dlgReportAsFormCmp;
  @ViewChild(DataChartsComponent, {static: false}) documentChartsCmp;
  @ViewChild(GeoChartsComponent, {static: false}) documentGeoChartsCmp;
  @ViewChild(DialogDownloadOptionsComponent, {static: false}) projectDataDownloadOptionsCmp;

  template = '';
  private _dataMode = 2;
  dataSelectedRows: any = {};
  builderMode = false;
  dctDocs: any[] = [];
  projectBiData: Pageable = new Pageable();
  cdoc: string = "";
  loading: boolean = false;
  private columns: any[] = [];
  private _columns: any[] = [];
  private _cdoc: string = '';
  groupBy_val: string [] = ['', '', ''];
  statusFilter: any[] = [68608, 65536, 65536 + 1024 + 1];
  groupByLst: string [] = [];
  groupByDropdown: SelectItem[] [] = [[], [], []];
  private sorting = '';
  private _pageSize = 100;
  private _page = 0;
  private filter: any = {};
  // timer
  private idleId;
  private _enableAutorefresh = false;
  private fisrtGetData = false;
  private _enableRenew = false;
  statusList: SelectItem[];
  showDlgCharts: boolean;
  showDlgGeoCharts: boolean;
  private _itemsCharts: MenuItem[];
  private _docChartsDct: any[];
  private _docCharts: any[];
  showDlgDownloadOptions: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private router: Router,
              private _configService: ConfigService,
              private docService: DocumentUtilsPrivateService,
              private messageService: AppMessageService,
              private translate: TranslateService,
              private biDataService: BiDataUtilsService) {
    this.statusList = [
      {value: 1024, label: 'Data Stage'},
      {value: 65536, label: 'Data'},
      {value: 65536 + 1024 + 64, label: 'Duplicates'},
      {value: 65536 + 1024 + 8, label: 'Errors'},
      {value: 65536 + 1024 + 2048, label: 'Submitted'},
      {value: 65536 + 1024 + 1, label: 'Saved'},
      {value: -1024, label: 'Uploaded'},
      {value: 1024 + 256, label: 'Rejected'},
      {value: 1024 + 512, label: 'Approved'},
      {value: 1024 + 128, label: 'Under Review'}
    ];
  }

  ngOnInit(): void {
    this.projectBiData.content = [];
    const _self = this;
    this.idleId = setInterval(function () {
      const user = _self.authService.user;
      if (user.id) {
        if ((_self.fisrtGetData) && (_self._enableAutorefresh)) {
          _self.JobAutoRefresh();
        }

      }
    }, 1000 * 60 * 3);
  }

  JobAutoRefresh(): void {
    this.loadBiData(this._page, this.buildFilter());
  }

  ngOnDestroy() {
    clearInterval(this.idleId);
  }

  @Input()
  set idReport(val: string) {
    this.projectBiData.content = [];
    if (val) {
      this.cdoc = val;
      this.refresh(val).then();
    }
  }

  @Input()
  set accessdoc(val: any) {
    this._enableRenew = false;
    for (let ii in val) {
      if (val[ii].cdoc == this.cdoc) {
        if (val[ii].accessDocumentAdmin || val[ii].accessProjectAdmin || val[ii].fullAccess) {
          this._enableRenew = true;
        }
      }
    }
  }

  private refreshVisibleGroupby() {
    for (let i in this.columns) {
      if (this.columns[i].aggType === "") {
        this.columns[i].visible = false;
        let norder = 0;
        for (let ii in this.groupByLst) {
          norder++;
          if (this.groupByLst[ii] === this.columns[i].field) {
            this.columns[i].norder = norder;
            this.columns[i].visible = true;
          }
        }
      }
    }
    this.columns.sort((n1, n2) => {
      if (n1.norder > n2.norder) {
        return 1;
      }
      if (n1.norder < n2.norder) {
        return -1;
      }
      return 0;
    });
  }

  private pushGroupbyLst(item: string) {
    if ((item != 'none') && (item != '')) {
      if (this.groupByLst.indexOf(item) === -1) {
        this.groupByLst.push(item);
      }
    }
  }

  private refreshGroupbyLst() {
    this.groupByLst = [];
    for (let i in this.groupBy_val) {
      this.pushGroupbyLst(this.groupBy_val[i]);
    }
    this.refreshVisibleGroupby();
  }

  private refreshGroupby() {
    for (let i in this.groupByDropdown) {
      this.groupByDropdown[i] = [];
      this.groupBy_val[i] = 'none';
      this.groupByDropdown[i].push({value: 'none', label: 'None'});
    }
    let ii = 0;
    for (let i in this.columns) {
      const rdata = this.columns[i];
      if (rdata.aggType === "") {
        ii++;
        for (let jj in this.groupByDropdown) {
          this.groupByDropdown[jj].push({value: rdata.field, label: rdata.label});
        }

        if (ii === 1) {
          this.groupBy_val[0] = rdata.field;
        }
      }
    }
    if (ii < 3) {
      this.groupByDropdown[2] = [];
    }
    if (ii < 2) {
      this.groupByDropdown[1] = [];
    }
    this.refreshGroupbyLst();
  }


  async refresh(cdoc: string) {
    this.dctDocs = [];
    this.template = '';
    this.builderMode = false;
    this._columns = [];
    this._cdoc = cdoc;
    this.filter = null;
    if (this.builderData) {
      this.builderData.filters = {};
    }
    this.dataSelectedRows = {};
    this.projectBiData.content = [];
    if (this._cdoc || '' !== '') {
      this.loading = true;
      this._columns = await this.biDataService.getBiParams(this._cdoc);
      this.loading = false;
    }
    let vars = [];
    for (let i in this._columns) {
      const rdata = this._columns[i];
      if ((this.isMobileResolution) && (rdata.mobileVisible)) {
        continue;
      }
      if ((rdata.visible !== undefined) && (!rdata.visible)) {
        continue;
      }
      rdata.aggType = rdata.aggType || '';
      rdata['filterable'] = false;
      rdata['sortField'] = rdata.field;
      if (rdata.aggType === "") {
        rdata['filterable'] = true;
        rdata.norder += 1000;
      }
      vars.push(rdata);
    }
    this.columns = vars;
    this.refreshGroupby();
    if (this.isMobileResolution) {
      this._columns = this._columns.filter(v => v.mobileVisible);
    }
    this.loadBiData(this._page, this.buildFilter());
  }


  private checkProcessDone(cntAll: number, cnt: number): boolean {
    return cntAll === cnt;
  }

  filterValue(columnName: string): string {
    return this.builderData?.filters[columnName] ? (<FilterMetadata>this.builderData?.filters[columnName]).value : '';
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(p: number) {
    this._pageSize = p;
  }

  get configService(): ConfigService {
    return this._configService;
  }

  get typeAdmin(): number {
    return this.authService.user.typeAdmin;
  }


  onProjectDataLazyLoad(event) {
    // this.projectBiData.content = [];
    this.filter = event.filters;
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    if (!this.sorting) {
      this.sorting = '&sort=';
    }
    this._pageSize = event.rows;
    this._page = Math.floor(event.first / event.rows);

    this.loadBiData(this._pageSize == 0 ? 0 : this._page, this.buildFilter());

  }

  errorStatus(row: any): number {
    const status = row.FLAG || 0;
    if (status === 0) return 0;
    return ((status & 2) !== 0 || (status & 4) !== 0 || (status & 8) !== 0) ? 2 : 1;
  }


  outputRowCellValue(field, row) {
    let ret = row[field.field];

    if (field.dataType.toUpperCase() == "STRING") {
      if (ret && ret.length) {
        return ret.length > 41 ? (`${ret.substring(0, 40)}...`) : ret;
      }
    }
    return ret;
  }

  outputHeaderLabel(data) {
    data = BuilderUtils.removeHtmlTags(data);
    if (data && data.length) {
      return data.length > 41 ? (`${data.substring(0, 40)}...`) : data;
    }
    return data;
  }

  getVisibleColumns() {
    return this.columns.filter(v => v.visible);
  }

  getNgClassColumn(field: string, row: any) {
    return "";
  }

  onDblRowClick(event, row) {
    this.openReport(row);
  }

  handleGroupByChanged(event, num) {
    if (num === 0) {
      this.groupBy_val[1] = '';
      this.groupBy_val[2] = '';
    } else if (num === 1) {
      this.groupBy_val[2] = '';
    }
    this.refreshGroupbyLst();
    this.loadBiData(0, this.buildFilter());
  }

  async loadBiData(page: number, filter?: string) {
    if (this._cdoc.length > 0 && this.columns && this.columns.length > 0) {
      try {
        this.dataSelectedRows = {};
        this.projectBiData.content = [];
        this.loading = true;
        const rows = await this.biDataService.getQueryBi(this._cdoc,
          `page=${page}&size=${this._pageSize}` + (filter || ''), this.columns, this.groupByLst, this.statusFilter);
        this.loading = false;
        if (rows !== undefined) {
          if (!this.fisrtGetData) {
            this.fisrtGetData = true;
          }
          this.projectBiData = rows;


          this.setScheduleJob();
        }

      } catch (e) {
        console.error(e)
        this.loading = false;
      }
    }
  }

  async recalc() {
    if (this._cdoc.length > 0 && this.columns && this.columns.length > 0) {
      try {
        this.loading = true;
        const res = await this.biDataService.renewBi(this._cdoc);
        this.loadBiData(0, this.buildFilter());
        this.loading = false;
      } catch (e) {
        console.error(e)
        this.loading = false;
      }
    }
  }


  private buildFilter(): string {
    let filter = this.sorting || '';
    if (this.filter) {
      let search = UIUtils.getFilterStr(this.filter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    return filter;
  }

  private convertParam(rdata: any): any {
    return {
      field: `${rdata.cdoc}_${rdata.field}`,
      cdoc: rdata.cdoc,
      name: rdata.field,
      header: rdata.label,
      group: false,
      dataType: rdata.dataType,
      filterable: true,
      visible: rdata.visible,
      editType: rdata.editType,
      tabn: rdata.tabn
    }
  }

  // async downloadProjectData() {
  //   let filter = this.buildFilter();
  //   this.loading = true;
  //   const rows = await this.biDataService.exportBiData(this._cdoc,
  //     (filter || ''), this.columns, this.groupByLst,this.statusFilter);
  //   this.loading = false;
  // }

  async downloadData(onlySelected?: boolean, optionsMask?: any) {
    let filter = this.buildFilter();
    this.loading = true;
    const rows = await this.biDataService.exportBiData(this._cdoc,
      (filter || ''), this.columns, this.groupByLst, this.statusFilter, optionsMask);
    this.loading = false;
  }


  handleBtnRefresh(event) {
    this.loadBiData(this._page, this.buildFilter());
  }

  handleBtnRenew(event) {
    this.recalc();
  }


  private get isMobileResolution() {
    return this._configService.isMobileDevice;
  }

  isShowBtnDocOpen(): boolean {
    return true; //todo
  }

  get isDisabledBtnDocOpen(): boolean {
    return this.loading || !this.dataSelectedRows;
  }

  get isDisabledBtnAutorefresh(): boolean {
    return this.loading || !this.fisrtGetData;
  }

  get isDisabledBtnRefresh(): boolean {
    return this.loading || !this.fisrtGetData || this._enableAutorefresh;
  }

  get isDisabledBtnRenew(): boolean {
    return this.loading || !this.fisrtGetData || !this._enableRenew;
  }


  get isAutorefresh(): boolean {
    return this._enableAutorefresh;
  }

  set isAutorefresh(val: boolean) {
    this._enableAutorefresh = val;
  }

  get isDisabledBtnExcel(): boolean {
    return this.loading;
  }

  isDisableGroupBy(ctrlN: number): boolean {
    return (this._columns.length < 1 || !this.groupByDropdown[ctrlN] || this.groupByDropdown[ctrlN].length < 2);
  }

  private openReport(row: any) {
    this.dlgReportAsFormCmp.refreshVars(this._cdoc, this.columns, this.groupByLst, row);
  }

  docOpen() {
    const rows: any = this.dataSelectedRows;
    if (this.dataSelectedRows !== null) {
      this.openReport(rows);
    }
  }

  onProcessDoc(ret: RespOnProcessDoc) {
  }


  private setScheduleJob() {
    const _self = this;
    if ((this.idleId !== undefined) && (this.idleId !== null)) return;

  }


  get dataMode(): number {
    return this._dataMode;
  }

  get docCharts(): any[] {
    return this._docCharts;
  }

  onHideCharts() {
    this.documentChartsCmp.mychart.chart.destroy();
  }

  onHideGeoCharts() {

  }

  /**
   *
   * @param chartMeta - dictionary from charts
   */
  private openChart(chartMeta: any) {
    if (chartMeta?.type.length && chartMeta?.type.toLowerCase().startsWith("geo")) {
      this.documentGeoChartsCmp.refresh(chartMeta, this.buildFilter(), this._dataMode);
      this.showDlgGeoCharts = true;
    } else {
      this.documentChartsCmp.refresh(chartMeta, this.buildFilter(), this._dataMode);
      this.showDlgCharts = true;
    }
  }

  @Input()
  set docCharts(val: any[]) {
    this._docCharts = val;
    this._docChartsDct = [];
    this._itemsCharts = [];
    if (this._docCharts?.length) {
      this.docService.getListCharts(this._docCharts).subscribe(
        data => {
          this._docChartsDct = data;
          for (let i22 = 0; i22 < this._docChartsDct.length; i22++) {
            this._itemsCharts.push({
              label: this._docChartsDct[i22].shortName,
              command: () => this.openChart(this._docChartsDct[i22])
            });
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
    }

    // for (let i22 = 0; i22 < this._docCharts.length; i22++) {
    //   this._itemsCharts.push({label: this._docCharts[i22], command: () => this.openChart(this._docCharts[i22])});
    // }
  };

  get itemsCharts(): MenuItem[] {
    return this._itemsCharts;
  }

  get isDisabledBtnCharts(): boolean {
    return this.loading || this.docCharts.length < 1;
  }

  handleBtnExcel(event) {
    // this.downloadProjectData();
    this.openDownloadOptions(false);
  }


  private openDownloadOptions(onlySelected?: boolean) {
    if (onlySelected && !this.dataSelectedRows?.length) {
      this.messageService.showWarn(this.translate.instant('message.document.noId'));
      this.showDlgDownloadOptions = false;
      return;
    }
    this.projectDataDownloadOptionsCmp.refresh(this._dataMode, !!onlySelected, 1 | 0 | 8); //2^0 Variable name, 2^1 - Variable description  , 2^2 - Upload raw data including codes where possible, 2^3 - Download description values instead codes
    this.showDlgDownloadOptions = true;
  }

  downloadOptionsSuccess($event: any) {
    this.showDlgDownloadOptions = false;
    this.downloadData($event.onlySelected, $event.options);
  }

  downloadOptionsSuccessClose() {
    this.showDlgDownloadOptions = false;
  }


}
