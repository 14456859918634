import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoaderService} from '../../core/loader/loader.service';
import {AuthService} from '../../../_services/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-sso-login',
  template: `
    <div class="row" style="min-height: 200px">
      <div *ngIf="failed"
           class="col-12 text-center">
        <img src="assets/images/failure.png" height="210" width="210"/>
      </div>
      <div class="col-12 text-center"><span>{{message}}</span></div>
    </div>
  `
})
export class SsoLoginComponent implements OnInit, OnDestroy {
  private subscriptionAuthService: Subscription;
  private _message: string;
  private _failed: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private loaderService: LoaderService,
              private authService: AuthService) {
    this._message = 'Loading...';
    const ssoId = this.activatedRoute.snapshot.paramMap.get('id');
    this.loaderService.show();

    this.subscriptionAuthService = this.authService.getAuthData$.subscribe(userLoggidIn => {
      if (!userLoggidIn) {
        this.login(ssoId);
      }
    });
    this.authService.logout();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptionAuthService.unsubscribe();
  }

  private login(ssoId: string) {
    this._failed = false;
    const success = (): void => {
      if (this.authService.projectRegistration) {
        this.router.navigateByUrl('/registration/confirm', {state: this.authService.projectRegistration});
        this.authService.projectRegistration = '';
      } else if (this.authService.redirectUrl && this.authService.redirectUrl !== '/') {
        this.router.navigate([this.authService.redirectUrl]);
        this.authService.redirectUrl = null;
      } else {
        this.router.navigate(['/home']);
      }
    };
    const failure = (error: any): void => {
      this._message = (error.error && error.error.message) || 'Sign in error.';
      this._failed = true;
    };
    this.authService.ssoLogin(ssoId, success, failure);
  }

  get message(): string {
    return this._message;
  }

  get failed(): boolean {
    return this._failed;
  }
}
