import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import {DocumentUtilsPrivateService} from "../../doceditor/_services/document-utils-private.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {AuthService} from "../../../_services/auth.service";

@Component({
  selector: 'dialog-download-options',
  templateUrl: './dialog-download-options.component.html'
})

export class DialogDownloadOptionsComponent implements OnInit {
  @Input() onlySelected = false;
  @Input() options = 1 | 0 | 8; //2^0 Variable name, 2^1 - Variable description  , 2^2 - Download descriptive values instead the codes, 2^3 Ignore filters (Full download)
  @Output() onSave = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  datamode = 0;
  _onlySelected = false;
  userForm: UntypedFormGroup;

  constructor(private dataService: DocumentUtilsPrivateService,
              private authService: AuthService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      headerVarName: !!(this.options & 1),//2^0 Variable name, 2^1 - Variable description  , 2^2 - Download descriptive values instead the codes, 2^3 Ignore filters (Full download)
      headerDescription: !!(this.options & 2),
      downloadDescription: (this.options & 4),
      fullDownload: !!(this.options & 8)
    });
    this.datamode = 0;
  }


  refresh(datamode: number, onlySelected?: boolean, options?: number) {
    this.datamode = datamode;
    this._onlySelected = onlySelected;
    this.options = options;
    this.userForm.get('headerVarName').setValue(!!(this.options & 1));
    this.userForm.get('headerDescription').setValue(!!(this.options & 2));
    this.userForm.get('downloadDescription').setValue((this.options & 4) ? 1 : 0);
    this.userForm.get('fullDownload').setValue(!!(this.options & 8));


  }

  save(value: any) {
    this.onSave.emit({options: value, datamode: this.datamode, onlySelected: this._onlySelected});
  }

  close() {
    this.onClose.emit();
  }
}
