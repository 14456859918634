import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../_services/config.service';
import {Observable} from 'rxjs';
import {Hierarchy} from '../../../_domains/UITypes';
import {Pageable} from '../../../_domains/spring/pageable';
import {DbOrganization} from '../../../_domains/DbOrganization';

@Injectable()
export class ProjectUtilsService {
  constructor(private http: HttpClient,
              private configService: ConfigService) {
  }


  exportTemplate(cdoc: string, type: string, prefix?: string): Observable<any> {
    return this.http.get(`${this.configService.api.dicts.docutl}/${cdoc}/download/${type}`);
  }

  exportProjectData(id: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.export}/project/${id}`);
  }

  exportReportData(idProject: string, ids: string[], dictColumns: any, filter?: string, options?: any): Observable<any> {
    return this.http.post<any>(
      `${this.configService.api.builder}/${idProject}/query/download?${(filter || '')}`,
      {
        ids: ids,
        columns: dictColumns,
        options: options
      });
  }

  uploadTemplate(cdoc: string, type: string, formData: any): Observable<any> {
    return this.http.post<any>(`${this.configService.api.dicts.docutl}/${cdoc}/upload/${type}`, formData);
  }

  /** Project administration */
  getAdmHierarchySummary(id: string): Observable<any> {
    return this.http.get(`${this.configService.api.docutl.project}/${id}/summary`);
  }

  getAdmHierarchy(id: string): Observable<any> {
    return this.http.get(`${this.configService.api.projects.oper}/${id}`);
  }

  getAdmHierarchies(): Observable<any> {// TODO
    return this.http.get<any>(`${this.configService.api.authuser.projects}`);
  }

  saveAdmHierarchy(value: any): Observable<any> {
    return (value.id == null ? this.http.put(this.configService.api.projects.oper, value) :
      this.http.post(`${this.configService.api.projects.oper}/${value.id}`, value));
  }

  getAdmWorkflow(id: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.oper}/${id}/workflow`);
  }

  delAdmHierarchy(hierarchy: Hierarchy): Observable<any> {
    return this.http.delete(this.configService.api.projects.oper + '/' + hierarchy.id);
  }

  setAdmHierarchyStatus(id: string, status: number): Observable<any> {
    return this.http.post(`${this.configService.api.projects.oper}/${id}/status/${status}`, null);
  }

  getOrganization(idProject: string, id: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.oper}/${idProject}/organizations/${id}`);
  }

  getOrganizations(idProject: string, query?: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.configService.api.projects.oper}/${idProject}/organizations${query ? '?search=name~:' + query : ''}`);
  }

  saveOrganization(idProject: string, org: DbOrganization): Observable<any> {
    return (!org?.id ?
      this.http.put(`${this.configService.api.projects.oper}/${idProject}/organizations`, org) :
      this.http.post(`${this.configService.api.projects.oper}/${idProject}/organizations/${org.id}`, org));
  }

  delAdmOrganization(idProject: string, id: string): Observable<void> {
    return this.http.delete<void>(`${this.configService.api.projects.oper}/${idProject}/organizations/${id}`);
  }

  // getOrganizations(query?: string): Observable<any> {
  //   return this.http.get<any>(this.configService.api.account.organizations + (query ? '?search=name~:' + query : ''));
  // }

  getStates(query?: string): Observable<any> {
    return this.http.get<any>(this.configService.api.dicts.states + '?name=' + query);
  }

  /** Templates administration */
  getListDictDocs(filter?: string): Observable<any> {
    return this.http.get(this.configService.api.dicts.docs + (filter || ''));
  }

  getOneDictDocs(cdoc: string): Observable<any> {
    return this.http.get(`${this.configService.api.dicts.docs}/${cdoc}`);
  }

  saveDictDoc(isNew: boolean, currentReport: any): Observable<any> {
    return (isNew) ? this.http.put(`${this.configService.api.dicts.docs}`, currentReport) : this.http.post(`${this.configService.api.dicts.docs}`, currentReport);
  }

  deleteDictDoc(cdoc: string): Observable<any> {
    return this.http.delete(`${this.configService.api.dicts.docs}/${cdoc}`);
  }

  getListDictDocParams(cdoc: string, filter: string): Observable<any> {
    return this.http.get(this.configService.api.dicts.docutl + '/' + cdoc + '/params/page' + (filter || ''));
  }

  getListDictDocRules(cdoc: string, filter: string): Observable<any> {
    return this.http.get(this.configService.api.dicts.docutl + '/' + cdoc + '/rules/page' + (filter || ''));
  }

  getListDictDocCharts(cdoc: string, filter: string): Observable<any> {
    return this.http.get(this.configService.api.dicts.docutl + '/' + cdoc + '/charts/page' + (filter || ''));
  }


  //
  getCurrentUserProjectAccess(idProject: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.configService.api.authuser.projects}/${idProject}/acc`);
  }

  getCurrentUserProjectUiSettings(idProject: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.authuser.projects}/${idProject}/ui-settings`);
  }

  setCurrentUserProjectUiSettings(idProject: string, data: any[]): Observable<void> {
    return this.http.post<any>(`${this.configService.api.authuser.projects}/${idProject}/ui-settings`, data);
  }

  //
  saveAppendixDoc(cdoc: string, prm: any) {
    return this.http
      .put(this.configService.api.dicts.docs + '/' + cdoc + '/childs', prm);
  }

  delAppendixDoc(p_cdoc: string, cdoc: string) {
    return this.http.delete(`${this.configService.api.dicts.docs}/${p_cdoc}/childs/${cdoc}`);
  }

  getBuilderTemplates(id: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.dicts.templates}/${id}/list`);
  }

  isGeoExistsDocs(idProject: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.projects.oper}/${idProject}/geo-exists`);
  }

  getDictLabels(): Observable<Pageable> {
    return this.http.get<Pageable>(this.configService.api.dicts.tags + '?page=0&size=10000');
  }

  getDictCustButtons(idProject: string, filter?: string): Observable<any> {
    return this.http.get(this.configService.api.dicts.buttons + `/${idProject}?${filter || ''}`);
  }

  deleteCustButton(id: string): Observable<any> {
    return this.http.delete<any>(`${this.configService.api.dicts.buttons}/${id}`);
  }

  saveCustButton(idProject: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.configService.api.dicts.buttons}/${idProject}`, data);
  }
}
