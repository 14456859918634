import {Component, OnInit} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AuthService} from '../../../_services/auth.service';
import {Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent, SelectItem} from 'primeng/api';
import {Label} from '../../../_domains/UITypes';
import {UIUtils} from '../../../_utils/ui-utils';
import {DataAdminService} from '../_services/data-admin.service';
import {finalize} from 'rxjs/operators';
import {AppMessageService} from '../../../_services/app-message.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-admin-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css']
})
export class LabelsComponent implements OnInit {

  private _labelForm: UntypedFormGroup;
  private _labels: Pageable;
  private _loading: boolean;
  private _currentLabel: Label = new Label();
  private _currentPage: number = 0;
  private _sorting: string;
  private _tableFilter: any;

  selected: Label;
  items: SelectItem[] = [];
  types: SelectItem[] = [];

  showDlgLabelRecord: boolean = false;

  constructor(private dataService: DataAdminService,
              private authService: AuthService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private translate: TranslateService) {
    this._labels = new Pageable();
    this._labelForm = fb.group({
      id: {value: '', disabled: false},
      label: [null, [Validators.required, Validators.maxLength(40)]],
      name: [null, [Validators.required, Validators.maxLength(100)]],
      severity: [null, this.LabelSeverityValidator]
    });


  }

  ngOnInit() {
    this.types.push({label: 'None', value: ''});
    this.types.push({label: 'info', value: 'info'});
    this.types.push({label: 'danger', value: 'danger'});
    this.types.push({label: 'success', value: 'success'});
    this.types.push({label: 'warning', value: 'warning'});
  }


  private loadData(filter?: string) {
    filter = '?page=' + this._currentPage + (this._sorting || '');
    if (this._tableFilter) {
      let search = UIUtils.getFilterStr(this._tableFilter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    this._loading = true;
    this.dataService.getDictLabels(filter)
      .pipe(finalize(() => this._loading = false))
      .subscribe(
        data => {
          this._labels = data;
        },
        error => {
          this.messageService.showMessage(error, 'Error receiving data');
        }
      );
  }


  openDlgLabelRecord(isCreate: boolean) {
    if (isCreate) {
      this._currentLabel = new Label();
      this._labelForm.reset();
    } else {
      this._labelForm.reset();
      this._labelForm.get('id').setValue(this._currentLabel.id);
      this._labelForm.get('label').setValue(this._currentLabel.label);
      this._labelForm.get('severity').setValue(this._currentLabel.severity);
      this._labelForm.get('name').setValue(this._currentLabel.name);
    }
    this.showDlgLabelRecord = true;
  }


  handlePageChange(event: LazyLoadEvent) {
    this._labels.content = [];
    this._tableFilter = event.filters;
    this._currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('' );
  }

  handleRowSelect(event) {
    this.selected = event.data;
    this._currentLabel = this.selected;
  }

  handleDblRowClick(event, row) {
    this.openDlgLabelRecord(false);
  }


  private reloadData() {
    this._currentPage = 0;
    this.loadData('');
    this.selected = null;
    this._currentLabel = new Label();
    this._labelForm.reset();
  }

  save(value: any) {
    if (this._currentLabel == null) return;
    this._loading = true;
    this._currentLabel.id = value.id;
    this._currentLabel.label = value.label;
    this._currentLabel.severity = value.severity;
    this._currentLabel.name = value.name;

    (this._currentLabel.label == null ? this.dataService.createLabel(this._currentLabel) : this.dataService.saveLabel(this._currentLabel))
      .pipe(finalize(() => this._loading = false))
      .subscribe(
        data => {
          this.showDlgLabelRecord = false;
          this.messageService.showSuccess(this._currentLabel.label == null ?
            this.translate.instant('message.user.add.success') : this.translate.instant('message.user.save.success'));
          this.reloadData();
        },
        error => {
          this.messageService.showMessage(error, 'Error');
        }
      );
  }

  delete() {
    if (this._currentLabel == null || this._currentLabel.label == null) return;
    this._loading = true;
    this.confirmationService.confirm({
      message: this.translate.instant('message.user.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.dataService.deleteLabel(this._currentLabel.id)
          .pipe(finalize(() => this._loading = false))
          .subscribe(
            data => {
              this.reloadData();
              this.messageService.showSuccess(this.translate.instant('message.user.delete.success'));
            },
            error => {
              this.messageService.showMessage(error, this.translate.instant('message.header.error'));
            }
          );
      },
      reject: () => {
      }
    });
  }

  private LabelSeverityValidator(control: UntypedFormControl): ValidationErrors {
    const value = control.value;
    if (value) {
      var re = /(info|success|warning|danger|(color:\s{0,}(#){0,1}\w{1,})|(background-color:\s{0,}(#){0,1}\w{1,})|(icon:\s{0,}(\w|\\-){1,}))/ig;
      var found = value.match(re);
      // console.log(found);
      if (found == null) {
        return {invalidSeverity: 'You have to select an severity'};
      } else if (found.length < 1) {
        return {invalidSeverity: 'You have to select an severity'};
      }
    } else {
      return {invalidSeverity: 'You have to select an severity'};
    }
    return null;
  }

  get labels(): Pageable {
    return this._labels;
  }

  get loading(): boolean {
    return this._loading;
  }

  get labelForm(): UntypedFormGroup {
    return this._labelForm;
  }


  get currentLabel(): Label {
    return this._currentLabel;
  }


  sanityObj(str) {
    return str.replace(new RegExp('\'|\}|\{"|', 'g'), '').trim();
  }

  getclassOfLabel(s: string): any {
    let res = {};
    let obs = s.split(',');
    obs.forEach(element => {
        const vals = element.split(':');
        if (vals.length === 2) {
          let key = this.sanityObj(vals[0]),
            val = this.sanityObj(vals[1]);
          if ((key !== '') && (key !== 'icon') && (val !== ''))
            res[key] = val;
        }
      }
    )
    return res;
  }

  getIconOfLabel(s: string): string {
    let res = "";
    let obs = s.split(',');
    obs.forEach(element => {
        const vals = element.split(':');
        if (vals.length === 2) {
          let key = this.sanityObj(vals[0]),
            val = this.sanityObj(vals[1]);
          if ((key === 'icon') && (val !== '')) {
            res = val;
          }
        }
      }
    )
    return res;
  }

  get saveButtonDisabled(): boolean {
    return this.loading || !this._labelForm.valid;
  }

  get cancelButtonDisabled(): boolean {
    return this.loading;
  }

}
