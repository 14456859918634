<form (ngSubmit)="save()" [formGroup]="repForm">
  <div class="p-fluid">
    <div class="row form-group">
      <label class="col-4 col-form-label" for="cdoc"><span class="field-required">*&nbsp;</span>Code</label>
      <div class="col-8">
        <input formControlName="cdoc" class="form-control" type="text" id="cdoc"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-show-error [control]="repForm.get('cdoc')"></app-show-error>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-4 col-form-label" for="name"><span class="field-required">*&nbsp;</span>Name</label>
      <div class="col-8">
        <textarea pInputTextarea formControlName="name" class="form-control" id="name" rows="5"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-show-error [control]="repForm.get('name')"></app-show-error>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-4 col-form-label" for="name">Short name</label>
      <div class="col-8">
            <textarea pInputTextarea formControlName="shortname" class="form-control" id="shortname"
                      rows="3"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-show-error [control]="repForm.get('shortname')"></app-show-error>
      </div>
    </div>
    <div class="row form-group">
      <label class="col-4 col-form-label" for="mode">Mode</label>
      <div class="col-8">
        <p-dropdown [options]="itemsModes" formControlName="mode" id="mode"
                    appendTo="body" [overlayOptions]="{baseZIndex: 9999}"></p-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-show-error [control]="repForm.get('mode')"></app-show-error>
      </div>
    </div>
    <div class="row form-group" formGroupName="style">
      <label class="col-4 col-form-label" for="mode">Background color</label>
      <div class="col-1">
        <p-checkbox formControlName="backgroundColorEnabled" binary="true"></p-checkbox>
      </div>
      <div class="col-7" *ngIf="repForm.get('style').value?.backgroundColorEnabled">
        <div class="input-group">
          <div class="input-group-prepend">
            <p-colorPicker formControlName="backgroundColor"
                           appendTo="body"></p-colorPicker>
          </div>
          <input pInputText formControlName="backgroundColorValue" class="form-control" type="text">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-show-error [control]="repForm.get('style').get('backgroundColorValue')"></app-show-error>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-4">
        <p-checkbox name="access" formControlName="access" label="Enabled" binary="true"
                    (change)="currentReport.access = !$event && currentReport.access"></p-checkbox>
      </div>
    </div>
  </div>
  <div class="row form-group" *ngIf="repForm.get('mode').value === 1">
    <div class="col-12">
      <p-checkbox name="wizardType" formControlName="wizardType" label="All tabs are open for navigation"
                  binary="true"></p-checkbox>
    </div>
  </div>
  <div class="row float-right">
    <div class="col-12">
      <button pButton type="submit" icon="pi pi-save"
              [disabled]="saveButtonDisabled" label="Save"></button>
      <button pButton type="button" icon="pi pi-times"
              [disabled]="cancelButtonDisabled"
              (click)="close()" label="Close"></button>
    </div>
  </div>
</form>
