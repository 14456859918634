import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {Pageable} from '../../../_domains/spring/pageable';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {UIUtils} from '../../../_utils/ui-utils';
import {ProjectDocumentRecordComponent} from '../project-document-record/project-document-record.component';
import {AppMessageService} from '../../../_services/app-message.service';
import {ClipboardService} from 'ngx-clipboard';
import {
  DialogTemplatePreviewComponent
} from '../../doceditor/dialog-template-preview/dialog-template-preview.component';
import {ProjectDocumentParamsComponent} from '../project-document-params/project-document-params.component';
import {ProjectDocumentRulesComponent} from '../project-document-rules/project-document-rules.component';
import {TranslateService} from '@ngx-translate/core';
import {ProjectDocumentChartsComponent} from '../project-document-charts/project-document-charts.component';
import {Subscription} from 'rxjs';
import {MessagingService} from '../../../_services/messaging.service';

@Component({
  selector: 'project-documents',
  templateUrl: './project-documents.component.html'
})
export class ProjectDocumentsComponent implements OnInit, OnDestroy {
  @Input() idProject = '';
  @Input() projectAdmin: boolean;
  private _grType: number;
  @Output() onSavedData = new EventEmitter<any>();
  @ViewChild(ProjectDocumentRecordComponent, {static: false}) docRecordCmp;
  @ViewChild(ProjectDocumentParamsComponent, {static: false}) docParamsCmp;
  @ViewChild(ProjectDocumentRulesComponent, {static: false}) docRulesCmp;
  @ViewChild(ProjectDocumentChartsComponent, {static: false}) docChartsCmp;
  @ViewChild(DialogTemplatePreviewComponent, {static: false}) docPreviewCmp;

  showDocProp = false;
  dialogDocTitle = '';
  uploadShow = false;
  private uploadType = 'XSD';
  private filters: any;
  reports: Pageable = new Pageable();
  selectedReport: any[] = [];
  private currentPage = 0;
  loading = true;
  private sorting = '&sort=name,asc';
  readonly itemsExport: MenuItem[];
  readonly itemsImport: MenuItem[];
  readonly itemsOpts: MenuItem[];
  private templateRecordUpdate: Subscription;
  genLinkLabel!: string;
  genLinkVisible!: boolean;
  genLink!: string;
  genLinkType!: number;
  genLinkIdOrg: string;
  orgsList!: any[];

  constructor(private dataService: ProjectUtilsService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private clipboardService: ClipboardService,
              private router: Router,
              private messagingService: MessagingService,
              private translate: TranslateService) {
    this.itemsExport = [
      {
        label: 'EXCEL', icon: 'far fa-file-excel', command: () => {
          this.download('XLSX');
        }
      },
      {
        label: 'HTML', icon: 'far fa-file-code', command: () => {
          this.download('HTML');
        }
      }
    ];
    this.itemsImport = [
      {
        label: 'EXCEL', icon: 'far fa-file-excel', command: () => {
          this.uploadType = 'XLSX';
          this.uploadShow = true;
        }
      },
      {
        label: 'HTML', icon: 'far fa-file-code', command: () => {
          this.uploadType = 'HTML';
          this.uploadShow = true;
        }
      },
    ];
    this.itemsOpts = [
      {
        label: 'Parameters',
        command: () => this.optsParameters()
      },
      {
        label: 'Rules',
        command: () => this.optsRules()
      },
      {
        label: 'Charts',
        command: () => this.optsCharts()
      }
    ];
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if (this.templateRecordUpdate) {
      this.templateRecordUpdate.unsubscribe();
    }
  }

  @Input()
  set grType(val: number) {
    this._grType = val;
    this.genLinkLabel = this._grType === 2 ? 'Generate public link' : 'Generate link for Self-Registration';
  }

  optsParameters() {
    if (this.selectedReport?.length === 1) {
      const rec = this.selectedReport[0];
      this.docParamsCmp.refresh(rec.cdoc, rec.mode);
    } else {
      this.messageService.showWarn(this.translate.instant('message.cdoc.item.selRecord'));
    }
  }

  private optsRules() {
    if (this.selectedReport?.length === 1) {
      const rec = this.selectedReport[0];
      this.docRulesCmp.refresh(rec.cdoc);
    } else {
      this.messageService.showWarn('Please select a record');
    }
  }

  private optsCharts() {
    if (this.selectedReport?.length === 1) {
      const rec = this.selectedReport[0];
      this.docChartsCmp.refresh(rec.cdoc);
    } else {
      this.messageService.showWarn('Please select a record');
    }
  }


  get isSetIdProject() {
    return this.idProject && this.idProject.length > 0 ? (this.idProject !== '*') : false;
  }

  private loadData(filter?: string) {
    if (this.idProject.length > 0) {
      this.loading = true;
      this.dataService.getListDictDocs(filter)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          data => {
            this.reports = data;
          },
          error => {
            this.messageService.showMessage(error, 'getListDictDocs');
          }
        );
    }
  }

  private reloadData() {
    this.currentPage = 0;
    this.selectedReport = [];

    this.loadData(this.buildFilter());
  }

  private buildFilter(): string {
    let ret = `?page=${this.currentPage || '0'}`,
      search = `idHierarchy:${this.idProject},docAdmin:1,`,
      search1: string = UIUtils.getFilterStr(this.filters);
    ret += this.sorting;
    ret += search1.length > 0 ? `&search=${search},${search1}` : `&search=${search}`;

    return ret;
  }

  docCreate() {
    this.docRecordCmp.create(this.idProject);
    this.dialogDocTitle = this.translate.instant('message.cdoc.create.title');
    this.showDocProp = true;
  }

  private checkProcessDone(cntAll: number, cnt: number): boolean {
    return cntAll === cnt;
  }

  docDelete() {
    if (!this.selectedReport?.length) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('message.cdoc.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.loading = true;
        const cntAll = this.selectedReport.length;
        let cnt = 0;
        for (const row of this.selectedReport) {
          this.dataService.deleteDictDoc(row.cdoc)
            .pipe(finalize(() => {
              cnt++;
              if (this.checkProcessDone(cntAll, cnt)) {
                this.reloadData();
              }
            }))
            .subscribe(_ => null,
              error => this.messageService.showMessage(error)
            );
        }
      },
      reject: () => {
      }
    });
  }

  docEdit() {
    if (this.selectedReport?.length !== 1) {
      return;
    }
    this.dialogDocTitle = this.translate.instant('message.cdoc.edit.title');
    this.showDocProp = true;
    this.docRecordCmp.open(this.selectedReport[0]);
  }

  callDocBuilder() {
    if (this.selectedReport?.length !== 1) {
      return;
    }
    this.docPreviewCmp.refresh(this.selectedReport[0].cdoc);
  }

  copyToClipbord() {
    if (this.selectedReport?.length !== 1) {
      return;
    }
    this.genLinkVisible = true;
    if (this._grType === 2) {
      this.genLink = UIUtils.getPublicFormLink(this.selectedReport[0].cdoc);
    } else {
      this.genLinkType = 2;
      this.genLink = UIUtils.getPublicRegistrationLink(this.selectedReport[0].cdoc, this.genLinkType);
      if (!this.orgsList) {
        this.dataService.getOrganizations(this.idProject)
          .subscribe(data => this.orgsList = data);
      }
    }
  }

  genLinkTypeChanged() {
    if (this.genLinkType === 1 && !this.genLinkIdOrg) {
      this.genLink = '';
    } else {
      this.genLink = UIUtils.getPublicRegistrationLink(this.selectedReport[0].cdoc, this.genLinkType, this.genLinkIdOrg);
    }
  }

  copyGeneratedLink() {
    this.clipboardService.copy(this.genLink);
    this.genLinkVisible = false;
  }

  handlePageChange(event) {
    this.filters = event.filters || {};
    this.currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;

    this.loadData(this.buildFilter());
  }

  upload(type: string) {
    this.uploadType = type;
    this.uploadShow = true;
  }

  download(type: string) {
    if (!this.selectedReport?.length) {
      return;
    }
    this.loading = true;
    const cntAll = this.selectedReport.length;
    let cnt = 0;
    for (const row of this.selectedReport) {
      this.dataService.exportTemplate(row.cdoc, type)
        .pipe(finalize(() => {
          cnt++;
          if (this.checkProcessDone(cntAll, cnt)) {
            this.loading = false;
          }
        }))
        .subscribe(
          data => {
            if (data.status === 1) {
              this.messageService.showInfo(this.translate.instant('message.request.inProgress'));
            }
          },
          error => {
            this.messageService.showError(error);
          }
        );
    }
  }

  fileUploader(event) {
    if (this.selectedReport?.length !== 1) {
      return;
    }
    let fileList: FileList = event.files;
    if (fileList.length >= 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file, file.name);

      this.dataService.uploadTemplate(this.selectedReport[0].cdoc, this.uploadType, formData)
        .subscribe(
          data => {
            this.selectedReport = [];
            this.uploadShow = false;
            if (data.status == 1) {
              if (this.uploadType === 'HTML') {
                this.messageService.showSuccess(this.translate.instant('message.cdoc.file.upload.success'));
              } else {
                this.messageService.showInfo(this.translate.instant('message.request.inProgress'));
              }
            } else {
              this.messageService.showError(data.message);
            }
          },
          error => {
            this.messageService.showMessage(error);
          });
    }
  }

  onSaveProperty(event: any) {
    this.showDocProp = false;
    this.onSavedData.emit(event);
    this.reloadData()
  }

  refresh() {
    this.orgsList = null;
    if (this.templateRecordUpdate) {
      this.templateRecordUpdate.unsubscribe();
    }
    this.templateRecordUpdate =
      this.messagingService.templateRecordUpdate
        .subscribe({
            next: (cdoc: string) => this.updateOneRecord(cdoc)
          }
        );
    this.reloadData();
  }

  updateOneRecord(cdoc: string) {
    if (this.reports?.content?.length) {
      const ind = this.reports.content.findIndex(v => v.cdoc === cdoc);
      if (ind >= 0) {
        this.dataService.getOneDictDocs(cdoc)
          .subscribe(data => {
            this.reports.content[ind].operDate = data.operDate;
            this.reports.content[ind].operUserName = data.operUserName;
          });
      }
    }
  }

  rowDoubleClick(row: any) {
    this.docPreviewCmp.refresh(row.cdoc);
  }

  get docAddButtonVisible(): boolean {
    return this.projectAdmin;
  }

  get docEditButtonVisible(): boolean {
    return this.projectAdmin;
  }

  get docDeleteButtonVisible(): boolean {
    return this.projectAdmin;
  }

  get docEditButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || this.selectedReport?.length !== 1;
  }

  get docDeleteButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || !this.selectedReport?.length;
  }

  get itemsExportButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || !this.selectedReport?.length;
  }

  get itemsImportButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || this.selectedReport?.length !== 1;
  }

  get docPreviewButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || this.selectedReport?.length !== 1;
  }

  get copyLinkButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || this.selectedReport?.length !== 1;
  }

  get itemsOptsButtonDisabled(): boolean {
    return this.loading || !this.isSetIdProject || this.selectedReport?.length !== 1;
  }

  get copyGeneratedLinkButtonDisabled(): boolean {
    return !this.genLink;
  }

  get grType(): number {
    return this._grType;
  }
}
