<p-dialog header="Form template preview" [(visible)]="showDialog"
          [modal]="true" [style]="dialogStyle" [minY]="500" (onHide)="onHide()"
          [maximizable]="true" [baseZIndex]="8000">
  <div class="dialog-wrapper row no-gutters">
    <div class="col-12">
      <p-toolbar>
        <div class="p-toolbar-group-left">
          <button pButton type="button" icon="pi pi-file-pdf" (click)="print2pdf()"
                  [disabled]="isDisabledBtnPdf"
                  pTooltip="Generate PDF file"></button>
        </div>
      </p-toolbar>
    </div>

    <div class="col-12 padding: 2px;">
      <document-editor [showErrors]="false"></document-editor>
    </div>
  </div>
</p-dialog>

<!-- Popup for field definition  -->
<p-dialog header="{{popupHeader}}" [(visible)]="popupShow" [modal]="true"
          [style]="popupType === 'info' ? { 'max-width':'400px', 'width':'400px', 'max-height':'250px'} : { 'max-width':'800px', 'width':'800px'}"
          [baseZIndex]="12000">
  <div [innerHTML]="popupContent"></div>
</p-dialog>

