import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../_services/auth.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {
  minDate: Date;
  maxDate: Date;

  private _toggleable = false;
  private _grType = 0;

  constructor(private _authService: AuthService) {
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 21);
  }

  ngOnInit() {
  }

  get toggleable() {
		return this._toggleable;
  }

	get authService(): AuthService {
		return this._authService;
	}

	get grType() {
	  return this._grType;
  }
}
