import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  @Output() onSavedData = new EventEmitter<any>();


  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onSaveProperty(event: any) {
    this.router.navigate(['/home']);
  }

  onclose() {
    this.router.navigate(['/home']);
  }

}
