import {Component, Input, OnInit} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {LazyLoadEvent} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UIUtils} from '../../../_utils/ui-utils';
import {DataAdminService} from '../_services/data-admin.service';
import {finalize} from 'rxjs/operators';
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
  selector: 'app-adminlog-connects',
  templateUrl: './log-connects.component.html'
})
export class LogConnectsComponent implements OnInit {
  private _idUser: string;
  private _data: Pageable;
  private _loading: boolean = false;
  private _currentPage: number = 0;
  private _sorting: string;
  private _iduser: string = '';
  private _userInfo: any = {};
  private _filters: any = {};

  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService,
              private activatedRoute: ActivatedRoute) {
    this._data = new Pageable();
    this._iduser = this.activatedRoute.snapshot.paramMap.get('id') || '';
    if ((this._iduser && this._iduser.length) > 0) {
      this.loadUserData();
    }
  }

  ngOnInit() {
    this._loading = true;
  }

  @Input()
  set idUser(value: string) {
    this._idUser = value;
    if ((this._idUser && this._idUser.length) > 0) {
      this._iduser = this._idUser;
      this.loadUserData();
      this.handlePageChange({first: 0, rows: 0});
    }
  }

  private loadUserData() {
    this.dataService.getUser(this._iduser)
      .subscribe(data => {
        this._userInfo = data;
      }, error => {
        console.warn('ngOnInit', error);
      });
  }

  private loadData(filter?: string) {
    let search = UIUtils.getFilterStr(this._filters);
    if (this._iduser.length > 0) {
      search = `iduser:${this._iduser}` + (search.length > 0 ? `,${search}` : ``);
    }
    filter = (search.length > 0 ? (`?search=${search}`) : '?q=1') + (filter || '');
    this._loading = true;
    this.dataService.getEvtConnects(filter)
      .pipe(finalize(() => {
        this._loading = false;
      }))
      .subscribe(
        data => {
          this._data = data;
        },
        error => {
          this.messageService.showMessage(error, 'Error receiving data');
        }
      );
  }

  handleRowSelect(event) {
  }

  handlePageChange(event: LazyLoadEvent) {
    this._data.content = [];
    this._filters = event.filters;
    this._currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('&page=' + this._currentPage + this._sorting);
  }

  private reloadData() {
    this.loadData('?page=' + this._currentPage + (this._sorting || ''));
  }

  get isShowUserInformation(): boolean {
    return (this._iduser && this._iduser.length) === 0;
  }

  get tableHeader(): string {
    return this._iduser.length === 0 ? `Users connects` : `Connects of user "${this._userInfo.username}", ${this._userInfo.fullname}`;
  }

  get data(): Pageable {
    return this._data;
  }

  get loading(): boolean {
    return this._loading;
  }
}
