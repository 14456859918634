import {Injectable} from '@angular/core';
import {ConfigService} from '../../../_services/config.service';
import {HttpClient} from '@angular/common/http';
import {AppMessageService} from "../../../_services/app-message.service";

@Injectable()
export class BiDataUtilsService {
  constructor(private http: HttpClient,
              private messageService: AppMessageService,
              private configService: ConfigService) {
  }


  getBiParams(cdoc: string) {
    return this.http.get<any>(`${this.configService.api.bidata}/${cdoc}/cols`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  exportBiData(cdoc: string, _filter?: string, fields?: any, orderBy?: any, statusFilter?: any, options?: any) { // getQueryDocuments
    const prms = {params: fields, groupby: orderBy, statusfilter:statusFilter, options: options};
    return this.http.post<any>(`${this.configService.api.bidata}/${cdoc}/excel?` + (_filter || ''), prms)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });

  }

  getQueryBi(cdoc: string, _filter?: string, fields?: any, orderBy?: any, statusFilter?: any) { // getQueryDocuments
    const prms = {params: fields, groupby: orderBy, statusfilter:statusFilter};
    return this.http.post<any>(`${this.configService.api.bidata}/${cdoc}/data?` + (_filter || ''), prms)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }

  renewBi(cdoc: string) { // renew table
    return this.http.get<any>(`${this.configService.api.bidata}/${cdoc}/renew`)
      .toPromise()
      .catch(err => {
        this.messageService.showMessage(err);
      });
  }
}
