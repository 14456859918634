import {Injectable} from '@angular/core';
import {Base64} from 'js-base64';
import {v4 as uuid} from 'uuid';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class TokensStoreService {
  constructor() {
  }

  generateClient(): string {
    const s = uuid() + ":" + moment.now();
    console.log("generateClient", s);
    return Base64.encode(s);
  }

  getClientId(tokenId: string): string {
    let _tokens: any = this.getData();
    let _ret: string = '';
    for (let i in _tokens) {
      const r = _tokens[i];
      if (r.tokenId == tokenId) {
        _ret = r.clientId;
        break;
      }
    }
    return _ret;
  }

  save(tokenId: string, clientId: string) {
    let _tokens: any[] = this.getData();
    let _fnd: boolean = false;
    for (let i in _tokens) {
      const r = _tokens[i];
      if (r.tokenId == tokenId) {
        _fnd = true;
        _tokens[i].clientId = clientId;
        break;
      }
    }
    if (!_fnd) {
      _tokens.push({tokenId: tokenId, clientId: clientId});
    }
    this.setData(_tokens);
  }

  del(tokenId: string) {
    let _tokens: any = this.getData();
    for (let i in _tokens) {
      let r = _tokens[i];
      if (r.tokenId == tokenId) {
        _tokens.splice(i, 1);
        break;
      }
    }
    this.setData(_tokens);
  }

  private getData(): any[] {
    let _val: string = localStorage.getItem('anonimous.user.data.tokens');
    return _val && _val.length > 0 ? JSON.parse(_val) : [];
  }

  private setData(surveys: any) {
    localStorage.setItem('anonimous.user.data.tokens', JSON.stringify(surveys));
  }
}
