import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConfigService} from "./config.service";
import {AppMessageService} from "./app-message.service";
import * as FileSaver from 'file-saver';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) { }

  getRecord(code: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.download}/${code}/data`);
  }

  download(code: string) {
    this.getRecord(code)
      .subscribe(
        file => {
          this.http.get(`${this.configService.api.download}/${code}`, { responseType: 'blob' })
                           .subscribe(
                             data => {
                               FileSaver.saveAs(data, file.fname);
                             },
                             error => {
                               this.messageService.showMessage(error);
                             });
        },
        error => {
          this.messageService.showMessage(error);
        });
  }
}
