import {RichtextComponent} from "../richtext/richtext.component";
import {ViewContainerRef} from "@angular/core";

export function showDynamicComponent(self: any, data: any) {
  const viewContainerRef: ViewContainerRef = self.dynamicTemplate.viewContainerRef;
  viewContainerRef.clear();
  const factory = self.dynamicTemplate.componentFactoryResolver.resolveComponentFactory(RichtextComponent);
  const componentRef = viewContainerRef.createComponent<RichtextComponent>(factory);
  if (data) {
    for (const [key, value] of Object.entries(data)) {
      componentRef.instance[key] = value;
    }
  }
}
