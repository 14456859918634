import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {RegistrationConfirmComponent} from './registration-confirm/registration-confirm.component';
import {PanelModule} from 'primeng/panel';
import {ButtonModule} from 'primeng/button';
import {AuthGuard} from '../../_services/auth.guard';

const routes: Routes = [
  {path: 'confirm', component: RegistrationConfirmComponent, canActivate: [AuthGuard]}
];

@NgModule({
  declarations: [
    RegistrationConfirmComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PanelModule,
    ButtonModule
  ]
})
export class RegistrationModule {
}
