import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'project-document-record',
  templateUrl: './project-document-record.component.html',
  styleUrls: ['./project-document-record.component.css']
})
export class ProjectDocumentRecordComponent implements OnInit {
  @Output() onSavedData = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<void>();

  repForm: UntypedFormGroup;
  currentReport: any;
  itemsModes: SelectItem[] = [
    {value: 0, label: 'Form'},
    {value: 1, label: 'Wizard'}
  ];
  datamode = 0;
  private loading: boolean;
  private readonly DEFAULT_COLOR = '#8775a4';

  constructor(private dataService: ProjectUtilsService,
              private messageService: AppMessageService,
              private fb: UntypedFormBuilder) {
    this.repForm = fb.group({
      cdoc: [null, [Validators.required, Validators.maxLength(8)]],
      name: [null, [Validators.required, Validators.maxLength(1024)]],
      shortname: null,
      mode: null,
      docMode: [null, Validators.required],
      access: null,
      mainDoc: null,
      wizardType: null,
      style: fb.group({
        backgroundColorEnabled: false,
        backgroundColor: null,
        backgroundColorValue: [null, Validators.pattern('^#[a-zA-z0-9]{6}$')]
      })
    });
    const backgroundColor = this.repForm.get('style').get('backgroundColor');
    const backgroundColorValue = this.repForm.get('style').get('backgroundColorValue');
    backgroundColor.valueChanges.subscribe(v =>
      backgroundColorValue.setValue(v, {emitEvent: false})
    );
    backgroundColorValue.valueChanges.subscribe(v =>
      backgroundColor.setValue(v, {emitEvent: false})
    );
    this.repForm.get('style').get('backgroundColorEnabled').valueChanges.subscribe(v => {
      if (v) {
        backgroundColor.setValue(v ? this.DEFAULT_COLOR : null, {emitEvent: false})
        backgroundColorValue.setValue(v ? this.DEFAULT_COLOR : null, {emitEvent: false})
      }
    });

  }

  ngOnInit() {
  }

  create(idHierarchy: string) {
    this.repForm.reset();
    this.currentReport = {
      cdoc: '',
      name: '',
      shortname: '',
      idHierarchy: idHierarchy,
      mode: 1
    };
    this.repForm.get('cdoc').setValue(this.currentReport.cdoc);
    this.repForm.get('cdoc').enable();
    this.repForm.get('name').setValue(this.currentReport.name);
    this.repForm.get('name').enable();
    this.repForm.get('shortname').setValue(this.currentReport.shortname);
    this.repForm.get('mode').setValue(this.currentReport.mode);
    this.repForm.get('docMode').setValue(false);
    this.repForm.get('access').setValue(false);
    this.repForm.get('wizardType').setValue(false);
    this.repForm.get('style').setValue({backgroundColorEnabled: false, backgroundColor: '#ffffff', backgroundColorValue: '#ffffff'});
  }


  open(row: any) {
    this.repForm.reset();
    this.currentReport = row;
    this.datamode = this.currentReport.mode;
    this.repForm.get('cdoc').setValue(this.currentReport.cdoc);
    this.repForm.get('cdoc').disable();
    this.repForm.get('name').setValue(this.currentReport.name);
    this.repForm.get('shortname').setValue(this.currentReport.shortname);
    this.repForm.get('mode').setValue(this.currentReport.mode);
    this.repForm.get('docMode').setValue(0);
    this.repForm.get('access').setValue(this.currentReport.access === 1);
    this.repForm.get('mainDoc').setValue(this.currentReport.mainDoc === 1);
    this.repForm.get('wizardType').setValue(this.currentReport.wizardType === 1);
    if (this.currentReport.style?.hasOwnProperty('background-color')) {
      this.repForm.get('style').setValue({
        backgroundColorEnabled: true,
        backgroundColor: this.currentReport.style['background-color'],
        backgroundColorValue: this.currentReport.style['background-color']
      });
    } else {
      this.repForm.get('style').setValue({backgroundColorEnabled: false, backgroundColor: '#ffffff'});
    }
  }

  save() {
    if (this.currentReport == null) return;
    this.loading = true;
    const value = this.repForm.getRawValue();
    const isNew = this.currentReport.cdoc == '';

    const doc = {
      cdoc: value.cdoc,
      name: value.name,
      shortname: value.shortname,
      idHierarchy: this.currentReport.idHierarchy,
      periodType: 1,
      mode: value.mode,
      mainDoc: value.mainDoc ? 1 : 0,
      access: value.access ? 1 : 0,
      wizardType: value.mode === 1 && value.wizardType ? 1 : 0,
      style: value.style?.backgroundColorEnabled
        ? {"background-color": value.style?.backgroundColor}
        : null
    };

    this.dataService.saveDictDoc(isNew, doc)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        data => {
          //
          this.onSavedData.emit({action: 'save', data: doc});
          this.messageService.showSuccess('The information saved');
        },
        error => {
          this.messageService.showMessage(error);
        }
      );
  }

  close() {
    this.onClose.emit();
  }

  get saveButtonDisabled(): boolean {
    return this.loading || !this.repForm.valid;
  }

  get cancelButtonDisabled(): boolean {
    return this.loading;
  }
}
