<p-table #dt
         [value]="data.content"
         [totalRecords]="data.totalElements"
         [rows]="data.size"
         [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
         selectionMode="single"
         [paginator]="true" paginatorPosition="bottom"
         [resizableColumns]="true"
         [scrollable]="true" [scrollHeight]="'calc(100vh - 330px)'"
         sortField="dateRec" [sortOrder]="0">
  <ng-template pTemplate="caption">
    {{tableHeader}}
  </ng-template>
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col style="width:5rem;" *ngIf="isShowDocInformation">
      <col style="width:3rem;" *ngIf="isShowDocInformation">
      <col style="width:2rem;">
      <col style="width:3rem;">
      <col style="width:3rem;">
      <col style="width:4rem;">
      <col style="width:3rem;">
      <col style="width:3rem;">
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="iddoc" *ngIf="isShowDocInformation">ID doc<p-sortIcon field="iddoc"></p-sortIcon></th>
      <th pSortableColumn="cdoc" *ngIf="isShowDocInformation">Document code<p-sortIcon field="cdoc"></p-sortIcon></th>
      <th pSortableColumn="mode">Mode<p-sortIcon field="mode"></p-sortIcon></th>
      <th pSortableColumn="ipaddress">IP Address<p-sortIcon field="ipaddress"></p-sortIcon></th>
      <th pSortableColumn="countryCode">Country Code<p-sortIcon field="countryCode"></p-sortIcon></th>
      <th pSortableColumn="countryName">Country Name<p-sortIcon field="countryName"></p-sortIcon></th>
      <th pSortableColumn="cityName">City Name<p-sortIcon field="cityName"></p-sortIcon></th>
      <th pSortableColumn="dateRec">Date<p-sortIcon field="dateRec"></p-sortIcon></th>
    </tr>
    <tr>
      <th *ngIf="isShowDocInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'iddoc', 'eq')" />
      </th>
      <th *ngIf="isShowDocInformation">
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'cdoc', 'eq')" />
      </th>
      <th>
        <p-dropdown appendTo="body" [options]="items" [style]="{ 'min-width':'110px'  }"
                    (onChange)="dt.filter($event.value, 'mode', 'eq')"></p-dropdown>
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'ipaddress', 'eq')" />
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'countryCode', 'eq')" />
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'countryName', 'contains')" />
      </th>
      <th>
        <input pInputText type="text" [style.width]="'99%'"
               (change)="dt.filter($event.target.value, 'cityName', 'contains')" />
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row let-i="rowIndex">
    <tr [pSelectableRow]="row">
      <td *ngIf="isShowDocInformation">{{row.iddoc}}</td>
      <td *ngIf="isShowDocInformation">{{row.cdoc}}</td>
      <td>{{row.mode == 0?
            'Open':
             (row.mode == 1 ?
                  'Save' :
                  (row.mode == 2 ?
                      'Silent save' :
                      (row.mode == 3 ?
                          'Submit force' :
                          (row.mode == 4 ?
                            'Submit' :
                              (row.mode == 5 ?
                                  'Remove' :
                                  (row.mode == 6 ?
                                    'Accept' : (row.mode == 7 ? 'Decline' : '?'))))))) }}</td>
      <td>{{row.ipaddress}}</td>
      <td>{{row.countryCode}}</td>
      <td>{{row.countryName}}</td>
      <td>{{row.cityName}}</td>
      <td>{{row.dateRec}}</td>
    </tr>
  </ng-template>
</p-table>
