import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from '../../../_services/config.service';
import {StatUtilsService} from '../_services/stat-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-stat-dashboardForms',
  template: `
    <div class="dashboard">
      <div class="overview-boxes row">

        <div class="col-12 col-sm-3">
          <div class="overview-box overviewform-box-new">
            <div class="overview-box-count">
              <p-skeleton *ngIf="loading" height="100px" width="100%" styleClass="overview-box-count-load"></p-skeleton>
              <span *ngIf="!loading">{{ data.count_countries }}</span>
            </div>
            <div class="overview-box-title">
              <span>&nbsp;Countries</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-3">
          <div class="overview-box overviewform-box-new">
            <div class="overview-box-count">
              <p-skeleton *ngIf="loading" height="100px" width="100%" styleClass="overview-box-count-load"></p-skeleton>
              <span *ngIf="!loading">{{ data.count_cities }}</span>
            </div>
            <div class="overview-box-title">
              <span>&nbsp;Cities</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-3">
          <div class="overview-box overviewform-box-new">
            <div class="overview-box-count">
              <p-skeleton *ngIf="loading" height="100px" width="100%" styleClass="overview-box-count-load"></p-skeleton>
              <span *ngIf="!loading">{{ data.count_institutions }}</span>
            </div>
            <div class="overview-box-title">
              <span>&nbsp;Institutions</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-3">
          <div class="overview-box overviewform-box-new">
            <div class="overview-box-count">
              <p-skeleton *ngIf="loading" height="100px" width="100%" styleClass="overview-box-count-load"></p-skeleton>
              <span *ngIf="!loading">{{ data.count_patients }}</span>
            </div>
            <div class="overview-box-title">
              <span>&nbsp;Submissions</span>
            </div>
          </div>
        </div>
      </div>
    </div>`,
  styles: [`
    .dashboard .overview-boxes .overview-box {
      padding: 10px;
      color: #ffffff;
      min-height: 100px;
      height: 100%;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      border-radius: 4px;
    }

    .dashboard .overview-boxes .overview-box .overview-box-count {
      color: #E35855;
      font-size: 4rem;
      font-weight: bold;
      width: 100%;
      display: block;
      padding: 3px 0 1px 0;
    }

    .dashboard .overview-boxes .overview-box .overview-box-title {
      font-size: 2.0rem;
      font-weight: bold;
      width: 100%;
    }

    .dashboard .overview-boxes .overview-box.overviewform-box-new {
      background-color: #F1F3F5;
      color: #212529;
    }
  `]
})
export class StatSurveysDashboardComponent implements OnInit {
  @Input() grType: number;
  @Input() enableInit: boolean;

  data: any;
  loading = true;

  constructor(private messageService: AppMessageService,
              private configService: ConfigService,
              private dataService: StatUtilsService) {
    this.clear();
  }

  ngOnInit() {
    if (this.enableInit) this.refresh(null, null);
  }

  clear() {
    this.loading = true;
    this.data = {
      count_countries: 0,
      count_cities: 0,
      count_institutions: 0,
      count_patients: 0
    };
  }

  refresh(idProject?: string, grType?: number) {
    this.getSurveysStat(idProject, grType);
  }

  private getSurveysStat(cdoc: string, grtype: number) {
    this.loading = true;
    this.clear();
    this.dataService.getStatListSurveys(cdoc, grtype, "CountryISO3Code,MissionCity,InstitutionName,id")
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => this.calculateSurveysStat(data),
        error: (error) => {
          this.messageService.showError(error);
          console.log(error);
        }
      });
  }


  private calculateSurveysStat(data: any) {
    this.data.count_countries = 0;
    this.data.count_cities = 0;
    this.data.count_institutions = 0;
    this.data.count_patients = 0;

    for (let i in data) {
      let rec = data[i];
      if (data[i].name === 'CountryISO3Code')
        this.data.count_countries += rec.cnt;
      else if (data[i].name === 'MissionCity')
        this.data.count_cities += rec.cnt;
      else if (data[i].name === 'InstitutionName')
        this.data.count_institutions += rec.cnt;
      else if (data[i].name === 'id')
        this.data.count_patients += rec.cnt;
    }
  }


  get isMobileDevice() {
    return this.configService.isMobileDevice;
  }
}
