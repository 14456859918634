import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {map, retry} from 'rxjs/operators';
import * as Base64js from 'base64-js';
import {DataService} from './data.service';
import {AppMessageService} from '../../_services/app-message.service';
import {HttpEventType} from '@angular/common/http';
import {v4 as uuid} from 'uuid';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {
  @Input() private src: string;
  @Input() private iddoc: string;


  @Output()
  private onSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private onFailed: EventEmitter<any> = new EventEmitter<any>();
  visible: boolean;
  fileName: string;
  @ViewChild('fileCmp', {static: false})
  private fileCmp: FileUpload;
  /**
   * 0 - before uploading, 1 - uploading, 2 - succeed, 3 - failed
   */
  status: number;
  uploadProgress: number;

  constructor(private dataService: DataService,
              private messageService: AppMessageService) {
  }

  ngOnInit(): void {
  }

  show() {
    this.status = 0;
    this.uploadProgress = 0;
    this.visible = true;
    this.fileCmp.clear();
  }

  endsWith(str, suffix) {
    return str.toLowerCase().indexOf(suffix.toLowerCase(), str.length - suffix.length) !== -1;
  }

  fileUploader(event) {
    const fileList: FileList = event.files;
    if (fileList.length === 1) {
      const file: File = fileList[0];
      const fileType = file.type || 'application/octet-stream';
      this.fileName = file.name;
      this.status = 1;
      const idFile = uuid().replaceAll('-', '');
      this.dataService.publicDocBeforeUpload(this.iddoc, idFile, {name: file.name, type: fileType})
        .subscribe({
          next: (uploadId) => {
            const fr = new FileReader();
            fr.onload = () => {
              this.upload(this.iddoc, idFile, uploadId, <ArrayBuffer>fr.result);
            };
            fr.readAsArrayBuffer(file);
          },
          error: (error) => {
            this.messageService.showMessage(error, '[fileUploader]');
            this.status = 3;
            this.onFailed.emit();
          }
        });
    }
  }

  private async upload(idDoc: string, idFile: string, uploadId: string, fileData: ArrayBuffer) {
    const chunkSize = 5 * 1024 * 1024;
    let chunkInd = 1;
    const parts: string[] = [];
    let cntCompleted = 1;
    const fileLength = fileData.byteLength;
    const totalLength = fileLength * 1.35;
    let uploadedLength = 0;
    const chunkCnt = Math.ceil(fileLength / chunkSize);
    for (let offset = 0; offset < fileLength; offset += chunkSize) {
      const chunk = fileData.slice(offset, offset + chunkSize);
      let chunkLength: number;
      try {
        const partId = await lastValueFrom(
          this.dataService.publicDocUpload(idDoc, idFile, {
            uploadId: uploadId,
            data: Base64js.fromByteArray(new Uint8Array(chunk)),
            chunkNumber: cntCompleted
          }).pipe(
            retry(3),
            map((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  this.uploadProgress = Math.round((100 / totalLength) * (uploadedLength + event.loaded));
                  chunkLength = event.total;
                } else if (event.type === HttpEventType.Response) {
                  uploadedLength += chunkLength;
                  return event.body;
                }
              }
            )));
        parts.push(partId)
        if (cntCompleted === chunkCnt) {
          this.onSuccess.emit({
            uploadId: uploadId,
            name: this.fileName,
            idFile: idFile,
            parts: parts.join(',')
          })
        }
        cntCompleted++;
      } catch (error) {
        this.messageService.showMessage(error, '[fileUploader]');
        this.status = 3;
        this.onFailed.emit();
      }
      chunkInd++;
    }
  }

  finish(succeed: boolean) {
    this.uploadProgress = 100;
    this.status = succeed ? 2 : 3;
  }

}
