import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AuthService} from '../../../_services/auth.service';
import {Router} from '@angular/router';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {CustomButton, Hierarchy} from '../../../_domains/UITypes';
import {UIUtils} from '../../../_utils/ui-utils';
import {finalize} from 'rxjs/operators';
import {AppMessageService} from '../../../_services/app-message.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectUtilsService} from "../_services/project-utils.service";


@Component({
  selector: 'project-buttons',
  templateUrl: './project-buttons.component.html',
  styleUrls: ['./project-buttons.component.css']
})
export class ProjectButtonsComponent implements OnInit {
  @Output() onClose = new EventEmitter<void>();
  // @Output() onClose = new EventEmitter<void>();
  private _ButtonsEditForm: UntypedFormGroup;
  private _buttons: any;
  private _loading: boolean;
  private _currentButton: CustomButton = new CustomButton();
  private _currentPage: number = 0;
  private _sorting: string;
  private _tableFilter: any;
  private _itemsSplitButton: any[];

  data: Hierarchy = null;
  projectId = '-1';

  selected: CustomButton;
  // items: SelectItem[] = [];

  showDlgLabelRecord: boolean = false;

  constructor(private projectService: ProjectUtilsService,
              private authService: AuthService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private router: Router,
              private fb: UntypedFormBuilder,
              private translate: TranslateService) {
    this._buttons = [];
    this._ButtonsEditForm = fb.group({
      button: {value: '', disabled: true},
      blabel: {value: '', disabled: false},
      tooltip: {value: '', disabled: false},
      icon: [null, this.buttonIconValidator],
      lang: {value: 'en', disabled: true},
    });

  }

  repaintSplitButtons() {
    this._itemsSplitButton = [];

    this._buttons.forEach(element => {
        if (element.buttonType == 'split_button') {
          this._itemsSplitButton[element.button] = []
          if ((element.button == '/data/add') || (element.button == '/data/chart')) {
            this._itemsSplitButton[element.button].push({
              label: 'TEST0101',
              icon: 'pi pi-plus',
              command: () => this.emptyCommand('unk')
            });
            this._itemsSplitButton[element.button].push({
              label: 'TEST0201',
              icon: 'pi pi-copy',
              command: () => this.emptyCommand('unk')
            });
          }
        }
      }
    )

    this._buttons.forEach(element => {
        if (element.buttonType == 'detail_split_button') {
          const vals = element.button.split(':');
          if (vals.length === 2) {
            let splitDet = this.sanityObj(vals[1]);
            let splitPath = this.sanityObj(vals[0]);
            if ((splitPath !== undefined) && (splitDet !== undefined) && (splitDet !== '') && (splitDet !== '') && this._itemsSplitButton[splitPath] !== undefined) {
              this._itemsSplitButton[splitPath].push({
                // label: splitDet,
                label: element.blabel,
                icon: element.icon,
                command: () => this.emptyCommand(splitDet)
              });
            }
          }
        }
      }
    )

  }

  ngOnInit() {
  }



  refresh(data): void {
    this.data = data;
    this.projectId = data.id;
  }

  reload() {
    this._currentPage = 0;
    this.loadData('');
    this.selected = null;
    this._currentButton = new CustomButton();
    this._ButtonsEditForm.reset();
  }

  private loadData(filter?: string) {
    if ((this.projectId === '-1') || (this.projectId === undefined)) {
      return
    }
    filter = '?page=0&size=1000&' + (this._sorting || '');

    if (this._tableFilter) {
      let search = UIUtils.getFilterStr(this._tableFilter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    this._loading = true;
    this.projectService.getDictCustButtons(this.projectId, filter)
      .pipe(finalize(() => this._loading = false))
      .subscribe(
        data => {
          this._buttons = data.content;
          this.repaintSplitButtons();
        },
        error => {
          this.messageService.showMessage(error, 'Error receiving data');
        }
      );
  }


  openDlgLabelRecord(isCreate: boolean) {
    if (isCreate) {
      this._currentButton = new CustomButton();
      this._ButtonsEditForm.reset();
    } else {
      this._ButtonsEditForm.reset();
      this._ButtonsEditForm.get('button').setValue(this._currentButton.button);
      this._ButtonsEditForm.get('blabel').setValue(this._currentButton.blabel);
      this._ButtonsEditForm.get('tooltip').setValue(this._currentButton.tooltip);
      this._ButtonsEditForm.get('icon').setValue(this._currentButton.icon);
      this._ButtonsEditForm.get('lang').setValue(this._currentButton.lang);
    }
    this.showDlgLabelRecord = true;
  }


  handlePageChange(event: LazyLoadEvent) {
    this._buttons = [];
    this._tableFilter = event.filters;
    this._currentPage = 0;
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('');
  }

  handleRowSelect(event) {
    this.selected = event.data;
    this._currentButton = this.selected;
  }

  handleDblRowClick(event, row) {
    this.openDlgLabelRecord(false);
  }



  save(value: any) {
    if (this._currentButton == null) return;
    if (this._currentButton.button == null) return;
    this._loading = true;
    this._currentButton.idHierarchy = this.projectId;
    this._currentButton.id = this.currentButton.id;
    this._currentButton.button = this.currentButton.button;
    this._currentButton.blabel = value.blabel;
    this._currentButton.icon = value.icon;
    this._currentButton.tooltip = value.tooltip;

    this.projectService.saveCustButton(this.projectId, this._currentButton)
      .pipe(finalize(() => this._loading = false))
      .subscribe(
        data => {
          this.showDlgLabelRecord = false;
          if ((data?.status < 0) && (data?.message !== undefined)) {
            this.messageService.showError(data?.message);
          } else if ((data?.status < 0) && (data?.message === undefined)) {
            this.messageService.showError(this.translate.instant('message.error'));
          } else {
            this.messageService.showSuccess(this.translate.instant('message.user.save.success'));
          }
          this.reload();
          this._loading = false;
        },
        error => {
          this.showDlgLabelRecord = false;
          this.messageService.showMessage(error, 'Error');
          this._loading = false;
        }
      );
  }

  delete() {
    if (this._currentButton == null || this._currentButton.button == null) return;
    this._loading = true;
    this.confirmationService.confirm({
      message: this.translate.instant('message.user.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.projectService.deleteCustButton(this._currentButton.id)
          .pipe(finalize(() => this._loading = false))
          .subscribe(
            data => {
              this.reload();
              this.messageService.showSuccess(this.translate.instant('message.user.delete.success'));
            },
            error => {
              this.messageService.showMessage(error, this.translate.instant('message.header.error'));
            }
          );
      },
      reject: () => {
      }
    });
  }

  private buttonIconValidator(control: UntypedFormControl): ValidationErrors {
    const value = control.value;
    if (value) {
      // fas fa-share-square
      var re = /^(far|fas|fab|fa|pi)\s{1,}(fa\-|pi\-)\w{1,}|\-$/ig;
      var found = value.match(re);
      if (found == null) {
        return {invalidIcon: 'You have to select an icon'};
      } else if (found.length < 1) {
        return {invalidIcon: 'You have to select an icon'};
      }
    } else {
      // return {invalidIcon: 'You have to select an icon'};
      return null;
    }
    return null;
  }

  get buttons(): any[] {
    return this._buttons;
  }

  get loading(): boolean {
    return this._loading;
  }

  get ButtonsEditForm(): UntypedFormGroup {
    return this._ButtonsEditForm;
  }


  get currentButton(): CustomButton {
    return this._currentButton;
  }


  sanityObj(str) {
    return str.replace(new RegExp('\'|\}|\{"|', 'g'), '').trim();
  }




  get saveButtonDisabled(): boolean {
    return this.loading || !this._ButtonsEditForm.valid;
  }

  get cancelButtonDisabled(): boolean {
    return this.loading;
  }


  emptyCommand(s: string) {
    console.log('emptyCommand', s)
    // this.onClose.emit();
  }

  itemsSplitButton(s: string) {
    return this._itemsSplitButton[s];
  }

  rowStyleClass(data) {

    if (data?.customized > 0) {
      return 'button-customized';
    }

    return 'button-not-customized';
  }

}
