import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {DataAdminService} from '../../admin/_services/data-admin.service';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {finalize, pairwise} from 'rxjs/operators';
import {AccessUtils, cloneAbstractControl, DocRole, UIUtils} from '../../../_utils/ui-utils';
import {Pageable} from '../../../_domains/spring/pageable';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {Table} from 'primeng/table';
import {TranslateService} from '@ngx-translate/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import {User} from '../../../_domains/UITypes';
import {ConfigService} from "../../../_services/config.service";

@Component({
  selector: 'project-users',
  templateUrl: './project-users.component.html',
  providers: [DataAdminService]
})
export class ProjectUsersComponent implements AfterViewInit {
  @Input() idProject = '';
  @Input() grType = 0;
  @Input() workflow!: any;

  private currentPage: number = 0;
  private sorting: string = '&sort=user.username,asc&sort=cdoc,asc';
  loading = true;
  selected: any[] = [];
  editUserVisible: boolean;

  projects: Pageable = new Pageable();
  private levelsAll: SelectItem[] = [
    {label: 'Document', value: 0},
    {label: 'Organization', value: 1},
    {label: 'Only user', value: 2},
  ];
  levels: SelectItem[];
  showDlgUsers: boolean = false;
  @ViewChild('dataTable', {static: false})
  private dataTable: Table;
  userData: any = {};
  editRowData: any = {};
  docsList: any[] = [];
  cdocsList: string[] = [];
  readonly form: UntypedFormGroup;
  private readonly allListValue = [{value: '0', label: 'All'}];
  private readonly docAccForm: UntypedFormGroup;
  filteredOrgs: any[];
  isSurvey = false;
  private mode!: string;
  showDlgUserVariables = false;
  userVariables!: string;
  readonly UserAddType = UserAddType;
  private addType: UserAddType;
  usersTotal!: number;

  constructor(private dataService: DataAdminService,
              private projectService: ProjectUtilsService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private fb: UntypedFormBuilder,
              public configService: ConfigService) {
    this.docAccForm = fb.group({
      cdoc: '0',
      mode: null,
      viewAcc: 0,
      editAcc: 0,
      view: false,
      create: false,
      write: false,
      remove: false,
      reopen: false,
      submit: false,
      admin: false,
      upload: false,
      download: false,
      fileUpload: false,
      fileView: false,
      print: true,
      recordInfo: true
    });
    this.docAccForm.valueChanges
      .subscribe(v => {
        const permissions = this.form.get('permissions') as UntypedFormArray;
        permissions.controls.forEach((control, ind) => {
          if (ind !== 0) {
            const val = {...v};
            delete val['cdoc'];
            control.patchValue(val);
          }
        });
      });
    this.form = fb.group({
      org: null,
      docs: null,
      currDoc: '0',
      permissions: fb.array([this.docAccForm]),
      projectAdmin: false
    });
    const {docs} = this.form.controls;
    docs.valueChanges.pipe(pairwise()).subscribe(([oldValue, newValue]) => {
      if (!newValue || !oldValue) {
        return;
      }
      this.userData.docs = [...this.allListValue, ...newValue];
      const {currDoc, permissions} = this.form.controls;
      currDoc.setValue('0');
      const diffAdd = newValue.filter(x => !oldValue.includes(x));
      const diffDel = oldValue.filter(x => !newValue.includes(x));
      for (const cdoc of diffDel) {
        const ind = (permissions as UntypedFormArray).controls.findIndex(v => v.value.cdoc === cdoc);
        if (ind >= 0) {
          (permissions as UntypedFormArray).removeAt(ind);
        }
      }
      for (const cdoc of diffAdd) {
        const form = cloneAbstractControl(this.docAccForm);
        form.patchValue({cdoc: cdoc});
        (permissions as UntypedFormArray).push(form);
      }
      if (diffAdd.length) {
        currDoc.setValue(diffAdd[0]);
      } else if (newValue.length) {
        currDoc.setValue(newValue[0]);
      } else {
        currDoc.setValue('0');
      }
    });
  }

  ngAfterViewInit(): void {
    this.refresh();
  }

  private resetAccForm() {
    this.form.reset();
    const {docs, currDoc, permissions, projectAdmin} = this.form.controls;
    docs.reset();
    currDoc.setValue('0');
    (permissions as UntypedFormArray).clear();
    const docForm = this.docAccForm;
    docForm.patchValue({
      cdoc: '0',
      mode: null,
      viewAcc: 0,
      editAcc: 0,
      view: false,
      create: false,
      write: false,
      remove: false,
      reopen: false,
      submit: false,
      admin: false,
      upload: false,
      download: false,
      fileUpload: false,
      fileView: false,
      print: true,
      recordInfo: true
    }, {emitEvent: false});
    (permissions as UntypedFormArray).push(docForm);
    projectAdmin.setValue(false);
  }

  private loadDocsData() {
    this.docsList = [];
    this.cdocsList = [];
    if (this.idProject) {
      const observer = {
        next: (data: any) => {
          this.docsList = data.content;
          this.levels = this.grType === 2 ? [this.levelsAll[0]] : this.levelsAll;
          this.cdocsList = this.docsList.map(v => v.cdoc);
        },
        error: (err: Error) => console.error(err)
      };
      this.dataService.getListDictDocs(`?page=0&size=1000&sort=cdoc,asc&search=idHierarchy:${this.idProject}`)
        .subscribe(observer);
    }
  }

  refresh() {
    let filter: string = '';
    this.currentPage = 0;
    filter = `page=${this.currentPage || '0'}`;
    filter += this.sorting;
    const search: string = UIUtils.getFilterStrNew(this.dataTable.filters);
    if (search.length > 0) {
      filter += `&search=${search}`;
    }
    this.isSurvey = this.grType === 2;
    if (this.grType !== 2) {
      this.form.get('org').setValidators(this.UserOrgValidator);
    } else {
      this.form.get('org').clearValidators();
    }
    this.form.get('org').updateValueAndValidity();
    this.loadDocsData();
    this.loadData(filter);
  }

  private resetFilter() {
    for (const key of Object.keys(this.dataTable.filters)) {
      this.dataTable.filters[key][0].value = null;
    }
  }

  private loadData(filter: string) {
    if (this.idProject.length > 0) {
      this.selected = [];
      this.usersTotal = null;
      this.loading = true;
      this.dataService.getProjectUsersAcc(this.idProject, filter)
        .pipe(finalize(() => this.loading = false))
        .subscribe({
          next: (res) => {
            const data = res.page
            this.usersTotal = res.usersTotal;
            for (const i in data.content) {
              data.content[i].isChanged = false;
              data.content[i].accessView = (data.content[i].acc & AccessUtils.VIEW) != 0;
              data.content[i].accessCreate = (data.content[i].acc & AccessUtils.CREATE) != 0;
              data.content[i].accessWrite = (data.content[i].acc & AccessUtils.WRITE) != 0;
              data.content[i].accessRemove = (data.content[i].acc & AccessUtils.REMOVE) != 0;
              data.content[i].accessReopen = (data.content[i].acc & AccessUtils.REOPEN) != 0;
              data.content[i].accessSubmit = (data.content[i].acc & AccessUtils.SUBMIT) != 0;
              data.content[i].accessDocumentAdmin = (data.content[i].acc & AccessUtils.DOCUMENT_ADMIN) != 0;
              data.content[i].accessProjectAdmin = (data.content[i].acc & AccessUtils.PROJECT_ADMIN) != 0;
              data.content[i].roleUpload = (data.content[i].roles & DocRole.UPLOAD) !== 0;
              data.content[i].roleDownload = (data.content[i].roles & DocRole.DOWNLOAD) !== 0;
              data.content[i].roleFileUpload = (data.content[i].roles & DocRole.FILE_UPLOAD) !== 0;
              data.content[i].roleFileView = (data.content[i].roles & DocRole.FILE_VIEW) !== 0;
              data.content[i].rolePrint = (data.content[i].roles & DocRole.PRINT) !== 0;
              data.content[i].roleRecordInfo = (data.content[i].roles & DocRole.RECORD_INFO) !== 0;
            }
            this.projects = data;
          },
          error: (error) => this.messageService.showMessage(error)
        });
    }
  }

  handlePageChange(event) {
    let filter: string = '', search: string = UIUtils.getFilterStrNew(event.filters);
    this.currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}&sort=cdoc,asc`;
    filter = `page=${this.currentPage || '0'}`;
    filter += this.sorting;
    if (search.length > 0) {
      filter += `&search=${search}`;
    }
    this.loadData(filter);
  }

  private checkProcessDone(cntAll: number, cnt: number): boolean {
    return cntAll === cnt;
  }

  beforeAdd(type: UserAddType) {
    if (type === UserAddType.CLONE && this.selected?.length !== 1) {
      return;
    }
    this.addType = type;
    this.showDlgUsers = true;
  }

  onRowDelete() {
    if (!this.selected?.length) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('message.project.user.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.loading = true;
        const cntAll = this.selected.length;
        let cnt = 0;
        for (const row of this.selected) {
          this.dataService.deleteAccessUserProjects(row.idUser, this.idProject)
            .pipe(finalize(() => {
              cnt++;
              if (this.checkProcessDone(cntAll, cnt)) {
                this.messageService.showSuccess(this.translate.instant('message.project.user.delete.success'));
                this.resetFilter();
                this.refresh();
              }
            }))
            .subscribe(_ => null,
              error => this.messageService.showMessage(error)
            );
        }
      }
    });
  }

  private initUserAccForm(data: any[]) {
    const {docs, currDoc, projectAdmin} = this.form.controls;
    docs.setValue(data.map(v => v.cdoc));
    this.userData.docs = [...this.allListValue, ...data.map(v => ({value: v.cdoc, label: v.cdoc}))];
    for (const item of data) {
      const accForm = cloneAbstractControl(this.currDocAccForm, true);
      accForm.setValue({
        cdoc: item.cdoc,
        mode: this.docsList.find(v => v.cdoc === item.cdoc)?.mode,
        viewAcc: item.viewAcc,
        editAcc: item.editAcc,
        view: (item.acc & AccessUtils.VIEW) !== 0,
        create: (item.acc & AccessUtils.CREATE) !== 0,
        write: (item.acc & AccessUtils.WRITE) !== 0,
        remove: (item.acc & AccessUtils.REMOVE) !== 0,
        reopen: (item.acc & AccessUtils.REOPEN) !== 0,
        submit: (item.acc & AccessUtils.SUBMIT) !== 0,
        admin: (item.acc & AccessUtils.DOCUMENT_ADMIN) !== 0,
        upload: (item.roles & DocRole.UPLOAD) !== 0,
        download: (item.roles & DocRole.DOWNLOAD) !== 0,
        fileUpload: (item.roles & DocRole.FILE_UPLOAD) !== 0,
        fileView: (item.roles & DocRole.FILE_VIEW) !== 0,
        print: (item.roles & DocRole.PRINT) !== 0,
        recordInfo: (item.roles & DocRole.RECORD_INFO) !== 0
      });
      (this.form.get('permissions') as UntypedFormArray).push(accForm);
      projectAdmin.setValue((item.acc & AccessUtils.PROJECT_ADMIN) !== 0);
    }
    currDoc.setValue(docs.value.length ? docs.value[0] : '0');
  }

  onRowEditInit(row: any) {
    this.editRowData = {};
    if (!row?.id) {
      return;
    }
    this.editRowData = {...row};
  }

  beforeEditVariables() {
    if (this.selected?.length !== 1) {
      return;
    }

    this.loading = true;
    const row = this.selected[0];
    this.userData = {idUser: row.idUser, username: row.user.username, fullname: row.user.fullname};
    this.userVariables = '';
    this.dataService.getUserVariables(this.idProject, this.userData.idUser)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => {
          let vars = '';
          if (data) {
            for (const key of Object.keys(data)) {
              const val = data[key];
              vars += `${key}=${isNaN(val) ? ('"' + val + '"') : val};`
            }
            this.userVariables = vars;
          }
          this.showDlgUserVariables = true;
        },
        error: (error) => this.messageService.showMessage(error)
      });
  }

  saveVariables() {
    this.loading = true;
    this.dataService.saveUserVariables(this.idProject, this.userData.idUser, this.userVariables)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data) => {
          this.showDlgUserVariables = false;
          this.selected = [];
          this.messageService.showSuccess(this.translate.instant('message.success'));
        },
        error: (error) => this.messageService.showMessage(error)
      });
  }

  beforeEditRow() {
    if (this.selected?.length !== 1) {
      return;
    }
    this.mode = 'edit';
    this.editRow(this.selected[0]);
  }

  editRow(row) {
    this.loading = true;
    const observer = {
      next: (data: any[]) => {
        this.resetAccForm();
        if (this.mode === 'edit') {
          // for mode === 'add' userData filled in the onUserSelect method
          this.userData = {idUser: row.idUser, username: row.user.username, fullname: row.user.fullname};
        }
        this.initUserAccForm(data);
        if (data?.length > 0 && data[0].idOrg) {
          this.projectService.getOrganization(this.idProject, data[0].idOrg)
            .subscribe({
                next: (org) => this.form.get('org').setValue({id: org.id, name: org.name}),
                error: (error) => this.messageService.showMessage(error, '[loadOrgs]')
              }
            );
        }
        this.editUserVisible = true;
      },
      error: (err: Error) => this.messageService.showMessage(err)
    };
    this.dataService.getProjectOneUserAcc(this.idProject, row.idUser)
      .pipe(finalize(() => this.loading = false))
      .subscribe(observer);
  }

  save(formVal: any) {
    const data = [];
    formVal.permissions.forEach(v => {
      if (v.cdoc === '0') {
        return;
      }
      const acc = (v.view && AccessUtils.VIEW || 0)
        + (v.create && AccessUtils.CREATE || 0)
        + (v.write && AccessUtils.WRITE || 0)
        + (v.remove && AccessUtils.REMOVE || 0)
        + (v.reopen && AccessUtils.REOPEN || 0)
        + (v.submit && AccessUtils.SUBMIT || 0)
        + (v.admin && AccessUtils.DOCUMENT_ADMIN || 0)
        + (formVal.projectAdmin && AccessUtils.PROJECT_ADMIN || 0);
      const roles = (v.download && DocRole.DOWNLOAD || 0)
        + (v.upload && DocRole.UPLOAD || 0)
        + (v.fileUpload && DocRole.FILE_UPLOAD || 0)
        + (v.fileView && DocRole.FILE_VIEW || 0)
        + (v.print && DocRole.PRINT || 0)
        + (v.recordInfo && DocRole.RECORD_INFO || 0)
      ;
      data.push({
        idOrg: formVal.org?.id,
        cdoc: v.cdoc,
        access: acc,
        viewAcc: v.viewAcc,
        editAcc: v.editAcc,
        roles: roles
      })
    });
    this.loading = true;
    this.dataService.updUserAccess2Project(this.idProject, this.userData.idUser, data)
      .subscribe(data => {
          this.messageService.showSuccess(this.translate.instant('message.project.user.save.success'));
          this.loading = false;
          this.editUserVisible = false;
          this.selected = [];
          if (this.mode === 'add') {
            this.resetFilter();
          }
          this.refresh();
        },
        error => {
          this.loading = false;
          this.messageService.showMessage(error);
        });
  }

  onRowEditSave() {
    if (!this.editRowData?.id) {
      return;
    }
    const acc = (this.editRowData.accessView && AccessUtils.VIEW || 0)
      + (this.editRowData.accessCreate && AccessUtils.CREATE || 0)
      + (this.editRowData.accessWrite && AccessUtils.WRITE || 0)
      + (this.editRowData.accessRemove && AccessUtils.REMOVE || 0)
      + (this.editRowData.accessReopen && AccessUtils.REOPEN || 0)
      + (this.editRowData.accessSubmit && AccessUtils.SUBMIT || 0)
      + (this.editRowData.accessDocumentAdmin && AccessUtils.DOCUMENT_ADMIN || 0);
    const roles = (this.editRowData.roleDownload && DocRole.DOWNLOAD || 0)
      + (this.editRowData.roleUpload && DocRole.UPLOAD || 0)
      + (this.editRowData.roleFileUpload && DocRole.FILE_UPLOAD || 0)
      + (this.editRowData.roleFileView && DocRole.FILE_VIEW || 0)
      + (this.editRowData.rolePrint && DocRole.PRINT || 0)
      + (this.editRowData.recordInfo && DocRole.RECORD_INFO || 0);
    const data = {
      access: acc,
      viewAcc: this.editRowData.viewAcc,
      editAcc: this.editRowData.editAcc,
      roles: roles
    };
    this.loading = true;
    const observer = {
      next: _ => {
        this.messageService.showSuccess(this.translate.instant('message.project.user.save.success'));
        this.refresh();
      },
      error: (err: Error) => this.messageService.showMessage(err)
    };
    this.dataService.updUserAccess2ProjectDocument(this.idProject, this.editRowData.cdoc, this.editRowData.idUser, this.editRowData.id, data)
      .pipe(finalize(() => this.loading = false))
      .subscribe(observer);
  }

  handleFilterOrgs(event: any) {
    const query = event.query;
    this.projectService.getOrganizations(this.idProject, query)
      .subscribe(
        data => {
          this.filteredOrgs = data;
        }
      );
  }

  onUserSelect(user: User) {
    this.mode = 'add';
    this.resetAccForm();
    this.userData = {idUser: user.id, username: user.username, fullname: user.fullname};
    if (this.addType === UserAddType.NEW) {
      this.initUserAccForm([]);
      this.editUserVisible = true;
    } else {
      this.editRow(this.selected[0]);
    }
  }

  private UserOrgValidator(control: UntypedFormControl): ValidationErrors {
    const value = control.value;
    if (!value?.id) {
      return {invalidOrg: 'You have to select an organization'};
    }
    return null;
  }

  get currDocAccForm(): UntypedFormGroup {
    const {currDoc, permissions} = this.form.controls;
    return (permissions as UntypedFormArray).controls.find(v => v.value.cdoc === (currDoc.value || '0')) as UntypedFormGroup;
  }

  get docSavedVisible(): boolean {
    return !this.isSurvey
  }

  get docSubmittedVisible(): boolean {
    return !this.isSurvey && (
      (this.workflow?.lvl0Acc & AccessUtils.SUBMIT) != 0
      || (this.workflow?.lvl1Acc & AccessUtils.SUBMIT) != 0
      || (this.workflow?.lvl2Acc & AccessUtils.SUBMIT) != 0
    );
  }

  get editButtonDisabled(): boolean {
    return this.loading || this.selected?.length !== 1;
  }

  get variablesButtonDisabled(): boolean {
    return this.loading || this.selected?.length !== 1;
  }

  get deleteButtonDisabled(): boolean {
    return this.loading || !this.selected?.length;
  }

  get saveButtonDisabled(): boolean {
    return this.loading || !this.form.valid;
  }

  get closeButtonDisabled(): boolean {
    return this.loading;
  }

  get saveVarsButtonDisabled(): boolean {
    return this.loading;
  }

  get closeVarsButtonDisabled(): boolean {
    return this.loading;
  }

}

enum UserAddType {
  NEW, CLONE
}
