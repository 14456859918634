<div>
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="pi pi-plus"
              (click)="openDlgLabelRecord(true)" pTooltip="Add new tag"></button>
      <button pButton type="button" icon="pi pi-pencil"
              [disabled]="selected == null" (click)="openDlgLabelRecord(false)" pTooltip="Edit the tag"></button>
      <button pButton type="button" icon="pi pi-trash"
              [disabled]="selected == null || selected.label.indexOf('DOC:')!==-1" (click)="delete()"
              pTooltip="Remove the tag"></button>

    </div>
  </p-toolbar>

  <p-table #dt
           [value]="labels.content"
           [totalRecords]="labels.totalElements" [rows]="labels.size"
           [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading"
           (onRowSelect)="handleRowSelect($event)" selectionMode="single" [selection]="selected"
           [paginator]="true" paginatorPosition="bottom"
           [resizableColumns]="true"
           [scrollable]="true" [scrollHeight]="'calc(100vh - 250px)'"
           sortField="label" [sortOrder]="1">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col/>
        <col/>
        <col/>
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="label">Tag
          <p-sortIcon field="label"></p-sortIcon>
        </th>
        <th pSortableColumn="name">Description
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="severity">Style
          <p-sortIcon field="severity"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'label', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'name', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'severity', 'contains')"/>

        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row" (dblclick)="handleDblRowClick($event, row)"
          class="tag-success">

        <td>
          <p-tag styleClass="mr-2 project-label" *ngIf="row.severity.indexOf(':') === -1" severity="{{row.severity}}"
                 icon="pi {{row.severity==='danger'?'pi-times':row.severity==='success'?'pi-check':row.severity==='warning'?'pi-exclamation-triangle':'pi-info-circle'}}"
                 [rounded]="true" value="{{row.label}}"></p-tag>
          <p-tag styleClass="mr-2 project-label" *ngIf="row.severity.indexOf(':') !== -1"
                 [style]="getclassOfLabel(row.severity)" [icon]="getIconOfLabel(row.severity)" [rounded]="true"
                 value="{{row.label}}"></p-tag>
        </td>

        <td>{{row.name}}</td>
        <td>{{row.severity}}</td>

      </tr>
    </ng-template>
  </p-table>

  <p-dialog header="Label details" [(visible)]="showDlgLabelRecord" [closable]="false"
            (onHide)="op.hide()"
            [modal]="true" [style]="{ minWidth: '800px' }" [maximizable]="false" [baseZIndex]="10000">
    <form [formGroup]="labelForm" (ngSubmit)="save(labelForm.value)">
      <div class="p-fluid">
        <div class="row form-group">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Label</div>
          <div class="col-8">
            <input pInputText formControlName="label" class="form-control" type="text" placeholder="Required"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="labelForm.get('label')"></app-show-error>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-4">
            Color
            <i class="pi pi-question-circle info"
               (click)="op.toggle($event)"></i>
            <p-overlayPanel #op [showCloseIcon]="false" [style]="{width: '450px'}">
              <ng-template pTemplate>
                <pre>
put there <b>info, success, warning, danger</b>
or style like
<b>&nbsp;color: #FFFFFF, background-color: red, icon: pi pi-info</b>
                  </pre>
              </ng-template>
            </p-overlayPanel>
          </div>
          <div class="col-8">
            <input pInputText formControlName="severity" class="form-control" type="text" placeholder="Required"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-show-error [control]="labelForm.get('severity')"></app-show-error>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-4"><span class="field-required">*&nbsp;</span>Description</div>
          <div class="col-8">
            <input pInputText formControlName="name" class="form-control" type="text" placeholder="Required"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-show-error [control]="labelForm.get('name')"></app-show-error>
          </div>
        </div>

      </div>

      <div class="row float-right">
        <div class="col-12">
          <button pButton type="submit" icon="pi pi-save" label="Save"
                  [disabled]="saveButtonDisabled"></button>
          <button pButton type="button" icon="pi pi-times"
                  [disabled]="cancelButtonDisabled"
                  (click)="showDlgLabelRecord = false" label="Close"></button>
        </div>
      </div>
    </form>
  </p-dialog>

  <!--Confirm dialog-->
  <p-confirmDialog></p-confirmDialog>
</div>
