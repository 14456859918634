<div class="layout-topbar nologin" [class.page-home]="!isPublic">
  <div class="site-name" *ngIf="isPublic">
    <a routerLink="">
      <img ngSrc="/assets/images/logo.png" height="100" width="120" alt="City Cancer Challenge">
    </a>
  </div>
  <div class="topbar-right">
    <ul *ngIf="!isPublic">
      <li>
        <a href="/saml/login" title="Sign In" class="sign-in">
          Sign In
          <i class="pi pi-arrow-right"></i>
        </a>
      </li>
    </ul>
  </div>
</div>

<p-confirmDialog [baseZIndex]="11000"></p-confirmDialog>
