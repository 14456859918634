import moment from 'moment';

export class DateUtils {
	static formatDate(date: Date, fmt?:string): string {
		if (typeof date === 'object' && moment.isDate(date)) {
			return moment(date).format(fmt || 'YYYY-MM-DD HH:mm:ss');
		}
		return null;
	}

	static date2Str(date: Date, fmt?: string): string {
		return DateUtils.formatDate(date, fmt || 'DD-MM-YYYY');
	}
}
