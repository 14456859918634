import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from 'src/app/_services/config.service';
import {Observable} from 'rxjs';
import {UIUtils, UserConnectInfo} from 'src/app/_utils/ui-utils';
import {AppMessageService} from '../../../_services/app-message.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Injectable()
export class DocumentUtilsPublicService {
  constructor(private http: HttpClient,
              private messageService: AppMessageService,
              private translate: TranslateService,
              private router: Router,
              private configService: ConfigService) {
  }

  save(iddoc: string, vars: any, data: any, logs: any) {
    const user: UserConnectInfo = UIUtils.getUserConnectInfo();
    const json: any = {
      'iddoc': iddoc,
      'data': data,
      'logs': logs,
      'ipaddress': user.ip_address,
      'countryCode': user.countryCode,
      'countryName': user.countryName,
      'city': user.city,
      'dataVars': JSON.stringify(vars)
    };
    return this.http.put<any>(this.configService.api.docutl.public.save, json);
  }

  saveWithoutValidate(iddoc: string, vars: any, data: any) {
    const user: UserConnectInfo = UIUtils.getUserConnectInfo();
    const json: any = {
      'iddoc': iddoc,
      'data': data,
      'logs': [],
      'ipaddress': user.ip_address,
      'countryCode': user.countryCode,
      'countryName': user.countryName,
      'city': user.city,
      'dataVars': JSON.stringify(vars)
    };
    return this.http.put<any>(`${this.configService.api.docutl.public.save}/without_check`, json);
  }

  check(iddoc: string, cdoc: string, data: string, logs: any) {
    const user: UserConnectInfo = UIUtils.getUserConnectInfo();
    const json: any = {
      'cdoc': cdoc,
      'data': data,
      'logs': logs,
      'ipaddress': user.ip_address,
      'countryCode': user.countryCode,
      'countryName': user.countryName,
      'city': user.city
    };
    return this.http.post<any>(this.configService.api.docutl.public.check, json);
  }

  submit(iddoc: string, vars: any, data: any, logs: any) {
    const user: UserConnectInfo = UIUtils.getUserConnectInfo();
    const json: any = {
      'iddoc': iddoc,
      'data': data,
      'logs': logs,
      'ipaddress': user.ip_address,
      'countryCode': user.countryCode,
      'countryName': user.countryName,
      'city': user.city,
      'dataVars': JSON.stringify(vars)
    };
    return this.http.put<any>(this.configService.api.docutl.public.send, json);
  }

  submitForce(iddoc: string) {
    const user: UserConnectInfo = UIUtils.getUserConnectInfo();
    const json: any = {
      'iddoc': iddoc,
      'iduser': '0',
      'ipaddress': user.ip_address,
      'countryCode': user.countryCode,
      'countryName': user.countryName,
      'city': user.city
    };
    return this.http.post<any>(`${this.configService.api.docutl.public.send}/force`, json);
  }

  isExistReport(id: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.docutl.public.isExists}/${id}`);
  }

  createCheck(cdoc: string): Observable<any> {
    return this.http.post<any>(this.configService.api.docutl.public.createCheck, cdoc);
  }

  createEmptyReport(cdoc: string): Observable<string> {
    return this.http.post(this.configService.api.docutl.public.createEmpty, cdoc, {responseType: 'text'});
  }

  getDocumentStatus(iddoc: string, cdoc?: string) {
    let user: UserConnectInfo = UIUtils.getUserConnectInfo();
    let query: string = `ipaddress=${user.ip_address}&countryCode=${user.countryCode}&countryName=${user.countryName}&city=${user.city}&cdoc=${cdoc}`;

    return this.http.get<any>(`${this.configService.api.docutl.public.status}/${iddoc}?${query}`)
      .toPromise()
      .catch(error => {
        if (error.status === 404) {
          const v: string = this.translate.instant('message.document.notFound');
          this.router.navigate(['/public/error'], {queryParams: {v: v}, skipLocationChange: true});
        } else {
          this.messageService.showMessage(error);
        }
      });
  }

  getJSONData(iddoc: string, params?: any): Observable<any> {
    let user: UserConnectInfo = UIUtils.getUserConnectInfo();
    let query: string = `ipaddress=${user.ip_address}&countryCode=${user.countryCode}&countryName=${user.countryName}&city=${user.city}`;

    return this.http.post<any>(`${this.configService.api.docutl.public.datajson}/${iddoc}?${query}`, params);
  }

  getToken(iddoc: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.docutl.public.access_token}/${iddoc}/obtain`);
  }

  checkClientToken(tokenId: string, clientId: string): Observable<any> {
    return this.http.put<any>(`${this.configService.api.docutl.public.access_token}/validate?token_id=${tokenId}&client_id=${clientId}`, null);
  }

  getFileLink(iddoc: string, id: string): Observable<string> {
    return this.http.get(`${this.configService.api.fs}/public/docs/${iddoc}/files/${id}/link`, {responseType: 'text'});
  }
}
