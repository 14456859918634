import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


import {DocumentUtilsPrivateService} from "../../doceditor/_services/document-utils-private.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {AuthService} from "../../../_services/auth.service";
import {DocStage} from "../../../_utils/ui-utils";


@Component({
  selector: 'dialog-docstage-workflow',
  templateUrl: './dialog-datastage-workflow.component.html'
})

export class DialogDatastageWorkflowComponent implements OnInit {
  @Input() idProject = '';
  @Input() idDoc = '';
  @Input() _rowDoc: any;
  @Input() projectAdmin: boolean;
  @Output() onSave = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  _datamode = 0;

  private _docBody: any[];
  private _loading: boolean = false;
  userForm: UntypedFormGroup;

  // showDlgUserRecord: boolean;

  constructor(private dataService: DocumentUtilsPrivateService,
              private authService: AuthService,
              private fb: UntypedFormBuilder) {
    this._docBody = [];
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      userUploaded: '',
      userUnderReview: '',
      userApproved: '',
      userRejected: '',
    });
  }

  private loadData() {
    if (this.idDoc !== null && this.idDoc !== undefined && this.idDoc !== "") {
      this._loading = true;
      this.dataService.getListImport4docref(this.idDoc)
        .subscribe(
          data => {
            if (data && data.length > 0) {
              this._docBody = data[0];
              this.userForm.get('userUploaded').setValue('');
              this.userForm.get('userUnderReview').setValue('');
              this.userForm.get('userApproved').setValue('');
              this.userForm.get('userRejected').setValue('');
              if ((this._rowDoc.STATE == DocStage.APPROVED)
                || (this._rowDoc.STATE == DocStage.REJECTED)
                || (this._rowDoc.STATE == DocStage.UNDERREVIEW)
                || (this._rowDoc.STATE == DocStage.DATAUPLOAD)) {
                this.userForm.get('userUploaded').setValue(this._docBody['nameUser'] + '\n' + this._docBody['dateRec']);
              }

              if ((this._rowDoc.STATE == DocStage.APPROVED)
                || (this._rowDoc.STATE == DocStage.REJECTED)
                || (this._rowDoc.STATE == DocStage.UNDERREVIEW)) {
                this.userForm.get('userUnderReview').setValue(this._docBody['nameUserReview'] + '\n' + this._docBody['dateReview']);
              }

              if (this._rowDoc.STATE == DocStage.APPROVED) {
                this.userForm.get('userApproved').setValue(this._docBody['nameUserDecission'] + '\n' + this._docBody['dateDecission']);
              }

              if (this._rowDoc.STATE == DocStage.REJECTED) {
                this.userForm.get('userRejected').setValue(this._docBody['nameUserDecission'] + '\n' + this._docBody['dateDecission']);
              }

            } else {
              this._loading = false;
              this.onClose.emit();
            }
            this._loading = false;
          }
        );
    }

  }

  refresh(iddoc: string, row: any, datamode: number) {
    if (iddoc && iddoc != "") {
      this.idDoc = iddoc;
    }
    this._rowDoc = row;


    this._datamode = datamode;
    this.loadData();


  }

  get loading(): boolean {
    return this._loading;
  }

  outputRowCellValue(data, len) {
    if (data && data.length) {
      return data.length > len ? (`${data.substring(0, len - 1)}...`) : data;
    }
    return data;
  }

  get approveHidden(): boolean {
    return !(this._rowDoc && ((this._rowDoc.STATE == DocStage.APPROVED) || (this._rowDoc.STATE == DocStage.UNDERREVIEW)));
  }

  get cancelUnderReviewHidden(): boolean {
    return !(this._rowDoc && (this._rowDoc.STATE == DocStage.UNDERREVIEW));
  }

  get cancelApprove(): boolean {
    return !(this._rowDoc && (this._rowDoc.STATE == DocStage.APPROVED) && this.projectAdmin);
  }

  get cancelReject(): boolean {
    return !(this._rowDoc && (this._rowDoc.STATE == DocStage.REJECTED) && this.projectAdmin);
  }

  get rejectHidden(): boolean {
    return !(this._rowDoc && ((this._rowDoc.STATE == DocStage.REJECTED) || (this._rowDoc.STATE == DocStage.UNDERREVIEW)));
  }

  get underReviewHidden(): boolean {
    return !this._rowDoc || this._rowDoc.STATE === DocStage.DATAUPLOAD;
  }


  get UploadedButtonDisabled(): boolean {
    return !this._rowDoc || this._rowDoc.STATE !== DocStage.UNDERREVIEW;
  }


  get UnderReviewButtonDisabled(): boolean {
    return !this._rowDoc || this._rowDoc.STATE !== DocStage.DATAUPLOAD;
  }

  get ApprovedButtonDisabled(): boolean {
    return !this._rowDoc || this._rowDoc.STATE !== DocStage.UNDERREVIEW;
  }

  get RejectedButtonDisabled(): boolean {
    return !this._rowDoc || this._rowDoc.STATE !== DocStage.UNDERREVIEW;
  }


  save(value: any) {
    this.onSave.emit({iddoc: this.idDoc, status: value, datamode: this._datamode});
  }

  close() {
    this.onClose.emit();
  }
}
