<div>
<!--  <h4>{{header}}</h4>-->
  <div id="doc-charts" [class.d-none]="ischart == 0">
      <p-chart   #mychart  width="1000px" height="700px"
                 [type]="charttype"

                 [data]="data0" [class.d-none]="!this.geoLoaded" [options]="this.chart_options"></p-chart>
  </div>
</div>

