<p-panel [header]="header">
  <div id="stat-docs-distribution">
    <p-toolbar>
      <div class="p-toolbar-group-right">
        <p-dropdown id="idTemplate"
                    [options]="items"
                    [(ngModel)]="metric"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="handleMetricsChanged()"
                    [style]="{'width':'200px'}"></p-dropdown>
      </div>
    </p-toolbar>
    <ng-container *ngIf="metric == 0">
      <p-chart  #mychart0 type="line" [data]="data0" [options]="this.chart_options" (onDataSelect)="onDataSelect($event)"></p-chart>
    </ng-container>
    <ng-container *ngIf="metric == 1 || metric == 2">
      <p-chart #mychart1 type="line" [data]="data1" [options]="this.chart_options"></p-chart>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    {{ footer }}
  </ng-template>
</p-panel>
