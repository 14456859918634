import {NgModule} from '@angular/core';
import {ProgressBarModule} from 'primeng/progressbar';
import {LoaderComponent} from './loader.component';
import {LoaderService} from './loader.service';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [
    ProgressBarModule,
    CommonModule
  ],
  exports: [
    LoaderComponent
  ],
  declarations: [
    LoaderComponent
  ],
  providers: [
    LoaderService
  ]
})
export class LoaderModule {
}
