import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppMessageService} from '../../../_services/app-message.service';
import {RegistrationService} from '../_services/registration.service';
import {finalize} from 'rxjs/operators';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../_services/auth.service';

@Component({
  selector: 'app-registration-confirm',
  templateUrl: './registration-confirm.component.html',
  styleUrls: ['./registration-confirm.component.css'],
  providers: [RegistrationService]
})
export class RegistrationConfirmComponent implements OnInit {
  private readonly params: any;
  private loading: boolean;
  private _failed: boolean;
  private _message: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataService: RegistrationService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private authService: AuthService) {
    this.params = this.authService.projectRegistration
      ? this.authService.projectRegistration
      : this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit() {
  }

  confirm() {
    this.loading = true;
    this.dataService.userRegistration(this.params)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        data => {
          if (data?.status === 1) {
            this.confirmationService.confirm({
              message: this.translate.instant('message.cdoc.registration.success'),
              header: this.translate.instant('message.header.confirmation'),
              rejectVisible: false,
              acceptLabel: 'Ok',
              key: 'uncloseable',
              accept: () => this.authService.logout()
            });
          } else {
            this._message = data.message;
            this._failed = true;
          }
        },
        error => this.messageService.showMessage(error)
      );
  }

  get confirmButtonDisabled() {
    return this.loading;
  }

  get failed(): boolean {
    return this._failed;
  }

  get message(): string {
    return this._message;
  }
}
