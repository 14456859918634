import {Injectable} from '@angular/core';
import {ConfigService} from 'src/app/_services/config.service';
import {Observable} from 'rxjs';
import {DateUtils} from 'src/app/_utils/date-utils';
import {HttpClient} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import {AppMessageService} from "../../../_services/app-message.service";

@Injectable()
export class StatUtilsService {
  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService) {
  }

  exportSurveyStat(cdoc: string, prefix?: string) {
    this.http.get(`${this.configService.api.export}/stat/${cdoc}`, {
      responseType: 'blob'
    })
      .subscribe(
        data => {
          FileSaver.saveAs(data, (prefix || 'SURVEYSTAT') + '_' + cdoc + '.zip');
        },
        error => {
          this.messageService.showMessage(error, '[exportDocument]');
        });
  }

  getStatListSurveys(code: string, grtype: number, cols: string) {
    // console.log("point1: getStatListSurveys",grtype);
    let _filter: string = this._getFIlterStr(code, grtype, cols);
    // console.log("point1/2: getStatListSurveys url",this.configService.api.chartstat.surveys + (_filter.length > 0 ? `?${_filter}` : ''));
    return this.http.get<any>(this.configService.api.chartstat.surveys + (_filter.length > 0 ? `?${_filter}` : ''));
  }

  getListSurveysDailyStat(dbegin: Date, dend: Date, code: string, grtype: number): Observable<any> {
    const _dbegin: string = DateUtils.formatDate(dbegin, 'DD-MM-YYYY'),
      _dend: string = DateUtils.formatDate(dend, 'DD-MM-YYYY');
    let _filter: string = this._getFIlterStr(code, grtype, '');
    _filter += (_filter.length > 0 ? `&` : '') + `dbegin=${_dbegin}&dend=${_dend}`;

    return this.http.get<any>(`${this.configService.api.chartstat.surveys}/daily?${_filter}`);
  }

  getListSurveysMonthlyStat(code: string, grtype: number): Observable<any> {
    let _filter: string = this._getFIlterStr(code, grtype, '');
    return this.http.get<any>(`${this.configService.api.chartstat.surveys}/monthly?${_filter}`);
  }

  getListDocChartsStat(code: string, filter: string, datamode: number): Observable<any> {
    return this.http.get<any>(`${this.configService.api.chartstat.datachart}/${code}?search=${filter}&datamode=${datamode}`);
  }

  getMetaDocChartsStat(code: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.chartstat.metachart}`);
  }

  getListSurveysGeoStat(code: string, isDraft: number): Observable<any> {
    let _filter: string = this._getFIlterStr(code, isDraft, '');
    return this.http.get<any>(`${this.configService.api.chartstat.surveys}/geo?${_filter}`);
  }

  getStatGrpDocuments(cdoc: string, field: string, aggMethod?: string): Observable<any> {
    return this.http.get<any>(`${this.configService.api.docValues}/${cdoc}/${field}${aggMethod ? '?agg-method=' + aggMethod : ''}`);
  }

  getStatListUsers(cdoc: string, grtype: number): Observable<any> {
    let _filter: string = this._getFIlterStr(cdoc, grtype, '');
    return this.http.get<any>(`${this.configService.api.chartstat.users}?${_filter}`);
  }

  private _getFIlterStr(code, grtype, cols: string): string {
    let _filter: string = '';
    if (code != null) if (code.length > 0) _filter += `code=${code}`;
    if (grtype != null) _filter += (_filter.length > 0 ? `&` : '') + `grtype=${grtype}`;
    if (cols != null) if (cols.length > 0) _filter += (_filter.length > 0 ? `&` : '') + `cols=${cols}`;

    return _filter;
    //http://localhost:4200/api/doc/stat/surveys?code=7C557DC8D3454EF0B63FDF748F61B3E4&grtype=1&cols=MissionCode,MissionCity,InstitutionName,iddoc
  }

  /**
   * Retrieving unique values by field name
   *
   * @param id - ID project
   * @param field - field name
   * @returns {Observable<UniqueValueResult[]>}
   */
  getUniqueValues(id: string, field: string): Observable<UniqueValueResult[]> {
    return this.http.get<UniqueValueResult[]>(`${this.configService.api.docValues}/${id}/docs-unique-values?field=${field}`);
  }

  getListParams(cdoc: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.configService.api.dicts.docutl}/${cdoc}/params`);
  }
}

interface UniqueValueResult {
  cdoc: string;
  value: string;
}

