<ng-container>
  <!-- DocEditor -->
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="pi pi-pencil"
              *ngIf="isShowBtnDocOpen()" [disabled]="isDisabledBtnDocOpen" (click)="docOpen()"
              pTooltip="Open the document data"></button>
      <button pButton type="button" icon="pi pi-file-excel"
              [disabled]="isDisabledBtnExcel" (click)="handleBtnExcel($event)"
              pTooltip="Open the document data"></button>
      <button pButton type="button" [disabled]="isDisabledBtnRefresh" icon="pi pi-refresh"
              (click)="handleBtnRefresh($event)"
              pTooltip="Refresh">
      </button>
      <p-toggleButton [(ngModel)]="isAutorefresh" [disabled]="isDisabledBtnAutorefresh"
                      onIcon="pi pi-refresh" offIcon="pi pi-refresh"
                      onLabel="3 min" offLabel="3 min"
                      [title]="isAutorefresh ? 'Autorefresh is On' : 'Autorefresh is Off'">
      </p-toggleButton>
    </div>
    <div class="p-toolbar-group-right" *ngIf="!isDisabledBtnRenew">
      <button pButton type="button" [disabled]="isDisabledBtnRenew" icon="pi pi-play"
              (click)="handleBtnRenew($event)"
              pTooltip="Renew & recalc">
      </button>
    </div>
  </p-toolbar>


</ng-container>
<div>
  <div style="margin-bottom: 5px;">

    <label>Status&nbsp;</label>
    <p-multiSelect id="statusBy"
                   [options]="statusList" placeholder="Data"
                   [(ngModel)]="statusFilter"
                   [ngModelOptions]="{standalone: true}"
                   (onChange)="handleGroupByChanged($event, 10)"
                   [style]="{'width':'150px'}"
                   styleClass="mr-2"></p-multiSelect>

    <label>Aggregate by&nbsp;</label>
    <p-dropdown id="groupBy_1"
                [options]="groupByDropdown[0]"
                [(ngModel)]="groupBy_val[0]"
                [ngModelOptions]="{standalone: true}"
                (onChange)="handleGroupByChanged($event, 0)"
                [disabled]="isDisableGroupBy(0)"
                *ngIf="!isDisableGroupBy(0)"
                [style]="{'width':'150px'}"
                styleClass="mr-2"></p-dropdown>
    <p-dropdown id="groupBy_2"
                [options]="groupByDropdown[1]"
                [(ngModel)]="groupBy_val[1]"
                [ngModelOptions]="{standalone: true}"
                (onChange)="handleGroupByChanged($event, 1)"
                [disabled]="isDisableGroupBy(1)"
                *ngIf="!isDisableGroupBy(1)"
                [style]="{'width':'150px'}"
                styleClass="mr-2"></p-dropdown>
    <p-dropdown id="groupBy_3"
                [options]="groupByDropdown[2]"
                [(ngModel)]="groupBy_val[2]"
                [ngModelOptions]="{standalone: true}"
                (onChange)="handleGroupByChanged($event, 2)"
                [disabled]="isDisableGroupBy(2)"
                *ngIf="!isDisableGroupBy(2)"
                [style]="{'width':'150px'}"
                styleClass="mr-2"></p-dropdown>
    <p-splitButton icon="fa fa-chart-area"
                   [disabled]="isDisabledBtnCharts"
                   [model]="itemsCharts" iconPos="left"
                   pTooltip="View charts"></p-splitButton>


  </div>

  <div style="padding-top: 2px;" *ngIf="builderMode == false">
    <!--    projectBuilderBi-->
    <p-table #projectBuilderBi
             [value]="projectBiData.content"
             [totalRecords]="projectBiData.totalElements"
             [rows]="pageSize"
             [rowsPerPageOptions]="[100,200,500]"
             [lazy]="true" (onLazyLoad)="onProjectDataLazyLoad($event)" [loading]="loading"
             selectionMode="single"
             dataKey="BiDataServiceId"
             [(selection)]="dataSelectedRows"
             [paginator]="true"
             paginatorPosition="bottom"
             [scrollable]="true" [scrollHeight]="'calc(100vh - 400px)'"
             scrollDirection="both"
             sortField="" [sortOrder]="0">
      <ng-template pTemplate="headergrouped">
        <tr>
          <ng-container *ngFor="let column of getVisibleColumns()"> <!-- For .. -->
            <!-- Fields without sort -->
            <ng-container *ngIf="column.sortField == null">
              <th
                class="header-field datatype-{{column.dataType.toLowerCase()}} header-field-{{column.field.toLowerCase()}}">
                {{outputHeaderLabel(column.label)}}
              </th>
            </ng-container>
            <!-- Fields with sort -->
            <ng-container *ngIf="column.sortField != null">
              <th pSortableColumn="{{column.sortField || column.field}}"
                  class="header-field datatype-{{column.dataType.toLowerCase()}} header-field-{{column.field.toLowerCase()}}">
                {{outputHeaderLabel(column.label)}}
                <p-sortIcon field="{{column.sortField}}" *ngIf="column.sortField"></p-sortIcon>
              </th>
            </ng-container>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let column of getVisibleColumns()"><!-- BUILDING FILTER -->
            <!--- validation status  -->
            <ng-container *ngIf="(column.filterable || false) === false">
              <th></th>
            </ng-container>
            <!--- validation status  -->
            <ng-container *ngIf="(column.filterable || false) === true">
              <ng-container [ngSwitch]="column.dataType">
                <th *ngSwitchCase="'STRING'">
                  <input pInputText type="text" [style.width]="'99%'"
                         [value]="filterValue(column.field)"
                         (change)="projectBuilderBi.filter($event.target.value, column.field,  column.field == 'CDOC' ? 'startsWith' : 'contains')"/>
                </th>
                <th *ngSwitchCase="'INTEGER'">
                  <input pInputText type="text" [style.width]="'99%'"
                         [value]="filterValue(column.field)"
                         (change)="projectBuilderBi.filter($event.target.value, column.field, 'eq')"/>
                </th>
                <th *ngSwitchCase="'NUMBER'">
                  <input pInputText type="text" [style.width]="'99%'"
                         [value]="filterValue(column.field)"
                         (change)="projectBuilderBi.filter($event.target.value, column.field, 'eq')"/>
                </th>
                <th *ngSwitchCase="'DATE'">
                  <input pInputText type="text" [style.width]="'99%'"
                         [value]="filterValue(column.field)"
                         (change)="projectBuilderBi.filter($event.target.value, column.field, 'eq')"/>
                </th>
                <th *ngSwitchCase="'TIME'">
                  <input pInputText type="text" [style.width]="'99%'"
                         [value]="filterValue(column.field)"
                         (change)="projectBuilderBi.filter($event.target.value, column.field, 'eq')"/>
                </th>
                <th *ngSwitchDefault>
                </th>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-rowIndex let-i="rowIndex">
        <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex" class="tb-active"
            (dblclick)="onDblRowClick($event, row)">
          <ng-container *ngFor="let column of getVisibleColumns()">
            <td [ngClass]="getNgClassColumn(column.field, row)"
                class="data-field datatype-{{column.dataType.toLowerCase()}} field-{{column.field.toLowerCase()}}">
              <span> {{ outputRowCellValue(column, row) }}</span>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorleft">
        <div class="text-left">
          Total records: {{projectBiData.totalElements}}
        </div>
      </ng-template>
    </p-table>
    <!-- projectBuilderBi >> -->
  </div>
</div>

<!-- DocEditor -->
<app-dialog-report-as-form (onProcess)="onProcessDoc($event)"></app-dialog-report-as-form>
<p-dialog [(visible)]="showDlgCharts"
          [modal]="true" [style]="{ width: '1100px', minWidth: '1100px' }"
          [maximizable]="false"
          (onHide)="onHideCharts()">
  <app-data-charts></app-data-charts>
</p-dialog>

<p-dialog [(visible)]="showDlgGeoCharts"
          [modal]="true" [style]="{ width: '1100px', minWidth: '1100px' }"
          [maximizable]="false"
          (onHide)="onHideGeoCharts()">
  <app-data-geocharts></app-data-geocharts>
</p-dialog>

<p-dialog header="Download options" [(visible)]="showDlgDownloadOptions"
          [modal]="true" [style]="{ width: '700px', minWidth: '700px' }"
          [maximizable]="false">
  <dialog-download-options [options]="9"
                           (onSave)="downloadOptionsSuccess($event)"
                           (onClose)="downloadOptionsSuccessClose()"></dialog-download-options>
</p-dialog>
