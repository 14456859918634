<!--It's document errors-->
<div class="{{classContent}}" *ngIf="errors.length > 0" [hidden]="showErrors == false">
  <table class='table table-striped' width='100%'>
    <tbody>
      <tr *ngFor="let r of errors">
        <td>{{r.rnum}}</td>
        <td>{{r.type}}</td>
        <td>{{r.field}}</td>
        <td>{{r.message}}</td>
      </tr>
    </tbody>
  </table>
</div>
<!--It's a document place-->
<div id="DocContent"
     [innerHTML]="templateHtml"
     class="{{classContent}} DocContent doc-content jdm-rpt-content" style="text-align: center;">
</div>

