import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrderListComponent} from './orderlist.component';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogModule} from 'primeng/dialog';
import {PickListModule} from "primeng/picklist";


@NgModule({
  declarations: [OrderListComponent],
  exports: [OrderListComponent],
  imports: [
    CommonModule,
    DialogModule,
    FileUploadModule,
    PickListModule
  ]
})
export class OrderListModule {
}
