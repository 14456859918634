export class BuilderUtils {
  static removeHtmlTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }
}

export class QueryRowUtils {
  static processRowData(row: any, dataMode: number): any {
    row["STATE"] = QueryRowUtils.getState(row, dataMode);
    row["STATUS"] = QueryRowUtils.getStatus(row, dataMode);
    row["ERROR_STATUS"] = "";

    return row;
  }

  private static getState(row: any, dataMode: number): number {



    let state: number = 0, status = row.FLAG;
    if ((dataMode ==3) || (dataMode == 4 )) {
      if ((status & /*IMPORTED*/32768) != 0) {
        state = 5;
      } else if (((status & /*DATAUPLOAD*/1024 + 512 + 256 + 128) == 1024)) {
        state = 9; // data staging env,  Uploaded
      } else if (((status & /*DATAUPLOAD*/1024) != 0) && ((status & /*ACCEPTED*/512) != 0)) {
        state = 6; // data staging env,  approved
      } else if (((status & /*DATAUPLOAD*/1024) != 0) && ((status & /*DECLINED*/256) != 0)) {
        state = 7; // data staging env,  rejected
      } else if (((status & /*DATAUPLOAD*/1024) != 0) && ((status & /*NEW*/128) != 0)) {
        state = 8; // data staging env,  Under Review
      } else if ((status & /*SENT*/2048) != 0) {
        state = 2;
      } else if ((status & /*DECLINED*/8192) != 0) {
        state = 3;
      } else if ((status & /* ACCEPTED*/4096) != 0) {
        state = 4;
      } else if ((status & 1/*NEW*/) != 0) {
        state = 1;
      }
    } else {
      if   (((status & /*DATAUPLOAD*/1024) != 0) && ((status & /*ACCEPTED*/512) != 0)) {
        // state = 2;
        state = 6;
      } else if ((status & /*SENT*/2048) != 0) {
        state = 2;
      } else if ((status & /*DECLINED*/8192) != 0) {
        state = 3;
      } else if ((status & /* ACCEPTED*/4096) != 0) {
        state = 4;
      } else if ((status & 1/*NEW*/) != 0) {
        state = 1;
      }
    }
    return state;
  }

  private static getStatus(row: any, dataMode: number): string {
    let ret: string = "Opened";
    const state = row.STATE;
    const flag = row.FLAG;
    switch (state) {
      case 4:
        if ((dataMode === 3) || (dataMode === 4)) {
          ret = 'Approved';
        } else {
          ret = ("" + row.ID_ORG_CURR).length === 0 ? ('Processed by CCAN on ' + row.D_CONFIRM) : ('Accepted by "' + row.ORG_PROC_NAME + '" on ' + row.D_CONFIRM);
        }
        break;
      case 3: /* Declined */
        if ((dataMode === 3) || (dataMode === 4)) {
          ret = 'Rejected';
        } else {
          ret = 'Declined';
        }
        break;
      case 2: /* Submitted */
        ret = 'Submitted';
        break;
      case 1: /* Draft */
        ret = "Saved";
        break;
      case 5: /* Imported */
        ret = "Imported";
        break;
      case 6: /* approved */
        ret = "Approved";
        break;
      case 7: /* rejected */
        ret = "Rejected";
        break;
      case 8: /* Under Review */
        ret = "Under Review";
        break;
      case 9: /* data staging env,  Uploaded */
        ret = "Uploaded";
        break;
    }
    if ((dataMode===3) && ((flag & (1024 + 2048)) === (1024 + 2048))) ret += '<br/>submitted';
    if ((flag & (1024 + 8)) === (1024 + 8)) ret += '<br/>errors';
    if ((flag & (1024 + 64)) === (1024 + 64)) ret += '<br/>duplicate';
    return ret;
  }
}
