import {Component, OnInit} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {finalize} from 'rxjs/operators';
import { StatUtilsService } from '../_services/stat-utils.service';
import {AppMessageService} from "../../../_services/app-message.service";

@Component({
    selector: 'app-stat-formsdata',
    template: `
      <p-treeTable [value]="nodes" [style]="{ 'min-height': '100px' }"
                   selectionMode="single" [(selection)]="selectedNode" (onNodeSelect)="nodeSelect($event)"
                   [scrollable]="true" [scrollHeight]="'calc(100vh - 280px)'">
      <ng-template pTemplate="caption">
          Forms statistic
      </ng-template>
	    <ng-template pTemplate="colgroup">
		    <colgroup>
			    <col>
			    <col style="width:7rem;">
			    <col style="width:7rem;">
		    </colgroup>
	    </ng-template>
	    <ng-template pTemplate="header">
		    <tr>
			    <th>Name</th>
			    <th>Total draft records</th>
			    <th>Total submitted records</th>
		    </tr>
	    </ng-template>
	    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
		    <tr [ttSelectableRow]="rowNode" (dblclick)="onDblRowClick($event, rowData)">
			    <td>
				    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
				    {{rowData.name}}
			    </td>
			    <td>{{rowData.cntDocs1}}</td>
			    <td>{{rowData.cntDocs2}}</td>
		    </tr>
	    </ng-template>
    </p-treeTable>`
})
export class StatSurveysDataComponent implements OnInit {
  private _nodes: TreeNode[];
  private _selectedNode: TreeNode;
  private _loading: boolean;

  constructor(private messageService: AppMessageService,
              private dataService: StatUtilsService) { }

  ngOnInit() {
    this._loading = true;
    this.loadOrgNodes();
  }

  loadOrgNodes() {
    this._loading = true;
    this.dataService.getStatListSurveys(null, null,"CountryISO3Code,MissionCity,InstitutionName,id")
        .pipe(finalize(() => { this._loading = false; }))
                .subscribe(data => {
                          let agr_1 = { cnt_1:0, cnt_2:0, cnt_3:0, cnt_4:0, cnt_5:0 },
                              agr_2 = { cnt_1:0, cnt_2:0, cnt_3:0, cnt_4:0, cnt_5:0 },
                              orgs: TreeNode[] = [];
                          for (let i in data) {
                            const rec = data[i];
                            if (rec.grtype === 2) {
                              switch (rec.state) {
                                case 5:
                                  agr_2.cnt_5 += rec.cnt;
                                  break;
                                case 4:
                                  agr_2.cnt_4 += rec.cnt;
                                  break;
                                case 3:
                                  agr_2.cnt_3 += rec.cnt;
                                  break;
                                case 2:
                                  agr_2.cnt_2 += rec.cnt;
                                  break;
                                default:
                                  agr_2.cnt_1 += rec.cnt;
                                  break;
                              }
                            } else {
                              switch (rec.state) {
                                case 5:
                                  agr_1.cnt_5 += rec.cnt;
                                  break;
                                case 4:
                                  agr_1.cnt_4 += rec.cnt;
                                  break;
                                case 3:
                                  agr_1.cnt_3 += rec.cnt;
                                  break;
                                case 2:
                                  agr_1.cnt_2 += rec.cnt;
                                  break;
                                default:
                                  agr_1.cnt_1 += rec.cnt;
                                  break;
                              }
                            }
                          }
                          let node_2 = {
                              data: {
                                  cdoc:'*',
                                  name:'SURVEYS',
                                  grtype: 2,
                                  cntDocs1: agr_2.cnt_1,
                                  cntDocs2: agr_2.cnt_2,
                                  cntDocs3: agr_2.cnt_3,
                                  cntDocs4: agr_2.cnt_4,
                                  cntDocs5: agr_2.cnt_5
                              },
                              leaf: false,
                              expanded: true,
                              children: this.getChildrenNodes(data, 2)
                          },
                          node_1 = {
                            data: {
                              cdoc:'*',
                              name:'FORMS',
                              grtype: 1,
                              cntDocs1: agr_1.cnt_1,
                              cntDocs2: '',
                              cntDocs3: '',
                              cntDocs4: '',
                              cntDocs5: ''
                            },
                            leaf: false,
                            expanded: true,
                            children: this.getChildrenNodes(data, 1)
                          };
                          orgs.push(node_2);
                          orgs.push(node_1);

                          this._nodes = orgs;
                        },
                        error => {
                          this.messageService.showError(error, 'Error receiving data');
                          console.log(error);
                        });
  }

  onDblRowClick(event, row) {
  }

  nodeSelect(event) {
      // const _data: any = this._selectedNode.data;
  }

  private getUniqueValues (data, code, nm, grType) {
    let a = [], l = data.length;
    for(let i = 0; i < l; i++) {
      if (data[i]['grtype'] === grType) {
        for (let j = i + 1; j < l; j++) {
          if (data[i][code] === data[j][code]) {
            j = ++i;
          }
        }
        a.push({code: data[i][code], name: data[i][nm]});
      }
    }
    return a;
  }

  private getChildrenNodes(data: any, grType: number) {
        let nodes:TreeNode[] = [];
        let codes = this.getUniqueValues(data, 'cdoc', 'name', grType);
        for(let i in codes) {
            let r = codes[i];
            let cnt_1 = 0, cnt_2 = 0, cnt_3 = 0, cnt_4 = 0, cnt_5 = 0;
            for (let i in data) {
              const rdata = data[i];
              if (rdata.cdoc == r.code && rdata.grtype == grType) {
                if (grType == 2) {
                  switch (rdata.state) {
                    case 5:
                      cnt_5 += rdata.cnt;
                      break;
                    case 4:
                      cnt_4 += rdata.cnt;
                      break;
                    case 3:
                      cnt_3 += rdata.cnt;
                      break;
                    case 2:
                      cnt_2 += rdata.cnt;
                      break;
                    default:
                      cnt_1 += rdata.cnt;
                      break;
                  }
                } else {
                  cnt_1 += rdata.cnt;
                }
              }
            }
          let node = {
            data: {
                cdoc: r.code,
                name: r.name,
                grtype: grType,
                cntDocs1: cnt_1,
                cntDocs2: grType == 2 ? cnt_2 : '',
                cntDocs3: grType == 2 ? cnt_3 : '',
                cntDocs4: grType == 2 ? cnt_4 : '',
                cntDocs5: grType == 2 ? cnt_5 : ''
            },
            leaf: true,
          };
          nodes.push(node);
        }
        return nodes;
    }

  get nodes(): TreeNode[] {
    return this._nodes;
  }

  get loading(): boolean {
    return this._loading;
  }

  get selectedNode(): TreeNode {
      return this._selectedNode;
  }

  set selectedNode(v: TreeNode) {
      this._selectedNode = v;
  }
}
