import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DownloadFileService} from "../../../_services/download-file.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {AuthMessagesService} from "../../../_services/auth-messages.service";
import {AuthService} from "../../../_services/auth.service";
import {ConfirmationService} from 'primeng/api';
import {Sidebar} from 'primeng/sidebar';
import {MessagingService} from "../../../_services/messaging.service";

@Component({
  selector: 'app-list-messages',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit, OnDestroy {
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  @Input() dot: boolean;
  @Input() backgroundColor = '';
  @Input() badgeClass: string;

  messages = [];
  showMessages: boolean = false;
  cntMessages: number = 0;

  private idleId;
  private idleIdRecordUpdate;
  @ViewChild('sidebar', {static: false})
  private sidebarCmp: Sidebar;

  constructor(private _authService: AuthService,
              private confirmationService: ConfirmationService,
              private downloadService: DownloadFileService,
              private messageService: AppMessageService,
              private userMessagesService: AuthMessagesService,
              private messagingService: MessagingService) {
  }

  ngOnInit(): void {
    const _self = this;
    _self.userMessagesService.getCount()
      .subscribe({
        next: data => {
          _self.cntMessages = data;
          _self.setScheduleJob();
        },
        error: (error) => _self.messageService.showError(error)
      });
  }

  ngOnDestroy() {
    clearInterval(this.idleId);
    clearInterval(this.idleIdRecordUpdate);
  }

  get countMessages(): string {
    return this.cntMessages > 99 ? "99+" : "" + this.cntMessages;
  }

  get messagesTitle(): string {
    return this.cntMessages === 0 ? "You have not received any messages" : `View your ${this.cntMessages} messages"`;
  }

  showList() {
    this.showMessages = true;
    this.messages = [];
    this.userMessagesService.getList()
      .subscribe(data => {
        this.messages = data;
        this.showMessages = true;
      });
  }

  showInfoList(cnt: number) {
    this.userMessagesService.getList()
      .subscribe(data => {
        for (let i = 0; i < cnt; i++) {
          if (data && data[i] && data[i].message) {
            this.messageService.showInfo(data[i].message);
          }
        }
      });
  }

  delete(code: string) {
    this.userMessagesService.delete(code)
      .subscribe(data => {
        this.refreshCmp();
      });
  }

  outputExecTimeInMins(t) {
    return Math.floor(t / 60);
  }

  outputExecTimeInSecs(t) {
    const min = Math.floor(t / 60);
    return (t - min * 60).toFixed(2);
  }

  download(idDownload: string) {
    this.downloadService.download(idDownload);
  }

  clearMessages() {
    this.userMessagesService.deleteAll()
      .subscribe(data => {
        this.messages = [];
        this.refreshCmp();
      });
  }

  private refreshCmp() {
    this.onDelete.emit(1);
    this.userMessagesService.getCount()
      .subscribe(data => {
          this.cntMessages = data;
          if (this.showMessages && this.cntMessages != 0) {
            this.showList();
          } else {
            this.showMessages = false;
            this.sidebarCmp.hide();
          }
        },
        error => {
          this.messageService.showError(error);
        });
  }

  private setScheduleJob() {
    const _self = this;
    this.idleId = setInterval(function () {
      const user = _self._authService.user;
      if (user.id) {
        _self.userMessagesService.getCount()
          .subscribe(data => {
              const _cnt: number = parseInt(data, 10);
              const _cntMessages = _self.cntMessages;
              _self.cntMessages = _cnt;
              if (_self.showMessages) {
                _self.showList();
              } else {
                if (_cnt > _cntMessages) {
                  _self.showInfoList(_cnt - _cntMessages);
                }
              }
            },
            error => {
              _self.messageService.showError(error);
            });
      }
    }, 1000 * 30);

    this.idleIdRecordUpdate = setInterval(function () {
      const user = _self._authService.user;
      if (user.id) {
        _self.userMessagesService.getCount(5)
          .subscribe(data => {
              const cnt: number = parseInt(data, 10);
              if (cnt > 0) {
                _self.userMessagesService.getList(5)
                  .subscribe(data => {
                    data.forEach(v => {
                      if (v.fileName === 'TEMPLATE') {
                        _self.messagingService.templateRecordUpdate.next(v.idDownload);
                      } else {
                        _self.messagingService.stageDataUploadRecordUpdate.next(v.idDownload);
                      }
                      _self.userMessagesService.delete(v.code)
                        .subscribe(_ => null);
                    });
                  });
              }
            },
            error => _self.messageService.showError(error));
      }
    }, 1000 * 30);
  }
}
