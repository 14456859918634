import {Component, OnInit, ViewChild} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {LazyLoadEvent} from 'primeng/api';
import {UIUtils} from '../../../_utils/ui-utils';
import {finalize} from 'rxjs/operators';
import {DataAdminService} from '../_services/data-admin.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {Table} from 'primeng/table';
import {AuthService} from '../../../_services/auth.service';

@Component({
  selector: 'app-admin-dict-countries',
  templateUrl: './dict-countries.component.html',
  styleUrls: ['./dict-countries.component.css']
})
export class DictCountriesComponent implements OnInit {
  private _data: Pageable;
  private _loading = false;
  private currentPage: number = 0;
  private sorting: string = '';
  private tableFilter: any;
  tableFilterData: any = {};
  uploadShow: boolean;
  @ViewChild('dt', {static: false})
  private dataTable: Table;


  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService,
              private authService: AuthService) {
    this._data = new Pageable();
  }

  ngOnInit(): void {
  }

  private loadData(filter?: string) {
    filter = filter || '';

    if (this.tableFilter) {
      let search = UIUtils.getFilterStr(this.tableFilter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    this._loading = true;
    this.dataService.getDictCountries(filter)
      .pipe(finalize(() => this._loading = false))
      .subscribe(
        data => {
          data.content.map(v => v.flag = this.getFlagSvg(v.iso2));
          this._data = data;
        },
        error => this.messageService.showMessage(error, 'Error receiving data')
      );
  }

  handlePageChange(event: LazyLoadEvent) {
    this._data.content = [];
    this.tableFilter = event.filters;
    this.currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    this.loadData('&page=' + this.currentPage + (this.sorting || ''));
  }

  refresh() {
    this.tableFilter = null;
    this.currentPage = 0;
    this.tableFilterData = {code: '', name: ''};
    this.dataTable.reset();
  }

  beforeUpload() {
    this.uploadShow = true;
  }

  fileUploader(event) {
    let fileList: FileList = event.files;
    if (fileList.length === 1) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('file', file);
      this.dataService.uploadDictCountries(formData)
        .subscribe(
          data => {
            this.uploadShow = false;
            if (data.status !== 1) {
              this.messageService.showError(data.message);
            }
            this.refresh();
          },
          error => {
            this.messageService.showMessage(error);
          });
    }
  }

  get data(): Pageable {
    return this._data;
  }

  get loading(): boolean {
    return this._loading;
  }

  outputRowCellValue(data, len) {
    if (data && data.length) {
      return data.length > len ? (`${data.substring(0, len - 1)}...`) : data;
    }
    return data;
  }

  getFlagSvg(iso2: string): string {
    return 'assets/flags/' + iso2.toLowerCase() + '.svg'
  }

  get uploadButtonVisible(): boolean {
    return this.authService?.user?.typeAdmin > 0;
  }
}
