import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Pageable} from "../../../_domains/spring/pageable";
import {finalize} from "rxjs/operators";
import {BuilderUtils, QueryRowUtils} from "../../../_utils/builder-utils";
import {BuilderUtilsService} from "../_services/builder-utils.service";
import {AppMessageService} from "../../../_services/app-message.service";
import {Table} from 'primeng/table';
import {ConfigService} from '../../../_services/config.service';
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'app-view-tabdata',
  templateUrl: './query-tab-data.component.html',
  styleUrls: ['../query-data/query-data.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryTabDataComponent implements OnInit {
  @Input() idProject = '';

  @ViewChild('queryTabData', {static: false}) builderTableData: Table;

  dialogVisible: boolean;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable();
  tabData: Pageable = new Pageable();
  isGroupData: boolean = false;

  rowId: string = "";
  private tabNum: number = 0;
  private _tabColumns: any[] = [];

  constructor(private builderService: BuilderUtilsService,
              private messageService: AppMessageService,
              private configService: ConfigService) {
  }

  ngOnInit(): void {
  }

  async refresh(id: string, cdoc: string, tabNum: number, isGroup: boolean) {
    this.tabNum = tabNum;
    this.rowId = id;
    this.isGroupData = isGroup;

    this._tabColumns = [];
    const _columns = await this.builderService.getBuilderTabParams(this.idProject, cdoc, this.tabNum);
    _columns.forEach(rdata => {
      if (this.isMobileResolution && !rdata.mobileVisible) {
        return;
      }
      const rec = {
        field: `${rdata.cdoc}_${rdata.field}`,
        cdoc: rdata.cdoc,
        name: rdata.field,
        header: rdata.label,
        group: false,
        dataType: rdata.dataType,
        filterable: true,
        required: rdata.required,
        visible: rdata.visible,
        mobileVisible: rdata.mobileVisible,
        editType: rdata.editType,
        tabn: this.tabNum
      };
      this._tabColumns.push(rec);
    });

    this.rowId = id;
    this.tabData = new Pageable();
    this.builderTableData.reset();
    this.dialogVisible = true;
  }

  handleTablePageChange(event) {
    const npage = event.rows == 0 ? 0 : Math.floor(event.first / event.rows);
    this.loadTableData(npage)
  }

  outputHeaderLabel(data) {
    data = BuilderUtils.removeHtmlTags(data);
    if (data && data.length) {
      return data.length > 41 ? (`${data.substring(0, 40)}...`) : data;
    }
    return data;
  }

  outputRowCellValue(field, row) {
    let ret = "";
    if (this.isGroupData == false || field.cdoc == 'SYS') ret = row[field.field];
    if (this.isGroupData == true) {
      for (const k in row) {
        const pos = k.indexOf("_");
        if (pos != 0) {
          const nm = k.substring(pos + 1);
          if (nm == field.name) {
            ret = row[k];
            break;
          }
        }
      }
    }

    if (field.dataType.toUpperCase() == "STRING") {
      if (ret && ret.length) {
        return ret.length > 41 ? (`${ret.substring(0, 40)}...`) : ret;
      }
    }
    return ret;
  }

  downloadFileData(iddoc: string, id: string) {
    this.builderService.getFileLink(iddoc, id)
      .subscribe({
        next: (data) => window.open(data, '_blank'),
        error: (error) => this.messageService.showMessage(error)
      });
  }

  getTableColumns() {
    const cols = this._tabColumns || [];
    return cols.filter(col => col.tabn === this.tabNum && col.dataType !== 'TABLE');
  }

  private loadTableData(page: number) {
    if (typeof (this.rowId) !== 'undefined' && this.idProject != "" && this.tabNum != 0) {
      this.tabData.content = [];
      this.loadingSubject.next(true);

      let vars = {tabn: this.tabNum, cols: []};
      for (const col of this._tabColumns.filter(col => (col.tabn || 0) === this.tabNum)) {
        const rdata = col, mutable = rdata.mutable || false, group = rdata.group || false;
        if (!mutable && group === false) {
          vars.cols.push({cdoc: rdata.cdoc, name: rdata.name, dataType: rdata.dataType});
        }
      }
      this.builderService.getDocumentTableData(this.rowId, vars, 'page=' + page)
        .pipe(finalize(() => this.loadingSubject.next(false)))
        .subscribe(
          data => {
            for (const i in data.content) {
              const rdata = data.content[i];
              data.content[i] = QueryRowUtils.processRowData(rdata, 0);
            }
            this.tabData = data;
          },
          error => {
            this.messageService.showMessage(error);
          });
    }
  }

  get header(): string {
    return `Dynamic [${this.tabNum}] table data`;
  }

  private get isMobileResolution() {
    return this.configService.isMobileDevice;
  }

}
