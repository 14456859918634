<p-dialog header="The list of users" [(visible)]="cmpVisible"
          (onHide)="hide()"
          [modal]="true" [style]="{ width: '1100px', minWidth: '1100px' }"
          [maximizable]="false" [baseZIndex]="10000">
  <p-toolbar>
    <div class="p-toolbar-group-left">
      <button pButton type="button" icon="pi pi-check"
              [disabled]="selectButtonDisabled"
              (click)="rowSelect()" pTooltip="Grant access to the selected users"></button>
    </div>
  </p-toolbar>
  <p-table #dt
           [value]="users.content"
           [totalRecords]="users.totalElements" [rows]="users.size"
           [lazy]="true" (onLazyLoad)="handlePageChange($event)" [loading]="loading$ | async"
           selectionMode="single" [(selection)]="selected"
           [paginator]="true" paginatorPosition="bottom"
           [resizableColumns]="true" [scrollable]="true" [scrollHeight]="'300px'"
           sortField="username" [sortOrder]="1">
    <ng-template pTemplate="colgroup">
      <colgroup>
        <col style="width: 20rem;">
        <col style="width: 50rem;">
        <col style="width: 50rem;">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="username">Login
          <p-sortIcon field="username"></p-sortIcon>
        </th>
        <th pSortableColumn="fullname">Full name
          <p-sortIcon field="fullname"></p-sortIcon>
        </th>
        <th pSortableColumn="email">e-Mail
          <p-sortIcon field="email"></p-sortIcon>
        </th>
      </tr>
      <tr>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'username', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'fullname', 'contains')"/>
        </th>
        <th>
          <input pInputText type="text" [style.width]="'99%'"
                 (change)="dt.filter($event.target.value, 'email', 'contains')"/>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr [pSelectableRow]="row">
        <td>{{row.username}}</td>
        <td>{{row.fullname}}</td>
        <td>{{row.email}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
