import {AfterViewInit, Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {BuilderUtilsService} from '../_services/builder-utils.service';
import {TemplateUtilsPrivateService} from '../../doceditor/_services/template-utils.service';
import {ImportComponent} from '../../import/import-excel/import.component';
import {BuilderTemplateRecordComponent} from '../builder-template-record/builder-template-record.component';
import {BuilderListTemplatesComponent} from '../builder-list-templates/builder-list-templates.component';
import {Pageable} from '../../../_domains/spring/pageable';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../_services/auth.service';
import {ConfigService} from '../../../_services/config.service';
import {DocumentUtilsPrivateService} from '../../doceditor/_services/document-utils-private.service';
import {ProjectUtilsService} from '../_services/project-utils.service';
import {AppMessageService} from '../../../_services/app-message.service';
import {ConfirmationService, MenuItem, SelectItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CProcessType, RespOnProcessDoc, User} from '../../../_domains/UITypes';
import {QueryRowUtils} from '../../../_utils/builder-utils';
import {UIUtils} from '../../../_utils/ui-utils';
import {QueryTabDataComponent} from '../query-tab-data/query-tab-data.component';
import {DialogLogEvtPrivateComponent} from '../../admin/events/dialog-log-evt-private.component';
import {DialogLogEvtPublicComponent} from '../../admin/events/dialog-log-evt-public.component';
import {finalize} from 'rxjs/operators';
import {Table} from 'primeng/table';
import {ClipboardService} from 'ngx-clipboard';
import {MessagingService} from '../../../_services/messaging.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {DialogDocInfoComponent} from '../dialog-doc-info/dialog-doc-info.component';
import {DocumentUtilsService} from '../_services/document-utils.service';
import {DownloadFileService} from '../../../_services/download-file.service';
import {DataChartsComponent} from '../data-charts/data-charts.component';
import {DialogDatastageWorkflowComponent} from '../dialog-datastage-workflow/dialog-datastage-workflow.component';
import {GeoChartsComponent} from '../geo-charts/geo-charts.component';
import {ImportUtilsService} from '../../import/_services/import-utils.service';
import {DialogDownloadOptionsComponent} from '../dialog-download-options/dialog-download-options.component';

@Component({
  selector: 'project-data',
  templateUrl: './query-data.component.html',
  styleUrls: ['./query-data.component.css'],
  providers: [
    BuilderUtilsService, TemplateUtilsPrivateService
  ]
})
export class QueryDataComponent implements OnInit, OnDestroy, AfterViewInit {
  private _idProject = '';
  private _hasApproveRole = false;
  private _dataMode = 0;
  idDoc = '';
  @ViewChild(Table, {static: false}) builderData: Table;
  @ViewChild(ImportComponent, {static: false}) importCmp;
  @ViewChild(BuilderTemplateRecordComponent, {static: false}) builderTemplateRecordCmp;
  @ViewChild(BuilderListTemplatesComponent, {static: false}) builderLstTemplatesCmp;
  @ViewChild(QueryTabDataComponent, {static: false}) queryTabData;
  @ViewChild(DialogLogEvtPrivateComponent, {static: false}) dlgViewPrivateCmp;
  @ViewChild(DialogLogEvtPublicComponent, {static: false}) dlgViewPublicCmp;
  @ViewChild(DialogDocInfoComponent, {static: false}) documentInfoCmp;
  @ViewChild(DataChartsComponent, {static: false}) documentChartsCmp;
  @ViewChild(GeoChartsComponent, {static: false}) documentGeoChartsCmp;
  @ViewChild(DialogDatastageWorkflowComponent, {static: false}) projectDataStageWorkflowCmp;
  @ViewChild(DialogDownloadOptionsComponent, {static: false}) projectDataDownloadOptionsCmp;
  template = '';
  dataSelectedRows: any[];
  selectedIdDocs: string[];
  dataMetaRows: any[];
  showMode = ShowMode.Data;
  dctDocs: any[] = [];
  projectsDocs: any[] = [];
  isGroupData: boolean = false;
  projectData: Pageable = new Pageable();
  loading: boolean = true;
  columns: any[] = [];
  columnsGrp: any = null;
  showDlgUsers: boolean;
  showDlgInfo: boolean;
  showDlgCharts: boolean;
  showDlgGeoCharts: boolean;
  dlgChartHeader: "";
  recOwnersVisible: boolean;
  showDlgWorkflow: boolean;
  showDlgDownloadOptions: boolean;
  projectAdmin: boolean;
  hasDownloadRole: boolean;
  hasUploadRole: boolean;
  privateLogsVisible = false;
  publicLogsVisible = false;
  docEditorVisible = false;
  private _columns: any[] = [];
  private sorting = '';
  pageSize = 20;
  private filter: any = {};
  private access: any[] = [];
  private isGroupSurvey: boolean = false;
  private _uiSettings: any[];
  private _docCharts: any[];
  private _docChartsDct: any[];
  uiButtons: any;
  private _includeMetadata: number;
  private _defaultTemplate: string;
  private _hasUniqKey = false;
  private statusListAll: SelectItem[];
  statusList: SelectItem[];
  private _docsList: MenuItem[];
  private showBtnDocCreate = false;
  showBtnDocOpen = false;
  showBtnDocInfo = false;
  private showBtnDocDuplicate = false;
  private showBtnDocRemove = false;
  private showBtnDocSend = false;
  showBtnCopyLink = false;
  itemsLogs: MenuItem[];
  private _itemsCharts: MenuItem[];
  private _itemsExcel: MenuItem[];
  private _itemsAdmin: MenuItem[];
  private _itemsRejectAprove: MenuItem[];
  private _visibleColumns: any[];
  // these edit types should be hidden in the data tab
  private HIDDEN_EDIT_TYPES = ['helpwindow'];
  private stageDataUploadRecordUpdate: Subscription;
  private fields: any[];
  createCdoc: string;
  private isPackage = false;
  descriptiveText!: string;
  scrollHeight = 'calc(100vh - 280px)';
  private resizeObserver: any;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private router: Router,
              private _configService: ConfigService,
              private docService: DocumentUtilsPrivateService,
              private tmplService: TemplateUtilsPrivateService,
              private projectService: ProjectUtilsService,
              private builderService: BuilderUtilsService,
              private docUtilsService: DocumentUtilsService,
              private messageService: AppMessageService,
              private confirmationService: ConfirmationService,
              private clipboardService: ClipboardService,
              private translate: TranslateService,
              private messagingService: MessagingService,
              private downloadService: DownloadFileService,
              private importService: ImportUtilsService,
  ) {
    this.statusListAll = [
      {value: 1, label: 'Opened'},
      {value: 2, label: 'Saved'},
      {value: 4, label: 'Submitted'},
    ];
    this.itemsLogs = [
      {
        label: this.getCustomLabel('/data/log:public', 'Public'),
        icon: this.getCustomIcon('/data/log:public', ''),
        command: () => this.refreshLogs(1)
      },
      {
        label: this.getCustomLabel('/data/log:private', 'Private'),
        icon: this.getCustomIcon('/data/log:private', ''),
        command: () => this.refreshLogs(2)
      }

    ];
  }

  ngOnInit(): void {
    this.loading = false;
    this.projectData.content = [];
  }

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(v => this.scrollHeight = `calc(100vh - ${v.contentRect.height + 280}px)`);
    });
    setTimeout(() => {
      this.resizeObserver.observe(document.getElementById('datatab-desc-text'));
    }, 200);
  }

  ngOnDestroy() {
    if (this.stageDataUploadRecordUpdate) {
      this.stageDataUploadRecordUpdate.unsubscribe();
    }
  }

  @Input()
  set idProject(val: string) {
    this._idProject = val;
    this.projectData = new Pageable();
  }

  @Input()
  set hasApproveRole(val: boolean) {
    this._hasApproveRole = val;
  }


  @Input()
  set dataMode(val: number) {
    this._dataMode = val;
    if (this._dataMode === 4) {
      this.statusListAll = [
        // {value: 1, label: 'Opened'},
        // {value: 2, label: 'Draft'},
        // {value: 5, label: 'Imported'},
        {value: 1024 + 64, label: 'Duplicates'},
        {value: 1024 + 8, label: 'Errors'},
        {value: 9, label: 'Uploaded'},
        {value: 8, label: 'Under Review'},
        {value: 7, label: 'Rejected'},
        {value: 6, label: 'Approved'}
      ];
    } else if (this._dataMode === 3) {
      this.statusListAll = [
        {value: 1, label: 'Opened'},
        {value: 2, label: 'Saved'},
        // {value: 5, label: 'Imported'},
        {value: 9, label: 'Uploaded'},
        {value: 8, label: 'Under Review'},
        {value: 7, label: 'Rejected'},
        {value: 6, label: 'Approved'}
        // {value: 4, label: 'Submitted'}
      ];
      if (this.stageDataUploadRecordUpdate) {
        this.stageDataUploadRecordUpdate.unsubscribe();
      }
      this.stageDataUploadRecordUpdate =
        this.messagingService.stageDataUploadRecordUpdate
          .subscribe({
              next: (id: string) => this.updateOneRecord(id)
            }
          );
    }
  }

  async updateOneRecord(id: string) {
    const docs = await this.builderService.getQueryDocuments2(
      this._idProject, this.fields, `datamode=${this._dataMode}&search=iddoc:${id}`
    );
    if (docs?.content?.length === 1) {
      const doc = docs.content[0];
      const ind = this.projectData.content.findIndex(v => v.ID === doc.ID);
      if (ind >= 0) {
        this.projectData.content[ind] = QueryRowUtils.processRowData(doc, this._dataMode);
      }
    }
  }


  get includeMetadata(): number {
    return this._includeMetadata;
  }

  get dataMode(): number {
    return this._dataMode;
  }

  get docCharts(): any[] {
    return this._docCharts;
  }

  get hasApproveRole(): boolean {
    return this._hasApproveRole;
  }


  @Input()
  set uiSettings(val: any[]) {
    this.pageSize = 20;
    this._uiSettings = val;
    if (this._uiSettings) {
      const p = this._uiSettings.find(v => v.name === 'DATA_VIEW:PAGE_SIZE');
      if (p) {
        this.pageSize = +p.value;
      }
    }
  };

  @Input()
  set docCharts(val: any[]) {
    this._docCharts = val;
    this._docChartsDct = [];
    this._itemsCharts = [];
    if (this._docCharts?.length) {
      this.docService.getListCharts(this._docCharts).subscribe(
        data => {
          this._docChartsDct = data;
          for (let i22 = 0; i22 < this._docChartsDct.length; i22++) {
            this._itemsCharts.push({
              label: this._docChartsDct[i22].shortName,
              command: () => this.openChart(this._docChartsDct[i22])
            });
          }
        },
        error => {
          this.messageService.showMessage(error);
        });
    }


    // for (let i22 = 0; i22 < this._docCharts.length; i22++) {
    //   this._itemsCharts.push({label: this._docCharts[i22], command: () => this.openChart(this._docCharts[i22])});
    // }
  };


  @Input()
  set loadOnInit(val: boolean) {
    if (val) {
      this.refresh();
    }
  }

  @Input()
  set includeMetadata(val: number) {
    this._includeMetadata = val;
  }

  /**
   *
   * @param type 1 - public, 2.private
   */
  private refreshLogs(type: number) {
    if (this.dataSelectedRows?.length !== 1) {
      return;
    }
    this.idDoc = this.dataSelectedRows[0].ID;
    if (type === 1) {
      this.publicLogsVisible = true;
    } else {
      this.privateLogsVisible = true;
    }
  }

  async refresh(vApprove?: boolean) {
    this._hasApproveRole = vApprove || false;
    this.dctDocs = [];
    this.projectsDocs = [];
    this.template = '';
    this.isGroupSurvey = false;
    this.showMode = ShowMode.Data;
    this._columns = [];
    this._hasUniqKey = false;
    this.filter = null;
    this.isPackage = false;
    if (this.builderData) {
      this.builderData.filters = {};
    }
    this.dataSelectedRows = [];
    this.projectData.content = [];
    this._visibleColumns = null;
    // obtaining project record
    const _project = await this.projectService.getAdmHierarchy(this._idProject)
      .toPromise()
      .catch(error => {
        this.messageService.showMessage(error);
      });
    this.descriptiveText = _project?.tabs?.find(v => v.name === 'data')?.text;
    this.isPackage = _project?.cdoc?.length > 0;
    this.uiButtons = {};
    if ((_project?.buttons !== undefined) && (_project.buttons.length > 0)) {
      _project.buttons.forEach(element => {
          this.uiButtons[element.button] = element;
        }
      )
    }
    this._defaultTemplate = _project.template;
    this.statusList = this.statusListAll.filter(v => (_project.hiddenDocStatus & v.value) !== v.value);
    // getting project parameters for all forms included in the project
    this._columns = await this.builderService.getBuilderParams(this._idProject, this._dataMode);
    if (this.isMobileResolution) {
      this._columns = this._columns.filter(v => v.mobileVisible);
    }
    // obtaining project access
    try {
      this.access = await lastValueFrom(this.projectService.getCurrentUserProjectAccess(this._idProject));
    } catch (error) {
      this.messageService.showMessage(error);
    }
    this.projectsDocs = await this.tmplService.getListProjectDocs(this._idProject);
    if (_project.grType === 1 /* Document is FORM */) {
      const listChildForms = await this.tmplService.getListProjectAppendixDocs(this._idProject);
      this.projectsDocs.forEach(rdata => {

        // if ((rdata.access === 1) && !(rdata.mode >= 2)) {
        if (rdata.uniqKeyCnt) {
          this._hasUniqKey = true;
        }
        // todo may be error here (rdata.mode == 3 && this._dataMode === 4) ??
        if ((rdata.access === 1) && ((rdata.mode < 2 && this._dataMode < 2) || (rdata.mode == 3 && this._dataMode === 4) || (rdata.mode >= 2 && rdata.mode === this._dataMode))) {


          if (!this.access.some(v => v.cdoc === rdata.cdoc && v.accessCreate)) {
            return;
          }
          if (listChildForms.some(v => v.cdoc === rdata.cdoc)) {
            return;
          }
          if ((this._dataMode === 4) && rdata.stageDataCdocs || '') {
            let _items = rdata.stageDataCdocs.split(';');
            for (let i22 = 0; i22 < _items.length; i22++) {
              let _vals = _items[i22].split('.');
              for (let j22 = 0; j22 < _vals.length; j22++) {
                if (_vals[1] && !this.dctDocs.find(v => v.cdoc === _vals[1])) {
                  this.dctDocs.push({cdoc: _vals[1], name: _vals[1]});
                }
              }
            }
          } else {
            if (!this.dctDocs.find(v => v.cdoc === rdata.cdoc)) {
              this.dctDocs.push({cdoc: rdata.cdoc, name: rdata.name});
            }
          }
        }
      });
      this._docsList = this.dctDocs.map(v => ({label: v.name, command: () => this.createDocument(v)}));
    } else /* Document is SURVEY */ {
      // const listProjectForms = await this.tmplService.getListProjectDocs(this._idProject);
      this.isGroupSurvey = true;
    }

    this.initButtonsVisibility();
    // Getting templates list
    if (this.builderLstTemplatesCmp && typeof (this.builderLstTemplatesCmp.refresh) !== 'undefined') {
      this.builderLstTemplatesCmp.refresh();
    }
  }

  initButtonsVisibility() {
    this.showBtnDocCreate = !this.isGroupSurvey && this.access.some(v => v.accessCreate);
    this.showBtnDocOpen = this.access.some(v => v.accessCreate || v.accessWrite || v.accessView);
    this.showBtnDocInfo = this.configService.isDesktopDevice
      && (this.typeAdmin === 1 || this.access.some(v => v.accessProjectAdmin || v.roleRecordInfo));
    this.showBtnDocDuplicate = this.authService.user.typeAdmin === 1 || this.access.some(v => v.accessCreate);
    this.showBtnDocRemove = this.access.some(v => v.accessRemove);
    this.showBtnDocSend = this.access.some(v => v.accessSubmit);
    this.showBtnCopyLink = this.isGroupSurvey
      && (this.typeAdmin === 1 || this.access.some(v => v.accessDocumentAdmin || v.accessProjectAdmin))
      && this._dataMode !== 3 && this._dataMode !== 4;
    const docOrProjectAdmin = this.access.some(v => v.accessDocumentAdmin || v.accessProjectAdmin);
    this.projectAdmin = this.typeAdmin === 1 || this.access.some(v => v.accessProjectAdmin);
    this.hasDownloadRole = this.typeAdmin === 1 || this.access.some(v => v.accessProjectAdmin || v.roleDownload);
    this.hasUploadRole = this.typeAdmin === 1 || this.access.some(v => v.accessProjectAdmin || v.roleUpload);

    this._itemsExcel = [];

    this._itemsRejectAprove = [
      {
        label: this.getCustomLabel('/data/detApprove:underReview', 'Under Review'),
        icon: this.getCustomIcon('/data/detApprove:underReview', 'pi pi-question'),
        command: () => this.docRejectApproveData('under_review', this._dataMode)
      },
      {
        label: this.getCustomLabel('/data/detApprove:reject', 'Reject'),
        icon: this.getCustomIcon('/data/detApprove:reject', 'pi pi-times'),
        command: () => this.docRejectApproveData('decline', this._dataMode)
      }
    ];

    this._itemsAdmin = [];
    if ((this.dataMode !== 3) && ((this.typeAdmin > 0 || this.projectAdmin))) {
      // if  (this.authService.user.typeAdmin === 1 && !this.isGroupSurvey) {
      this._itemsAdmin.push({
        label: this.getCustomLabel('/data/admin:assignUsers', 'Assign Users to the record(s)'),
        icon: this.getCustomIcon('/data/admin:assignUsers', 'pi pi-users'),
        command: () => this.beforeRecordOwners()
      });
      this._itemsAdmin.push({
        label: this.getCustomLabel('/data/admin:assignOwners', 'Assign Owner of the record(s)'),
        icon: this.getCustomIcon('/data/admin:assignOwners', 'pi pi-user'),
        command: () => this.beforeDocChangeOwner()
      });
      // }

      this._itemsAdmin.push({
        label: this.getCustomLabel('/data/admin:bulkCheck', 'Bulk data validation according to the rules'),
        icon: this.getCustomIcon('/data/admin:bulkCheck', 'pi pi-check'),
        command: () => this.bulkDataValidation('queuerule')
      });
      this._itemsAdmin.push({
        label: this.getCustomLabel('/data/admin:bulkChkDbl', 'Bulk data check for duplicates'),
        icon: this.getCustomIcon('/data/admin:bulkChkDbl', 'fa fa-check-double'),
        command: () => this.bulkDataValidation('queueduplicates'),
        disabled: !this._hasUniqKey
      });
    }

    if (this.hasDownloadRole) {
      this._itemsExcel.push({
        // label: 'Download all', icon: 'fas fa-cloud-download-alt', command: () => this.openDownloadOptions(false)
        // icon: 'fas fa-cloud-download-alt', command: () => this.openDownloadOptions(false)
        label: this.getCustomLabel('/data/download:all', 'Download all'),
        icon: this.getCustomIcon('/data/download:all', 'fas fa-cloud-download-alt'),
        command: () => this.openDownloadOptions(false)
      });
      this._itemsExcel.push({
        // label: 'Download selected',
        // icon: 'fas fa-cloud-download-alt',
        label: this.getCustomLabel('/data/download:selected', 'Download selected'),
        icon: this.getCustomIcon('/data/download:selected', 'fas fa-cloud-download-alt'),
        command: () => this.openDownloadOptions(true)
      });
    }
    if (this.hasUploadRole && this._dataMode < 3) {
      this._itemsExcel.push({
        // label: 'Upload',
        // icon: 'fas fa-cloud-upload-alt',
        label: this.getCustomLabel('/data/download:upload', 'Upload'),
        icon: this.getCustomIcon('/data/download:upload', 'fas fa-cloud-upload-alt'),
        command: () => this.docImport()
      });
    }
    if ((this.typeAdmin > 0 || docOrProjectAdmin) && this.showMode === ShowMode.Data && this._dataMode < 3) {
      if (this.typeAdmin == 1) {
        this._itemsExcel.push({separator: true})
        this._itemsExcel.push({
          // label: 'Export data',
          label: this.getCustomLabel('/data/download:export', 'Export data'),
          icon: this.getCustomIcon('/data/download:export', ''),
          command: () => this.downloadProjectData()
        });
      }
    }
    this.itemsLogs = [
      {
        label: this.getCustomLabel('/data/log:public', 'Public'),
        icon: this.getCustomIcon('/data/log:public', ''),
        command: () => this.refreshLogs(1)
      },
      {
        label: this.getCustomLabel('/data/log:private', 'Private'),
        icon: this.getCustomIcon('/data/log:private', ''),
        command: () => this.refreshLogs(2)
      }

    ];
  }

  async onTemplateChanged($event) {
    this.template = $event;

    this.columns = [];
    this.columns.push({
      field: 'ID',
      header: 'ID document',
      cdoc: 'SYS',
      name: 'ID',
      group: true,
      dataType: 'STRING',
      visible: false,
      sortField: 'iddoc'
    });
    this.columns.push({
      field: 'ID_ORG',
      header: 'IdOrgCurr',
      cdoc: 'SYS',
      name: 'ID_ORG',
      group: true,
      dataType: 'STRING',
      visible: false,
      sortField: 'idOrg'
    });
    this.columns.push({
      field: 'ORG_NAME',
      header: 'OrgCurrName',
      cdoc: 'SYS',
      name: 'ORG_NAME',
      group: true,
      dataType: 'STRING',
      visible: false
    });
    this.columns.push({
      field: 'D_SUBMITTED',
      header: 'Submitted date',
      cdoc: 'SYS',
      // name: 'D_SEND',
      name: 'D_SUBMITTED',
      group: true,
      dataType: 'DATE',
      visible: false,
      sortField: 'dateSend'
    });
    this.columns.push({
      field: 'FLAG',
      header: 'Flags',
      cdoc: 'SYS',
      name: 'FLAG',
      group: true,
      dataType: 'STRING',
      visible: false
    });
    this.columns.push({
      field: 'STATUS',
      header: 'Status',
      cdoc: 'SYS',
      name: 'STATUS',
      group: true,
      dataType: 'STRING',
      mutable: true,
      sortField: 'state',
      filterable: true
    });
    this.columns.push({
      field: 'CDOC',
      header: 'Code',
      cdoc: 'SYS',
      name: 'CDOC',
      group: true,
      dataType: 'STRING',
      sortField: 'cdoc',
      filterable: true
    });

    if (this.template.length > 0) {
      const _columns = await this.builderService.getBuilderTemplateParams(this.template);
      _columns.forEach(_col1 => {
        const chk = this._columns.filter(_col => (_col.cdoc === 'SYS' ? `${_col.field}` : `${_col.cdoc}_${_col.field}`) === `${_col1.cdoc}_${_col1.field}`);
        if (chk.length != 0 && !this.HIDDEN_EDIT_TYPES.includes(chk[0].editType)) {
          const rdata = chk[0];
          this.columns.push(QueryDataComponent.convertParam(rdata));
        }
      });
    } else {
      this._columns.forEach(rdata => {
        if (!this.HIDDEN_EDIT_TYPES.includes(rdata.editType)) {
          const rec = QueryDataComponent.convertParam(rdata),
            chk = this.columns.filter(_col => (_col.name === rec.name));
          if (chk.length === 0) {
            this.columns.push(rec);
          }
        }
      });
    }

    // Sys vars
    if (!!this._includeMetadata) {
      this.columns.push({
        // field: 'D_REC',
        field: 'D_CREATED',
        header: 'Created on',
        cdoc: 'SYS',
        name: 'D_CREATED',
        group: true,
        dataType: 'DATE',
        sortField: 'createDate'
      });
      this.columns.push({
        // field: 'D_UPDATE',
        field: 'D_UPDATED',
        header: 'Last update',
        cdoc: 'SYS',
        name: 'D_UPDATE',
        group: true,
        dataType: 'DATE',
        sortField: 'updateDate'
      });
      this.columns.push({
        field: 'CREATE_USER',
        header: 'Created by UserId',
        cdoc: 'SYS',
        name: 'CREATE_USER',
        group: true,
        dataType: 'STRING',
        visible: false
      });
      this.columns.push({
        field: 'CREATE_USER_NAME',
        header: 'Created by',
        cdoc: 'SYS',
        name: 'CREATE_USER_NAME',
        group: true,
        dataType: 'STRING'
      });
      this.columns.push({
        field: 'UPDATE_USER',
        header: 'Updated by UserId',
        cdoc: 'SYS',
        name: 'UPDATE_USER',
        group: true,
        dataType: 'STRING',
        visible: false
      });
      this.columns.push({
        field: 'UPDATE_USER_NAME',
        header: 'Updated by',
        cdoc: 'SYS',
        name: 'UPDATE_USER_NAME',
        group: true,
        dataType: 'STRING'
      });
    }
    // refreshProjectData
    this.columns.map(v => {
      if (v.cdoc !== 'SYS') {
        v.sortField = 'TYPE:' + v.dataType + ':' + v.name;
      }
      return v;
    });
    this.refreshProjectData(0, this.columns, this.isGroupSurvey);
  }

  onTemplateOper($event: any) {
    const _self = this;
    _self.showMode = ShowMode.Template;

    setTimeout(function () {
      _self.builderTemplateRecordCmp.refresh($event, _self._columns);
    }, 100);
  }

  resetFilter() {
    for (const key of Object.keys(this.builderData.filters)) {
      this.builderData.filters[key][0].value = null;
    }
    this.onProjectDataLazyLoad({filters: this.builderData.filters})
  }

  onSavedTemplate(evt: any) {
    this.loading = true;
    this.showMode = ShowMode.Data;
    this.refresh();
  }

  docCreate() {
    if (this.dctDocs.length == 1) {
      const rdata = this.dctDocs[0];
      this.createDocument(rdata);
    }
  }

  docOpen() {
    const rows: any = this.dataSelectedRows;
    if (rows?.length === 1) {
      this.openReport(rows[0].ID);
    }
  }

  private static checkProcessDone(cntAll: number, cnt: number): boolean {
    return cntAll === cnt;
  }


  docInfo(dmode: number, rowClick: any) {
    if (!this.showBtnDocInfo) {
      return;
    }
    if ((dmode === 0) && (this.dataMode !== 0)) {
      return;
    }
    if ((this.dataMode > 0) && (dmode !== this.dataMode)) {
      return;
    }

    let row: any;
    if (rowClick) {
      row = rowClick;
    } else if (this.dataSelectedRows?.length === 1) {
      row = this.dataSelectedRows[0];
    }

    if (row) {
      this.docUtilsService.getListDocParams(row.CDOC)
        .subscribe(data => {
          this.dataMetaRows = [];
          for (let i in data) {
            if (data[i].metadataField !== null && data[i].metadataField !== '') {
              this.dataMetaRows.push(data[i].metadataField);
            }
          }
        });
      this.openInfo(row, this.dataMode);
    }
  }

  datastageWorkflow(dmode: number, rowClick: any) {
    if ((dmode == 0) && (this.dataMode !== 0)) {
      return;
    }
    if ((this.dataMode > 0) && (dmode !== this.dataMode)) {
      return;
    }

    let row: any;
    if (rowClick) {
      row = rowClick;
    } else if (this.dataSelectedRows?.length === 1) {
      row = this.dataSelectedRows[0];
    }

    if (row) {
      this.openWorkFlow(row, this.dataMode);
    }
  }

  docRejectApproveData(status: string, datamode: number) {
    if (((this.dataSelectedRows?.length !== 1) && (datamode == 3))
      || (!this.dataSelectedRows?.length) && (datamode == 4)) {
      return;
    }
    if (!((status === 'under_review') || (status === 'accept') || (status === 'decline') || (status === 'uploaded'))) {
      return;
    }

    this.confirmationService.confirm({
      message: this.translate.instant('message.document.datastage.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        const _self = this;
        this.loading = true;
        // const cntAll = this.dataSelectedRows.length;
        let ids = [];
        for (const row of this.dataSelectedRows) {
          if (row.ID) {
            ids.push(row.ID);
            if (datamode !== 4) {
              break;
            }
          }
        }
        if ((datamode !== 4) && ids.length > 0) {
          _self.docService.approveReject(ids[0], status, datamode).subscribe(
            data => {
              const res = data;
              if (res.status === 1) {

                _self.messageService.showSuccess(this.translate.instant('message.document.datastage.success'));
                _self.refreshProjectData(0, this.columns, this.isGroupSurvey);
              } else {
                this.loading = false;
                _self.messageService.showWarn(res.message);
              }
              this.loading = false;
            },
            error => {
              this.loading = false;
              this.messageService.showMessage(error);
            });
        } else if ((datamode === 4) && ids.length > 0) {
          _self.docService.approveRejectBatch(ids, status, datamode).subscribe(
            data => {
              const res = data;
              if (res.status === 1) {

                _self.messageService.showSuccess(this.translate.instant('message.document.datastage.success'));
                _self.refreshProjectData(0, this.columns, this.isGroupSurvey);
              } else {
                this.loading = false;
                _self.messageService.showWarn(res.message);
              }
              this.loading = false;
            },
            error => {
              this.loading = false;
              this.messageService.showMessage(error);
            });
        }
      }
    });
  }


  docDuplicate() {
    if (!this.dataSelectedRows?.length) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.duplicate.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        this.loading = true;
        const ids = this.dataSelectedRows.map(v => v.ID);
        this.docService.duplicate(ids)
          .pipe(finalize(() => this.loading = false))
          .subscribe(_ => this.refreshProjectData(0, this.columns, this.isGroupSurvey),
            error => this.messageService.showMessage(error)
          );
      }
    });

  }

  docDelete() {
    if (!this.dataSelectedRows?.length) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.delete.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        const _self = this;
        this.loading = true;
        const cntAll = this.dataSelectedRows.length;
        let cnt = 0;
        for (const row of this.dataSelectedRows) {
          if (row.ID) {
            const iddoc = row.ID;
            _self.docService.delete(iddoc)
              .pipe(finalize(() => {
                cnt++;
                if (QueryDataComponent.checkProcessDone(cntAll, cnt)) {
                  _self.refreshProjectData(0, _self.columns, _self.isGroupSurvey);
                }
              }))
              .subscribe(
                data => {
                  const res = data;
                  if (res.status !== 1) {
                    this.loading = false;
                    _self.messageService.showWarn(res.message);
                  }
                },
                error => {
                  this.loading = false;
                  _self.messageService.showMessage(error);
                });
          } else {
            this.loading = false;
            this.messageService.showWarn(this.translate.instant('message.document.noId'));
            cnt++;
            if (QueryDataComponent.checkProcessDone(cntAll, cnt)) {
              _self.refreshProjectData(0, _self.columns, _self.isGroupSurvey);
            }
          }
        }
      }
    });

  }

  docSend() {
    if (this.dataSelectedRows?.length !== 1) {
      return;
    }
    const row: any = this.dataSelectedRows[0];
    const _self = this;
    if (row.ID) {
      const iddoc = row.ID;
      this.idDoc = row.ID;
      this.confirmationService.confirm({
        message: this.translate.instant('message.document.submit.confirmation'),
        header: this.translate.instant('message.header.confirmation'),
        icon: 'fa fa-question-circle',
        accept: () => {
          _self.docService.send(iddoc)
            .subscribe(
              data => {
                const res = data;
                if (res.status === 1) {
                  _self.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
                  _self.refreshProjectData(0, this.columns, this.isGroupSurvey);
                } else if (res.status === 0) {
                  _self.docSendForce(iddoc);
                } else {
                  _self.messageService.showWarn(res.message);
                }
              },
              error => {
                this.loading = false;
                this.messageService.showMessage(error);
              });
        },
        reject: () => {
        }
      });
    } else {
      this.messageService.showWarn(this.translate.instant('message.document.noId'));
    }
  }

  private docSendForce(iddoc: string) {
    const _self = this;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.submit.warnConfirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        _self.docService.sendForce(iddoc)
          .subscribe(
            data => {
              const res = data;
              if (res.status === 1) {
                _self.messageService.showSuccess(this.translate.instant('message.document.submit.success'));
                _self.refreshProjectData(0, _self.columns, _self.isGroupSurvey);
              } else {
                this.loading = false;
                _self.messageService.showWarn(res.message);
              }
            },
            error => {
              this.loading = false;
              _self.messageService.showMessage(error);
            });
      },
      reject: () => {
      }
    });
  }

  async downloadData(onlySelected?: boolean, optionsMask?: any) {
    let filter_columns = [];
    let cdocs0: any[] = [];
    this.projectsDocs = await this.tmplService.getListProjectDocs(this._idProject);
    this.projectsDocs.forEach(rdata => {
        if (
          ((rdata.access === 1) || ((this.authService.user.typeAdmin === 1) || this.isGroupSurvey)) &&
          ((rdata.mode < 2 && (this._dataMode === 4 || this._dataMode < 2))
            // || (rdata.mode == 0 && this._dataMode === 4)
            || (rdata.mode >= 2 && rdata.mode === this._dataMode))) {
          if (!((this.authService.user.typeAdmin === 1) || this.isGroupSurvey) && !this.access.some(v => v.cdoc === rdata.cdoc && (v.accessCreate || v.roleDownload))) {
            return;
          }
          cdocs0.push(rdata);
        }
      }
    );
    if (cdocs0.length < 1) {
      this.loading = false;
      this.messageService.showError('No rights to download data!');
      return;
    }
    if (optionsMask['fullDownload']) {
      filter_columns.push({
        field: 'ID',
        header: 'ID document',
        cdoc: 'SYS',
        name: 'ID',
        group: true,
        dataType: 'STRING',
        visible: false,
        sortField: 'iddoc',
        order: 1
      });
      filter_columns.push({
        field: 'ID_PAR',
        header: 'Parent ID',
        cdoc: 'SYS',
        name: 'ID_PAR',
        group: true,
        dataType: 'STRING',
        visible: false,
        sortField: 'idpar',
        order: 2
      });
      filter_columns.push({
        field: 'FLAG',
        header: 'Flags',
        cdoc: 'SYS',
        name: 'FLAG',
        group: true,
        dataType: 'STRING',
        visible: false,
        order: 3
      });
      filter_columns.push({
        field: 'STATUS',
        header: 'Status',
        cdoc: 'SYS',
        name: 'STATUS',
        group: true,
        dataType: 'STRING',
        mutable: true,
        sortField: 'state',
        filterable: true,
        order: 4
      });
      filter_columns.push({
        field: 'CDOC',
        header: 'Code',
        cdoc: 'SYS',
        name: 'CDOC',
        group: true,
        dataType: 'STRING',
        sortField: 'cdoc',
        order: 5
      });

      for (let onedoc of cdocs0) {
        // for (let onedoc of this.access) {
        for (let rdata of this._columns) {
          if (rdata.cdoc === onedoc.cdoc) {
            const rec = QueryDataComponent.convertParam(rdata),
              chk = filter_columns.filter(_col => (_col.name === rec.name && _col.cdoc === rec.cdoc));
            if (chk.length === 0 && !this.HIDDEN_EDIT_TYPES.includes(rdata.editType)) {
              filter_columns.push(rec);
            }
          }
        }

      }

      // Sys vars
      if (!!this._includeMetadata) {
        filter_columns.push({
          field: 'D_CREATED',
          header: 'Created on',
          cdoc: 'SYS',
          name: 'D_CREATED',
          group: true,
          dataType: 'DATE',
          sortField: 'createDate',
          order: -1
        });
        filter_columns.push({
          field: 'D_UPDATED',
          header: 'Last update',
          cdoc: 'SYS',
          name: 'D_UPDATED',
          group: true,
          dataType: 'DATE',
          sortField: 'updateDate',
          order: -2
        });
        filter_columns.push({
          field: 'D_SUBMITTED',
          header: 'Submitted date',
          cdoc: 'SYS',
          name: 'D_SUBMITTED',
          group: true,
          dataType: 'DATE',
          visible: false,
          sortField: 'dateSend',
          order: -3
        });
        filter_columns.push({
          field: 'CREATE_USER',
          header: 'Created by UserId',
          cdoc: 'SYS',
          name: 'CREATE_USER',
          group: true,
          dataType: 'STRING',
          visible: false,
          order: -4
        });
        filter_columns.push({
          field: 'CREATE_USER_NAME',
          header: 'Created by',
          cdoc: 'SYS',
          name: 'CREATE_USER_NAME',
          group: true,
          dataType: 'STRING',
          order: -5
        });
        filter_columns.push({
          field: 'UPDATE_USER',
          header: 'Updated by UserId',
          cdoc: 'SYS',
          name: 'UPDATE_USER',
          group: true,
          dataType: 'STRING',
          visible: false,
          order: -6
        });
        filter_columns.push({
          field: 'UPDATE_USER_NAME',
          header: 'Updated by',
          cdoc: 'SYS',
          name: 'UPDATE_USER_NAME',
          group: true,
          dataType: 'STRING',
          order: -7
        });
      }
      if (!this.isGroupSurvey) {
        filter_columns.push({
          field: 'ORG_NAME',
          header: 'OrgCurrName',
          cdoc: 'SYS',
          name: 'ORG_NAME_USER',
          group: true,
          dataType: 'STRING',
          visible: false,
          order: -8
        });
      }
    } else {
      filter_columns = this.columns;
    }

    if (filter_columns.length > 0 && cdocs0.length > 0) {
      let vars = [];
      let selected: string[] = null;
      let cdocs: string[] = []
      if (onlySelected && this.dataSelectedRows?.length) {
        selected = this.dataSelectedRows.map(v => v.ID);
        cdocs = this.dataSelectedRows.map(v => v.CDOC);
      }
      const columns = selected
        ? filter_columns.filter(v => v.cdoc === 'SYS' || cdocs.includes(v.cdoc))
        : filter_columns;
      const tabns = [];
      for (let rdata of columns) {
        const mutable = rdata.mutable || false, group = rdata.group || false;
        if (rdata.dataType === 'TABLE' && !tabns.includes({cdoc: rdata.cdoc, tabn: rdata.tabn})) {
          const tabCols: any[] = await this.builderService.getBuilderTabParams(this.idProject, rdata.cdoc, rdata.tabn);
          vars.push(...tabCols.map(v => QueryDataComponent.convertParam(v)));
          tabns.push({cdoc: rdata.cdoc, tabn: rdata.tabn});
          continue;
        } else if (rdata.dataType === 'SUBFORM') {
          continue;
        }
        if (!mutable) {
          if (group) {
            rdata.cdoc = 'SYS'
          }
          vars.push(rdata);
        }
      }
      let filtr = optionsMask['fullDownload'] ? '' : this.buildFilter() + '&';
      this.projectService.exportReportData(this._idProject, selected, vars, filtr + 'datamode=' + this._dataMode, optionsMask)
        .subscribe(data => {
            this.messageService.showInfo(this.translate.instant('message.request.inProgress'));
          },
          error => {
            this.loading = false;
            this.messageService.showMessage(error);
          });
    }
  }

  downloadProjectData() {
    this.projectService.exportProjectData(this._idProject)
      .subscribe(data => {
          this.messageService.showInfo(this.translate.instant('message.request.inProgress'));
        },
        error => {
          this.messageService.showMessage(error);
        })
  }

  docImport() {
    this.importCmp.open(this._idProject);
  }

  onProcessDoc(ret: RespOnProcessDoc) {
    if (ret.oper === CProcessType.Save || ret.oper === CProcessType.Remove || ret.oper === CProcessType.Reset
      || (ret.oper === CProcessType.Send && ret.status === 1) || ret.oper === CProcessType.SendForce) {
      this.refreshProjectData(0, this.columns, this.isGroupSurvey);
    }
  }

  onImportProc(ret: boolean) {
    if (ret) { // import completed
      this.messageService.showInfo(this.translate.instant('message.request.inProgress'));
    }
  }

  beforeDocChangeOwner() {
    this.showDlgUsers = true;
  }

  beforeRecordOwners() {
    this.selectedIdDocs = this.dataSelectedRows.map(v => v.ID);
    setTimeout(() => this.recOwnersVisible = true);
  }


  bulkDataValidation(queuerule: string) {
    this.selectedIdDocs = this.dataSelectedRows.map(v => v.ID);
    this.importService.bulkDataValidation(this._idProject, queuerule, this._dataMode, this.selectedIdDocs)
      .subscribe(data => {
          this.messageService.showInfo(this.translate.instant('message.request.inProgress'));
        },
        error => {
          this.messageService.showMessage(error);
        })
  }

  getRowAttr(attr) {
    if (attr == 'metadata') {
      return this.dataMetaRows;
    }
    return this.dataSelectedRows?.length === 1 && this.dataSelectedRows[0][attr] ? this.dataSelectedRows[0][attr] : '';
  }

  docChangeOwner(user: User) {
    if (!this.dataSelectedRows?.length || !user?.id) {
      return;
    }
    const userId = user.id;
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.changeOwner.confirmation'),
      header: this.translate.instant('message.header.confirmation'),
      icon: 'fa fa-question-circle',
      accept: () => {
        const _self = this;
        this.loading = true;
        const cntAll = this.dataSelectedRows.length;
        let cnt = 0;
        for (const row of this.dataSelectedRows) {
          const iddoc = row.ID;
          this.idDoc = row.ID;
          _self.docService.changeOwner(iddoc, userId)
            .pipe(finalize(() => {
              cnt++;
              if (QueryDataComponent.checkProcessDone(cntAll, cnt)) {
                _self.refreshProjectData(0, _self.columns, _self.isGroupSurvey);
              }
            }))
            .subscribe(
              data => {
                const res = data;
                if (res.status !== 1) {
                  this.loading = false;
                  _self.messageService.showWarn(res.message);
                }
              },
              error => {
                this.loading = false;
                _self.messageService.showMessage(error);
              });
        }
      }
    });
  }

  itemsExcelDropdownClick() {
    if (this.hasDownloadRole) {
      this._itemsExcel[1].disabled = !this.dataSelectedRows?.length;
    }
  }

  itemsAdminOptionClick() {
    if (this._itemsAdmin?.length > 0) {
      this._itemsAdmin[0].disabled = !this.dataSelectedRows?.length; //Assign Users to the record(s)
    }
    if (this._itemsAdmin?.length > 1) {
      this._itemsAdmin[1].disabled = !this.dataSelectedRows?.length; //Assign Owner of the record(s)
    }
  }

  itemsRejectAproveClick() {
  }

  isSelected(id: string): boolean {
    return this.dataSelectedRows.find(v => v.ID === id);
  }

  isDisabledBtnDocOpen(): boolean {
    return this.loading || this.dataSelectedRows?.length !== 1
      || !this.access.some(v => v.cdoc === this.dataSelectedRows[0].CDOC && (v.accessCreate || v.accessWrite || v.accessView));
  }

  isDisabledBtnDocDuplicate(): boolean {
    return this.loading || !this.dataSelectedRows?.length
      || !this.dataSelectedRows.every(d => this.access.some(v => v.cdoc === d.CDOC && v.accessCreate));
  }

  isDisabledBtnInfo(): boolean {
    return this.loading || this.dataSelectedRows?.length !== 1
      || !this.access.some(v => v.cdoc === this.dataSelectedRows[0].CDOC);
  }

  isDisabledBtnDocRemove(): boolean {
    return this.loading || !this.dataSelectedRows?.length
      || !this.dataSelectedRows.every(d => this.access.some(v => v.cdoc === d.CDOC && v.accessRemove));
  }

  isDisabledBtnDocSend(): boolean {
    return this.loading || this.dataSelectedRows?.length !== 1
      || !this.access.some(v => v.cdoc === this.dataSelectedRows[0].CDOC && v.accessSubmit);
  }

  isDisabledBtnDocExport(): boolean {
    const row = this.dataSelectedRows;
    return row == null;
  }

  isShowBtnDocCreate(): boolean {
    return this.showBtnDocCreate;
  }

  isShowBtnDocOpen(): boolean {
    return this.showBtnDocOpen;
  }

  isShowBtnDocDuplicate(): boolean {
    return this.showBtnDocDuplicate;
  }

  isShowBtnDocRemove(): boolean {
    return this.showBtnDocRemove;
  }

  isShowBtnDocSend(): boolean {
    return this.showBtnDocSend;
  }

  get isDisabledBtnReset() {
    return this.loading;
  }

  get isDisabledBtnLogs(): boolean {
    return this.loading || this.dataSelectedRows?.length !== 1;
  }

  get isDisabledBtnCharts(): boolean {
    return this.loading || this.docCharts.length < 1;
  }

  get isShowBtnDownloadUpload(): boolean {
    return this.configService.isDesktopDevice && this.dataMode !== 3 && this.itemsExcel?.length > 0;
  }

  get isDisabledBtnDownloadUpload(): boolean {
    return this.loading;
  }

  get isDisabledBtnRejectApprove(): boolean {
    return this.loading || (!((this.dataSelectedRows?.length == 1 && this._dataMode != 4) || (this.dataSelectedRows?.length >= 1 && this._dataMode == 4)));
  }

  get isShowBtnChangeOwner(): boolean {
    return this.configService.isDesktopDevice && this.authService.user.typeAdmin === 1 && !this.isGroupSurvey && this.dataMode !== 3;
  }

  get isDisabledBtnChangeOwner(): boolean {
    return this.loading;
  }

  isDisabledBtnCopyLink(): boolean {
    if (!this.access?.length) {
      return true;
    }
    return this.dataSelectedRows?.length !== 1 || this.dataSelectedRows[0].DOC_STATE >= 2 || !this.isGroupSurvey
      || !(this.typeAdmin > 0 || this.access.some(v => v.cdoc === this.dataSelectedRows[0].CDOC && (v.accessDocumentAdmin || v.accessProjectAdmin)))
      || this.loading;
  }

  openReport(id: string) {
    if (id) {
      this.idDoc = id;
      setTimeout(() => this.docEditorVisible = true);
    } else {
      this.messageService.showWarn(this.translate.instant('message.document.noId'));
    }
  }

  private openInfo(row: any, datamode: number) {
    if (row.ID) {
      this.idDoc = row.ID;
      this.documentInfoCmp.refresh(this.idDoc, row, datamode);
      this.showDlgInfo = true;
    } else {
      this.messageService.showWarn(this.translate.instant('message.document.noId'));
    }
  }

  private openWorkFlow(row: any, datamode: number) {
    if (row.ID) {
      this.idDoc = row.ID;
      this.projectDataStageWorkflowCmp.refresh(this.idDoc, row, datamode);
      this.showDlgWorkflow = this._hasApproveRole;
    } else {
      this.messageService.showWarn(this.translate.instant('message.document.noId'));
      this.showDlgWorkflow = false;
    }
  }

  docstageWorkflowSuccess($event: any) {
    this.showDlgWorkflow = false;
    if ($event && $event.iddoc && this.dataSelectedRows.length == 1 && this.dataSelectedRows[0].ID === $event.iddoc) {
      this.docRejectApproveData($event.status, $event.datamode);
    }
  }

  docstageWorkflowSuccessClose() {
    this.showDlgWorkflow = false;
  }

  /**
   *
   * @param chartMeta - dictionary from charts
   */
  private openChart(chartMeta: any) {
    if (chartMeta?.type.length && chartMeta?.type.toLowerCase().startsWith('geo')) {
      this.documentGeoChartsCmp.refresh(chartMeta, this.buildFilter(), this._dataMode);
      this.showDlgGeoCharts = true;
      this.dlgChartHeader = chartMeta?.name;
    } else {
      this.documentChartsCmp.refresh(chartMeta, this.buildFilter(), this._dataMode);
      this.showDlgCharts = true;
      this.dlgChartHeader = chartMeta?.name;
    }
  }

  onHideCharts() {
    this.documentChartsCmp.mychart.chart.destroy();
  }

  onHideGeoCharts() {

  }

  createDocument(rdata: any) {
    if (rdata.parents) {
      if (this.dataSelectedRows?.length === 1) {
        const fnd = rdata.parents.some(e => e === this.dataSelectedRows[0].CDOC);
        if (!fnd) {
          this.messageService.showWarn(this.translate.instant('message.document.create.wrongType'));
        }
      } else {
        this.messageService.showWarn(this.translate.instant('message.document.create.selRecord'));
      }
    } else {
      this.createCdoc = rdata.cdoc;
      this.docService.createCheck(rdata.cdoc)
        .subscribe(
          data => {
            const res = data;
            if (res.status !== 1) {
              this.messageService.showError(res.message);
            } else {
              this.idDoc = res.message;
              setTimeout(() => this.docEditorVisible = true);
            }
          },
          error => {
            this.messageService.showMessage(error);
          });
    }
  }

  get items(): MenuItem[] {
    return [
      {
        label: 'Open',
        icon: 'fa fa-pencil',
        disabled: this.isDisabledBtnDocOpen(),
        visible: this.isShowBtnDocOpen(),
        command: () => {
          this.docOpen();
        }
      },
      {
        label: 'Remove',
        icon: 'fa fa-trash',
        disabled: this.isDisabledBtnDocRemove(),
        visible: this.isShowBtnDocRemove(),
        command: () => {
          this.docDelete();
        }
      },
      {
        label: 'Export', icon: 'fa fa-download', disabled: this.isDisabledBtnDocExport(), command: () => {
          this.downloadData();
        }
      },
      {separator: true},
      {
        label: 'Refresh', icon: 'fa fa-refresh', command: () => {
          this.refreshProjectData(0, this.columns, this.isGroupSurvey);
        }
      },
    ]
  }

  get configService(): ConfigService {
    return this._configService;
  }

  get typeAdmin(): number {
    return this.authService.user.typeAdmin;
  }

  get itemsExcel(): MenuItem[] {
    return this._itemsExcel;
  }

  get itemsAdmin(): MenuItem[] {
    return this._itemsAdmin;
  }

  get itemsRejectAprove(): MenuItem[] {
    return this._itemsRejectAprove;
  }

  get itemsCharts(): MenuItem[] {
    return this._itemsCharts;
  }

  get docs(): MenuItem[] {
    return this._docsList;
  }

  //<< Builder Data

  refreshProjectData(npage: number, columns: any, isGroupSurvey: boolean) {
    this.dataSelectedRows = [];
    this.projectData.content = [];
    this.isGroupData = isGroupSurvey;
    const columnsNew = (columns as any[]).filter(v => (v.tabn || 0) === 0 || v.dataType === 'TABLE');
    if (this.isGroupData) {
      let columns1 = [];
      for (const i in columnsNew) {
        let isExists = false;
        const col = columnsNew[i];
        for (const j in columns1) {
          const col1 = columns1[j];
          if (col1['name'] == col['name']) {
            isExists = true;
            break;
          }
        }
        if (isExists == false) {
          if (col.dataType === 'TABLE') {
            col['field'] = col['name'];
          }
          columns1.push(col);
        }
      }
      this.columnsGrp = columns1;
    } else {
      this.columnsGrp = [];
    }
    this._visibleColumns = this.getVisibleColumns();
    this.loadProjectData(npage, this.buildFilter());
  }

  onProjectDataLazyLoad(event) {
    this.projectData.content = [];
    this.filter = event.filters;
    const newPageSize = event.rows !== 0 ? event.rows : this.pageSize;
    if (newPageSize && newPageSize !== this.pageSize) {
      this.pageSize = newPageSize;
      this.projectService.setCurrentUserProjectUiSettings(this._idProject, [{
        name: 'DATA_VIEW:PAGE_SIZE',
        value: newPageSize
      }])
        .subscribe();
    }
    this.sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;
    if (!this.sorting) {
      this.sorting = '&sort=createDate,desc';
    }
    this.loadProjectData(event.rows == 0 ? 0 : Math.floor(event.first / event.rows), this.buildFilter())
  }

  errorStatus(row: any): number {
    const status = row.FLAG || 0;
    if (status === 0) return 0;
    if ((status & 64) !== 0) return 3;
    if ((status & 2) !== 0 ||
      (status & 4) !== 0 ||
      (status & 8) !== 0) return 2;
    return 1;
  }

  getVisibleColumns() {
    const cols = this.isGroupData ? (this.columnsGrp || []) : (this.columns || []);
    return cols.filter((col) => (((col.visible ?? true) &&
      (col.cdoc === 'SYS' && ((this._dataMode !== 3) || (col.field !== 'CDOC' && this._dataMode === 3)))
    ) || col.cdoc !== 'SYS') && col.editType !== 'file-meta');
  }

  onDblRowClick(event, row) {
    this.openReport(row.ID);
  }

  async loadProjectData(page: number, filter?: string) {
    if (this._idProject.length > 0 && this.columns && this.columns.length > 0) {
      try {
        this.dataSelectedRows = [];
        this.projectData.content = [];
        this.loading = true;
        const codes = [...new Set(this._columns.filter(v => !v.mutable && !v.group).map(v => v.cdoc))]
          .filter(v => this.dctDocs.some(a => a.cdoc === v)).join(',');
        let fields = [], tab = [], subDocs = [];
        for (let i in this.columns) {
          const rdata = this.columns[i], mutable = rdata.mutable || false, group = rdata.group || false;
          if (!mutable && group === false) {
            if (this.template !== '') { // template choosen
              if ((rdata.tabn || 0) === 0) {
                fields.push(rdata.name);
              }
            } else { // default tempplate "FULL LIST"
              if ((rdata.tabn || 0) !== 0) {
                tab.push({tabn: rdata.tabn, name: rdata.name})
              } else if (rdata.dataType === 'SUBFORM') {
                subDocs.push({cdoc: rdata.cdoc, name: rdata.name});
              }
            }
          }
        }
        this.fields = fields;
        const docs = await this.builderService.getQueryDocuments2(this._idProject, fields,
          `datamode=${this._dataMode}&codes=${codes.length > 0 ? codes : '*'}&page=${page}&size=${this.pageSize}` + (filter || ''));
        let ids = [], sIds = ''; // documents ids
        let newDocs = [];
        for (const i in docs.content) {
          const rdata = docs.content[i];
          docs.content[i] = QueryRowUtils.processRowData(rdata, this._dataMode);
          ids.push(rdata.ID);
          sIds = (sIds.length != 0 ? `${sIds},` : ``) + `'${rdata.ID}'`;
          if (!rdata.VIEWED) {
            newDocs.push(rdata.ID);
          }
        }
        if (newDocs.length) {
          this.docService.markViewed(newDocs).subscribe(
            _ =>
              setTimeout(() => this.messagingService.updateProjectNewDocsCount.next({
                id: this._idProject,
                cnt: newDocs.length
              }), 3000)
          );
        }
        const dataTb = tab.length != 0 ? await this.builderService.getQueryDocumentsTabData(this._idProject, ids) : [];
        for (const j in dataTb) {
          const rdataVal = dataTb[j];
          for (const i in docs.content) {
            let rdata = docs.content[i];
            if (rdataVal.iddoc === rdata.ID) {
              rdata[`TABLE_VIEW_${rdataVal.tabn}`] = 1;
            }
          }
        }
        const dataSubDocs = subDocs.length != 0 ? await this.builderService.getQueryDocumentsSubDocsData(this._idProject, ids) : [];
        for (const rdataVal of dataSubDocs) {
          for (const rdata of docs.content) {
            if (rdataVal.idpar === rdata.ID) {
              rdata[`SUBFORM_VIEW_${rdataVal.cdoc}`] = (rdata[`SUBFORM_VIEW_${rdataVal.cdoc}`]
                ? (rdata[`SUBFORM_VIEW_${rdataVal.cdoc}`] + ';') : '') + rdataVal.cnt + ':' + rdataVal.iddoc;
            }
          }
        }
        this.loading = false;
        this.projectData = docs;
      } catch (e) {
        console.error(e)
        this.loading = false;
      }
    }
  }

  private buildFilter(): string {
    let filter = this.sorting || '';
    if (this.filter) {
      let search = UIUtils.getFilterStrNew(this.filter);
      if (search.length > 0) {
        filter += `&search=${search}`;
      }
    }
    return filter;
  }

  downloadFileData(iddoc: string, id: string) {
    this.builderService.getFileLink(iddoc, id)
      .subscribe(
        data => {
          window.open(data, '_blank');
        },
        error => {
          this.messageService.showMessage(error);
        }
      );
  }

  viewTableData(id: string, cdoc: string, col: any) {
    this.queryTabData.refresh(id, cdoc, col.tabn, this.isGroupData);
  }

  copyLink() {
    if (this.dataSelectedRows?.length !== 1) {
      return;
    }
    this.confirmationService.confirm({
      message: this.translate.instant('message.document.copyLink'),
      header: this.translate.instant('message.header.warn'),
      icon: 'fas fa-exclamation',
      accept: () => this.getDocumentExtToken(this.dataSelectedRows[0].ID)
    });
  }

  private getDocumentExtToken(iddoc: string) {
    this.loading = true;
    this.docService.getDocumentToken(iddoc, 1)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        data => this.clipboardService.copy(UIUtils.getPublicDocLink(data.message)),
        error => this.messageService.showMessage(error)
      );
  }

  private static convertParam(rdata: any): any {
    return {
      field: rdata.field,
      cdoc: rdata.cdoc,
      name: rdata.field,
      header: rdata.label,
      group: false,
      dataType: rdata.dataType,
      filterable: true,
      visible: rdata.visible,
      required: rdata.required,
      uniqKey: rdata.uniqKey,
      editType: rdata.editType,
      tabn: rdata.tabn
    }
  }

  visualizeData() {
    this.showMode = ShowMode.Chart;
  }

  private get isMobileResolution() {
    return this._configService.isMobileDevice;
  }

  get idProject(): string {
    return this._idProject;
  }

  get defaultTemplate(): string {
    return this._defaultTemplate;
  }

  get visibleColumns(): any[] {
    return this._visibleColumns;
  }

  downloadLog(idDownload: string) {
    if (idDownload !== null && idDownload !== 'undefined' && idDownload !== '')
      this.downloadService.download(idDownload);
  }

  private openDownloadOptions(onlySelected?: boolean) {
    if (onlySelected && !this.dataSelectedRows?.length) {
      this.messageService.showWarn(this.translate.instant('message.document.noId'));
      this.showDlgDownloadOptions = false;
      return;
    }
    this.projectDataDownloadOptionsCmp.refresh(this._dataMode, !!onlySelected, 1 | 0 | 8); //2^0 Variable name, 2^1 - Variable description  , 2^2 - Upload raw data including codes where possible, 2^3 Ignore filters (Full download)
    this.showDlgDownloadOptions = this.hasDownloadRole;
  }

  downloadOptionsSuccess($event: any) {
    this.showDlgDownloadOptions = false;
    this.downloadData($event.onlySelected, $event.options);
  }

  downloadOptionsSuccessClose() {
    this.showDlgDownloadOptions = false;
  }

  getAsteriskClass(column: any): string {
    if (column?.uniqKey) return 'pk'
    if (column?.required) return 'required'
    return ''
  }

  getCustomIcon(buttonPath: string, defaultVal: string) {
    if ((this.uiButtons !== undefined) && (this.uiButtons.hasOwnProperty(buttonPath))) {
      return this.uiButtons[buttonPath].icon;
    }
    return defaultVal;
  }

  getCustomLabel(buttonPath: string, defaultVal: string) {
    if ((this.uiButtons !== undefined) && (this.uiButtons.hasOwnProperty(buttonPath))) {
      return this.uiButtons[buttonPath].blabel;
    }
    return defaultVal;
  }

  getCustomToolTip(buttonPath: string, defaultVal: string) {
    if ((this.uiButtons !== undefined) && (this.uiButtons.hasOwnProperty(buttonPath))) {
      return this.uiButtons[buttonPath].tooltip;
    }
    return defaultVal;
  }
}

@Pipe({
  name: 'dataColumnClass'
})
export class DataColumnClassPipe implements PipeTransform {
  transform(state: number): string {
    let cls = 'doc-state-new';

    if (state == 5) {
      cls = 'doc-state-imported'; //imported
    } else if (state == 9) {
      cls = 'doc-state-uploaded'; //  data staging env,  Uploaded
    } else if (state == 6) {
      cls = 'doc-state-approved'; // data staging env,  approved
    } else if (state == 7) {
      cls = 'doc-state-rejected'; // data staging env,  rejected
    } else if (state == 8) {
      cls = 'doc-state-underreview'; // data staging env,  Under Review
    } else if (state == 2) {
      cls = 'doc-state-sent';
    } else if (state == 3) {
      cls = 'doc-state-declined';
    } else if (state == 4) {
      cls = 'doc-state-accepted';
    } else if (state == 1) {
      cls = 'doc-state-sent';
    }
    return cls;

  }
}

enum ShowMode {
  Data, Template, Chart
}

@Pipe({
  name: 'outputErrorName'
})
export class OutputErrorName implements PipeTransform {
  transform(row: any): string {
    let ret: string = '';
    const status: number = row.FLAG,
      state: number = row.STATE;
    if (state !== 0) {
      if ((status & /*ERR_CONTROL*/8) !== 0) {
        ret = 'contains errors';
      }
      if ((status & /*ERR_RULE*/2) !== 0) {
        ret += (ret.length !== 0 ? ', ' : '') + 'has inspections errors';
      }
      if ((status & /*ERR_XSD*/4) !== 0) {
        ret += (ret.length !== 0 ? ', ' : '') + 'has xsd errors';
      }
      if ((status & /*DUPLICATE_KEY*/64) !== 0) {
        ret += (ret.length !== 0 ? ', ' : '') + 'has duplicate keys';
      }
      ret = ret.length === 0 ? 'Valid' : ('The document ' + ret + '. Please correct your input.');
      return ret +
        `\n${row.D_UPDATED ? 'Updated' : 'Created'} on: ${row.D_UPDATED || row.D_CREATED}` +
        `${row.CREATE_USER_NAME ? '\nCreated by: ' + row.CREATE_USER_NAME : ''}` +
        `${row.UPDATE_USER_NAME ? '\nUpdated by: ' + row.UPDATE_USER_NAME : ''}` +
        `${row.STATE == 2 ? '\nSubmitted on: ' + row.D_SUBMITTED : ''}`;
    }
    return 'The document not yet validated' +
      `\n${row.D_UPDATE ? 'Updated' : 'Created'} on: ${row.D_UPDATED || row.D_CREATED}` +
      `${row.CREATE_USER_NAME ? '\nCreated by: ' + row.CREATE_USER_NAME : ''}` +
      `${row.UPDATE_USER_NAME ? '\nUpdated by: ' + row.UPDATE_USER_NAME : ''}` +
      `${row.STATE == 2 ? '\nSubmitted on: ' + row.D_SUBMITTED : ''}`;
  }
}


@Pipe({
  name: 'OutputErrorNameWoUser'
})
export class OutputErrorNameWoUser implements PipeTransform {
  transform(row: any): string {
    let ret: string = '';
    const status: number = row.FLAG,
      state: number = row.STATE;
    if (state !== 0) {
      if ((status & /*ERR_CONTROL*/8) !== 0) {
        ret = 'contains errors';
      }
      if ((status & /*ERR_RULE*/2) !== 0) {
        ret += (ret.length !== 0 ? ', ' : '') + 'has inspections errors';
      }
      if ((status & /*ERR_XSD*/4) !== 0) {
        ret += (ret.length !== 0 ? ', ' : '') + 'has xsd errors';
      }
      if ((status & /*DUPLICATE_KEY*/64) !== 0) {
        ret += (ret.length !== 0 ? ', ' : '') + 'has duplicate keys';
      }
      ret = ret.length === 0 ? 'Valid' : ('The document ' + ret + '. Please correct your input.');

      return ret;
    }
    return 'The document not yet validated';
  }
}
