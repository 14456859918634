import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard  {
  constructor(private router: Router, private authService: AuthService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url;
    this.authService.redirectUrl = url;
    this.authService.projectRegistration = '';
    if (url === '/privacy-notice') {
      return true;
    }
    const registrationConfirmRe = /^\/registration\/confirm$/;
    if (this.authService.loggedIn() && registrationConfirmRe.test(url)) {
      return true;
    }
    if (this.authService.loggedIn() && !this.authService.user.id && url !== '/') {
      this.router.navigate(['/']);
      return true;
    }
    const repViewRe = /^\/reports\/view\/\w{32}$/;
    const dataTabRe = /^\/projects\/\w{32}\/data$/;
    const registrationRe = /^\/registration\/(\w{6,8})(?:(?:\?type=(\w{3,4}))|)(?:(?:[&\\?]id=(\w{32}))|)$/;
    if (!this.authService.loggedIn()) {
      if (repViewRe.test(url) || dataTabRe.test(url) || registrationRe.test(url)) {
        this.authService.projectDataView = dataTabRe.test(url);
        if (registrationRe.test(url)) {
          const match = url.match(registrationRe);
          if (match[2] === 'org' && !match[3]) {
            this.router.navigate(['/']);
            return false;
          }
          this.authService.projectRegistration = {cdoc: match[1], type: match[2], id: match[3]};
        }
        window.location.href = '/saml/login';
        return false;
      } else if (url !== '/') {
        this.router.navigate(['/']);
        return false;
      } else {
        return true;
      }
    }
    if (this.authService.loggedIn() && this.authService.user.id) {
      if (registrationRe.test(url)) {
        const match = url.match(registrationRe);
        if (match[2] === 'org' && !match[3]) {
          this.router.navigate(['/home']);
        } else {
          const data = {cdoc: match[1], type: match[2], id: match[3]};
          this.router.navigateByUrl('/registration/confirm', {state: data, skipLocationChange: true});
          this.authService.projectRegistration = '';
        }
        return true;
      }
      this.authService.projectDataView = dataTabRe.test(url);
      if (repViewRe.test(url) || dataTabRe.test(url)) {
        return true;
      } else if (url === '/' || url.startsWith('/#')) {
        this.router.navigate(['/home']);
        return true;
      }
    }
    return true;

  }
}
