import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {firstValueFrom, Observable, of, Subject} from 'rxjs';
import {Cookie} from 'ng2-cookies';
import {LogonUser, OAuthHelper} from './oauth.helper';
import {HttpClient} from '@angular/common/http';
import {MenuRefreshOpt} from '../_domains/UITypes';
import {AppMessageService} from './app-message.service';
import {CustomMenuItem} from '../_domains/CustomMenuItem';
import {EnvService} from './env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private subjectAuthService: Subject<boolean> = new Subject<boolean>();
  private subjectUserService: Subject<MenuRefreshOpt> = new Subject<MenuRefreshOpt>();
  getAuthData$ = this.subjectAuthService.asObservable();
  getUserData$ = this.subjectUserService.asObservable();
  private _redirectUrl: string;
  private _projectDataView: boolean;

  constructor(private http: HttpClient,
              private configService: ConfigService,
              private messageService: AppMessageService,
              private envService: EnvService) {
  }

  ssoLogin(ssoId: string, success: () => any, failure: (resp) => any) {
    return this.http.put<any>(this.configService.api.oauth.ssoLogin, ssoId)
      .subscribe(data => {
          this.setConnectSso(data, success);
        },
        error => {
          failure(error);
          if (error.status !== 401) {
            if (error.error && error.error.error_description) {
              this.messageService.showError(error.error.error_description);
            } else {
              this.messageService.showMessage(error);
            }
          }
        });
  }

  refreshToken(): Observable<any> {
    const data = `grant_type=refresh_token&refresh_token=${OAuthHelper.tokenObject.refresh_token}&client_id=${this.envService.clientId}`;
    return this.http.post<any>(`${this.configService.api.oauth.token}`, data,
      {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
  }

  revokeToken(tokenValue: string): Observable<any> {
    // todo check token revocation
    return of(null);
    if (!tokenValue) {
      return of(null);
    }
    const data = `token=${tokenValue}`;
    return this.http.post<any>(this.configService.api.oauth.logout, data);
  }

  private setConnectSso(authRes: any, callback: () => any) {
    OAuthHelper.tokenObject = {...authRes};
    return this.http.get<LogonUser>(this.configService.api.account.authInfo)
      .subscribe(data => {
          // store token
          OAuthHelper.tokenObject = {...authRes, user: data};
          Cookie.set('DCPS-AUTH', 'true', null, '/');
          this.subjectAuthService.next(true);
          callback();
        }, error => this.messageService.showMessage(error)
      );
  }

  getUserProjects(): Observable<any[]> {
    return this.http.get<any[]>(`${this.configService.api.authuser.projects}`);
  }

  private async isProjectAdmin() {
    return await firstValueFrom(this.http.get<boolean>(this.configService.api.account.authCheckProjectAdmin));
  }

  async setMenuItems() {
    let isProjectAdmin;
    try {
      isProjectAdmin = await this.isProjectAdmin();
    } catch (err) {
      isProjectAdmin = false;
    }
    let ret: CustomMenuItem[] = [];
    const _user: LogonUser = this.user;
    const _typeAdmin: number = _user.typeAdmin;
    let _projects1 = [], _projects0 = [], _projects2 = [];
    // if (_user.typeAdmin !== 0) {
    //   _projects1.push({Label: 'New project', Icon: 'fa-plus', RouterLink: 'projects/add'});
    // }
    let res = [];
    try {
      res = await firstValueFrom(this.getUserProjects());
    } catch (err) {
      return ret;
    }
    for (let i in res) {
      const rdata = res[i];
      const labels = _typeAdmin > 0 ? rdata.labels : '';
      if (rdata.access === 1) {
        _projects1.push({
          Label: rdata.name,
          Icon: rdata.grType == 1 ? 'fa-key' : null,
          RouterLink: `projects/view`,
          RouterArgs: `${rdata.id}`,
          BadgeValue: rdata.newDocsCnt,
          labels: '' + labels
        });
      } else if (rdata.access === 2) {
        _projects2.push({
          Label: rdata.name,
          Icon: rdata.grType == 1 ? 'fa-key' : null,
          RouterLink: `projects/view`,
          RouterArgs: `${rdata.id}`,
          BadgeValue: rdata.newDocsCnt,
          labels: '' + labels
        });
      } else {
        _projects0.push({
          Label: rdata.name,
          Icon: rdata.grType == 1 ? 'fa-key' : null,
          RouterLink: `projects/view`,
          RouterArgs: `${rdata.id}`,
          BadgeValue: rdata.newDocsCnt,
          labels: '' + labels
        });
      }
    }
    // ret.push({Label: 'HOME', Icon: 'fa-chart-line', RouterLink: 'start', Childs: null, IsChildVisible: false});
    ret.push({
      Label: 'Questionnaires',
      Icon: 'fa-folder-open',
      RouterLink: null,
      Childs: _projects1,
      IsChildVisible: true
    });
    if (_projects2.length > 0) {
      ret.push({Label: 'Saved', Icon: 'fa-folder', RouterLink: null, Childs: _projects2, IsChildVisible: false});
    }
    if (_projects0.length > 0) {
      ret.push({Label: 'Archive', Icon: 'fa-folder', RouterLink: null, Childs: _projects0, IsChildVisible: false});
    }

    if (_typeAdmin > 0 || isProjectAdmin) {
      // show this menu items for project admin too
      let childs = [];
      if (_typeAdmin > 0) {
        childs.push({
          Label: 'New questionnaire',
          RouterLink: 'projects/add',
          Icon: 'fa-plus',
          Childs: null,
          IsChildVisible: false
        });
      }
      childs.push({Label: 'Users', RouterLink: 'admin/users', Childs: null, IsChildVisible: false});
      childs.push({Label: 'Dictionaries', RouterLink: 'admin/dicts', Childs: null, IsChildVisible: false});
      childs.push({Label: 'Countries', RouterLink: 'admin/countries', Childs: null, IsChildVisible: false});
      childs.push({Label: 'Tags', RouterLink: 'admin/tags', Childs: null, IsChildVisible: false});
      ret.push({
        Label: 'Settings', Icon: 'fa-users-cog', RouterLink: null, Childs: childs, IsChildVisible: false
      });
      if (_typeAdmin > 0) {
        ret.push({
          Label: 'Logs', Icon: 'fa-sitemap', RouterLink: null, Childs: [
            {Label: 'Users connects', RouterLink: 'admin/log/evt/connects', Childs: null, IsChildVisible: false},
            {Label: 'Users events', RouterLink: 'admin/log/evt/private', Childs: null, IsChildVisible: false},
            {Label: 'Public events', RouterLink: 'admin/log/evt/public', Childs: null, IsChildVisible: false},
            {Label: 'Document events', RouterLink: 'admin/log/evt/templates', Childs: null, IsChildVisible: false},
          ], IsChildVisible: false
        });
      }
    }
    return ret;
  }

  loggedIn(): boolean {
    return OAuthHelper.isLogin;
  }

  logout() {
    const isLoggedIn = this.loggedIn();
    const arrLs = [];
    const accessToken = OAuthHelper.tokenObject.access_token;
    localStorage.setItem('anonimous.user.data.test', 'test-value');
    // get all items from localStorage
    for (let i = 0; i <= localStorage.length - 1; i++) {
      const key = localStorage.key(i);
      if (key.indexOf('anonimous.user.data') !== -1) {
        arrLs.push({key, value: localStorage.getItem(key)});
      }
    }

    localStorage.clear();
    for (const i in arrLs) {
      const r: any = arrLs[i];
      localStorage.setItem(r.key, r.value);
    }
    Cookie.deleteAll('/');
    this.subjectAuthService.next(false);
    // setTimeout(() => {
    //   this.revokeToken(accessToken)
    //     .subscribe(ret => {
    //       this.subjectAuthService.next(false);
    //       if (isLoggedIn) {
    //         window.location.href = '/';
    //       }
    //     });
    // }, 100);
  }

  updateStatus(opt: MenuRefreshOpt) {
    this.subjectUserService.next(opt);
  }

  get user(): LogonUser {
    if (this.loggedIn()) {
      return OAuthHelper.tokenObject.user;
    }
    return new LogonUser();
  }

  get isAdmin(): boolean {
    // tslint:disable-next-line: variable-name
    const _user: LogonUser = this.user;
    return _user && _user.typeAdmin && _user.typeAdmin > 0;
  }

  // get orgLevel(): number {
  //   // tslint:disable-next-line: variable-name
  //   const _user: LogonUser = this.user;
  //   return _user.organization.org_level;
  // }

  set redirectUrl(url) {
    sessionStorage.setItem('DCPS_redirectUrl', url);
    this._redirectUrl = url;
  }

  get redirectUrl() {
    this._redirectUrl = sessionStorage.getItem('DCPS_redirectUrl');
    return this._redirectUrl;
  }

  get projectDataView(): boolean {
    this._projectDataView = sessionStorage.getItem('DCPS_projectDataView') === 'true';
    return this._projectDataView;
  }

  set projectDataView(value: boolean) {
    sessionStorage.setItem('DCPS_projectDataView', value + '');
    this._projectDataView = value;
  }

  set projectRegistration(value: any) {
    sessionStorage.setItem('DCPS_projectRegistration', JSON.stringify(value));
  }

  get projectRegistration(): any {
    return JSON.parse(sessionStorage.getItem('DCPS_projectRegistration'));
  }
}
