import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CustomMenuItem} from '../../../_domains/CustomMenuItem';
import {Subscription} from 'rxjs';
import {AuthService} from 'src/app/_services/auth.service';
import {CMenuRefreshType} from 'src/app/_domains/UITypes';
import {AppMessageService} from '../../../_services/app-message.service';
import {SessionStorageUtils} from '../../../_utils/ui-utils';
import {Router} from '@angular/router';
import {MessagingService} from '../../../_services/messaging.service';
import {ConfigService} from '../../../_services/config.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-privateMenu',
  templateUrl: './layout-private-menu.component.html'
})
export class LayoutPrivateMenuComponent implements OnInit, OnDestroy {
  @Input() items: CustomMenuItem[];
  @Input() className: string;
  @Input() itemClassName: string;
  @Output() closeClicked = new EventEmitter<boolean>();

  private subscriptionAuthService: Subscription;
  private updateProjectNewDocsCount: Subscription;

  constructor(private router: Router,
              private authService: AuthService,
              private configService: ConfigService,
              private messageService: AppMessageService,
              private messagingService: MessagingService) {
  }

  ngOnInit() {
    this.subscriptionAuthService = this.authService.getUserData$.subscribe(opt => {
      if (opt.opt === CMenuRefreshType.STORE_POSITION) {
        let menu = null;
        for (let i in this.items) {
          if (this.items[i].Label === 'Home') {
            menu = this.items[i];
            break;
          }
        }
        if (menu) {
          this.onMenuClick(menu);
        }
      }
    });
    this.updateProjectNewDocsCount = this.messagingService.updateProjectNewDocsCount
      .subscribe(v => {
        if (this.items) {
          this.items = this.items.map(m => {
            if (m.Label === 'Projects' && m.Childs?.length) {
              m.Childs = m.Childs.map(p => {
                if (p.RouterLink === 'projects/view' && p.RouterArgs === v.id && p.BadgeValue) {
                  p.BadgeValue -= v.cnt;
                  if (p.BadgeValue <= 0) {
                    p.BadgeValue = null;
                  }
                }
                return p;
              });
            }
            return m;
          });
        }
      });
  }

  ngOnDestroy() {
    SessionStorageUtils.removeItem('active-menu');
    this.subscriptionAuthService.unsubscribe();
    this.updateProjectNewDocsCount.unsubscribe();
  }

  // on menu click event
  onMenuClick(menu: CustomMenuItem) {
    // if child are available then open child
    if (menu.Childs != undefined || menu.Childs != null) {
      this.toggleSubMenu(menu);
      return;
    }
    if (menu.RouterLink === undefined || menu.RouterLink == null || menu.RouterLink == '') {
      this.messageService.showWarn('404 Page not found.');
      return;
    }
    SessionStorageUtils.setItem('active-menu', menu.Label);
    if (menu.RouterArgs && menu.RouterLink !== '') {
      this.router.navigate([menu.RouterLink, menu.RouterArgs], {skipLocationChange: true});
    } else {
      this.router.navigate([menu.RouterLink], {skipLocationChange: true});
    }
    // hide menu bar after menu click for mobile layout
    if (this.configService.isMobileDevice) {
      setTimeout(() => {
        this.closeClicked.emit(false);
      }, 100);
    }
  }

  // toggle sub menu on click
  toggleSubMenu(menu: CustomMenuItem) {
    menu.IsChildVisible = !menu.IsChildVisible;
  }

  get selectedItem(): string {
    const activeMenu = SessionStorageUtils.getItem('active-menu');
    if (activeMenu) {
      return activeMenu;
    }
    return 'Home';
  }

  itemCssClass(menu: any) {
    let css = menu.Childs != null ? 'menu-node' : '';
    css = `${css.length !== 0 ? css + ' ' : ''}${menu.Css ? menu.Css : ''}`;
    return this.selectedItem === menu.Label ? `active ${this.itemClassName} ${css}` : `${this.itemClassName} ${css}`;
  }

  onCloseClicked() {
    if (this.configService.isMobileDevice) {
      setTimeout(() => {
        this.closeClicked.emit(false);
      }, 100);
    }
  }
}
