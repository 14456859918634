import {Component, Input, OnInit} from '@angular/core';
import {Pageable} from '../../../_domains/spring/pageable';
import {LazyLoadEvent, SelectItem} from 'primeng/api';
import {ActivatedRoute} from '@angular/router';
import {UIUtils} from '../../../_utils/ui-utils';
import {DataAdminService} from '../_services/data-admin.service';
import {finalize} from 'rxjs/operators';
import {AppMessageService} from "../../../_services/app-message.service";
import {AuthService} from "../../../_services/auth.service";
import {LogonUser} from "../../../_services/oauth.helper";

@Component({
  selector: 'app-adminlog-private',
  templateUrl: './log-evt-private.component.html'
})
export class LogEvtPrivateComponent implements OnInit {
  private _idUser: string;
  private _data: Pageable;
  private _loading = false;
  private _currentPage: number = 0;
  private _sorting: string;
  private _iduser: string = '';
  private _iddoc: string = '';
  private _userInfo: any = {};
  private _filters: any = {};
  private _items: SelectItem[] = [];

  constructor(private dataService: DataAdminService,
              private messageService: AppMessageService,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute) {
    this._data = new Pageable();
    this.activatedRoute.params.subscribe(params => {
      if (Object.keys(params).length !== 0) {
        this._iduser = params['iduser'] || '';
        if (this._iduser.length > 0) {
          this.loadUserData();
        }
      }
    });
  }

  ngOnInit() {
    // Form typeItems
    this._items = [
      {label: 'All', value: ''},
      {label: 'Open', value: '0'},
      {label: 'Save', value: '1'},
      {label: 'Silent save', value: '2'},
      {label: 'Submit force', value: '3'},
      {label: 'Submit', value: '4'},
      {label: 'Remove', value: '5'},
      {label: 'Accept', value: '6'},
      {label: 'Decline', value: '7'}
    ];
  }

  public refreshVars(iddoc: string) {
    this._data = new Pageable();
    this._iddoc = iddoc;
    this.loadData();
  }

  @Input()
  set idUser(value: string) {
    this._idUser = value;
    if ((this._idUser && this._idUser.length) > 0) {
      this._iduser = this._idUser;
      this.loadUserData();
      this.handlePageChange({first: 0, rows: 0});
    }
  }

  private loadUserData() {
    this.dataService.getUser(this._iduser)
      .subscribe(data => {
        this._userInfo = data;
      }, error => {
        console.warn('ngOnInit', error);
      });
  }

  private loadData(filter?: string) {
    const _user: LogonUser = this.authService.user;
    const _typeAdmin: number = _user.typeAdmin;
    //
    if (_typeAdmin === 0) {
      this._loading = false;
      return;
    }
    //
    let _filter: string = filter && filter.length > 0 ? `?${filter}` : '',
      _search: string = UIUtils.getFilterStr(this._filters);
    //
    if (this._iduser.length > 0 || this._iddoc.length > 0) {
      if (this._iduser.length > 0) {
        _search += (_search.length > 0 ? ',' : '') + `iduser:${this._iduser}`;
      }
      if (this._iddoc.length > 0) {
        _search += (_search.length > 0 ? ',' : '') + `iddoc:${this._iddoc}`;
      }
    }
    if (_search.length > 0) {
      _filter += (_filter.length > 0 ? '&' : '?') + `search=${_search}`;
    }
    //
    this._loading = true;
    this.dataService.getEvtPrivate(_filter)
      .pipe(finalize(() => this._loading = false))
      .subscribe(
        data => {
          this._data = data;
        },
        error => {
          this.messageService.showMessage(error, 'Error receiving data');
        }
      );
  }

  handleRowSelect(event: any) {
    console.log('handleRowSelect', event);
  }

  handlePageChange(event: LazyLoadEvent) {
    this._data.content = [];
    this._filters = event.filters;
    this._currentPage = (event.rows == 0) ? 0 : Math.floor(event.first / event.rows);
    this._sorting = event.sortField && `&sort=${event.sortField},${event.sortOrder == 1 ? 'asc' : 'desc'}`;

    this.loadData('page=' + this._currentPage + this._sorting);
  }

  get isShowUserInformation(): boolean {
    return this._iduser.length === 0;
  }

  get isShowDocInformation(): boolean {
    return this._iddoc.length === 0;
  }

  get tableHeader(): string {
    if (this._iduser.length > 0) {
      return `Events of the user "${this._userInfo.username}", ${this._userInfo.fullname}`;
    }
    if (this._iddoc.length > 0) {
      return `Events for the document`;
    }

    return 'Users events';
  }

  get data(): Pageable {
    return this._data;
  }

  get loading(): boolean {
    return this._loading;
  }

  get items(): SelectItem[] {
    return this._items;
  }
}
