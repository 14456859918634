import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  updateProjectNewDocsCount = new Subject<{ id: string, cnt: number }>();
  stageDataUploadRecordUpdate = new Subject<string>();
  templateRecordUpdate = new Subject<string>();

  constructor() {
  }
}
